/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ firstName, lastName, role, userId }) => ({
      url: `users/${userId}`,
      method: 'PUT',
      body: {
        firstName: firstName,
        lastName: lastName,
        // emailId: emailId,
        role: role,
      },
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['users'],
  });
