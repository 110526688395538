/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ collectionId, data }) => {
      const formData = new FormData();
      // const data = {};
      // data.semanticEnabled = isSemanticSearchEnabled;
      // data.retrieverRatio = retrieverRatio;
      // data.finalTopK = finalTopK;

      formData.append('data', JSON.stringify(data));

      return {
        url: `file-collections/${collectionId}`,
        method: 'PATCH',
        body: formData,
      };
    },
    invalidatesTags: ['individualFileCollections', 'fileCollections'],
  });
