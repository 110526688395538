/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { styled, InputBase, Grid, ListItemButton, Button } from '@mui/material';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.secondary.main}`,
}));

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#E0E7F3',
  marginLeft: 0,
  width: '100%',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

export const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected	': {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const StyledButton = styled(Button)(({ theme, sorted }) => ({
  color: sorted ? theme.palette.primary.main : '#97A3B4',
  borderColor: 'transparent',
  ':hover': {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
}));
