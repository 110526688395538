import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { object, string, number, array, bool } from 'yup';

import {
  AlertStyled,
  DescriptionStyled,
  FormContainer,
  InputsContainer,
  PageHeader,
  ToogleButtonsContainer,
} from './styled';
import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import { LOAD_TITLE, stringsToArray } from '../../../utils/functions';
import Loader from '../../../components/loader';
import AppForm, {
  ToogleContainer,
  AppAutocompleteField,
  AppTextField,
  AppSelectField,
  FormButtons,
} from '../../../components/form';
import {
  useLazyGetFileCollectionSettingsQuery,
  useUpdateFileCollectionSettingsMutation,
  useLazyGetIndividualFileCollectionQuery,
} from '../../../redux/services/speciphicAsk';
import { PREPROCESSOR_CONFIG as API_ENDPOINT } from '../../../constants/filesCollectionSettings';
import { getChangedValues } from '../../../components/form/functions';
import UnauthorizedMessage from '../../../components/unauthorized';
import { Chip } from '@mui/material';

const translationJSONPrefix = 'filesCollectionSettingsPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const FILES_COLLECTION = `${translationJSONPrefix}.filesCollection`;
const PAGE_TITLE_SUFFIX = `${translationJSONPrefix}.pageTitleSuffix`;

const PAGE_TITLE = (t) =>
  `${t(`${translationJSONPrefix}.preprocessorConfig.drawerMenuTitle`)} | ${t(
    FILES_COLLECTION,
  )} | ${t(PAGE_TITLE_SUFFIX)}`;

const defaultDataState = {
  cleanEmptyLines: false,
  cleanWhitespace: false,
  cleanHeaderFooter: false,
  splitBy: '',
  splitLength: '',
  splitOverlap: '',
  splitRespectSentenceBoundary: false,
  language: [],
  progressBar: false,
  addPageNumber: false,
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.preprocessorConfig.form.${label}.errorMessages`;

  return object({
    cleanEmptyLines: bool(),
    cleanWhitespace: bool(),
    cleanHeaderFooter: bool(),
    splitBy: string(),
    splitLength: number().min(0, t(`${translationPrefix('splitLength')}.min`)),
    splitOverlap: number().min(0, t(`${translationPrefix('splitLength')}.min`)),
    splitRespectSentenceBoundary: bool(),
    language: array(),
    progressBar: bool(),
    addPageNumber: bool(),
  });
};

const splitByOptions = [
  { label: 'Token', value: 'token' },
  { label: 'Word', value: 'word' },
  { label: 'Sentence', value: 'sentence' },
  { label: 'Passage', value: 'passage' },
  { label: 'Page', value: 'page' },
];

const languageOptions = [
  { label: 'Russian', value: 'ru' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Spanish', value: 'es' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Estonian', value: 'et' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Italian', value: 'it' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Malayalam', value: 'ml' },
];

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.preprocessorConfig.form`;

  return (
    <Form>
      <ToogleButtonsContainer style={{ justifyContent: 'space-between' }}>
        <ToogleContainer
          width="30%"
          name="cleanEmptyLines"
          label={t(`${translationPrefix}.cleanEmptyLines.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <ToogleContainer
          width="30%"
          name="cleanWhitespace"
          label={t(`${translationPrefix}.cleanWhitespace.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <ToogleContainer
          width="30%"
          name="cleanHeaderFooter"
          label={t(`${translationPrefix}.cleanHeaderFooter.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </ToogleButtonsContainer>

      <ToogleButtonsContainer>
        <ToogleContainer
          width="30%"
          name="splitRespectSentenceBoundary"
          label={t(`${translationPrefix}.splitRespectSentenceBoundary.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <ToogleContainer
          width="30%"
          name="progressBar"
          label={t(`${translationPrefix}.progressBar.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <ToogleContainer
          width="30%"
          name="addPageNumber"
          label={t(`${translationPrefix}.addPageNumber.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </ToogleButtonsContainer>

      <InputsContainer>
        <AppSelectField
          style={{ width: '30%' }}
          name="splitBy"
          label={t(`${translationPrefix}.splitBy.label`)}
          placeholder={t(`${translationPrefix}.splitBy.placeholder`)}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          menuData={splitByOptions}
        />
        <AppTextField
          style={{ width: '30%' }}
          name="splitLength"
          label={t(`${translationPrefix}.splitLength.label`)}
          placeholder={t(`${translationPrefix}.splitLength.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          type="number"
        />
        <AppTextField
          style={{ width: '30%' }}
          name="splitOverlap"
          label={t(`${translationPrefix}.splitOverlap.label`)}
          placeholder={t(`${translationPrefix}.splitOverlap.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          type="number"
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="language"
          label={t(`${translationPrefix}.language.label`)}
          placeholder={t(`${translationPrefix}.language.placeholder`)}
          options={languageOptions}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          values={{
            ...values,
            language: stringsToArray(values.language),
          }}
          renderInput={(params) => (
            <AppTextField {...params} variant="outlined" />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={index}
                label={option?.label}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </InputsContainer>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const PreprocessorConfigPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => LOAD_TITLE(document, PAGE_TITLE(t)), []);

  const [collectionId, setCollectionId] = useState();
  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [
    getFormData,
    {
      data: formDataDB,
      error: formError,
      isError: isGetFormError,
      isLoading: isGetFormLoading,
      isSuccess: isGetFormSuccess,
    },
  ] = useLazyGetFileCollectionSettingsQuery();

  const [
    getFilesCollectionInfo,
    {
      data: filesCollectionInfo,
      error: filesCollectionError,
      isError: isGetFCError,
      isLoading: isGetFCLoading,
      isSuccess: isGetFCSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  useEffect(() => {
    if (params?.collectionId) {
      setCollectionId(params?.collectionId);
      getFilesCollectionInfo({ collectionId: params?.collectionId });
      getFormData({
        collectionId: params.collectionId,
        endpoint: API_ENDPOINT,
      });
    }
  }, [params?.collectionId]);

  const [
    updateSettings,
    {
      data: formDataUpdateDB,
      isError: isUpdateFormError,
      isLoading: isUpdateFormLoading,
      isSuccess: isUpdateFormSuccess,
    },
  ] = useUpdateFileCollectionSettingsMutation();

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    tempAlert.formUpdateError = isUpdateFormError;
    setShowAlert(tempAlert);
  }, [isUpdateFormError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    tempAlert.formUpdateSuccess = isUpdateFormSuccess;
    setShowAlert(tempAlert);
  }, [isUpdateFormSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  useEffect(() => {
    if (isGetFormSuccess) {
      const {
        addPageNumber = false,
        cleanEmptyLines = false,
        cleanHeaderFooter = false,
        cleanWhitespace = false,
        language = '',
        progressBar = false,
        splitBy = '',
        splitLength = '',
        splitOverlap = '',
        splitRespectSentenceBoundary = false,
      } = formDataDB;

      const languageCodes = language.split(',');

      // Map each language code to its corresponding label from languageOptions
      const selectedLanguages = languageCodes.map((code) => ({
        label: languageOptions.find((opt) => opt.value === code)?.label || '',
        value: code,
      }));

      const data = {
        addPageNumber,
        cleanEmptyLines,
        cleanHeaderFooter,
        cleanWhitespace,
        language: selectedLanguages,
        progressBar,
        splitBy,
        splitLength,
        splitOverlap,
        splitRespectSentenceBoundary,
      };

      setFormData(data);
      setInitialFormData(data);
    }
  }, [formDataDB]);

  if (formError?.status === 403 || filesCollectionError?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
      collectionId={collectionId}
    >
      <PageHeader>
        {`${filesCollectionInfo?.name || '...'} / ${t(
          `${translationJSONPrefix}.preprocessorConfig.pageHeader`,
        )}`}
      </PageHeader>

      <DescriptionStyled>{collectionId}</DescriptionStyled>

      {collectionId && !isGetFormLoading ? (
        <FormContainer>
          <AlertStyled
            onClose={closeAlert}
            severity={showAlert.formUpdateError ? 'error' : 'success'}
            style={{
              marginTop:
                showAlert.formUpdateError || showAlert.formUpdateSuccess
                  ? 10
                  : -60,
            }}
          >
            {showAlert.formUpdateError &&
              t(
                `${translationJSONPrefix}.preprocessorConfig.alert.updateFormError`,
              )}
            {showAlert.formUpdateSuccess &&
              t(
                `${translationJSONPrefix}.preprocessorConfig.alert.updateFormSuccess`,
              )}
          </AlertStyled>

          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSend = getChangedValues(values, initialFormData);
              if (valuesToSend?.language) {
                valuesToSend.language = valuesToSend.language
                  .map((l) => l.value) // Extract `value` from each object
                  .join(',');
              }

              updateSettings({
                collectionId,
                data: valuesToSend,
                endpoint: API_ENDPOINT,
              });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(formikState, isUpdateFormLoading)
            }
          />
        </FormContainer>
      ) : (
        <Loader label={t(`${translationJSONPrefix}.loadingMessage`)} />
      )}
    </PageContainer>
  );
};

export default PreprocessorConfigPage;
