/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import PropTypes from 'prop-types';
import { Typography, LinearProgress, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ParentLayoutBox, StyledBox } from './askDbLayout.styled';
import MiConversationList from '../miConversationList';

const AskDbLayout = ({
  isGetConversationHistoryLoading,
  conversationHistory,
  stockKey,
  isConversationLoading,
}) => {
  const { t } = useTranslation();
  return (
    <ParentLayoutBox>
      {conversationHistory === null ? (
        <LinearProgress />
      ) : (
        <>
          {conversationHistory && conversationHistory.length > 0 ? (
            <MiConversationList
              conversationHistory={conversationHistory}
              isConversationLoading={isConversationLoading}
            />
          ) : (
            <StyledBox>
              <>
                {stockKey?.length > 0 ? (
                  <>
                    {' '}
                    <Typography variant="h4">{stockKey}</Typography>
                    <Typography variant="body1">
                      <>
                        <li>
                          {t(
                            'newMarketIntelligencePage.reports.dbLayout.getStartedText',
                          )}
                        </li>
                        <li>
                          {t(
                            'newMarketIntelligencePage.reports.dbReportLayout.searchReportText',
                          )}
                        </li>
                        <li>
                          {t(
                            'newMarketIntelligencePage.reports.dbLayout.detailsText',
                          )}
                        </li>
                      </>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h4">
                      {t(
                        'newMarketIntelligencePage.reports.dbLayout.welcomeText',
                      )}
                    </Typography>
                    <Typography variant="body1">
                      <>
                        <li>
                          {t(
                            'newMarketIntelligencePage.reports.dbLayout.getStartedText',
                          )}
                        </li>
                        <li>
                          {t(
                            'newMarketIntelligencePage.reports.dbLayout.searchReportText',
                          )}
                        </li>
                        <li>
                          {t(
                            'newMarketIntelligencePage.reports.dbLayout.detailsText',
                          )}
                        </li>
                      </>
                    </Typography>
                    <Typography variant="h6">
                      {t(
                        'newMarketIntelligencePage.reports.dbLayout.endComment',
                      )}{' '}
                    </Typography>
                  </>
                )}
              </>
            </StyledBox>
          )}{' '}
        </>
      )}
    </ParentLayoutBox>
  );
};

AskDbLayout.propTypes = {
  isGetConversationHistoryLoading: PropTypes.bool,
  conversationHistory: PropTypes.array,
  stockKey: PropTypes.string,
  isConversationLoading: PropTypes.bool,
};

export default AskDbLayout;
