/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

import { Box, styled } from '@mui/material';

export const MarkdownWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
}));

export const getDefaultStyles = (theme) => {
  return {
    p: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.primary,
      textTransform: theme.typography.textTransform,
      fontFamily: theme.typography.fontFamily,
      margin: 0,
    },
    h1: {
      fontSize: theme.typography.h1.fontSize,
      fontWeight: theme.typography.h1.fontWeight,
      color: theme.typography.h1.color,
      lineHeight: theme.typography.h1.lineHeight,
    },
    h2: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
      color: theme.typography.h2.color,
      lineHeight: theme.typography.h1.lineHeight,
    },
    h3: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
      color: theme.typography.h3.color,
      lineHeight: theme.typography.h1.lineHeight,
      margin: 0,
    },
    h4: {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.h4.fontWeight,
      color: theme.typography.h4.color,
      lineHeight: theme.typography.h4.lineHeight,
    },
    h5: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.h5.fontWeight,
      color: theme.typography.h5.color,
      lineHeight: theme.typography.h5.lineHeight,
    },
    h6: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.h6.fontWeight,
      color: theme.typography.h6.color,
      lineHeight: theme.typography.h6.lineHeight,
    },
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    strong: {
      fontWeight: theme.typography.fontWeightBold,
    },
    table: {
      border: '1px solid #e0e0e0',
      borderCollapse: 'collapse',
      width: '100%',
    },
    td: {
      border: '1px solid #e0e0e0',
      padding: '8px',
    },
    th: {
      border: '1px solid #e0e0e0',
      padding: '8px', // Adjust padding as needed
      backgroundColor: theme.palette.grey[200],
    },
  };
};
