/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { Box, styled, IconButton, Button, Drawer } from '@mui/material';
export const ChatHistoryContainer = styled(Box)(({ theme }) => ({
  paddingTop: '70px',
  paddingBottom: '70px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const ChatHistoryCloseArrow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '7px',
  paddingTop: '7px',
  paddingBottom: '7px',
}));

export const CloseArrowButton = styled(Button)(({ theme }) => ({}));

export const TextContainer = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const SideBar = styled(Drawer)(({ theme }) => ({
  width: '300px',
  height: '100%',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '300px',
    boxSizing: 'border-box',
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0)',
  },
}));
