/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Toolbar,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  ProgressBar,
  StyledGrid,
  StyledPagination,
  StyledTypography,
} from './miRelatedEvents.styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useGetMiRelatedEventsQuery } from '../../../redux/services/speciphicAsk';

const MiRelatedEvents = ({ parentHeight }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [eventsPerPage, setEventsPerPage] = useState(10);
  const [paginationMeta, setPaginationMeta] = useState({
    startIndex: 0,
    endIndex: 15,
    currentPage: 1,
    noOfPages: 1,
  });
  const eventRef = useRef(null);

  const {
    data: events = [],
    isError: isGetMiEventsError,
    isLoading: isGetMiEventsLoading,
    isSuccess: isGetMiEventsSuccess,
    isFetching: isGetMiEventsFetching,
  } = useGetMiRelatedEventsQuery();

  useEffect(() => {
    const calculateEventsPerPage = () => {
      if (parentHeight && eventRef.current) {
        const availableHeight = parentHeight;
        const eventHeight = 140;
        const itemsPerPage = Math.floor(availableHeight / eventHeight);
        setEventsPerPage(itemsPerPage);
        setPaginationMeta((prevMeta) => ({
          ...prevMeta,
          endIndex: itemsPerPage,
          noOfPages: Math.ceil(events.length / itemsPerPage),
        }));
      }
    };

    calculateEventsPerPage();
  }, [parentHeight, events]);

  useEffect(() => {
    if (isGetMiEventsSuccess) {
      setPaginationMeta({
        startIndex: 0,
        endIndex: eventsPerPage,
        currentPage: 1,
        noOfPages: Math.ceil(events.length / eventsPerPage),
      });
    }
  }, [events, eventsPerPage, isGetMiEventsSuccess]);

  const handleClick = (e, page) => {
    setPaginationMeta((paginationMeta) => ({
      ...paginationMeta,
      startIndex: (page - 1) * eventsPerPage,
      endIndex: page * eventsPerPage,
      currentPage: page,
    }));
  };

  if (isGetMiEventsLoading || isGetMiEventsFetching) {
    return <ProgressBar />;
  }

  return (
    <Box elevation={0}>
      <StyledGrid container direction={'column'}>
        <Grid item container direction={'row'} justifyContent={'space-between'}>
          <Grid item>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', padding: 2 }}
              color={theme.palette.text.primary}
            >
              {t('newMarketIntelligencePage.reportStats.relatedEvents')}
            </Typography>
          </Grid>
        </Grid>

        <Divider flexItem style={{ width: '100%' }} />

        <Grid item container sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {events
            ?.slice(paginationMeta?.startIndex, paginationMeta?.endIndex)
            .map((event, index) => (
              <List key={index} sx={{ width: '100%' }}>
                <ListItem disablePadding ref={index === 0 ? eventRef : null}>
                  <ListItemAvatar style={{ padding: 5 }}>
                    <img
                      height={80}
                      width={90}
                      src={event?.urlToImage}
                      alt="event"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <StyledTypography
                        paddingLeft={1}
                        paddingRight={1}
                        variant="h6"
                        color={theme.palette.text.primary}
                      >
                        {event?.title}
                      </StyledTypography>
                    }
                    secondary={
                      <StyledTypography
                        paddingLeft={1}
                        paddingRight={1}
                        variant="body2"
                        color={theme.palette.secondary.light}
                      >
                        {event?.description}
                      </StyledTypography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            ))}
        </Grid>
        {/* <Divider flexItem style={{ width: '100%' }} /> */}
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'center'}
          p={{ lg: 1 }}
        >
          <Grid item xs={12}>
            <Toolbar>
              <StyledPagination
                siblingCount={0}
                count={paginationMeta.noOfPages}
                variant="outlined"
                shape="rounded"
                onChange={handleClick}
                size="small"
                page={paginationMeta.currentPage}
              />
            </Toolbar>
          </Grid>
        </Grid>
      </StyledGrid>
    </Box>
  );
};

MiRelatedEvents.propTypes = {
  parentHeight: PropTypes.any,
};

export default MiRelatedEvents;
