/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { styled, Grid, Button } from '@mui/material';

export const StyledModalGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 600,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[24],
  padding: theme.spacing(10),
}));

export const StyledSaveButton = styled(Button)(({ theme }) => ({
  width: '100%',
  ml: 2,
  margin: `${theme.spacing(4)} ${theme.spacing(1.2)} `,
}));

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  width: '100%',
  ml: 2,
  margin: `${theme.spacing(4)} ${theme.spacing(1.2)} `,
}));
