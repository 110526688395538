/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Button, Icon, styled, Typography, Box } from '@mui/material';

export const CookieBox = styled(Box)(({ theme }) => ({
  padding: 3,
  margin: 16,
  borderRadius: theme.spacing(1),
  textAlign: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.pageBackground,
  position: 'absolute',
  bottom: 0,
  left: 0,
  border: `0.5px dotted ${theme.palette.primary.main}`,
  maxHeight: 'fit-content',
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '60%',
  },
  whiteSpace: 'normal',
}));

export const CookieHeadingText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'inline-flex',
  },
  color: theme.palette.text.primary,
  marginBottom: 3,
}));

export const CookieWelcomeText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  margin: 1,
  padding: 2,
}));

export const CookieDocumentText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.light,
  margin: 2,
  padding: 2,
}));

export const CookiePageLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.light,
}));

export const CookieButtons = styled(Button)(() => ({
  margin: 10,
}));

export const CookieIconWrapper = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'relative',
  top: theme.spacing(0.5),
}));
