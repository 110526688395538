import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from '../settings/fileUploaderPage/fileUploader.page.styled';
import SubmitButtonGroup from './submitButtonGroup';
import PropTypes from 'prop-types';

const ServiceNowForm = (props) => {
  const [inputValues, setInputValues] = useState({
    name: '',
    accountType: props.storageContainer,
    params: {
      instanceName: '',
      userName: '',
      password: '',
      queryParams: '',
    },
  });
  const [validationErrorLabel, setValidationErrorLabel] = useState('');

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevState) => ({
      ...prevState,
      ...(fieldName.includes('.')
        ? { params: { ...prevState.params, [fieldName.split('.')[1]]: value } }
        : { [fieldName]: value }),
    }));
  };

  const clearValues = () => {
    setInputValues({
      name: '',
      type: props.storageContainer,
      params: {
        instanceName: '',
        userName: '',
        password: '',
        queryParams: '',
      },
    });
  };

  const { t } = useTranslation();
  return (
    <>
      <InputField
        size="small"
        required
        fullWidth
        name="name"
        label="Name"
        value={inputValues.name}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="instanceName"
        label="Instance Name"
        value={inputValues.params.instanceName}
        onChange={(e) =>
          handleInputChange('params.instanceName', e.target.value)
        }
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="userName"
        label="Username"
        value={inputValues.params.userName}
        onChange={(e) => handleInputChange('params.userName', e.target.value)}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="password"
        type="password"
        label="Password"
        password
        value={inputValues.params.password}
        onChange={(e) => handleInputChange('params.password', e.target.value)}
      />

      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="queryParams"
        label="Query Params"
        value={inputValues.params.queryParams}
        onChange={(e) =>
          handleInputChange('params.queryParams', e.target.value)
        }
      />
      <SubmitButtonGroup
        data={inputValues}
        onClear={clearValues}
        errorMessage={validationErrorLabel}
      />
    </>
  );
};

ServiceNowForm.propTypes = {
  storageContainer: PropTypes.string,
};

export default ServiceNowForm;
