/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Home, SentimentDissatisfied } from '@mui/icons-material';
import { Chip, Icon, Typography } from '@mui/material';
import { ErrorTypography, StyledPageWrapper } from './errorFallback.styled';
import { ReactComponent as Error500Poster } from '../../assets/error-500-poster.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PageContainer from '../../components/pageContainer';

const ErrorFallBackPage = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <StyledPageWrapper>
        <Error500Poster
          alt={t('errorPage.altText')}
          style={{ width: '100%', height: '100%' }}
        />
        <ErrorTypography variant="h3">
          {t('errorPage.message1')}
        </ErrorTypography>
        <Typography variant="h5" color={'error'} gutterBottom>
          {t('errorPage.message2')}
          <br />
          {t('errorPage.message3')}
        </Typography>
        <Icon color="error">
          <SentimentDissatisfied />
        </Icon>
        <ErrorTypography variant="overline" display="block">
          {t('errorPage.error', { error: error.message })}
        </ErrorTypography>
        <Chip
          label={t('errorPage.buttonText')}
          component="a"
          href="/"
          clickable
          color="primary"
          icon={<Home />}
        />
      </StyledPageWrapper>
    </PageContainer>
  );
};

ErrorFallBackPage.propTypes = {
  error: PropTypes.any,
  resetErrorBoundary: PropTypes.any,
};

export default ErrorFallBackPage;
