/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import * as React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Grid, FormLabel } from '@mui/material';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  StyledFormControl,
  StyledDesktopDatePicker,
} from './dateTextField.styled';

const DateTextField = ({
  label,
  value,
  required,
  disabled,
  onChange,
  isLabelAndFieldOnHorizontalLine = false,
}) => {
  const handleChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    onChange(formattedDate);
  };
  return (
    <StyledFormControl variant="standard" fullWidth>
      <Grid
        display={isLabelAndFieldOnHorizontalLine ? 'flex' : 'block'}
        justifyContent="space-between"
      >
        <Grid xs={isLabelAndFieldOnHorizontalLine ? 3 : 12} sx={{ my: 0.5 }}>
          <FormLabel required={required} sx={{ fontSize: 16 }}>
            {label}
          </FormLabel>
        </Grid>
        <Grid xs={isLabelAndFieldOnHorizontalLine ? 9 : 12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem>
              <StyledDesktopDatePicker
                value={value ? dayjs(value) : null}
                onChange={handleChange}
                defaultValue={dayjs()}
                disabled={disabled}
              />
            </DemoItem>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

DateTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLabelAndFieldOnHorizontalLine: PropTypes.bool,
};

export default DateTextField;
