/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
const FilterChip = ({
  filters,
  onFiltersChange,
  filterData,
  onChangeFilterData,
  definedMetaProperties,
}) => {
  const handleDelete = (deleteFilterIndex, filterType, filterValue) => {
    onFiltersChange(
      filters.filter((filter, index) => index !== deleteFilterIndex),
    );
    onChangeFilterData(() => {
      const updatedFilterData = Object.fromEntries(
        Object.entries(filterData).filter(([key]) => key !== filterType),
      );
      return updatedFilterData;
    });
  };
  return (
    <Stack direction="row" spacing={1}>
      {filters &&
        filters?.map((filter, index) =>
          Object.entries(filter).map(([filterType, filterValue]) => (
            <Chip
              key={index}
              label={`${definedMetaProperties[filterType].displayName} : ${filterValue}`}
              onDelete={() => handleDelete(index, filterType, filterValue)}
            />
          )),
        )}
    </Stack>
  );
};

FilterChip.propTypes = {
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
  filterData: PropTypes.object,
  onChangeFilterData: PropTypes.func,
  definedMetaProperties: PropTypes.object,
};

export default FilterChip;
