/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Box, Icon, Grid } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  StyledDataGrid,
  StyledFileWrapper,
  PaginationTextTypography,
} from './metadataGrid.styled';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { RowPerPageOptions } from '../../constants/rowPerPageOptions';
import { IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DropdownOne from '../dropdownone';

const MetaDataGrid = ({
  files,
  definedMetaProperties,
  pageSize,
  onPageSizeChange,
  pageNo,
  onPageNoChange,
  totalPages,
  isMainSearchPage = false,
  noRowsMessage = 'No Rows',
}) => {
  const { t } = useTranslation();

  const fileNameColumn = (props) => {
    return (
      <StyledFileWrapper>
        <Icon
          component={FileCopyOutlinedIcon}
          sx={{ marginRight: 1, fontSize: 'medium' }}
        />
        {props.row.fileName}
        <br />
      </StyledFileWrapper>
    );
  };

  const calculateColumnWidth = (propertyName) => {
    switch (propertyName) {
      case t('searchMetadataPage.components.metadataGrid.columns.author.field'):
        return 200;
      case t(
        'searchMetadataPage.components.metadataGrid.columns.country.field',
      ):
        return 300;
      case t(
        'searchMetadataPage.components.metadataGrid.columns.created.field',
      ):
        return 180;
      case t('searchMetadataPage.components.metadataGrid.columns.status.field'):
        return 150;
      default:
        return 180;
    }
  };

  const columns = [
    {
      field: t(
        'searchMetadataPage.components.metadataGrid.columns.fileName.field',
      ),
      headerName: t(
        'searchMetadataPage.components.metadataGrid.columns.fileName.header',
      ),
      flex: 1,
      minWidth: 400,
      renderCell: fileNameColumn,
    },
    ...Object.entries(definedMetaProperties).map(
      ([propertyName, property]) => ({
        field: propertyName,
        headerName: property.displayName,
        width: calculateColumnWidth(propertyName),
        align: 'left',
      }),
    ),
  ];

  const transformRows = (files, columns) => {
    return files.map((row) => {
      const newRow = {};
      // Extract the propertyValue for each field
      columns.forEach((column) => {
        const field = column.field;
        if (
          field ===
          t('searchMetadataPage.components.metadataGrid.columns.fileName.field')
        ) {
          newRow[field] = row[field];
        } else {
          newRow[field] = row[field]?.propertyValue || 'NA';
        }
      });

      return newRow;
    });
  };

  const transformedRows =
    files?.length > 0 ? transformRows(files, columns) : files;

  const customNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <Grid>{noRowsMessage}</Grid>
      </GridOverlay>
    );
  };
  const CustomPagination = () => (
    <Stack direction="row" spacing={3} alignItems="center">
      <Grid container alignItems="center" spacing={1} sx={{ pb: 1 }}>
        <Grid item>
          <Typography variant="body1">
            {' '}
            {t(
              'searchMetadataPage.components.metadataGrid.pagination.rowsPerPage',
            )}
          </Typography>
        </Grid>
        <Grid item alignItems="center">
          <DropdownOne
            value={`${pageSize}`}
            onChange={(value) => onPageSizeChange(value)}
            label=""
            items={RowPerPageOptions}
          />
        </Grid>
      </Grid>

      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => onPageNoChange(pageNo - 1)}
          disabled={pageNo === 1}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <Stack
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <PaginationTextTypography>
            {(pageNo - 1) * pageSize + 1}
          </PaginationTextTypography>
          <PaginationTextTypography> - </PaginationTextTypography>
          <PaginationTextTypography>
            {Math.min(pageNo * pageSize, totalPages)}
          </PaginationTextTypography>
          <PaginationTextTypography>
            {t('searchMetadataPage.components.metadataGrid.pagination.of')}
          </PaginationTextTypography>
          <PaginationTextTypography>
            {isMainSearchPage ? pageSize : totalPages}
          </PaginationTextTypography>
        </Stack>

        <IconButton
          onClick={() => onPageNoChange(pageNo + 1)}
          disabled={
            isMainSearchPage
              ? (pageNo + 1) * pageSize >= totalPages
              : transformedRows.length !== pageSize
          }
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );

  return (
    <StyledDataGrid
      autoHeight
      rows={transformedRows}
      columns={columns}
      disableSelectionOnClick
      disableColumnMenu
      getRowId={(row) => row.fileName}
      components={{
        Pagination: CustomPagination,
        NoRowsOverlay: customNoRowsOverlay,
      }}
    />
  );
};

MetaDataGrid.propTypes = {
  row: PropTypes.object,
  files: PropTypes.array,
  allMetaDataFiles: PropTypes.array,
  definedMetaProperties: PropTypes.object,
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  pageNo: PropTypes.number,
  onPageNoChange: PropTypes.func,
  totalPages: PropTypes.string,
  isMainSearchPage: PropTypes.bool,
  noRowsMessage: PropTypes.string,
};

export default MetaDataGrid;
