/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomToolbar from '../components/customToolbar';
import Header from '../../../components/form/header.component';
import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import { ACCESS_CONTROL } from '../../../constants/path';
import { ADD_PAGE, USERS } from '../../../constants/accessControlPaths';
import { NoDataTitle, TableStyled } from '../styled';
import {
  useDeleteManyRBACMutation,
  useLazyGetAllRBACQuery,
} from '../../../redux/services/speciphicAsk';
import { RBAC_USERS_ENDPOINT } from '../../../constants/accessControl';
import Loader from '../../../components/loader/loader.component';
import DisplayTime from '../../../components/timezone.component';
import { sortByDateField } from '../../../utils/functions';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import UnauthorizedMessage from '../../../components/unauthorized';
import DialogComponent from '../../../components/app.dialog';

const addPageRoute = `${ACCESS_CONTROL}${USERS.endpoint}${ADD_PAGE.endpoint}`;

const translationJSONPrefix = `accessControlSection.users.mainPage`;
const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.table.columnHeaders`;

  return [
    {
      field: 'name',
      headerName: t(`${translationPrefix}.name`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) =>
        `${params.row?.firstName || '-'} ${params.row?.lastName || ''}`,
    },
    {
      field: 'emailId',
      headerName: t(`${translationPrefix}.emailId`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.emailId || '-'}`,
    },
    {
      field: 'sub',
      headerName: t(`${translationPrefix}.sub`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.sub || '-'}`,
    },
    {
      field: 'groups',
      headerName: t(`${translationPrefix}.groups`),
      flex: 0.2,
      minWidth: 80,
      valueGetter: (params) => `${params.row?.groupCount}`,
    },
    {
      field: 'roles',
      headerName: t(`${translationPrefix}.roles`),
      flex: 0.2,
      minWidth: 80,
      valueGetter: (params) => `${params.row?.roleCount}`,
    },
    {
      field: 'policies',
      headerName: t(`${translationPrefix}.policies`),
      flex: 0.2,
      minWidth: 80,
      valueGetter: (params) => `${params.row?.policyCount}`,
    },
    {
      field: 'createdBy',
      headerName: t(`${translationPrefix}.createdBy`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.createdBy}`,
    },
    {
      field: 'createdAt',
      headerName: t(`${translationPrefix}.createdAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => <DisplayTime time={params.row?.createdDate} />,
    },
    {
      field: 'updatedAt',
      headerName: t(`${translationPrefix}.updatedAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => (
        <DisplayTime time={params.row?.lastUpdatedDate} />
      ),
    },
  ];
};

const AccessControlUsersGroupsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogTFValue, setDeleteDialogTFValue] = useState('');
  const [dialogDeleteButtonDisabled, setDialogDeleteButtonDisabled] =
    useState(true);

  const [getAllRBAC, { data, error, isError, isLoading, isSuccess }] =
    useLazyGetAllRBACQuery();

  const [
    deleteRBACs,
    {
      data: deletedResponse,
      error: deleteError,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteManyRBACMutation();

  useEffect(() => {
    setDialogDeleteButtonDisabled(true);
    setDeleteDialogTFValue('');
  }, [deleteDialogOpen]);

  useEffect(() => {
    if (deleteError?.status === 403) {
      dispatch(
        pushToast({
          message: t(`${unauthorizedTranslationPrefix}.delete`),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteError]);

  useEffect(() => {
    getAllRBAC({
      endpoint: RBAC_USERS_ENDPOINT,
    });

    setSelectedUsers([]);
  }, [deletedResponse]);

  useEffect(() => {
    setDeleteDialogOpen(false);
  }, [isDeleteSuccess]);

  const handleCellClick = (e) => {
    if (e?.field === 'name' || e?.field === 'emailId' || e?.filed === 'sub')
      navigate(`${addPageRoute}?id=${e?.id}`);
  };

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.ACCESS_CONTROL}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.ACCESS_CONTROL}
    >
      <DialogComponent
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        setTextFieldValue={setDeleteDialogTFValue}
        textField={{
          name: 'name',
          value: deleteDialogTFValue,
          onChange: (e) => {
            setDeleteDialogTFValue(e.target.value);

            if (selectedUsers.length > 1) {
              if (e.target.value === 'permanently delete')
                setDialogDeleteButtonDisabled(false);
              return;
            }

            setDialogDeleteButtonDisabled(
              !(e.target.value === selectedUsers[0]),
            );
          },
          placeholder:
            selectedUsers.length > 1
              ? t(
                  `accessControlSection.users.deleteDialog.textFieldPlaceholderPlural`,
                )
              : t(
                  `accessControlSection.users.deleteDialog.textFieldPlaceholder`,
                ),
        }}
        title={`${t(`accessControlSection.users.deleteDialog.title`)}`}
        content={
          selectedUsers.length > 1
            ? t(`accessControlSection.users.deleteDialog.contentPlural`)
            : `${t(`accessControlSection.users.deleteDialog.content`)}`
        }
        highlightedTitle={
          selectedUsers.length === 1
            ? t(`accessControlSection.users.deleteDialog.highlightedTitle`)
            : ''
        }
        highlightedContent={selectedUsers.length === 1 ? selectedUsers[0] : ''}
        onSubmit={(e) => {
          deleteRBACs({
            endpoint: RBAC_USERS_ENDPOINT,
            ids: selectedUsers,
          });
        }}
        submitBtnDisabled={dialogDeleteButtonDisabled}
        isDeleteLoading={isDeleteLoading}
        cancelBtnLabel={t(
          `accessControlSection.users.deleteDialog.buttons.cancel`,
        )}
        submitBtnLabel={t(
          `accessControlSection.users.deleteDialog.buttons.delete`,
        )}
      />
      <Header
        header={t(`${translationJSONPrefix}.title`)}
        addButtonLabel={t(`${translationJSONPrefix}.addButtonLabel`)}
        deleteButton={true}
        deleteButtonDisabled={selectedUsers.length === 0}
        addButtonOnClick={() => navigate(addPageRoute)}
        deleteButtonOnClick={() => setDeleteDialogOpen(true)}
        isDeleteLoading={isDeleteLoading}
      />
      {isLoading ? (
        <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
      ) : data?.length > 0 ? (
        <TableStyled
          onCellClick={handleCellClick}
          rows={sortByDateField([...data], 'lastUpdatedDate')}
          columns={columns(t)}
          pageSize={10}
          checkboxSelection
          autoHeight
          onSelectionModelChange={setSelectedUsers}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      ) : (
        <NoDataTitle>{t(`${translationJSONPrefix}.noDataLabel`)}</NoDataTitle>
      )}
    </PageContainer>
  );
};

export default AccessControlUsersGroupsPage;
