/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  Box,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import {
  ArchiveBox,
  ParentLayoutBox,
  StyledFormControl,
  StyledSwitch,
} from './viewReportLayout.styled';
import DisplayTime from '../../../../../components/timezone.component';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const translationJSONPrefix = 'newMarketIntelligencePage';

const ViewReportLayout = ({
  isGetFinancialReportFetching,
  isGetFinancialReportSuccess,
  financialReport,
  handleReportChange,
  allFinancialReports,
  reportData,
  showArchives,
  handleShowArchives,
}) => {
  const { t } = useTranslation();

  const [archiveData, setArchiveData] = useState({});
  useEffect(() => {
    if (reportData) {
      const archive = allFinancialReports
        ?.filter((report) => report.status !== 'ERROR')
        ?.find(
          (report) => report?.lastUpdatedDate === reportData.lastUpdatedDate,
        );
      setArchiveData(archive);
    }
  }, [reportData, allFinancialReports]);

  const handleChange = (event) => {
    const selectedDate = event.target.value;
    const selectedArchive = allFinancialReports.find(
      (report) => report.lastUpdatedDate === selectedDate,
    );
    setArchiveData(selectedArchive);
    handleReportChange(selectedArchive);
  };

  return (
    <ParentLayoutBox>
      {isGetFinancialReportFetching ? (
        <LinearProgress />
      ) : (
        <>
          {isGetFinancialReportSuccess && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexDirection: 'column',
              }}
            >
              {allFinancialReports?.filter(
                (report) => report.status !== 'ERROR',
              )?.length > 0 && (
                <ArchiveBox noValidate component="form">
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        value={showArchives}
                        checked={showArchives}
                        onChange={handleShowArchives}
                      />
                    }
                    label={t(
                      `${translationJSONPrefix}.reports.switch.archives`,
                    )}
                  />
                  {showArchives && (
                    <StyledFormControl>
                      <InputLabel>Archives</InputLabel>
                      <Select
                        onChange={handleChange}
                        size="small"
                        label="Archives"
                        value={archiveData?.lastUpdatedDate || ''}
                      >
                        {allFinancialReports
                          ?.filter((report) => report.status !== 'ERROR')
                          .map((report, index) => (
                            <MenuItem
                              key={index}
                              value={report?.lastUpdatedDate}
                              disabled={
                                reportData?.lastUpdatedDate ===
                                report?.lastUpdatedDate
                              }
                            >
                              <Stack>
                                <Typography>{report?.key}</Typography>
                                <DisplayTime
                                  time={report?.lastUpdatedDate}
                                  format="ddd, D MMM YYYY HH:mm"
                                />
                              </Stack>
                            </MenuItem>
                          ))}
                      </Select>
                    </StyledFormControl>
                  )}
                </ArchiveBox>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: 'column',
                }}
              >
                <ReactMarkdown>{financialReport?.summary}</ReactMarkdown>
              </Box>
            </Box>
          )}
        </>
      )}
    </ParentLayoutBox>
  );
};

ViewReportLayout.propTypes = {
  isGetFinancialReportFetching: PropTypes.bool,
  isGetFinancialReportSuccess: PropTypes.bool,
  financialReport: PropTypes.object,
  handleReportChange: PropTypes.func,
  allFinancialReports: PropTypes.array,
  showArchives: PropTypes.bool,
  handleShowArchives: PropTypes.func,
  reportData: PropTypes.object,
};

export default ViewReportLayout;
