/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const muiTextField = {
  styleOverrides: {
    root: ({ _, theme }) => ({
      '.MuiInputBase-root': {
        padding: '0',
      },
    }),
  },
};

export { muiTextField };
