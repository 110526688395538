/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, Box, TextField, Button, Link } from '@mui/material';
import colors from '../../themes/colors';

export const AssistantContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: 15,
  width: '100%',
  boxSizing: 'border-box',
});

export const PageTitle = styled(Box)({
  fontSize: 30,
  marginBottom: 25,
});

export const DropDownContainer = styled(Box)({
  width: 300,
  margin: '15px 0 10px',
});

export const PdfContainer = styled(Box)(({ withFile }) => ({
  margin: '10px auto',
  textAlign: 'center',
  position: 'relative',
  height: withFile ? '85%' : 50,
  boxSizing: 'border-box',
}));

export const DocTitleField = styled(TextField)({
  width: 250,
});

export const ModalFooter = styled(Box)({
  marginTop: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const ButtonLeft = styled(Button)({
  marginRight: 10,
});

export const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const MainContainerLeft = styled(Box)({
  backgroundColor: colors.neutralLight.N10,
  padding: '0 15px',
  borderRadius: 10,
  border: `1px solid ${colors.neutralLight.N50}`,
  width: '66.7%',
  position: 'relative',
});

export const MainContainerLeftHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const MainContainerLeftNoFile = styled(Box)({
  marginTop: '25%',
});

export const MainContainerRight = styled(Box)({
  backgroundColor: colors.neutralLight.N10,
  padding: 10,
  borderRadius: 10,
  border: `1px solid ${colors.neutralLight.N50}`,
  width: '32.7%',
  position: 'relative',
});

export const MainContainerRightHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const MainContainerMetric = styled(Box)({
  color: colors.blue.B400,
  fontWeight: 'bold',
});

export const TextLink = styled(Link)({
  cursor: 'pointer',
});

export const HighlightsContainer = styled(Box)({
  height: '86%',
  overflowY: 'scroll',
  margin: '10px 0',
});

export const HighlightStyled = styled(Box)({
  border: `1px solid ${colors.neutralLight.N50}`,
  margin: '10px 0',
});

export const HighlightHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: colors.neutralLight.N20,
});

export const HighlightContent = styled(Box)({
  backgroundColor: colors.neutralLight.N0,
  padding: 8,
  textAlign: 'justify',
});

export const HighlightContentHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 5,
  paddingBottom: 5,
  borderBottom: `1px solid ${colors.neutralLight.N50}`,
});

export const HighlightFooter = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: 0,
  margin: '10px 0',
  width: '95%',
});

export const HighlightFooterDropDown = styled(Box)({
  width: 250,
});

export const SummaryDocHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 10,
});

export const RTEContainer = styled(Box)({
  margin: '10px 0',
});

export const LinearProgressContainer = styled(Box)({
  margin: '15px 0',
});

export const SummaryFooter = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

export const RTEHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: colors.neutralLight.N10,
  border: `1px solid ${colors.neutralLight.N50}`,
  borderBottom: 'none',
});
