/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

import { DialogActions, styled } from '@mui/material';

export const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  padding: ` ${theme.spacing(1)} ${theme.spacing(3)}  ${theme.spacing(
    2.5,
  )} ${theme.spacing(0)}`,
  justifyContent: 'space-between',
}));
