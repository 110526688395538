/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { useAuth } from 'react-oidc-context';
import { REDIRECT_URL } from '../../constants/auth';
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ children, path, allowedRoles }) => {
  const auth = useAuth();
  const userRole = useSelector((state) => state.userRole);

  if (auth.isAuthenticated && auth.activeNavigator === undefined) {
    if (allowedRoles === undefined || allowedRoles?.includes(userRole)) {
      return children;
    } else {
      return window.location.replace('/');
    }
  }

  if (!auth.isLoading && !auth.isAuthenticated) {
    auth.signinRedirect({
      redirect_uri: REDIRECT_URL,
      state: btoa(`${REDIRECT_URL}${path}`),
    });
  }
};
