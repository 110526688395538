/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { useEffect, useState } from 'react';
import AppShadowBox from '../../components/app.shadowbox';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/pageContainer';
import { pushToast } from '../../redux/reducers/toasts.slice';
import { useDispatch } from 'react-redux';
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useLazyGetReportSummaryTopicsQuery } from '../../redux/services/speciphicAsk';
import { ReportSummaryTopicsWrapper } from './reportSummaryTopics.styled';
import SummaryTopicsDataGrid from './summaryTopicsDataGrid';
import { Assessment } from '@mui/icons-material';
import * as PATHS from '../../constants/path';
import { Link, useLocation } from 'react-router-dom';
import { use } from 'marked';

const ReportSummaryTopicsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [feature, setFeature] = useState(state?.feature);

  const [
    getReportSummaryTopic,
    {
      data: topics,
      isSuccess: isGetTopicsSuccess,
      isLoading: isGetTopicsLoading,
      isError: isGetTopicsError,
      isFetching: isGetTopicFetching,
    },
  ] = useLazyGetReportSummaryTopicsQuery();

  useEffect(() => {
    getReportSummaryTopic({
      feature: feature,
    });
  }, [feature]);

  useEffect(() => {
    if (isGetTopicsError) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.dataGrid.deleteErrorToast'),
          severity: 'error',
        }),
      );
    }
  }, [topics, isGetTopicsError]);

  const handleLinkClick = () => {
    setFeature((prevFeature) =>
      prevFeature === 'market-intelligence-listed'
        ? 'market-intelligence-unlisted'
        : 'market-intelligence-listed',
    );
  };

  const getFeature = () => {
    return feature;
  };

  return (
    <PageContainer>
      <ReportSummaryTopicsWrapper>
        <AppShadowBox>
          <Grid container spacing={2}>
            <Grid
              container
              item
              sx={{ marginBottom: 4 }}
              xs={12}
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <Typography sx={{ typography: { md: 'h3' } }}>
                  {feature === 'market-intelligence-listed'
                    ? t('reportSummaryTopicsPage.listedCompanyPage.title')
                    : t('reportSummaryTopicsPage.unlistedCompanyPage.title')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{ float: 'right' }}
                  component={Link}
                  to={
                    feature === 'market-intelligence-listed'
                      ? PATHS.MARKET_INTELLIGENCE
                      : PATHS.UNLISTED_COMPANY_REPORTS
                  }
                  variant="outlined"
                  startIcon={<Assessment />}
                >
                  {t('reportSummaryTopicsPage.navigation.marketIntelligence')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {isGetTopicFetching && (
            <>
              <LinearProgress />
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', marginTop: 2 }}
              >
                {t(
                  'reportSummaryTopicsPage.loadingMessage.loadingFileCollections',
                )}
              </Typography>
            </>
          )}
          {isGetTopicsSuccess && (
            <SummaryTopicsDataGrid topics={topics} feature={feature} />
          )}
        </AppShadowBox>
      </ReportSummaryTopicsWrapper>
    </PageContainer>
  );
};

export default ReportSummaryTopicsPage;
