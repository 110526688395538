/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
  Button,
} from '@mui/material';
import HideImageIcon from '@mui/icons-material/HideImage';
import fontWeights from '../../themes/fontWeights';

export const ContentBox = styled(Box)(() => ({}));

export const CardContentBox = styled(CardContent)(({ theme }) => ({
  padding: 0,
  '&:last-child': {
    paddingBottom: theme.spacing(0),
  },
}));

export const StyledPdfBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.light}`,
  cursor: 'pointer',
  height: 240,
  width: 186,
  overflow: 'hidden',
  boxSizing: 'border-box',
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'flex-end',
}));

export const StyleShareTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  color: theme.palette.text.caption,
  fontWeight: fontWeights.regular,
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  boxShadow: theme.shadows[25],
  padding: theme.spacing(2, 4, 0),
  boxSizing: `border-box`,
}));

export const StyledAnswerTypography = styled(Typography)(({ theme }) => ({
  fontWeight: fontWeights.light,
  fontSize: '18px',
  color: theme.palette.text.caption,
  lineHeight: '28px',
}));

export const StyledPdfContiner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  boxSizing: `border-box`,
  ':hover': {
    border: `1px solid ${theme.palette.primary.main} `,
    boxShadow: theme.shadows[27],
    borderRadius: 4,
  },
  padding: theme.spacing(1),
  width: 'fit-content',
  border: '1px solid transparent',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { color: theme.palette.primary.main, background: 'none' },
}));

export const AnswerComponentBottomWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '9px 32px',
  margin: '25px -32px 0',
  background: '#f9f9f9',
}));

export const FeedBackWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const ShareWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
}));

export const AnsWrapper = styled(Box)(({ theme }) => ({}));

export const EmptyPdfWrapper = styled(Box)(({ theme }) => ({
  width: '190px',
  height: '275px',
}));

export const PdfTypography = styled(Typography)(({ theme }) => ({
  marginTop: 10,
  width: '184px',
  fontWeight: '300',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '16px',
  color: theme.palette.text.caption,
}));

export const HtmlTypography = styled(Typography)(({ theme }) => ({
  marginTop: 1,
  fontWeight: '300',
  wordBreak: 'break-all',
  fontSize: '16px',
  textTransform: 'lowercase',
  lineHeight: '22px',
  variant: 'subtitle1',
  color: theme.palette.text.caption,
}));

export const HtmlPreviewWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '183.6px !important',
  height: '237.6px !important',
}));

export const NoPreviewIcon = styled(HideImageIcon)(({ theme }) => ({
  width: '100% !important',
  height: '80% !important',
  color: '#D3D3D3',
}));

export const ViewerContainerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: 40,

  // Media query for tablets (min-width: 600px and max-width: 900px)
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  // Media query for mobile screens (max-width: 600px)
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    rowGap: 20,
  },
}));

export const DocViewerContainer = styled(Box)(({ theme }) => ({
  height: 250,
  position: 'relative',
  cursor: 'pointer',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DocPreview = styled(Box)(({ theme }) => ({
  width: 200,
  height: '100%',
}));

export const PreviewCenterDiv = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const IframeStyled = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: 'none',
  overflow: 'hidden',
}));

export const ViewerCover = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 250,
  background: 'rgba(0,0,0,0.0)',
}));
