/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material/styles';
import colors from '../colors';

let { palette } = createTheme();

palette = {
  ...palette,
  primary: {
    main: '#3C434E',
    light: '#D7E7FF',
  },
  secondary: {
    main: '#FE5733',
    light: '#FFF8F6',
  },
  text: {
    primary: '#3C434E',
    secondary: '#97A3B4',
    light: '#B9C2D0',
    caption: '#333333',
    controls: '#333333',
    disabled: '#B9C2D0',
  },
  background: {
    pageBackground: '#F8F8F8',
    light: '#EDF2FA',
  },
  border: {
    main: '#091E42',
    secondary: '#DFEAF3',
  },
  poster: {
    main: '#FF8B6D',
  },
  blue: {
    main: '#4791FF',
  },
  spinner: {
    red: colors.red.R300,
    green: colors.green.G300,
    yellow: colors.yellow.Y300,
    background: '#999999',
  },
  dropdown: {
    primary: '#F3F3F3',
  },
};

export default palette;
