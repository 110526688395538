/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

import { Box, styled, LinearProgress } from '@mui/material';

export const LoadingTextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  height: '90vh',
}));

export const LoadingProgress = styled(LinearProgress)(({ theme }) => ({
  width: '20%',
}));
