/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({
      companyName,
      sourceUrls,
      companyLogoUrl,
      companyStockSymbol,
      feature,
    }) => {
      const body = {
        companyName: companyName,
        sourceUrls: sourceUrls,
        companyLogoUrl: companyLogoUrl,
        feature: feature,
      };

      if (companyStockSymbol) {
        body.key = companyStockSymbol;
      }

      return {
        url: `report-sources`,
        method: 'POST',
        body: body,
        'Content-Type': 'application/json',
      };
    },
    invalidatesTags: ['report-sources'],
  });
