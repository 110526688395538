/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { LinearProgress } from '@mui/material';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';

import AppShadowBox from '../../components/app.shadowbox/app.shadowbox.component';
import PageContainer from '../../components/pageContainer';
import {
  useGetChatHistoryQuery,
  useGetFileCollectionsQuery,
} from '../../redux/services/speciphicAsk';
import SearchHistoryDataGrid from './searchHistoryDataGrid.component';
import { StyledTypography } from './searchHistory.page.styled';
import PageTitle from '../../components/page.title';
import UnauthorizedMessage from '../../components/unauthorized';

const translationJSONPrefix = 'searchHistoryPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const SearchHistoryPage = () => {
  const auth = useAuth();
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  useState(() => {
    setUser(auth.user);
  }, []);

  const {
    data: chatHistory = [],
    error: chatHistoryError,
    isLoading: isGetChatHistoryLoading,
    isSuccess: isGetChatHistorySuccess,
  } = useGetChatHistoryQuery();

  const {
    data: fileCollections = [],
    error: fileCollectionsError,
    isError: isGetFileCollectionsError,
    isLoading: isGetFileCollectionsLoading,
    isSuccess: isGetFileCollectionsSuccess,
  } = useGetFileCollectionsQuery({
    include: ['name'],
    isStoreCreated: true,
  });

  if (chatHistoryError?.status === 403)
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <AppShadowBox>
        <PageTitle
          title={t(`${translationJSONPrefix}.title`)}
          subtitle={t(`${translationJSONPrefix}.subtitle`)}
          isActionButtonNeeded={false}
        />
        {isGetChatHistoryLoading && (
          <>
            <LinearProgress />
            <StyledTypography variant="h5">
              {t(`${translationJSONPrefix}.loading`)}
            </StyledTypography>
          </>
        )}
        {isGetChatHistorySuccess && (
          <SearchHistoryDataGrid
            chatHistory={chatHistory}
            fileCollections={fileCollections}
          />
        )}
      </AppShadowBox>
    </PageContainer>
  );
};

export default SearchHistoryPage;
