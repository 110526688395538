/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { MARKET_INTELLIGENCE_UNLISTED_FEATURE } from '../../../../constants/marketIntelligence';

export default (builder) =>
  builder.mutation({
    query: ({ updateData, feature, reportSourceId }) => {
      const body = {
        companyName: updateData.companyName,
        sourceUrls: updateData.sourceUrls,
        status: updateData.status,
        companyLogoUrl: updateData.companyLogoUrl || '',
      };

      if (feature === MARKET_INTELLIGENCE_UNLISTED_FEATURE) {
        body.key = updateData.companyStockSymbol;
      }

      return {
        url: `report-sources/${reportSourceId}`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      };
    },
    invalidatesTags: ['report-sources'],
  });
