import {
  Alert,
  Box,
  Button,
  Icon,
  Typography,
  LinearProgress,
  styled,
} from '@mui/material';
import fontWeights from '../../themes/fontWeights';
import { color } from '@uiw/react-codemirror';
import colors from '../../themes/colors';
export const HistoryButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  borderTopLeftRadius: 0,
  borderEndStartRadius: 0,
  zIndex: 1,
}));

export const SearchBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    paddingX: theme.spacing(8),
  },
  [theme.breakpoints.down('sm')]: {
    paddingX: theme.spacing(1),
  },
}));

export const LoadingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

export const ShadowBox = styled(Box)(({ theme }) => ({
  //boxShadow: '0px 0px 68px rgba(0, 0, 0, 0.15)',
  borderRadius: theme.spacing(0),
  padding: theme.spacing(3, 0),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
  background: 'transparent',
}));

export const StyledLinearProgress = styled(LinearProgress)({
  width: '40%',
});

export const StyledIcon = styled(Icon)(({ theme }) => ({
  width: 91,
  height: 38,
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(-1),
  color: theme.palette.primary.main,
}));

export const ShadowBoxNormal = styled(Box)(({ theme }) => ({
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(0),
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
}));

export const AnswerComponentWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(0, 4),
}));

export const AppTitleBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const LoadingTypography = styled(Typography)({
  textAlign: 'center',
  margin: 3,
});

export const ResultBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

export const FoundDataAlert = styled(Alert)({
  background: '#33C000',
  color: '#ffffff !important',
  mb: 2,
  '.MuiAlert-icon': { alignItems: 'center', color: '#fff' },
  '.MuiAlert-message': { wordBreak: 'break-all' },
});

export const ResultTitle = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(4, 0, 3),
  fontWeight: '300',
  fontSize: '24px',
  color: theme.palette.text.caption,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const MetaButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const HoveringLink = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  paddingX: theme.spacing(2.5),
  cursor: 'pointer',
  color: theme.palette.primary.main,
  fontSize: 14,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const MetaDataGridBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  paddingRight: `${theme.spacing(4)}`,
  paddingLeft: `${theme.spacing(4)}`,
}));

export const InfographicSVG = styled('img')(({ theme }) => ({
  height: 130,
  margin: '35px 0',
  padding: '35px 0',

  [theme.breakpoints.down('md')]: {
    height: 80,
    margin: '15px 0',
    padding: '15px 0',
  },

  [theme.breakpoints.down('sm')]: {
    height: 70,
    margin: '10px 0',
    padding: '10px 0',
  },
}));
