/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
export default (builder) =>
  builder.query({
    query: ({ report_id, report_format, download_report }) => {
      const params = new URLSearchParams();
      params.append('format', report_format);
      params.append('download', download_report);
      return {
        url: `/reports/${report_id}/view?${params}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      };
    },
  });
