/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Chip, ListItem, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { array, bool, object, string } from 'yup';
import { Form } from 'formik';

import PageContainer from '../../../../components/pageContainer';
import { SECTIONS } from '../../../../constants/drawer';
import AddPageHeader from '../../../../components/form/addPageHeader.component';
import AppForm, {
  AppAutocompleteField,
  AppTextField,
  FormButtons,
  ToogleContainer,
} from '../../../../components/form';
import { getChangedValues } from '../../../../components/form/functions';
import {
  DescriptionStyled,
  FormContainer,
  InputsContainer,
  ToogleButtonsContainer,
} from '../styled';
import { AlertStyled } from '../../../settings/filesCollectionSettingsPage/styled';
import Loader from '../../../../components/loader/loader.component';
import {
  useCreateFileCollectionAccessPolicyMutation,
  useLazyGetFileCollectionAccessPolicyByNameQuery,
  useLazyGetResourcePermissionQuery,
  useLazyGetAllRBACQuery,
  useUpdateFileCollectionAccessPolicyMutation,
} from '../../../../redux/services/speciphicAsk';
import { FILE_COLLECTIONS, SETTINGS } from '../../../../constants/path';
import { IAM } from '../../../../constants/filesCollectionSettingsPaths';
import { LOAD_TITLE, filterByIds } from '../../../../utils/functions';
import colors from '../../../../themes/colors';
import { RBAC_USERS_ENDPOINT } from '../../../../constants/accessControl';

const mainPageRoute = (collectionId) =>
  `${FILE_COLLECTIONS}${`/${collectionId}`}${SETTINGS}${IAM.endpoint}`;

const translationJSONPrefix = 'filesCollectionSettingsPage.iam';

const TRANSLATION_MENU_TITLES = `${translationJSONPrefix}.drawerMenuTitle`;
const FILES_COLLECTION = `${translationJSONPrefix}.filesCollection`;

const PAGE_TITLE = (t) =>
  `${t(TRANSLATION_MENU_TITLES)} | ${t(FILES_COLLECTION)}`;

const defaultDataState = {
  name: '',
  effect: false,
  description: '',
  users: [],
  // roles: [],
  // groups: [],
  actions: [],
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.form.${label}.errorMessages`;

  return object({
    name: string()
      .required(t(`${translationPrefix('name')}.required`))
      .min(3, t(`${translationPrefix('name')}.min`))
      .max(250, t(`${translationPrefix('name')}.max`)),
    effect: bool(),
    description: string()
      .min(4, t(`${translationPrefix('description')}.min`))
      .max(250, t(`${translationPrefix('description')}.min`)),
    users: array(),
    // roles: array(),
    // groups: array(),
    actions: array().min(1, t(`${translationPrefix('actions')}.min`)),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  isEdit,
  fetchingPermissions,
  permissionOptions,
  fetchingUsers,
  usersData,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.form`;

  const renderOption = (props, option) => (
    <ListItem
      {...props}
      key={`${option?.resource}-${option?.group}-${option?.action}`}
      component="div"
      button
    >
      <ListItemText
        primary={
          option?.resource === '*'
            ? '*'
            : `${option?.resource} - ${option?.action}`
        }
        secondary={
          <>
            <Typography component="span" variant="body2" color="text.primary">
              {option?.group}
            </Typography>
            {` — ${option?.description}`}
          </>
        }
      />
    </ListItem>
  );

  return (
    <Form>
      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="name"
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          inputProps={{ readOnly: isEdit }}
        />
      </InputsContainer>

      <ToogleButtonsContainer>
        <ToogleContainer
          name="effect"
          label={t(`${translationPrefix}.effect.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          tooltipTitle={t(`${translationPrefix}.effect.tooltipTitle`)}
        />
        <Box
          style={{
            color: values['effect'] ? colors.green.G400 : colors.red.R400,
          }}
        >
          {values['effect']
            ? t(`${translationPrefix}.effect.allow`)
            : t(`${translationPrefix}.effect.deny`)}
        </Box>
      </ToogleButtonsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="description"
          label={t(`${translationPrefix}.description.label`)}
          placeholder={t(`${translationPrefix}.description.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          multiline
          minRows={3}
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="actions"
          label={t(`${translationPrefix}.actions.label`)}
          placeholder={
            fetchingPermissions
              ? t(`${translationPrefix}.actions.dropdownDataLoading`)
              : `${t(`${translationPrefix}.actions.placeholder`)}${
                  values['actions']?.length === 0
                    ? t(`${translationPrefix}.actions.placeholderExtension`)
                    : ''
                }`
          }
          options={permissionOptions}
          getOptionLabel={(option) =>
            `${option?.resource} - ${option?.group} - ${option?.action}`
          }
          errors={errors}
          touched={touched}
          onChange={(e, value) =>
            setFieldValue(
              'actions',
              value.filter((v) => v?.action?.length > 0),
            )
          }
          values={values}
          renderOption={renderOption}
          renderInput={(params) => (
            <AppTextField {...params} variant="outlined" />
          )}
          isOptionEqualToValue={(option, value) =>
            option?.action === value?.action &&
            option?.resource === value?.resource
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={`${option?.resource}-${option?.group}-${option?.action}`}
                label={
                  option?.resource === '*'
                    ? '*'
                    : `${option?.resource}:${option?.action}`
                }
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="users"
          label={t(`${translationPrefix}.users.label`)}
          placeholder={
            fetchingUsers
              ? `${t(`${translationPrefix}.users.loading`)}`
              : `${t(`${translationPrefix}.users.placeholder`)}${
                  values['users']?.length === 0
                    ? t(`${translationPrefix}.users.placeholderExtension`)
                    : ''
                }`
          }
          options={usersData}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          values={values}
          getOptionLabel={(option) =>
            `${option?.firstName} ${option?.lastName}` || ''
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={`${option?.firstName} ${option?.lastName}` || '-'}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </InputsContainer>

      {/* <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="roles"
          label={t(`${translationPrefix}.roles.label`)}
          placeholder={`${t(`${translationPrefix}.roles.placeholder`)}${
            values['roles']?.length === 0
              ? t(`${translationPrefix}.roles.placeholderExtension`)
              : ''
          }`}
          options={[]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          values={values}
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="groups"
          label={t(`${translationPrefix}.groups.label`)}
          placeholder={`${t(`${translationPrefix}.groups.placeholder`)}${
            values['groups']?.length === 0
              ? t(`${translationPrefix}.groups.placeholderExtension`)
              : ''
          }`}
          options={[]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          values={values}
        />
      </InputsContainer> */}

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const AccessControlAddPoliciesPage = () => {
  const { t } = useTranslation();
  useEffect(() => LOAD_TITLE(document, PAGE_TITLE(t)), []);

  const params = useParams();
  const [searchParams] = useSearchParams();

  const [collectionId, setCollectionId] = useState();
  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [fcAPName, setFCAPName] = useState('');
  const [errorMessage, setErrorMessage] = useState(
    t(`${translationJSONPrefix}.errorAlertLabel`),
  );
  const [permissionOptions, setPermissionOptions] = useState([]);

  const transformPermissionsToOptions = (permissions) => {
    const transformedOptions = [];
    for (const resource in permissions) {
      for (const group in permissions[resource].actions) {
        permissions[resource].actions[group].forEach((action) => {
          transformedOptions.push({
            resource,
            group,
            action: action.action,
            description: action.description,
          });
        });
      }
    }
    return transformedOptions;
  };

  const [getAllRBAC, { data: usersData, isFetching: fetcingUsers }] =
    useLazyGetAllRBACQuery();

  const [
    getPermissions,
    {
      data: permissionsData,
      isFetching: fetchingPermissions,
      isError: isPermissionsError,
      isLoading: isPermissionsLoading,
      isSuccess: isPermissionsSuccess,
    },
  ] = useLazyGetResourcePermissionQuery();

  useEffect(() => {
    getPermissions({ resourceName: 'FileCollection' });

    getAllRBAC({
      endpoint: RBAC_USERS_ENDPOINT,
    });

    setCollectionId(params?.collectionId);
  }, []);

  useEffect(() => {
    if (permissionsData)
      setPermissionOptions(transformPermissionsToOptions(permissionsData));
  }, [permissionsData]);

  const [
    createFileCollectionAccessPolicy,
    {
      data: createdData,
      isError: isCreateError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateFileCollectionAccessPolicyMutation();

  const [
    updateFileCollectionAccessPolicy,
    {
      data: updatedData,
      isError: isUpdateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateFileCollectionAccessPolicyMutation();

  const [
    getFileCollectionAccessPolicy,
    {
      data: fileCollectionAccessPolicyDB,
      isError: isDataError,
      isLoading: isDataLoading,
      isSuccess: isDataSuccess,
    },
  ] = useLazyGetFileCollectionAccessPolicyByNameQuery();

  useEffect(() => {
    const tempPolicyName = searchParams.get('policy-name');
    const collectionId = params?.collectionId;

    if (tempPolicyName) {
      setIsEdit(true);
      getFileCollectionAccessPolicy({
        collectionId,
        accessPolicyName: tempPolicyName,
      });
      setFCAPName(tempPolicyName);
    }
  }, []);

  useEffect(() => {
    if (
      isDataSuccess &&
      fileCollectionAccessPolicyDB &&
      permissionOptions &&
      usersData
    ) {
      const tempFormData = {
        name: fileCollectionAccessPolicyDB?.name,
        effect: fileCollectionAccessPolicyDB?.effect === 'allow' ? true : false,
        description: fileCollectionAccessPolicyDB?.description || '',
        actions: fileCollectionAccessPolicyDB?.actions.map(
          (act) =>
            permissionOptions?.filter(
              (po) => act === `${po?.resource}:${po?.action}`,
            )[0],
        ),
        users: filterByIds(fileCollectionAccessPolicyDB?.users, usersData),
      };

      if (!tempFormData?.name) return;

      setFormData(tempFormData);
      setInitialFormData(tempFormData);
    }
  }, [fileCollectionAccessPolicyDB, permissionOptions, usersData]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateError || isUpdateError) {
      tempAlert.formUpdateError = true;
      tempAlert.formUpdateSuccess = false;
      setShowAlert(tempAlert);
      getFileCollectionAccessPolicy({
        collectionId,
        accessPolicyName: fcAPName,
      });

      const tempErrorMessage = createdData?.message || updatedData?.message;
      if (tempErrorMessage) setErrorMessage(tempErrorMessage);
    }
  }, [isCreateError, isUpdateError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateSuccess || isUpdateSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
      getFileCollectionAccessPolicy({
        collectionId,
        accessPolicyName: fcAPName,
      });
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  return (
    <PageContainer
      drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
      collectionId={collectionId}
    >
      <AddPageHeader
        routes={[
          {
            title: t(`${translationJSONPrefix}.pageHeader`),
            endpoint: mainPageRoute(collectionId),
          },
        ]}
        isEdit={isEdit}
      />

      <DescriptionStyled>{collectionId || '...'}</DescriptionStyled>

      <FormContainer>
        <AlertStyled
          onClose={closeAlert}
          severity={showAlert.formUpdateError ? 'error' : 'success'}
          style={{
            marginTop:
              showAlert.formUpdateError || showAlert.formUpdateSuccess
                ? 10
                : -60,
          }}
        >
          {showAlert.formUpdateSuccess &&
            (isEdit
              ? t(`${translationJSONPrefix}.updatedAlertLabel`)
              : t(`${translationJSONPrefix}.createdAlertLabel`))}
          {showAlert.formUpdateError && errorMessage}
        </AlertStyled>

        {isDataLoading ? (
          <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
        ) : (
          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              let valuesToSend = getChangedValues(values, initialFormData);

              if (!isEdit || initialFormData.effect !== values.effect)
                valuesToSend.effect = values.effect ? 'allow' : 'deny';

              if (valuesToSend.actions) {
                // Create a new array to avoid modifying original valuesToSend['actions']
                valuesToSend['actions'] = valuesToSend['actions'].map(
                  (act) =>
                    `${act?.resource}:${
                      act?.action === '*' ? '*' : act?.action
                    }`,
                );
              }

              if (valuesToSend.users) {
                valuesToSend['users'] = valuesToSend.users.map((u) => u.id);
              }

              if (isEdit)
                updateFileCollectionAccessPolicy({
                  collectionId,
                  accessPolicyName: values?.name,
                  updatedPolicy: { ...valuesToSend },
                });
              else
                createFileCollectionAccessPolicy({
                  collectionId,
                  newPolicy: valuesToSend,
                });

              setFCAPName(values?.name);

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isCreateLoading || isUpdateLoading,
                isEdit,
                fetchingPermissions,
                permissionOptions,
                fetcingUsers,
                usersData,
              )
            }
          />
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default AccessControlAddPoliciesPage;
