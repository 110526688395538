/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect, useContext } from 'react';

import PageContainer from '../../components/pageContainer';
import MarkdownComponent from '../../components/markdown.component';
import { MarkdownWrapper } from '../../components/markdown.component/markdown.component.styled';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';

const CookiePage = () => {
  const [cookiePolicy, setCookiePolicy] = useState('');
  const [markdownStyle, setMarkdownStyle] = useState('');
  const { getSiteConfig } = useContext(SiteConfigContext);

  useEffect(() => {
    const siteConfig = getSiteConfig();

    if (siteConfig) {
      setCookiePolicy(siteConfig?.cookiePolicy);
      setMarkdownStyle(siteConfig?.markdownStyle);
    }
  }, [getSiteConfig()]);

  return (
    <PageContainer>
      <MarkdownWrapper>
        <MarkdownComponent
          markdown={cookiePolicy}
          markdownStyle={markdownStyle}
          isConfigurationsSuccess={true}
        />
      </MarkdownWrapper>
    </PageContainer>
  );
};

export default CookiePage;
