/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { createContext, React, useState } from 'react';
import PropTypes from 'prop-types';

const SiteConfigContext = createContext();

const SiteConfigProvider = (props) => {
  const [siteConfig, setSiteConfig] = useState('');
  const [unauthorized, setUnauthorized] = useState(false);
  const [marketIntelligenceConfig, setMarketIntelligenceConfig] = useState('');

  const isSiteConfigured = () => {
    return siteConfig?.isSiteConfigured;
  };

  const getSiteConfig = () => {
    return siteConfig;
  };

  const isUnauthorized = () => {
    return unauthorized;
  };

  const getMarketIntelligenceConfig = () => {
    return marketIntelligenceConfig;
  };
  return (
    <SiteConfigContext.Provider
      value={{
        getSiteConfig,
        setSiteConfig,
        isUnauthorized,
        setUnauthorized,
        isSiteConfigured,
        setMarketIntelligenceConfig,
        getMarketIntelligenceConfig,
      }}
    >
      {props.children}
    </SiteConfigContext.Provider>
  );
};
SiteConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SiteConfigContext, SiteConfigProvider };
