/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const getConfig = (builder) =>
  builder.query({
    query: ({ collectionId, endpoint }) => ({
      url: `file-collections/${collectionId}/${endpoint}`,
      method: 'GET',
    }),
    providesTags: ['filecollection-settings'],
  });

const updateConfig = (builder) =>
  builder.mutation({
    query: ({ collectionId, data, endpoint }) => {
      return {
        url: `file-collections/${collectionId}/${endpoint}`,
        method: 'PATCH',
        body: data,
      };
    },
    invalidatesTags: ['filecollection-settings', 'fileCollections'],
  });

export const getFileCollectionSettingsEPFactory = (builder) =>
  getConfig(builder);
export const updateFileCollectionSettingsEPFactory = (builder) =>
  updateConfig(builder);
