import {
  Button,
  Chip,
  Box,
  Grid,
  CircularProgress,
  Typography,
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
} from '@mui/material';
import {
  StyledChip,
  SuggestionBox,
  SuggestionText,
} from './searchSuggestion.styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SearchSuggestion = ({ isLoading, faqList, setInput, suggestiveTrue }) => {
  const { t } = useTranslation();

  return (
    <SuggestionBox>
      {isLoading ? (
        <Box>
          <CircularProgress size="1.6rem" />
        </Box>
      ) : (
        <Grid container justifyContent={'space-evenly'} rowSpacing={1}>
          <List
            sx={{
              width: '100%',
              '.MuiTypography-root': {
                fontSize: '18px',
                fontWeight: '600',
              },
              '.MuiListItemButton-root:hover': {
                background: 'transparent',
              },
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {faqList.length
                  ? t('speciphicAskPage.suggestion.heading')
                  : null}
              </ListSubheader>
            }
          >
            {faqList.length ? (
              <ListItemButton
                onClick={() => {
                  setInput(faqList[0].query), suggestiveTrue(true);
                }}
              >
                <ListItemText primary={faqList[0].query} />
              </ListItemButton>
            ) : null}

            {faqList.length ? (
              <ListItemButton
                onClick={() => {
                  setInput(faqList[1]?.query), suggestiveTrue(true);
                }}
              >
                <ListItemText primary={faqList[1]?.query} />
              </ListItemButton>
            ) : null}

            {faqList.length ? (
              <ListItemButton
                onClick={() => {
                  setInput(faqList[2]?.query), suggestiveTrue(true);
                }}
              >
                <ListItemText primary={faqList[2]?.query} />
              </ListItemButton>
            ) : null}
          </List>
        </Grid>
      )}
    </SuggestionBox>
  );
};

SearchSuggestion.propTypes = {
  setInput: PropTypes.func,
  isLoading: PropTypes.bool,
  suggestiveTrue: PropTypes.func,
  faqList: PropTypes.array,
};

export default SearchSuggestion;
