/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Typography, Grid, styled } from '@mui/material';

export const ErrorTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const StyledPageWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: theme.spacing(2),
  padding: theme.spacing(1),
  minWidth: 'fit-content',
  height: `100%`,
}));

export const InstructionTextTypography = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(0.6),
}));
