/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import {
  styled,
  Alert,
  Typography,
  Box,
  ListItem,
  Chip,
  ListItemText,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
export const AddFilesButton = styled(LoadingButton)(({ theme }) => ({
  width: '110px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const FilesAddedAlert = styled(Alert)({
  width: '300px',
});

export const LoadingTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));

export const TableHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(3),
}));

export const FileNameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const FileName = styled(ListItem)(({ theme }) => ({
  marginTop: '-10px',
  marginRight: theme.spacing(2),
}));

export const FileNameText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
}));

export const TagsChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: '110px',
  width: 'fit-content',
}));

export const AlignmentBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const ButtonStyled = styled(LoadingButton)(({ theme }) => ({
  width: '110px',
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));
