/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.query({
    query: ({ pageNo, pageSize }) => {
      const params = new URLSearchParams();
      params.append('pageNo', pageNo);
      params.append('pageSize', pageSize);
      return {
        url: `/analytics/search-data?${params}`,
        method: 'GET',
      };
    },
  });
