const { REACT_APP_ENABLE_ACCESS_CONTROL } = process.env;

let isAccessControlEnabled = false;

if (
  REACT_APP_ENABLE_ACCESS_CONTROL &&
  REACT_APP_ENABLE_ACCESS_CONTROL?.toLowerCase() === 'true'
) {
  isAccessControlEnabled = true;
}

export const ACCESS_CONTROL_FEATURE = 'access-control';

export const RBAC_POLICIES_ENDPOINT = 'policies';
export const RBAC_GROUPS_ENDPOINT = 'groups';
export const RBAC_ROLES_ENDPOINT = 'roles';
export const RBAC_USER_ROLES_ENDPOINT = 'user-roles';
export const RBAC_USERS_GROUP_ENDPOINT = 'users-group';
export const RBAC_USERS_ENDPOINT = 'users';

export default isAccessControlEnabled;
