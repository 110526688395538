/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, ThemeProvider } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import AppNavbar from './components/app.navbar';
import CssBaseline from '@mui/material/CssBaseline';
import AppFooter from '../src/components/app.footer';
import AppRoutes from './route/routes/AppRoutes';
import AppToast from './components/app.toast';
import { getTheme } from './themes';
import ErrorFallBackPage from './pages/errorFallbackPage';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { removeToast } from './redux/reducers/toasts.slice';
import Cookie from './components/app.cookie';
import { CURRENT_THEME } from './constants/theme';
import { BackgroundColoredBox, ScrollBox } from './app.styled';
import useSizing from './hooks/useSizing';
import { FileCollectionProvider } from './hooks/useContext';
import { SiteConfigProvider } from './hooks/useSiteConfigContext';

const App = () => {
  const currentTheme = getTheme(CURRENT_THEME);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleCookieOpen = () => {
    let cookieName = getCookie('cookiepolicy');
    if (cookieName != '') {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  const getCookie = (cname) => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookies = decodedCookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) == 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };
  const handleAcceptCookie = () => {
    const expireDate = new Date();
    //Setting cookie expiry after 1 month
    expireDate.setTime(expireDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + expireDate.toUTCString();
    document.cookie = 'cookiepolicy=accepted;' + expires;
    setOpen(false);
  };
  const handleDeclineCookie = () => {
    setOpen(false);
    //Setting cookie with no expiry: omits after session expiry
    document.cookie = 'cookiepolicy=declined';
  };
  const parmas = useLocation();
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toasts);

  const handleToastDismiss = (toastId) => {
    dispatch(removeToast(toastId));
  };

  const handleApplicationError = (error, info) => {
    console.error('An error has occured in the applicaton.');
    console.error(error, info);
  };

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const { height: headerHeight } = useSizing(headerRef);
  const { height: footerHeight } = useSizing(footerRef);

  return (
    <ThemeProvider theme={currentTheme}>
      <SiteConfigProvider>
        <ErrorBoundary
          FallbackComponent={ErrorFallBackPage}
          onError={handleApplicationError}
        >
          <BackgroundColoredBox>
            <CssBaseline />
            <FileCollectionProvider>
              <AppNavbar ref={headerRef} />

              <ScrollBox
                headerheight={headerHeight}
                footerheight={footerHeight}
              >
                <AppRoutes />
              </ScrollBox>
            </FileCollectionProvider>

            <AppFooter ref={footerRef} />
            <Cookie
              handleBannerClose={handleClose}
              handleAcceptCookie={handleAcceptCookie}
              handleDeclineCookie={handleDeclineCookie}
              open={open}
            />
            {toasts &&
              toasts.length > 0 &&
              toasts.map(({ toastId, open, message, severity }) => (
                <AppToast
                  key={toastId}
                  open={open}
                  message={message}
                  alertSeverity={severity}
                  onClose={() => handleToastDismiss(toastId)}
                />
              ))}
          </BackgroundColoredBox>
        </ErrorBoundary>
      </SiteConfigProvider>
    </ThemeProvider>
  );
};

export default App;
