/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Alert,
  Box,
  Typography,
  styled,
  Link,
  LinearProgress,
} from '@mui/material';
import colors from '../../themes/colors';

export const ShadowBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
  background: 'transparent',
}));

export const MetaDataPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}));

export const MetaDataFilterBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
}));

export const MetaDataGridBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  paddingRight: `${theme.spacing(4)}`,
  paddingLeft: `${theme.spacing(4)}`,
}));

export const FoundDataAlert = styled(Alert)(({ theme }) => ({
  background: colors.green.G250,
  color: `${colors.neutralLight.N0} !important`,
  marginBottom: theme.spacing(3),
  '.MuiAlert-icon': { alignItems: 'center', color: colors.neutralLight.N0 },
  '.MuiAlert-message': { wordBreak: 'break-all' },
}));

export const ErrorAlert = styled(Alert)({
  background: colors.red.R250,
  color: colors.neutralLight.N0,
  '.MuiSvgIcon-root': { color: colors.neutralLight.N0 },
  borderRadius: 0,
});

export const BooleanOperatorTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  pt: theme.spacing(0.2),
  color: colors.yellow.Y100,
  fontWeight: 'bold',
}));

export const MessageTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  color: colors.neutralLight.N0,
}));

export const AlertTypography = styled(Typography)(({ theme }) => ({
  color: colors.neutralLight.N0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  cursor: 'pointer',
});

export const FlexLoadingContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const Loader = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));
