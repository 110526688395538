/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import Header from '../../../components/form/header.component';
import { useTranslation } from 'react-i18next';
import {
  useDeleteLanguageModelsMutation,
  useGetAllLanguageModelsQuery,
} from '../../../redux/services/speciphicAsk';
import UnauthorizedMessage from '../../../components/unauthorized';
import Loader from '../../../components/loader';
import DataGridTable from '../../../components/datagrid';
import { filterByIds, sortByDateField } from '../../../utils/functions';
import DisplayTime from '../../../components/timezone.component';
import { DataGridWrapper } from '../styled';
import DialogComponent from '../../../components/app.dialog';
import { useNavigate } from 'react-router-dom';
import { APP_CONFIGURATION } from '../../../constants/path';
import {
  LANGUAGE_MODELS_CONFIG,
  ADD_PAGE,
} from '../../../constants/appConfigurationPaths';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../redux/reducers/toasts.slice';

const translationJSONPrefix = 'appConfigurationSection.languageModelsConfig';

const addPageRoute = `${APP_CONFIGURATION}${LANGUAGE_MODELS_CONFIG.endpoint}${ADD_PAGE.endpoint}`;

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.table.columnHeaders`;

  return [
    {
      field: 'name',
      headerName: t(`${translationPrefix}.name`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.name || '-'}`,
    },
    {
      field: 'modelName',
      headerName: t(`${translationPrefix}.modelName`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.modelName || '-'}`,
    },
    {
      field: 'provider',
      headerName: t(`${translationPrefix}.provider`),
      flex: 0.2,
      minWidth: 100,
      valueGetter: (params) => `${params.row?.provider || '-'}`,
    },
    {
      field: 'deploymentName',
      headerName: t(`${translationPrefix}.deploymentName`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.deploymentName || '-'}`,
    },
    {
      field: 'createdAt',
      headerName: t(`${translationPrefix}.createdAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => <DisplayTime time={params.row?.createdDate} />,
    },
    {
      field: 'updatedAt',
      headerName: t(`${translationPrefix}.updatedAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => (
        <DisplayTime time={params.row?.lastUpdatedDate} />
      ),
    },
  ];
};

const LanguageModelsConfigPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedLMs, setSelectedLMs] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogTFValue, setDeleteDialogTFValue] = useState('');
  const [deleteDialogButtonDisabled, setDeleteDialogButtonDisabled] =
    useState(true);

  const { data, error, isFetching } = useGetAllLanguageModelsQuery();
  const [
    deleteLanguageModels,
    {
      data: deletedModels,
      error: deleteError,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteLanguageModelsMutation();

  useEffect(() => {
    setDeleteDialogButtonDisabled(true);
    setDeleteDialogTFValue('');
  }, [deleteDialogOpen]);

  useEffect(() => {
    if (deleteError?.status === 403) {
      dispatch(
        pushToast({
          message: t(`${translationJSONPrefix}.unauthorizedMessages.delete`),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteError]);

  const handleCellClick = (e) => {
    if (e?.field === 'name') navigate(`${addPageRoute}?id=${e?.row?.id}`);
  };

  useEffect(() => {
    setDeleteDialogOpen(false);
  }, [isDeleteSuccess, isDeleteError]);

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.ACCESS_CONTROL}
        description={t(`${translationJSONPrefix}.unauthorizedMessages.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setDrawerOpen(res)}
      drawer={SECTIONS.APP_CONFIGURATION}
    >
      <DialogComponent
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        setTextFieldValue={setDeleteDialogTFValue}
        textField={{
          name: 'name',
          value: deleteDialogTFValue,
          onChange: (e) => {
            setDeleteDialogTFValue(e.target.value);

            if (selectedLMs.length > 1) {
              if (e.target.value === 'permanently delete')
                setDeleteDialogButtonDisabled(false);
              return;
            }

            const names = filterByIds(selectedLMs, data).map((p) => p.name);

            setDeleteDialogButtonDisabled(!(e.target.value === names[0]));
          },
          placeholder:
            selectedLMs.length > 1
              ? t(
                  `${translationJSONPrefix}.deleteDialog.textFieldPlaceholderPlural`,
                )
              : t(`${translationJSONPrefix}.deleteDialog.textFieldPlaceholder`),
        }}
        title={t(`${translationJSONPrefix}.deleteDialog.title`)}
        content={
          selectedLMs.length > 1
            ? t(`${translationJSONPrefix}.deleteDialog.contentPlural`)
            : t(`${translationJSONPrefix}.deleteDialog.content`)
        }
        highlightedTitle={
          selectedLMs.length === 1
            ? t(`${translationJSONPrefix}.deleteDialog.highlightedTitle`)
            : ''
        }
        highlightedContent={
          selectedLMs.length === 1
            ? filterByIds(selectedLMs, data)[0]?.name
            : ''
        }
        onSubmit={(e) => {
          deleteLanguageModels({
            ids: selectedLMs,
          });
        }}
        submitBtnDisabled={deleteDialogButtonDisabled}
        isDeleteLoading={isDeleteLoading}
      />
      <Header
        header={t(`${translationJSONPrefix}.title`)}
        addButtonLabel={t('appConfigurationSection.components.header.add')}
        deleteButton={true}
        deleteButtonDisabled={selectedLMs.length === 0}
        addButtonOnClick={() => navigate(addPageRoute)}
        deleteButtonOnClick={() => setDeleteDialogOpen(true)}
      />
      {isFetching ? (
        <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
      ) : (
        <DataGridWrapper>
          <DataGridTable
            onCellClick={handleCellClick}
            row={sortByDateField([...data], 'lastUpdatedDate')}
            column={columns(t)}
            checkboxSelection
            autoHeight
            rowId={'id'}
            onSelectionModelChange={setSelectedLMs}
          />
        </DataGridWrapper>
      )}
    </PageContainer>
  );
};

export default LanguageModelsConfigPage;
