/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, LinearProgress, Tooltip, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { DeleteForever, Storage } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  useLazyGetDataSourceQuery,
  useDeleteDataSourceByIdMutation,
} from '../../redux/services/speciphicAsk/speciphic.ask.api';
import ConfirmationDialog from '../../components/confirmationDialog';
import DisplayTime from '../../components/timezone.component';
import AppShadowBox from '../../components/app.shadowbox/app.shadowbox.component';
import DataGridTable from '../../components/datagrid/datagrid.component';
import PageContainer from '../../components/pageContainer';
import PageTitle from '../../components/page.title';
import * as PATHS from '../../constants/path';
import {
  StyledWrapper,
  LoadingTypography,
  StyledNameWrapper,
} from './dataSources.styled';
import UnauthorizedMessage from '../../components/unauthorized';
import { pushToast } from '../../redux/reducers/toasts.slice';
import useDebounce from '../../hooks/useDebounce';
import { DEBOUNCE_DELAY } from '../../constants/debounce';

const DEFAULT_PAGE_SIZE = 50;

const translationJSONPrefix = 'dataSourcesPage';
const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const DataSourcesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDeleteDataSource, setOpenDeleteDataSource] = useState(false);
  const [dataSource, setDataSource] = useState({
    id: '',
    name: '',
  });
  const [isDataSourceInCollection, setIsDataSourceInCollection] =
    useState(false);
  const [isDeleteContainer, setIsDeleteContainer] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchQuery, setSearchQuery] = useState('');

  const [
    getDataSources,
    {
      data: dataSources = [],
      error: dataSourcesError,
      isError: isGetDataSourcesError,
      isFetching: isGetDataSourcesFetching,
      isSuccess: isGetDataSourcesSuccess,
    },
  ] = useLazyGetDataSourceQuery({});

  useDebounce(
    () => {
      getDataSources({
        pageNo,
        pageSize,
        query: searchQuery,
      });
    },
    [pageNo, pageSize, searchQuery],
    DEBOUNCE_DELAY,
  );

  const [
    deleteDataSource,
    {
      data: deleteddataSource = [],
      error: dataSourceDeleteError,
      isError: isDeletedataSourceError,
      isLoading: isDeletedataSourceLoading,
      isSuccess: isDeletedataSourceSuccess,
    },
  ] = useDeleteDataSourceByIdMutation();

  useEffect(() => {
    if (dataSourceDeleteError?.status === 403) {
      dispatch(
        pushToast({
          message: t(`${unauthorizedTranslationPrefix}.delete`),
          severity: 'error',
        }),
      );
    }
  }, [isDeletedataSourceError]);

  const openDeleteFileDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteDataSource(true);
    setDataSource({
      id: row.id,
      name: row.name,
    });
  };

  const getDataSourceType = (props) => {
    const formattedType = props.row.type
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return <StyledWrapper>{formattedType}</StyledWrapper>;
  };

  const getDataSourceName = (props) => {
    return (
      <StyledNameWrapper>
        <Storage sx={{ marginRight: 1 }} />
        {props.row.name}
      </StyledNameWrapper>
    );
  };

  const actionColumn = (props) => {
    return (
      <>
        <ButtonGroup>
          <Tooltip title={t('dataSourcesPage.buttons.deleteButton')} arrow>
            <LoadingButton
              variant="text"
              onClick={(e) => openDeleteFileDialog(e, props.row)}
              loading={
                props.row.id == dataSource.id && isDeletedataSourceLoading
              }
            >
              <span>
                <DeleteForever />
              </span>
            </LoadingButton>
          </Tooltip>
        </ButtonGroup>
      </>
    );
  };

  const handleDeleteDataSource = (dataSourcesIdDelete) => {
    setOpenDeleteDataSource(false);
    setIsDataSourceInCollection(false);
    deleteDataSource({
      dataSourceId: dataSourcesIdDelete,
      isDeleteContainer: isDeleteContainer,
    });
  };

  const handleCancelDeleteClick = () => {
    setOpenDeleteDataSource(false);
    setIsDataSourceInCollection(false);
  };

  const columns = [
    {
      field: 'name',
      headerName: t('dataSourcesPage.columns.dataSourceName'),
      renderCell: getDataSourceName,
      width: 300,
      sortable: false,
    },
    {
      field: 'type',
      headerName: t('dataSourcesPage.columns.dataSourceType'),
      renderCell: getDataSourceType,
      width: 200,
      sortable: false,
    },
    {
      field: 'lastUpdatedBy',
      headerName: t('dataSourcesPage.columns.createdBy'),
      width: 300,
      sortable: false,
    },
    {
      field: 'lastUpdatedDate',
      headerName: t('dataSourcesPage.columns.lastUpdatedDate'),
      renderCell: (params) => <DisplayTime time={params.row.lastUpdatedDate} />,
      minWidth: 300,
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: t('dataSourcesPage.columns.actions'),
      width: 80,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  const handleRowClick = (row) => {
    navigate(`${PATHS.DATA_SOURCES}/${row.id}`);
  };

  if (dataSourcesError?.status === 403)
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <AppShadowBox>
        <PageTitle
          title={t('dataSourcesPage.title')}
          subtitle={t('dataSourcesPage.subtitle')}
          path={PATHS.ADD_DATA_SOURCE}
          buttonText={'Add Data Source'}
          isActionButtonNeeded={true}
        />

        <>
          <DataGridTable
            isNextPageLoading={isGetDataSourcesFetching}
            column={columns}
            row={dataSources?.data || []}
            total={dataSources?.total || 0}
            rowId="id"
            pageSize={pageSize}
            serverSideSearch={true}
            onSearch={(e) => setSearchQuery(e)}
            isSearching={isGetDataSourcesFetching}
            rowHeightAuto={true}
            isAddButtonNeeded={false}
            path={PATHS.ADD_DATA_SOURCE}
            buttonText={t('dataSourcesPage.buttons.addDataSourceButton')}
            handleRow={(props) => handleRowClick(props.row)}
            isPaginationRequired={true}
            customPageNo={pageNo}
            customPageSize={pageSize}
            onCustomPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onCustomPageChange={(newPage) => setPageNo(newPage)}
          />

          <ConfirmationDialog
            open={openDeleteDataSource}
            title={t('dataSourcesPage.labels.title')}
            messageLine1={t('dataSourcesPage.labels.deleteMessage1', {
              name: dataSource?.name,
            })}
            messageLine2={
              isDataSourceInCollection
                ? t(
                    'dataSourcesPage.alerts.deleteConnectedFileCollectionsAlertMessage',
                    {
                      name: dataSource?.name,
                    },
                  )
                : t('dataSourcesPage.alerts.deleteAlert')
            }
            alertSeverity={
              isDataSourceInCollection
                ? t('dataSourcesPage.alerts.error')
                : t('dataSourcesPage.alerts.warning')
            }
            messageLine3={t('dataSourcesPage.labels.deleteFromCloudMessage')}
            isAdditionalCheck
            isYesDisabled={isDataSourceInCollection}
            checked={isDeleteContainer}
            onChange={setIsDeleteContainer}
            yesButtonLabel={t('dataSourcesPage.buttons.deleteButton')}
            noButtonLabel={t('dataSourcesPage.buttons.cancelButton')}
            onYesClick={() => handleDeleteDataSource(dataSource?.id)}
            onNoClick={handleCancelDeleteClick}
          />
        </>
      </AppShadowBox>
    </PageContainer>
  );
};

DataSourcesPage.propTypes = {
  row: PropTypes.object,
};

export default DataSourcesPage;
