import React, { useEffect, useState } from 'react';
import { PeopleAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../../components/pageContainer';
import MetricCard from '../../../components/metricCard';
import {
  CustomGrid,
  DataGridBox,
  MetricsGrid,
  MetricsInnerContainer,
  StyledDataGrid,
} from '../dashboard.page.styled';
import { DRAWER_WIDTH, SECTIONS } from '../../../constants/drawer';
import { LOAD_TITLE } from '../../../utils/functions';
import { useGetAnalyticsUsersQuery } from '../../../redux/services/speciphicAsk';
import Loader from '../../../components/loader';
import colors from '../../../themes/colors';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import UnauthorizedMessage from '../../../components/unauthorized';
import CustomToolbar from '../components/customToolbar';

const translationJSONPrefix = `analyticsSection.usersEngagementPage`;

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const data_schema = (t) => {
  const translationPrefix = `${translationJSONPrefix}.dataSchemaTitles`;

  return {
    metrics: [
      {
        id: 'usersTotal',
        label: t(`${translationPrefix}.usersTotal`),
        icon: (props) => <PeopleAlt {...props} />,
        value: '...',
        indicator: 'up',
      },
    ],
    tableData: [],
  };
};

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.columns`;

  return [
    {
      field: 'user',
      headerName: t(`${translationPrefix}.user`),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'fileCollectionCount',
      headerName: t(`${translationPrefix}.fileCollectionCount`),
      minWidth: 80,
      type: 'number',
      flex: 0.7,
      renderCell: (params) => {
        return <span style={{ color: colors.blue.B400 }}>{params.value}</span>;
      },
    },
    {
      field: 'dataSourceCount',
      headerName: t(`${translationPrefix}.dataSourceCount`),
      minWidth: 80,
      type: 'number',
      flex: 0.7,
      renderCell: (params) => {
        return <span style={{ color: colors.green.G400 }}>{params.value}</span>;
      },
    },
    {
      field: 'conversationCount',
      headerName: t(`${translationPrefix}.conversationCount`),
      minWidth: 80,
      type: 'number',
      flex: 0.7,
      renderCell: (params) => {
        return <span style={{ color: colors.blue.B400 }}>{params.value}</span>;
      },
    },
    {
      field: 'queriesAsked',
      headerName: t(`${translationPrefix}.queriesAsked`),
      minWidth: 80,
      type: 'number',
      flex: 0.5,
      renderCell: (params) => {
        return <span style={{ color: colors.blue.B400 }}>{params.value}</span>;
      },
    },
    {
      field: 'errorEncounteredCount',
      headerName: t(`${translationPrefix}.errorEncounteredCount`),
      minWidth: 100,
      type: 'number',
      flex: 0.6,
      renderCell: (params) => {
        return <span style={{ color: colors.red.R400 }}>{params.value}</span>;
      },
    },
    {
      field: 'helpfulTrueCount',
      headerName: t(`${translationPrefix}.totalPositiveFeedback`),
      minWidth: 100,
      type: 'number',
      flex: 0.4,
      renderCell: (params) => {
        return <span style={{ color: colors.green.G400 }}>{params.value}</span>;
      },
    },
    {
      field: 'helpfulFalseCount',
      headerName: t(`${translationPrefix}.totalNegativeFeedback`),
      minWidth: 100,
      type: 'number',
      flex: 0.4,
      renderCell: (params) => {
        return <span style={{ color: colors.red.R400 }}>{params.value}</span>;
      },
    },
  ];
};

export const AnalyticsUsersCollections = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  useEffect(
    () => LOAD_TITLE(document, t(`${translationJSONPrefix}.title`)),
    [],
  );

  const [pageData, setPageData] = useState(data_schema(t));
  const [pageDrawerOpen, setIsPageDrawerOpen] = useState(true);

  const { data, error, isError, isLoading, isSuccess } =
    useGetAnalyticsUsersQuery();

  useEffect(() => {
    if (data) {
      const updatedPageData = { ...pageData };
      updatedPageData.metrics[0].value = data?.totalUsers;
      updatedPageData.tableData = data?.data.map((d, i) => {
        return { id: i, ...d };
      });

      setPageData(updatedPageData);
    }
  }, [isLoading]);

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={pageDrawerOpen}
        drawer={SECTIONS.ANALYTICS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsPageDrawerOpen(res)}
      drawer={SECTIONS.ANALYTICS}
    >
      {isLoading && (
        <Loader
          label={t(`${translationJSONPrefix}.loadingMessage.loadingData`)}
        />
      )}

      <MetricsGrid
        style={{ width: width - (pageDrawerOpen ? DRAWER_WIDTH : 0) - 50 }}
      >
        <MetricsInnerContainer>
          {pageData?.metrics?.map((item, index) => (
            <CustomGrid item key={index}>
              <MetricCard
                icon={item.icon}
                indicator={item.indicator}
                value={item.value.toString()}
                label={item.label}
                valueColor={
                  item.indicator === 'down'
                    ? colors.red.R300
                    : colors.green.G400
                }
              />
            </CustomGrid>
          ))}
        </MetricsInnerContainer>
      </MetricsGrid>

      {pageData?.tableData?.length > 0 && (
        <DataGridBox>
          <StyledDataGrid
            rows={pageData?.tableData}
            columns={columns(t)}
            pageSize={50}
            disableRowSelectionOnClick
            autoHeight
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridBox>
      )}
    </PageContainer>
  );
};

export default AnalyticsUsersCollections;
