/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { getUserFromSession } from '../../user';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  SPECIPHIC_ASK_API_URL,
  API_V1_PATH,
} from '../../../constants/apiPaths';
import {
  resultsEPFactory,
  addFeedbackEPFactory,
  getIngestedFilesEPFactory,
  fileCollectionsEPFactory,
  getAllFileCollectionsForTableEPFactory,
  addCollectionsEPFactory,
  testCollectionsEPFactory,
  deleteCollectionsEPFactory,
  deleteIndividualFileEPFactory,
  getIndividualFileCollectionEPFactory,
  addChatHistoryEPFactory,
  exportPDFEPFactory,
  getAnalyticsChartsEPFactory,
  getAnalyticsFileCollectionsEPFactory,
  getAnalyticsFilesCollectionChartEPFactory,
  getAnalyticsSearchPerformanceEPFactory,
  getAnalyticsTotalCountsEPFactory,
  getAnalyticsUsersEPFactory,
  getChatHistoryEPFactory,
  getLanguagesEPFactory,
  getSearchEnginesEPFactory,
  getFaqEPFactory,
  getSummaryEPFactory,
  addNewUserEPFactory,
  deleteUserEPFactory,
  updateUserEPFactory,
  addDataSourceEPFactory,
  addFilesToCollectionEPFactory,
  getDataSourceEPFactory,
  addPromptIndividualFileCollectionEPFactory,
  addNewValuesFileCollectionEPfactory,
  getReportSourcesByIdEPFactory,
  getReportSourcesEPFactory,
  addReportSourceEPFactory,
  deleteReportSourceEPFactory,
  updateReportSourceEPFactory,
  getReportSummaryTopicsEPFactory,
  addReportSummaryTopicEPFactory,
  deleteReportSummaryTopicEPFactory,
  updateReportSummaryTopicEPFactory,
  getFinancialReportEPFactory,
  getAllFinancialReportsEPFactory,
  getPreferencesEPFactory,
  addPreferenceEPFactory,
  deletePreferenceEPFactory,
  addNewReportEPFactory,
  getReportSummaryTopicByIdEPFactory,
  addMetaDataEPFactory,
  addMetaDataFiltersEPFactory,
  addMetaDataPropertiesEPFactory,
  getMetaDataPropertiesEPFactory,
  getMetaDataByFileNameEPFactory,
  deleteMetaDataByFileNameEPFactory,
  deleteMetaDataPropertyEPFactory,
  deleteDefinedMetadataPropertyEPFactory,
  deleteMetaDataIndexEPFactory,
  addFaqEPFactory,
  updateFaqEPfactory,
  deleteFaqEPFactory,
  getSiteConfigurationEPFactory,
  addSiteConfigurationEPFactory,
  updateSiteConfigurationEPFactory,
  addEmbeddingsEPFactory,
  addConversationQueryEPFactory,
  getConversationEPFactory,
  getConversationHistoryEPFactory,
  deleteDataSourceByIdEPFactory,
  addFilesToDataSourceEPFactory,
  getFileCollectionSettingsEPFactory,
  updateFileCollectionSettingsEPFactory,
  getStockSymbolEPFactory,
  deleteFilesFromDataSourceEPFactory,
  getDataSourceFilesEPFactory,
  addMarketIntelligenceConfigEPFactory,
  getFinancialReportPdfEPFactory,
  getDownloadFileCollectionAnalyticsEPFactory,
  getConversationFileCollectionHistoryEPFactory,
  getReportSourcesPreferencesEPFactory,
  createReportSourcesPreferencesEPFactory,
  deleteReportSourcePreferenceEPFactory,
  addGenerateReportSourceEPFactory,
  getUnlistedCompaniesEPFactory,
  getRBACEPFactory,
  getAllRBACEPFactory,
  createRBACEPFactory,
  updateRBACEPFactory,
  deleteManyRBACEPFactory,
  getFileCollectionAccessPoliciesEPFactory,
  getFileCollectionAccessPolicyByNameEPFactory,
  createFileCollectionAccessPolicyEPFactory,
  updateFileCollectionAccessPolicyEPFactory,
  deleteFileCollectionAccessPolicyEPFactory,
  deleteFileCollectionAccessPoliciesEPFactory,
  getPermissionsEPFactory,
  getResourcePermissionEPFactory,
  getMiRelatedEventsEPFactory,
  getAllLanguageModelsEPFactory,
  getLanguageModelEPFactory,
  createLanguageModelEPFactory,
  updateLanguageModelEPFactory,
  deleteLanguageModelsEPFactory,
} from './endpoints';

export const speciphicAskApi = createApi({
  reducerPath: 'speciphicAskApi',
  tagTypes: [
    'fileCollections',
    'individualFileCollections',
    'chatHistory',
    'users',
    'data-sources',
    'data-source',
    'report-summary-topics',
    'preferences',
    'faqs',
    'site-configuration',
    'conversation',
    'filecollection-settings',
    'ingestedFiles',
    'report-sources-preferences',
    'rbac-get-all',
    'rbac-get',
    'rbac-create',
    'rbac-update',
    'rbac-delete',
    'resource-permission',
    'permissions',
    'metaData',
    'language-models',
    'language-model',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${SPECIPHIC_ASK_API_URL}/${API_V1_PATH}`,
    prepareHeaders: (headers) => {
      const user = getUserFromSession();
      const token = user?.access_token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getResults: resultsEPFactory(builder),
    addFeedback: addFeedbackEPFactory(builder),
    getIngestedFiles: getIngestedFilesEPFactory(builder),
    getFileCollections: fileCollectionsEPFactory(builder),
    getAllFileCollectionsForTable:
      getAllFileCollectionsForTableEPFactory(builder),
    addFileCollections: addCollectionsEPFactory(builder),
    testFileCollections: testCollectionsEPFactory(builder),
    deleteCollections: deleteCollectionsEPFactory(builder),
    deleteIndividualFile: deleteIndividualFileEPFactory(builder),
    getIndividualFileCollection: getIndividualFileCollectionEPFactory(builder),
    addChatHistory: addChatHistoryEPFactory(builder),
    exportPDF: exportPDFEPFactory(builder),
    getAnalyticsCharts: getAnalyticsChartsEPFactory(builder),
    getAnalyticsFileCollections: getAnalyticsFileCollectionsEPFactory(builder),
    getAnalyticsFilesCollectionChart:
      getAnalyticsFilesCollectionChartEPFactory(builder),
    getAnalyticsSearchPerformance:
      getAnalyticsSearchPerformanceEPFactory(builder),
    getAnalyticsTotalCounts: getAnalyticsTotalCountsEPFactory(builder),
    getAnalyticsUsers: getAnalyticsUsersEPFactory(builder),
    getPermissions: getPermissionsEPFactory(builder),
    getResourcePermission: getResourcePermissionEPFactory(builder),
    getChatHistory: getChatHistoryEPFactory(builder),
    getLanguages: getLanguagesEPFactory(builder),
    getSearchEngines: getSearchEnginesEPFactory(builder),
    getFaq: getFaqEPFactory(builder),
    getSummary: getSummaryEPFactory(builder),
    addNewUser: addNewUserEPFactory(builder),
    deleteUser: deleteUserEPFactory(builder),
    updateUser: updateUserEPFactory(builder),
    addDataSource: addDataSourceEPFactory(builder),
    addFilesToCollection: addFilesToCollectionEPFactory(builder),
    getDataSource: getDataSourceEPFactory(builder),
    addPromptToIndividualFileCollection:
      addPromptIndividualFileCollectionEPFactory(builder),
    addNewValuesToFileCollection: addNewValuesFileCollectionEPfactory(builder),
    getReportSource: getReportSourcesEPFactory(builder),
    getReportSourceById: getReportSourcesByIdEPFactory(builder),
    addReportSource: addReportSourceEPFactory(builder),
    deleteReportSource: deleteReportSourceEPFactory(builder),
    updateReportSource: updateReportSourceEPFactory(builder),
    getReportSummaryTopics: getReportSummaryTopicsEPFactory(builder),
    getReportSummaryTopicById: getReportSummaryTopicByIdEPFactory(builder),
    addReportSummaryTopic: addReportSummaryTopicEPFactory(builder),
    deleteReportSummaryTopic: deleteReportSummaryTopicEPFactory(builder),
    updateReportSummaryTopic: updateReportSummaryTopicEPFactory(builder),
    getFinancialReport: getFinancialReportEPFactory(builder),
    getAllFinancialReports: getAllFinancialReportsEPFactory(builder),
    addNewReport: addNewReportEPFactory(builder),
    getPreferences: getPreferencesEPFactory(builder),
    addPreference: addPreferenceEPFactory(builder),
    deletePreference: deletePreferenceEPFactory(builder),
    addMetaDataFilters: addMetaDataFiltersEPFactory(builder),
    addMetaData: addMetaDataEPFactory(builder),
    addMetaDataProperties: addMetaDataPropertiesEPFactory(builder),
    getMetaDataProperties: getMetaDataPropertiesEPFactory(builder),
    getMetaDataByFileName: getMetaDataByFileNameEPFactory(builder),
    deleteMetaDataByFileName: deleteMetaDataByFileNameEPFactory(builder),
    deleteMetaDataProperty: deleteMetaDataPropertyEPFactory(builder),
    deleteDefinedMetadataProperty:
      deleteDefinedMetadataPropertyEPFactory(builder),
    deleteMetaDataIndex: deleteMetaDataIndexEPFactory(builder),
    addFaqToCollection: addFaqEPFactory(builder),
    updateFaq: updateFaqEPfactory(builder),
    deleteFaq: deleteFaqEPFactory(builder),
    getSiteConfiguration: getSiteConfigurationEPFactory(builder),
    addSiteConfiguration: addSiteConfigurationEPFactory(builder),
    updateSiteConfiguration: updateSiteConfigurationEPFactory(builder),
    addEmbeddings: addEmbeddingsEPFactory(builder),
    addConversation: addConversationQueryEPFactory(builder),
    getConversation: getConversationEPFactory(builder),
    getConversationHistory: getConversationHistoryEPFactory(builder),
    deleteDataSourceById: deleteDataSourceByIdEPFactory(builder),
    addFilesToDataSource: addFilesToDataSourceEPFactory(builder),
    getFileCollectionSettings: getFileCollectionSettingsEPFactory(builder),
    updateFileCollectionSettings:
      updateFileCollectionSettingsEPFactory(builder),
    getStockSymbol: getStockSymbolEPFactory(builder),
    deleteFilesFromDataSource: deleteFilesFromDataSourceEPFactory(builder),
    getDataSourceFiles: getDataSourceFilesEPFactory(builder),
    addMarketIntelligenceConfiguration:
      addMarketIntelligenceConfigEPFactory(builder),
    getFinancialReportPdf: getFinancialReportPdfEPFactory(builder),
    downloadFileCollectionAnalytics:
      getDownloadFileCollectionAnalyticsEPFactory(builder),
    getConversationCollectionHistory:
      getConversationFileCollectionHistoryEPFactory(builder),
    getReportSourcesPreferences: getReportSourcesPreferencesEPFactory(builder),
    addReportSourcesPreferences:
      createReportSourcesPreferencesEPFactory(builder),
    deleteReportSourcePreference:
      deleteReportSourcePreferenceEPFactory(builder),
    generateReportSource: addGenerateReportSourceEPFactory(builder),
    getUnlistedCompanies: getUnlistedCompaniesEPFactory(builder),
    getOneRBAC: getRBACEPFactory(builder),
    getAllRBAC: getAllRBACEPFactory(builder),
    createRBAC: createRBACEPFactory(builder),
    updateRBAC: updateRBACEPFactory(builder),
    deleteManyRBAC: deleteManyRBACEPFactory(builder),
    getFileCollectionAccessPolicies:
      getFileCollectionAccessPoliciesEPFactory(builder),
    getFileCollectionAccessPolicyByName:
      getFileCollectionAccessPolicyByNameEPFactory(builder),
    createFileCollectionAccessPolicy:
      createFileCollectionAccessPolicyEPFactory(builder),
    updateFileCollectionAccessPolicy:
      updateFileCollectionAccessPolicyEPFactory(builder),
    deleteFileCollectionAccessPolicy:
      deleteFileCollectionAccessPolicyEPFactory(builder),
    deleteFileCollectionAccessPolicies:
      deleteFileCollectionAccessPoliciesEPFactory(builder),
    // mi related events
    getMiRelatedEvents: getMiRelatedEventsEPFactory(builder),
    getAllLanguageModels: getAllLanguageModelsEPFactory(builder),
    getLanguageModel: getLanguageModelEPFactory(builder),
    createLanguageModel: createLanguageModelEPFactory(builder),
    updateLanguageModel: updateLanguageModelEPFactory(builder),
    deleteLanguageModels: deleteLanguageModelsEPFactory(builder),
  }),
});

export const {
  useAddNewReportMutation,
  useGetAllFinancialReportsQuery,
  useLazyGetAllFinancialReportsQuery,
  useLazyGetFinancialReportQuery,
  useGetResultsMutation,
  useAddFeedbackMutation,
  useGetIngestedFilesQuery,
  useLazyGetIngestedFilesQuery,
  useGetFileCollectionsQuery,
  useLazyGetFileCollectionsQuery,
  useGetAllFileCollectionsForTableQuery,
  useLazyGetAllFileCollectionsForTableQuery,
  useGetIndividualFileCollectionQuery,
  useLazyGetIndividualFileCollectionQuery,
  useAddFileCollectionsMutation,
  useTestFileCollectionsMutation,
  useDeleteCollectionsMutation,
  useDeleteIndividualFileMutation,
  useAddChatHistoryMutation,
  useExportPDFMutation,
  useGetAnalyticsChartsMutation,
  useGetAnalyticsFileCollectionsQuery,
  useLazyGetAnalyticsFileCollectionsQuery,
  useGetAnalyticsFilesCollectionChartMutation,
  useGetAnalyticsSearchPerformanceQuery,
  useLazyGetAnalyticsSearchPerformanceQuery,
  useGetAnalyticsUsersQuery,
  useGetAnalyticsTotalCountsQuery,
  useGetPermissionsQuery,
  useGetResourcePermissionQuery,
  useLazyGetPermissionsQuery,
  useLazyGetResourcePermissionQuery,
  useGetChatHistoryQuery,
  useGetLanguagesQuery,
  useLazyGetLanguagesQuery,
  useLazyGetSearchEnginesQuery,
  useGetSearchEnginesQuery,
  useGetSummaryQuery,
  useGetSummaryMutation,
  useAddNewUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useAddDataSourceMutation,
  useAddFilesToCollectionMutation,
  useGetDataSourceQuery,
  useLazyGetDataSourceFilesQuery,
  useLazyGetDataSourceQuery,
  useAddPromptToIndividualFileCollectionMutation,
  useAddNewValuesToFileCollectionMutation,
  useGetReportSourceQuery,
  useLazyGetReportSourceQuery,
  useGetReportSourceByIdQuery,
  useAddReportSourceMutation,
  useDeleteReportSourceMutation,
  useUpdateReportSourceMutation,
  useLazyGetReportSummaryTopicsQuery,
  useGetReportSummaryTopicByIdQuery,
  useAddReportSummaryTopicMutation,
  useDeleteReportSummaryTopicMutation,
  useUpdateReportSummaryTopicMutation,
  useGetPreferencesQuery,
  useLazyGetPreferencesQuery,
  useAddPreferenceMutation,
  useDeletePreferenceMutation,
  useAddMetaDataMutation,
  useAddMetaDataFiltersMutation,
  useAddMetaDataPropertiesMutation,
  useGetMetaDataPropertiesQuery,
  useLazyGetMetaDataPropertiesQuery,
  useGetMetaDataByFileNameQuery,
  useDeleteMetaDataByFileNameMutation,
  useDeleteMetaDataPropertyMutation,
  useDeleteDefinedMetadataPropertyMutation,
  useDeleteMetaDataIndexMutation,
  useAddFaqToCollectionMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
  useGetFaqQuery,
  useLazyGetFaqQuery,
  useGetSiteConfigurationQuery,
  useLazyGetSiteConfigurationQuery,
  useAddSiteConfigurationMutation,
  useUpdateSiteConfigurationMutation,
  useAddEmbeddingsMutation,
  useAddConversationMutation,
  useGetConversationQuery,
  useGetConversationHistoryQuery,
  useDeleteDataSourceByIdMutation,
  useAddFilesToDataSourceMutation,
  useGetFileCollectionSettingsQuery,
  useLazyGetFileCollectionSettingsQuery,
  useUpdateFileCollectionSettingsMutation,
  useGetStockSymbolQuery,
  useLazyGetStockSymbolQuery,
  useDeleteFilesFromDataSourceMutation,
  useGetDataSourceFilesQuery,
  useAddMarketIntelligenceConfigurationMutation,
  useLazyGetFinancialReportPdfQuery,
  useLazyDownloadFileCollectionAnalyticsQuery,
  useLazyGetConversationCollectionHistoryQuery,
  useLazyGetConversationHistoryQuery,
  useGetReportSourcesPreferencesQuery,
  useLazyGetReportSourcesPreferencesQuery,
  useAddReportSourcesPreferencesMutation,
  useDeleteReportSourcePreferenceMutation,
  useGenerateReportSourceMutation,
  useGetUnlistedCompaniesQuery,
  useLazyGetOneRBACQuery,
  useLazyGetAllRBACQuery,
  useCreateRBACMutation,
  useUpdateRBACMutation,
  useDeleteManyRBACMutation,
  useLazyGetFileCollectionAccessPoliciesQuery,
  useLazyGetFileCollectionAccessPolicyByNameQuery,
  useCreateFileCollectionAccessPolicyMutation,
  useUpdateFileCollectionAccessPolicyMutation,
  useDeleteFileCollectionAccessPolicyMutation,
  useDeleteFileCollectionAccessPoliciesMutation,
  useGetMiRelatedEventsQuery,
  useGetAllLanguageModelsQuery,
  useLazyGetLanguageModelQuery,
  useCreateLanguageModelMutation,
  useUpdateLanguageModelMutation,
  useDeleteLanguageModelsMutation,
} = speciphicAskApi;
