import React, { useEffect, useState } from 'react';
import {
  ManageSearch,
  ScreenSearchDesktop,
  SearchOff,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from '@mui/icons-material';
import { Stack, Grid, Typography, IconButton } from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DropdownOne from '../../../components/dropdownone';
import DisplayTime from '../../../components/timezone.component';
import PageContainer from '../../../components/pageContainer';
import MetricCard from '../../../components/metricCard';
import {
  CustomGrid,
  DataGridBox,
  MetricsGrid,
  MetricsInnerContainer,
  StyledDataGrid,
} from '../dashboard.page.styled';
import {
  ProgressBar,
  PaginationTextTypography,
} from './searchPerformance.page.styled';
import { DRAWER_WIDTH, SECTIONS } from '../../../constants/drawer';
import { RowPerPageOptions } from '../../../constants/rowPerPageOptions';
import { LOAD_TITLE, nFormatter } from '../../../utils/functions';
import { useLazyGetAnalyticsSearchPerformanceQuery } from '../../../redux/services/speciphicAsk';
import Loader from '../../../components/loader';
import colors from '../../../themes/colors';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import UnauthorizedMessage from '../../../components/unauthorized';
import CustomToolbar from '../components/customToolbar';

const translationJSONPrefix = 'analyticsSection.searchPerformancePage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const data_schema = (t) => {
  const translationPrefix = `${translationJSONPrefix}.dataSchemaTitles`;

  return {
    metrics: [
      {
        id: 'totalSearches',
        label: t(`${translationPrefix}.totalSearches`),
        icon: (props) => <ScreenSearchDesktop {...props} color="primary" />,
        value: '...',
        indicator: 'up',
        color: colors.blue.B500,
      },
      {
        id: 'successfulSearches',
        label: t(`${translationPrefix}.successfulSearches`),
        icon: (props) => <ManageSearch {...props} color="success" />,
        value: '...',
        indicator: 'up',
        color: colors.green.G400,
      },
      {
        id: 'halunicationsPrevented',
        label: t(`${translationPrefix}.halunicationsPrevented`),
        icon: (props) => <SearchOff {...props} color="info" />,
        value: '...',
        indicator: 'down',
        color: colors.purple.P400,
      },
    ],
    success_queries: [],
    halunications_prevented: [],
  };
};

const successfulSearchesColumns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.columns`;

  return [
    {
      field: 'user',
      headerName: t(`${translationPrefix}.user`),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'query',
      headerName: t(`${translationPrefix}.query`),
      minWidth: 150,
      flex: 1.3,
    },
    {
      field: 'results',
      valueGetter: (params) => `${params.row?.results?.answer}`,
      headerName: t(`${translationPrefix}.result`),
      minWidth: 250,
      flex: 2.7,
      renderCell: (params) => {
        return (
          <span title={params.value} style={{ color: colors.green.G400 }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'collection',
      headerName: t(`${translationPrefix}.collection`),
      valueGetter: (params) => `${params.row?.collection_data?.name}`,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      type: 'date',
      renderCell: (params) => (
        <DisplayTime time={params.value} format="DD/MM/YYYY hh:mm A" />
      ),
      minWidth: 200,
      flex: 1,
    },
  ];
};

const halunicationsPreventedColumns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.columns`;

  return [
    {
      field: 'user',
      headerName: t(`${translationPrefix}.user`),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'query',
      headerName: t(`${translationPrefix}.hQuery`),
      minWidth: 300,
      flex: 2,
    },
    {
      field: 'collection',
      headerName: t(`${translationPrefix}.collection`),
      valueGetter: (params) => `${params.row?.collection_data?.name}`,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      type: 'date',
      renderCell: (params) => (
        <DisplayTime time={params.row.timestamp} format="DD/MM/YYYY hh:mm A" />
      ),
      minWidth: 150,
      flex: 1,
    },
  ];
};

export const SearchPerformance = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  useEffect(
    () => LOAD_TITLE(document, t(`${translationJSONPrefix}.title`)),
    [],
  );

  const [pageData, setPageData] = useState(data_schema(t));
  const [pageDrawerOpen, setIsPageDrawerOpen] = useState(true);
  const [showErrorData, setShowErrorData] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const [
    getSearchPerformance,
    {
      data: searchPerformance = {},
      error,
      isSuccess: isGetSearchPerformanceSuccess,
      isLoading: isGetSearchPerformanceLoading,
      isError: isGetSearchPerformanceError,
      isFetching: isGetSearchPerformanceFetching,
    },
  ] = useLazyGetAnalyticsSearchPerformanceQuery();

  useEffect(() => {
    getSearchPerformance({
      pageNo: pageNo + 1,
      pageSize: pageSize,
    });
  }, []);

  useEffect(() => {
    getSearchPerformance({
      pageNo: pageNo + 1,
      pageSize: pageSize,
    });
  }, [pageSize]);

  useEffect(() => {
    if (searchPerformance) {
      const updatedPageData = { ...pageData };
      updatedPageData.metrics[0].value = searchPerformance?.totalSearches;
      updatedPageData.metrics[1].value =
        searchPerformance?.totalSuccessfulSearches;
      updatedPageData.metrics[2].value =
        searchPerformance?.totalHalunicationsPrevented;
      updatedPageData.metrics.forEach((t) => (t.indicator = ''));
      updatedPageData.success_queries =
        searchPerformance?.successfulSearchesData;
      updatedPageData.halunications_prevented =
        searchPerformance?.halunicationsPreventedData;

      setPageData(updatedPageData);
    }
  }, [searchPerformance, isGetSearchPerformanceSuccess]);

  const handleGridClick = (index) => {
    if (index === 2) setShowErrorData(true);
    else setShowErrorData(false);
  };

  const CustomPagination = () => (
    <Stack direction="row" spacing={3} alignItems="center">
      <Grid container alignItems="center" spacing={1} sx={{ pb: 1 }}>
        <Grid item>
          <Typography variant="body1">
            {' '}
            {t(`${translationJSONPrefix}.columns.rows`)}
          </Typography>
        </Grid>
        <Grid item alignItems="center">
          <DropdownOne
            value={pageSize}
            onChange={(value) => handlePageSizeChange(value)}
            label=""
            items={RowPerPageOptions}
          />
        </Grid>
      </Grid>

      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => handlePageChange(pageNo - 1)}
          disabled={pageNo === 0}
        >
          <KeyboardArrowLeft />
        </IconButton>

        <Stack
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <PaginationTextTypography>
            {pageNo * pageSize + 1}{' '}
          </PaginationTextTypography>
          <PaginationTextTypography> - </PaginationTextTypography>
          <PaginationTextTypography>
            {showErrorData ? (
              <>
                {Math.min(
                  (pageNo + 1) * pageSize,
                  searchPerformance.totalHalunicationsPrevented,
                )}
              </>
            ) : (
              <>
                {Math.min(
                  (pageNo + 1) * pageSize,
                  searchPerformance.totalSuccessfulSearches,
                )}
              </>
            )}
          </PaginationTextTypography>
          <PaginationTextTypography>
            {t(`${translationJSONPrefix}.columns.of`)}
          </PaginationTextTypography>
          <PaginationTextTypography>
            {showErrorData ? (
              <>{searchPerformance.totalHalunicationsPrevented}</>
            ) : (
              <>{searchPerformance.totalSuccessfulSearches}</>
            )}
          </PaginationTextTypography>
        </Stack>

        <IconButton
          onClick={() => handlePageChange(pageNo + 1)}
          disabled={
            (pageNo + 1) * pageSize >= searchPerformance.totalSuccessfulSearches
          }
        >
          <KeyboardArrowRight />
        </IconButton>
      </Stack>
    </Stack>
  );

  const handlePageChange = (newPageNo) => {
    setPageNo(newPageNo);
    getSearchPerformance({
      pageNo: newPageNo + 1,
      pageSize: pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageNo(0);
  };

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={pageDrawerOpen}
        drawer={SECTIONS.ANALYTICS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsPageDrawerOpen(res)}
      drawer={SECTIONS.ANALYTICS}
    >
      {isGetSearchPerformanceLoading && (
        <Loader
          label={t(`${translationJSONPrefix}.loadingMessage.loadingData`)}
        />
      )}

      <MetricsGrid
        style={{ width: width - (pageDrawerOpen ? DRAWER_WIDTH : 0) - 50 }}
      >
        {/* <MetricsInnerContainer> */}
        <Grid container spacing={2}>
          {pageData?.metrics?.map((item, index) => (
            <Grid item sm={4} md={4} lg={2} key={index}>
              <CustomGrid
                key={index}
                item
                onClick={() => handleGridClick(index)}
              >
                <MetricCard
                  icon={item.icon}
                  indicator={item.indicator}
                  value={nFormatter(item.value)}
                  label={item.label}
                  valueColor={item.color}
                />
              </CustomGrid>
            </Grid>
          ))}
        </Grid>
        {/* </MetricsInnerContainer> */}
      </MetricsGrid>

      {showErrorData
        ? pageData?.halunications_prevented?.length > 0 && (
            <>
              <DataGridBox>
                <StyledDataGrid
                  autoHeight
                  rows={pageData['halunications_prevented']}
                  columns={halunicationsPreventedColumns(t)}
                  slots={{ toolbar: GridToolbar }}
                  components={{
                    Pagination: CustomPagination,
                    Toolbar: CustomToolbar,
                  }}
                  disableRowSelectionOnClick
                  loading={false}
                  autoPageSize
                />
              </DataGridBox>
            </>
          )
        : pageData?.success_queries?.length > 0 && (
            <>
              <DataGridBox>
                <StyledDataGrid
                  autoHeight
                  rows={pageData['success_queries']}
                  columns={successfulSearchesColumns(t)}
                  slots={{ toolbar: GridToolbar }}
                  components={{
                    Pagination: CustomPagination,
                    Toolbar: CustomToolbar,
                  }}
                  loading={false}
                  disableRowSelectionOnClick
                />
              </DataGridBox>
            </>
          )}
    </PageContainer>
  );
};

export default SearchPerformance;
