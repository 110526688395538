/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.query({
    query: ({ url }) => ({
      url,
      method: 'GET',
    }),
    // providesTags: (result, error, { url }) => [{ type: 'PDF', id: url }],
  });
