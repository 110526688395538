/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { DialogActions, styled, theme } from '@mui/material';

export const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));
