/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, Grid, IconButton, styled } from '@mui/material';

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

export const StyledIconButton = styled(IconButton)({
  borderRadius: 0,
  marginLeft: 1,
  background: 'transparent',
});

export const FormGrid = styled(Grid)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

export const PopoverBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.light,
  padding: 0,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1),
}));
