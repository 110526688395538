/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DialogActionsWrapper } from './dialog.styled';
const DialogComponent = ({
  isEditDialogOpen,
  isDeleteDialogOpen,
  isAddDialogOpen,
  handleAddFaqText,
  handleAddFaq,
  isAddFaqsLoading,
  handleCloseFaqDialog,
  faqText,
  handleEditFaq,
  handleUpdateFaq,
  handleDeleteFaq,
  isAddUpdatedFaqLoading,
  isDeletedFaqLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '450px',
        },
      }}
      open={isEditDialogOpen || isDeleteDialogOpen || isAddDialogOpen}
      onClose={handleCloseFaqDialog}
    >
      {(isEditDialogOpen || isDeleteDialogOpen || isAddDialogOpen) && (
        <>
          <DialogContent>
            <DialogContentText>
              {isEditDialogOpen && (
                <Typography>{t('faqPage.dialog.edit')}</Typography>
              )}

              {isDeleteDialogOpen && (
                <Alert severity="warning">{t('faqPage.dialog.delete')}</Alert>
              )}
              {isAddDialogOpen && (
                <Typography>{t('faqPage.addFaq')}</Typography>
              )}
            </DialogContentText>

            {isEditDialogOpen && (
              <TextField
                autoFocus
                required
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={faqText}
                onChange={handleEditFaq}
              />
            )}
            {isAddDialogOpen && (
              <TextField
                autoFocus
                required
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleAddFaqText}
              />
            )}
          </DialogContent>
          <DialogActionsWrapper>
            <Button variant="contained" onClick={handleCloseFaqDialog}>
              {t('faqPage.button.cancel')}
            </Button>
            {isEditDialogOpen && (
              <LoadingButton
                variant="contained"
                type="submit"
                onClick={handleUpdateFaq}
                loading={isAddUpdatedFaqLoading}
              >
                {t('faqPage.button.edit')}
              </LoadingButton>
            )}

            {isDeleteDialogOpen && (
              <LoadingButton
                variant="contained"
                type="submit"
                onClick={handleDeleteFaq}
                loading={isDeletedFaqLoading}
              >
                {t('faqPage.button.delete')}
              </LoadingButton>
            )}

            {isAddDialogOpen && (
              <LoadingButton
                variant="contained"
                type="submit"
                onClick={handleAddFaq}
                loading={isAddFaqsLoading}
              >
                {t('faqPage.button.add')}
              </LoadingButton>
            )}
          </DialogActionsWrapper>
        </>
      )}
    </Dialog>
  );
};

DialogComponent.propTypes = {
  isEditDialogOpen: PropTypes.bool,
  isDeleteDialogOpen: PropTypes.bool,
  handleCloseFaqDialog: PropTypes.func,
  faqText: PropTypes.string,
  handleEditFaq: PropTypes.func,
  handleUpdateFaq: PropTypes.func,
  handleDeleteFaq: PropTypes.func,
  isAddUpdatedFaqLoading: PropTypes.bool,
  isDeletedFaqLoading: PropTypes.bool,
  isAddDialogOpen: PropTypes.bool,
  handleAddFaqText: PropTypes.func,
  handleAddFaq: PropTypes.func,
  isAddFaqsLoading: PropTypes.bool,
};

export default DialogComponent;
