/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { AUTHORITY, CLIENT } from '../constants/auth';
import { User } from 'oidc-client-ts';
const SESSION_OIDC_TOKEN_KEY = 'oidc.user';

function getUserFromSession() {
  const oidcUser = sessionStorage.getItem(
    `${SESSION_OIDC_TOKEN_KEY}:${AUTHORITY}:${CLIENT}`,
  );
  if (!oidcUser) {
    return null;
  }

  return User.fromStorageString(oidcUser);
}

export { getUserFromSession };
