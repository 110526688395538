/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React, { useState, useEffect, useContext } from 'react';
import { LinearProgress, Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DeleteForever, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container } from './conversationHistory.styled';
import {
  useGetConversationQuery,
  useGetFileCollectionsQuery,
} from '../../redux/services/speciphicAsk';
import PageContainer from '../../components/pageContainer';
import AppShadowBox from '../../components/app.shadowbox';
import DataGridTable from '../../components/datagrid';
import { ReactComponent as FileCollectionIcon } from '../../assets/file-collection-icon.svg';
import * as PATHS from '../../constants/path';
import { FileCollectionContext } from '../../hooks/useContext';
import PageTitle from '../../components/page.title';
import { LoadingTypography } from '../conversationPage/conversation.page.styled';
import DisplayTime from '../../components/timezone.component';
import UnauthorizedMessage from '../../components/unauthorized';

const translationJSONPrefix = 'conversationPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const ConversationHistoryPage = () => {
  const [conversationHistory, setConversationHistory] = useState([]);
  const [fileCollection, setFileCollection] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateCollectionId } = useContext(FileCollectionContext);
  const {
    data: userConversationHistory = [],
    error,
    isError: isGetConversationHistoryError,
    isLoading: isGetConversationHistoryLoading,
    isSuccess: isGetConversationHistorySuccess,
  } = useGetConversationQuery();

  const {
    data: fileCollections = [],
    error: fileCollectionsError,
    isError: isGetFileCollectionsError,
    isLoading: isGetFileCollectionsLoading,
    isSuccess: isGetFileCollectionsSuccess,
  } = useGetFileCollectionsQuery({
    include: ['name'],
    isStoreCreated: true,
  });

  useEffect(() => {
    if (userConversationHistory.length > 0) {
      const sortedConversationHistory = userConversationHistory
        .slice()
        .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      setConversationHistory(sortedConversationHistory);
    }
  }, [userConversationHistory]);

  useEffect(() => {
    if (fileCollections.length > 0) {
      setFileCollection(fileCollections);
    }
  }, [fileCollections]);

  const actionColumn = (props) => {
    return (
      <>
        <LoadingButton variant="text" disabled>
          <span>
            <DeleteForever />
          </span>
        </LoadingButton>
      </>
    );
  };

  const getCreatedDate = (props) => {
    const date = props.row.createdDate;
    return (
      <>
        <DisplayTime time={date} />
      </>
    );
  };

  const getLastUpdatedDate = (props) => {
    const date = props.row.lastUpdatedDate;
    return (
      <>
        <DisplayTime time={date} />
      </>
    );
  };

  const handleRowClick = (row) => {
    updateCollectionId(row.collection_id);

    setTimeout(() => {
      navigate(
        `${PATHS.FILE_COLLECTIONS}/${row.collection_id}/${PATHS.CONVERSATION}/${row.id}`,
      );
    }, 300);
  };

  const fileCollectionNameColumn = (props) => {
    const collection = fileCollection.find(
      (collection) => collection.id === props.row.collection_id,
    );
    return (
      <Container>
        <Icon component={FileCollectionIcon} sx={{ marginRight: 1 }} />
        {collection ? collection.name : t('conversationPage.notFoundMessage')}
      </Container>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: t('conversationPage.dataGridHeaders.collectionNames'),
      renderCell: fileCollectionNameColumn,
      width: 300,
      sortable: false,
    },
    {
      field: 'title',
      headerName: t('conversationPage.dataGridHeaders.title'),
      width: 300,
      sortable: false,
    },
    {
      field: 'createdDate',
      headerName: t('conversationPage.dataGridHeaders.createdDate'),
      renderCell: getCreatedDate,
      minWidth: 300,
      sortable: true,
      flex: 1,
    },
    {
      field: 'lastUpdatedDate',
      headerName: t('conversationPage.dataGridHeaders.lastUpdatedDate'),
      renderCell: getLastUpdatedDate,
      width: 250,
      sortable: true,
    },
    // deletion operation yet to be implemented
    // {
    //   field: 'actions',
    //   headerName: t('conversationPage.dataGridHeaders.action'),
    //   width: 120,
    //   headerAlign: 'center',
    //   align: 'center',
    //   sortable: false,
    //   renderCell: actionColumn,
    // },
  ];

  if (error?.status === 403 || fileCollectionsError?.status === 403)
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <AppShadowBox>
        <PageTitle
          title={t('conversationPage.title')}
          subtitle={t('conversationPage.subtitle')}
          isActionButtonNeeded={false}
        />
        {isGetConversationHistoryLoading || isGetFileCollectionsLoading ? (
          <>
            <LinearProgress />
            <LoadingTypography variant="h5">
              {t('conversationPage.loadingMessage')}
            </LoadingTypography>
          </>
        ) : (
          <DataGridTable
            column={columns}
            row={conversationHistory}
            sortingOrder={'desc'}
            sortingField={'name'}
            rowId="id"
            rowHeightAuto={true}
            searchable={true}
            isAddButtonNeeded={false}
            handleRow={(props) => handleRowClick(props.row)}
            serverSideSearch={false}
            isPaginationRequired={false}
          />
        )}
      </AppShadowBox>
    </PageContainer>
  );
};

ConversationHistoryPage.propTypes = {
  row: PropTypes.object,
};

export default ConversationHistoryPage;
