/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  LinkContainer,
  LinkTitle,
  PageHeaderContainer,
  RightIcon,
  TitleText,
} from './form.styled';

const translationJSONPrefix = `accessControlSection.components.header`;

const AddPageHeader = ({ routes = [], isEdit = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageHeaderContainer style={{ justifyContent: 'flex-start' }}>
      {routes.map((r, i) => (
        <LinkContainer key={i}>
          <LinkTitle onClick={() => navigate(r?.endpoint)}>
            {r?.title}
          </LinkTitle>
          <RightIcon />
        </LinkContainer>
      ))}
      <LinkContainer>
        <TitleText>
          {isEdit
            ? t(`${translationJSONPrefix}.edit`)
            : t(`${translationJSONPrefix}.add`)}
        </TitleText>
        <RightIcon />
      </LinkContainer>
    </PageHeaderContainer>
  );
};

AddPageHeader.propTypes = {
  routes: PropTypes.array,
  isEdit: PropTypes.bool,
};

export default AddPageHeader;
