/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  Icon,
  OutlinedInput,
  styled,
  Box,
  IconButton,
  Select,
  FormControl,
} from '@mui/material';
import colors from '../../themes/colors';

export const SearchInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  padding: '12px 13px 12px 16px',
  backgroundColor: '#F3F3F3',
  '.MuiInputBase-input': {
    fontSize: 30,
  },
}));

export const SearchBoxContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  width: '100%',
});

export const SearchButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  padding: theme.spacing(1, 3),
  backgroundColor: theme.palette.primary.main,
  marginRight: -13,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const LanguageFormControl = styled(FormControl)(({ theme }) => ({
  borderRadius: 0,
  width: 130,
  p: 0,
  '& .MuiFormLabel-root': {
    paddingTop: '14px',
    fontWeight: 600,
  },
  '& .MuiFilledInput-root': {
    //remove default hover underline
    '&:before': {
      borderBottom: '0',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      '&:before': {
        borderBottom: '0 !important',
      },
    },
  },
}));

export const SelectLanguage = styled(Select)(({ theme }) => ({
  padding: 0,
  borderRadius: 0,
  height: '78px',
  '.MuiSelect-select': {
    color: '#131E29',
    paddingTop: '0',
    paddingBottom: '0px',
    fontSize: '14px',
    fontWeight: '300',
  },
  ':hover': {
    border: '0 !important',
  },
}));

export const AdornmentSearchIcon = styled(Icon)({
  width: 36,
  height: 62,
});
