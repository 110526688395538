const { REACT_APP_CONFIGURATION } = process.env;

let isAppConfigurationEnabled = false;

if (
  REACT_APP_CONFIGURATION &&
  REACT_APP_CONFIGURATION?.toLowerCase() === 'true'
) {
  isAppConfigurationEnabled = true;
}

export default isAppConfigurationEnabled;
