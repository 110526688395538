/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Typography, styled, Box, Button, Grid, Link } from '@mui/material';
import colors from '../../../../themes/colors';

export const FieldValueTypography = styled(Typography)(({ theme }) => ({
  color: colors.blue.B250,
  padding: '8px',
  borderRadius: '4px',
  fontSize: '20px',
}));

export const StyledFileWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
export const DefineNewPropertyButton = styled(Button)(({ theme }) => ({
  width: '410px',
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  marginRight: theme.spacing(1),
}));

export const HeaderGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'left',
  flex: 1,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const StyledGridContainer = styled(Grid)(({ theme, isMdOrLarger }) => ({
  position: 'sticky',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingLeft: isMdOrLarger ? theme.spacing(3) : theme.spacing(1),
  paddingRight: isMdOrLarger ? theme.spacing(3) : theme.spacing(1),
}));

export const DividerContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  cursor: 'pointer',
});

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  float: 'right',
  display: 'flex',
  width: 'auto',
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 'bold',
}));
