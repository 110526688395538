/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  ContentBox,
  StyledAnswerTypography,
  AnsWrapper,
} from './answer.styled';
import AnswerFooter from './answer.footer';

const Answer = ({
  title,
  description,
  meta,
  feedback,
  feedbackAPI,
  query,
  result,
}) => {
  return (
    <>
      <AnsWrapper>
        <ContentBox>
          <StyledAnswerTypography variant="h6" sx={{ whiteSpace: 'pre-wrap' }}>
            {title}
          </StyledAnswerTypography>
        </ContentBox>

        <ContentBox>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {description}
          </Typography>

          <AnswerFooter
            title={title}
            description={description}
            meta={meta}
            feedback={feedback}
            feedbackAPI={feedbackAPI}
            query={query}
            result={result}
          />
        </ContentBox>
      </AnsWrapper>
    </>
  );
};

Answer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,
  feedback: PropTypes.func,
  feedbackAPI: PropTypes.object,
  query: PropTypes.string,
  result: PropTypes.array || PropTypes.object,
};

export default Answer;
