/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material/styles';
import colors from '../colors';

let { palette } = createTheme();

palette = {
  ...palette,
  primary: {
    main: '#003E51',
  },
  secondary: {
    main: '#E7E7E7',
    light: '#DDDDDD',
    dark: '#000000',
  },
  text: {
    primary: '#131E29',
    secondary: '#666666',
    light: '#999999',
    caption: '#333333',
    controls: '#333333',
    disabled: '#DDDDDD',
  },
  background: {
    pageBackground: '#F8F8F8',
  },
  border: {
    main: '#091E42',
    secondary: '#DFEAF3',
  },
  poster: {
    main: '#FF8B6D',
  },
  spinner: {
    red: colors.red.R300,
    green: colors.green.G300,
    yellow: colors.yellow.Y300,
    background: '#999999',
  },
  dropdown: {
    primary: '#F3F3F3',
  },
};

export default palette;
