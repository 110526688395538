/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  Grid,
} from '@mui/material';

const SelectField = ({
  label,
  value = '',
  options = [],
  required,
  disabled,
  error,
  onChange,
  isLabelAndFieldOnHorizontalLine = false,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <Grid
        display={isLabelAndFieldOnHorizontalLine ? 'flex' : 'block'}
        justifyContent="space-between"
      >
        <Grid xs={isLabelAndFieldOnHorizontalLine ? 3 : 12} sx={{ my: 0.5 }}>
          <FormLabel required={required} sx={{ fontSize: 16 }}>
            {label}
          </FormLabel>
        </Grid>
        <Grid xs={isLabelAndFieldOnHorizontalLine ? 9 : 12}>
          <TextField
            select
            value={value}
            label=""
            fullWidth
            margin="dense"
            required={required}
            size="small"
            disabled={disabled}
            error={error !== undefined}
            helperText={error}
            sx={{ marginTop: '0' }}
            onChange={handleChange}
          >
            {options.map((option) =>
              typeof option === 'string' ? (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ) : (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ),
            )}
          </TextField>
        </Grid>
      </Grid>
    </FormControl>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLabelAndFieldOnHorizontalLine: PropTypes.bool,
};

export default SelectField;
