/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  ListItemAvatar,
  ListItemText,
  ListItem,
  CardContent,
  Button,
} from '@mui/material';
import { Download, Topic, ScatterPlot } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../components/pageContainer';
import CompanyList from '../../components/companyList';
import { pushToast } from '../../redux/reducers/toasts.slice';
import {
  useLazyGetFinancialReportQuery,
  useAddNewReportMutation,
  useLazyGetPreferencesQuery,
  useAddPreferenceMutation,
  useDeletePreferenceMutation,
  useLazyGetStockSymbolQuery,
  useLazyGetFinancialReportPdfQuery,
} from '../../redux/services/speciphicAsk/speciphic.ask.api';
import {
  LoadingProgress,
  StyledIconButton,
  PdfContainer,
  PdfSkeleton,
  StockWrapper,
  AutoCompleteWrapper,
  AutoComplete,
  AlertComplete,
  CardContentBox,
  CardBox,
  GridBox,
  PdfDownloadLoading,
  StatusWrapper,
} from './financialReport.page.styled';
import { isMarketIntelligenceLogoEnabled } from '../../constants/marketIntelligence';
import * as PATHS from '../../constants/path';
import {
  MARKET_INTELLIGENCE_LISTED_FEATURE,
  API_FETCH_TIME_PERIOD,
} from '../../constants/marketIntelligence';
import DisplayTime from '../../components/timezone.component';
import {
  DoneIcon,
  PendingIcon,
  ErrorIcon,
  PlannedIcon,
  ApprovedIcon,
} from '../../components/companyList/companyList.styled';
import UnauthorizedMessage from '../../components/unauthorized';
import AppShadowBox from '../../components/app.shadowbox';

const translationJSONPrefix = 'marketIntelligencePage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const statusConfig = {
  READY: { title: 'Report Ready', icon: <DoneIcon /> },
  ERROR: { title: 'Report Error', icon: <ErrorIcon /> },
  CREATING: { title: 'Creating Report', icon: <PendingIcon /> },
  PLANNED: { title: 'Planned Report', icon: <PlannedIcon /> },
  null: { title: null, icon: null },
  approved: { title: 'Approved', icon: <ApprovedIcon /> },
  pending: { title: 'Pending', icon: <PendingIcon /> },
};

const FinancialReportPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accordionContainerRef = useRef(null);
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const [stocksSummary, setStocksSummary] = useState({});
  const [symbolsList, setSymbolsList] = useState([]);
  const [reportInfo, setReportInfo] = useState({});
  const [input, setInput] = useState('');
  const inputRef = useRef(input);
  const [loadingStates, setLoadingStates] = useState({});
  const navigate = useNavigate();

  const [
    fetchFinancialReport,
    {
      data: result = {},
      error,
      isSuccess: isGetFinancialReportSuccess,
      isLoading: isGetFinancialReportLoading,
      isFetching: isGetFinancialReportFetching,
    },
  ] = useLazyGetFinancialReportQuery();

  const [
    fetchFinancialReportPDf,
    {
      data: pdfResult = {},
      isSuccess: isGetPdfResultSuccess,
      isLoading: isGetPdfResultLoading,
      isFetching: isGetPdfResultFetching,
    },
  ] = useLazyGetFinancialReportPdfQuery();

  const [
    generateNewReport,
    {
      isSuccess: isGenerateReportSuccess,
      isLoading: isGenerateReportLoading,
      isError: isGenerateReportError,
    },
  ] = useAddNewReportMutation();

  const [
    addPreference,
    {
      isSuccess: isAddPreferenceSuccess,
      isLoading: isAddPreferenceLoading,
      isError: isAddPreferenceError,
    },
  ] = useAddPreferenceMutation();

  const [
    deletePreference,
    {
      isSuccess: isDeletePreferenceSuccess,
      isLoading: isDeletePreferenceLoading,
      isError: isDeletePreferenceError,
    },
  ] = useDeletePreferenceMutation();

  const [
    getPreferences,
    {
      data: preferences,
      error: preferencesError,
      isSuccess: isGetPreferencesSuccess,
      isLoading: isGetPreferencesLoading,
      isFetching: isGetPreferencesFetching,
    },
  ] = useLazyGetPreferencesQuery();

  const [
    getStockSymbol,
    {
      data: symbol = [],
      error: getStockSymbolError,
      isSuccess: isGetStockSymbolSuccess,
      isLoading: isGetStockSymbolLoading,
      isFetching: isGetStockSymbolFetching,
    },
  ] = useLazyGetStockSymbolQuery();

  useEffect(() => {
    if (symbol && isGetStockSymbolSuccess) {
      setSymbolsList(symbol);
    }
  }, [symbol, isGetStockSymbolSuccess]);

  useEffect(() => {
    if (isGetFinancialReportSuccess && reportInfo) {
      setStocksSummary({
        symbol: reportInfo.symbol,
        summary: result.summary,
        reportId: reportInfo.reportId,
      });
      accordionContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isGetFinancialReportSuccess, reportInfo, result]);

  useEffect(() => {
    if (isGenerateReportError) {
      dispatch(
        pushToast({
          message: t(
            `${translationJSONPrefix}.listedCompanyPage.alertMessages.error`,
          ),
          severity: `error`,
        }),
      );
    }
  }, [isGenerateReportError]);

  useEffect(() => {
    if (isGenerateReportSuccess) {
      setLoadingStates({});
    }
  }, [isGenerateReportSuccess]);

  const handleSelectSymbolChange = (event, value) => {
    if (
      value &&
      !selectedSymbols.some((option) => option.symbol === value.symbol)
    ) {
      setSelectedSymbols((prevSelected) => [...prevSelected, { ...value }]);
      addPreference({
        feature: MARKET_INTELLIGENCE_LISTED_FEATURE,
        key: value.symbol,
      });
    }
  };

  useEffect(() => {
    getPreferences({ feature: MARKET_INTELLIGENCE_LISTED_FEATURE });

    // Interval logic
    const intervalId = setInterval(() => {
      getPreferences({ feature: MARKET_INTELLIGENCE_LISTED_FEATURE });
      setLoadingStates({});
    }, API_FETCH_TIME_PERIOD);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isGetPreferencesSuccess && preferences) {
      setSelectedSymbols(preferences);
    }
  }, [isGetPreferencesSuccess, preferences]);

  const handleDeletePreference = (key) => {
    deletePreference({ key: key, feature: MARKET_INTELLIGENCE_LISTED_FEATURE });
    setSelectedSymbols(selectedSymbols.filter((ss) => ss.key !== key));
    dispatch(
      pushToast({
        message: t(
          'marketIntelligencePage.listedCompanyPage.alertMessages.successDelete',
        ),
        severity: 'success',
      }),
    );
  };
  const handleShowReportClick = (reportId, symbol) => {
    if (reportId) {
      setReportInfo({ reportId, symbol });
      fetchFinancialReport({ report_id: reportId });
    } else {
      dispatch(
        pushToast({
          message: t(
            `${translationJSONPrefix}.listedCompanyPage.alertMessages.errorSelect`,
          ),
          severity: `error`,
        }),
      );
    }
  };

  const handleGenerateReport = (symbol) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [symbol]: true,
    }));
    generateNewReport({
      report_key: symbol,
      feature: MARKET_INTELLIGENCE_LISTED_FEATURE,
    });
  };

  // PDF generation function
  const handleDownload = (reportId, symbol) => {
    fetchFinancialReportPDf({
      report_id: reportId,
      report_format: `pdf`,
      download_report: true,
    })
      .unwrap()
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement(`a`);
        a.href = url;
        a.download = symbol;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  };

  const handleUserInput = (event) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    inputRef.current = input;
  }, [input]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getStockSymbol({
        query: input.length > 0 ? input : ``,
        feature: MARKET_INTELLIGENCE_LISTED_FEATURE,
        status: '',
      });
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [input]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getStockSymbol({
        query: inputRef.current.length > 0 ? inputRef.current : ``,
        feature: MARKET_INTELLIGENCE_LISTED_FEATURE,
        status: '',
      });
    }, API_FETCH_TIME_PERIOD);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleNavigate = (feature, type) => {
    if (type === `report-sources`) {
      navigate(PATHS.REPORT_SOURCES, {
        state: {
          feature: feature,
        },
      });
    } else if (type === `report-topics`) {
      navigate(PATHS.REPORT_SUMMARY_TOPICS, {
        state: {
          feature: feature,
        },
      });
    }
  };

  if (
    error?.status === 403 ||
    preferencesError?.status === 403 ||
    getStockSymbolError?.status === 403
  )
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  const filterOptions = (options, { inputValue }) => {
    const searchString = inputValue.toLowerCase();
    return options.filter(
      (option) =>
        option.description.toLowerCase().includes(searchString) ||
        option.displaySymbol.toLowerCase().includes(searchString),
    );
  };

  return (
    <PageContainer>
      <AppShadowBox>
        <GridBox container rowSpacing={2}>
          <Grid container item xs={12} justifyContent={`space-between`}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4">
                {t(`${translationJSONPrefix}.listedCompanyPage.title`)}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              spacing={2}
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <StockWrapper>
                  <Button
                    startIcon={<ScatterPlot />}
                    onClick={() =>
                      handleNavigate(
                        MARKET_INTELLIGENCE_LISTED_FEATURE,
                        `report-sources`,
                      )
                    }
                    variant="contained"
                  >
                    {t(`${translationJSONPrefix}.sourceButton`)}
                  </Button>
                  <Button
                    startIcon={<Topic />}
                    onClick={() =>
                      handleNavigate(
                        MARKET_INTELLIGENCE_LISTED_FEATURE,
                        `report-topics`,
                      )
                    }
                    variant="contained"
                    sx={{ marginLeft: 1 }}
                  >
                    {t(`${translationJSONPrefix}.reportButton`)}
                  </Button>
                </StockWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteWrapper isMargin={true}>
              <AutoComplete
                options={symbolsList}
                onChange={handleSelectSymbolChange}
                getOptionLabel={(option) => option.description || option.symbol}
                popupIcon={<></>}
                blurOnSelect={true}
                filterOptions={filterOptions}
                isOptionEqualToValue={(option, value) =>
                  option.symbol === value.symbol
                }
                renderOption={(props, option) => (
                  <ListItem {...props} key={option.symbol}>
                    {isMarketIntelligenceLogoEnabled && (
                      <ListItemAvatar>
                        <Avatar src={option.logoUrl}></Avatar>
                      </ListItemAvatar>
                    )}
                    <ListItemText
                      primary={
                        <StatusWrapper>
                          {option.symbol}
                          {option.status in statusConfig &&
                            statusConfig[option.status].icon && (
                              <Tooltip
                                title={statusConfig[option.status].title}
                              >
                                {statusConfig[option.status].icon}
                              </Tooltip>
                            )}
                          {option?.lastUpdatedDate == null ||
                          option?.lastUpdatedDate == undefined ||
                          option?.status == 'OBSOLETE' ? (
                            <></>
                          ) : (
                            <Typography fontSize={12}>
                              <DisplayTime
                                time={option.lastUpdatedDate}
                                format="ddd, D MMM YYYY HH:mm"
                              />
                            </Typography>
                          )}
                        </StatusWrapper>
                      }
                      secondary={option.description}
                    ></ListItemText>
                  </ListItem>
                )}
                renderInput={(params) => (
                  <Box sx={{ position: `relative` }}>
                    <TextField
                      size="medium"
                      {...params}
                      label="Select Stock Identifier"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: `off`,
                        style: { fontSize: `20px`, padding: `16px` },
                      }}
                      onChange={handleUserInput}
                    />
                    {isGetStockSymbolFetching && <LoadingProgress />}
                  </Box>
                )}
              />
            </AutoCompleteWrapper>
          </Grid>

          <Grid item xs={12}>
            <AutoCompleteWrapper isMargin={false}>
              <Typography variant="h4">
                {t(`${translationJSONPrefix}.listedCompanyPage.stock`)}
              </Typography>
            </AutoCompleteWrapper>
          </Grid>

          <Grid item xs={12}>
            <AutoCompleteWrapper isMargin={false}>
              {(selectedSymbols.length === 0) & isGetPreferencesSuccess ? (
                <AlertComplete severity="info">
                  {t(`${translationJSONPrefix}.listedCompanyPage.alert`)}
                </AlertComplete>
              ) : (
                <CompanyList
                  listOfCompanies={selectedSymbols}
                  isGenerateReportLoading={isGenerateReportLoading}
                  isGetFinancialReportLoading={isGetFinancialReportLoading}
                  handleGenerateReport={handleGenerateReport}
                  handleShowReportClick={handleShowReportClick}
                  handleDeletePreference={handleDeletePreference}
                  isGetPreferencesFetching={isGetPreferencesLoading}
                  loadingStates={loadingStates}
                />
              )}
            </AutoCompleteWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {isGetFinancialReportFetching ? (
                <PdfContainer>
                  <PdfSkeleton variant="rounded" />
                </PdfContainer>
              ) : (
                <>
                  {isGetFinancialReportSuccess && (
                    <PdfContainer ref={accordionContainerRef}>
                      <CardBox variant="outlined" sx={{ position: `relative` }}>
                        <CardContent>
                          <CardContentBox>
                            {isGetPdfResultFetching ? (
                              <>
                                <PdfDownloadLoading />
                              </>
                            ) : (
                              <Tooltip
                                title={t(
                                  `${translationJSONPrefix}.listedCompanyPage.export`,
                                )}
                              >
                                <StyledIconButton
                                  variant="contained"
                                  onClick={() => {
                                    handleDownload(
                                      stocksSummary.reportId,
                                      stocksSummary.symbol,
                                    );
                                  }}
                                >
                                  <Download />
                                </StyledIconButton>
                              </Tooltip>
                            )}
                          </CardContentBox>
                          <ReactMarkdown>{stocksSummary.summary}</ReactMarkdown>
                        </CardContent>
                      </CardBox>
                    </PdfContainer>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </GridBox>
      </AppShadowBox>
    </PageContainer>
  );
};

export default FinancialReportPage;
