import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from '../settings/fileUploaderPage/fileUploader.page.styled';
import SubmitButtonGroup from './submitButtonGroup';
import PropTypes from 'prop-types';
import { validateContainerName } from '../../utils/functions';
const AzureBlobForm = (props) => {
  const [inputValues, setInputValues] = useState({
    name: '',
    type: props.storageContainer,
    params: {
      containerName: '',
      accountName: '',
      accessKey: '',
    },
  });
  const [validationErrorLabel, setValidationErrorLabel] = useState('');

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevState) => ({
      ...prevState,
      ...(fieldName.includes('.')
        ? { params: { ...prevState.params, [fieldName.split('.')[1]]: value } }
        : { [fieldName]: value }),
    }));
  };

  const clearValues = () => {
    setInputValues({
      name: '',
      type: props.storageContainer,
      params: {
        containerName: '',
        accountName: '',
        accessKey: '',
      },
    });
  };

  const handleContainerNameBlur = () => {
    const validate = validateContainerName(inputValues.params.containerName, t);
    setValidationErrorLabel(validate);
  };

  const { t } = useTranslation();
  return (
    <>
      <InputField
        size="small"
        required
        fullWidth
        name="name"
        id="outlined-required"
        label={t('fileUploaderPage.storageConfiguration.newSourcePopover.name')}
        value={inputValues.name}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />

      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="accountName"
        id="outlined-required"
        label={t(
          'fileUploaderPage.storageConfiguration.newSourcePopover.accountName',
        )}
        value={inputValues.params.accountName}
        onChange={(e) =>
          handleInputChange('params.accountName', e.target.value)
        }
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        name="accessKey"
        id="outlined-required"
        label={t(
          'fileUploaderPage.storageConfiguration.newSourcePopover.accessKey',
        )}
        value={inputValues.params.accessKey}
        onChange={(e) => handleInputChange('params.accessKey', e.target.value)}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        name="containerName"
        id="outlined-required"
        label={t(
          'fileUploaderPage.storageConfiguration.newSourcePopover.containerName',
        )}
        value={inputValues.params.containerName}
        onChange={(e) =>
          handleInputChange('params.containerName', e.target.value)
        }
        onBlur={handleContainerNameBlur}
        helperText={validationErrorLabel}
        error={validationErrorLabel != '' ? true : false}
      />
      <SubmitButtonGroup
        data={inputValues}
        onClear={clearValues}
        errorMessage={validationErrorLabel}
      />
    </>
  );
};

AzureBlobForm.propTypes = {
  storageContainer: PropTypes.string,
};

export default AzureBlobForm;
