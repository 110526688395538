/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { StyledPageWrapper } from './logout.page.styled';
import { ReactComponent as LogoutPoster } from '../../assets/logout.svg';

const LogoutPage = () => {
  const { t } = useTranslation();

  return (
    <StyledPageWrapper>
      <LogoutPoster
        style={{ width: '80%', height: '80%' }}
        alt={t('notFoundPage.imgAlt')}
      />
      <Typography variant="h2">You have successfully logged out </Typography>
      <Typography component={NavLink} to="/" variant="h5" color={'primary'}>
        {t('notFoundPage.homePageLink')}
      </Typography>
    </StyledPageWrapper>
  );
};

export default LogoutPage;
