export const dataTypes = [
  {
    label: 'Number',
    value: 'Float',
  },
  { label: 'Text', value: 'String' },
  {
    label: 'Date',
    value: 'Date',
  },
];
