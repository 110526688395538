import { Clear, Search } from '@mui/icons-material';
import { Typography, styled } from '@mui/material';

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));

export const StyledClearIcon = styled(Clear)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));
