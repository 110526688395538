/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { FormControl, MenuItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import * as PATHS from '../../../constants/path';
import { StyledSelect } from './miNavigationDropdown.styled';

const MiNavigationDropdown = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPath, setSelectedPath] = React.useState(location.pathname);

  const pathToLabel = {
    [PATHS.MI_REPORTS]: 'Reports',
    [PATHS.MI_REPORT_SOURCES]: 'Report Sources',
    [PATHS.MI_REPORT_TOPICS]: 'Report Topics',
    [PATHS.MI_CONFIGURATION]: 'Configuration',
  };

  const handleChange = (event) => {
    const newPath = event.target.value;
    setSelectedPath(newPath);
    navigate(newPath);
  };

  React.useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120, mt: 3 }}>
        <StyledSelect
          defaultValue={selectedPath}
          onChange={handleChange}
          size="small"
          variant="outlined"
          inputProps={{ 'aria-label': 'Navigation' }}
        >
          {Object.keys(pathToLabel).map((path, index) => (
            <MenuItem key={index} value={path}>
              {pathToLabel[path]}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default MiNavigationDropdown;
