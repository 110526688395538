import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  Delete,
  Settings,
  Block,
  VisibilityOff,
  Engineering,
  FileOpen,
} from '@mui/icons-material';
import PropTypes from 'prop-types';

import * as FILES_COLLECTION_PATH from '../../../constants/filesCollectionSettingsPaths';

import {
  LoadingTypography,
  TagsChip,
  ButtonStyled,
  ButtonsContainer,
  TableHeader,
  ButtonsOnlyContainer,
  SettingsButton,
  DescriptionContainer,
  FilesNotAddedAlert,
  FileName,
  FileNameText,
  AlignmentBox,
  FileNameContainer,
  SuccessIcon,
  FailedIcon,
} from './individualFileCollection.styled';
import AppShadowBox from '../../../components/app.shadowbox';
import PageContainer from '../../../components/pageContainer';
import {
  useLazyGetIndividualFileCollectionQuery,
  useGetSearchEnginesQuery,
  useDeleteIndividualFileMutation,
  useLazyGetIngestedFilesQuery,
} from '../../../redux/services/speciphicAsk';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import DataGridTable from '../../../components/datagrid/datagrid.component';
import * as PATHS from '../../../constants/path';
import isMetadataFunctionalityEnabledInApp from '../../../constants/metadata';
import { LOAD_TITLE } from '../../../utils/functions';
import ConfirmationDialog from '../../../components/confirmationDialog';
import DisplayTime from '../../../components/timezone.component';
import UnauthorizedMessage from '../../../components/unauthorized';
import { DEBOUNCE_DELAY } from '../../../constants/debounce';
import useDebounce from '../../../hooks/useDebounce';

const translationJSONPrefix = 'individualFileCollectionPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const IndividualFileCollectionPage = () => {
  useEffect(
    () => LOAD_TITLE(document, t(`${translationJSONPrefix}.appTitle.heading`)),
    [],
  );

  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDeleteFile, setOpenDeleteFile] = useState(false);
  const [collectionId, setCollectionId] = useState();
  const [fileName, setFileName] = useState('');
  const [ingestedFiles, setIngestedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [unauthorized, setUnauthorized] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setCollectionId(params?.collectionId);
  }, [params?.collectionId]);

  useEffect(() => {
    if (collectionId) {
      getIngestedFiles({
        collectionId: collectionId,
        pageNo: pageNo,
        pageSize: pageSize,
      });
      getFileCollection({
        collectionId: collectionId,
      });
    }
  }, [collectionId]);

  const [
    deleteIndividualFile,
    {
      data: deleteFile,
      isSuccess: isDeleteFileSuccess,
      isLoading: isDeleteFileLoading,
      isError: isDeleteFileError,
    },
  ] = useDeleteIndividualFileMutation();

  const [
    getIngestedFiles,
    {
      data: ingestedFilesObj,
      error: ingestedFilesError,
      isSuccess: isGetIngestedFilesSuccess,
      isLoading: isGetIngestedFilesLoading,
      isError: isGetIngestedFilesError,
      isFetching: isGetIngestedFilesFetching,
    },
  ] = useLazyGetIngestedFilesQuery();

  const [
    getFileCollection,
    {
      data: individualFileCollection,
      error: individualFileCollectionError,
      isLoading: isGetIndividualFileCollectionLoading,
      isSuccess: isGetIndividualFileCollectionSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  const { data: searchEngines = [], error: searchEngineError } =
    useGetSearchEnginesQuery();

  useEffect(() => {
    if (isDeleteFileSuccess) {
      dispatch(
        pushToast({
          message: t(
            `${translationJSONPrefix}.alertMessages.fileDeleteSuccess`,
          ),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteFileSuccess]);

  useEffect(() => {
    if (isGetIngestedFilesSuccess && ingestedFilesObj) {
      setIngestedFiles(ingestedFilesObj?.ingestedFiles);
    }
  }, [isGetIngestedFilesSuccess, ingestedFilesObj]);

  const handleRowClick = (row) => {
    if (
      individualFileCollection?.isMetadataEnabled &&
      isMetadataFunctionalityEnabledInApp
    ) {
      navigate(
        `${PATHS.FILE_COLLECTIONS}/${collectionId}/${PATHS.ADD_METADATA_PAGE}/${row.fileName}`,
      );
    }
  };

  const handleDeleteFile = (file) => {
    setOpenDeleteFile(false);
    if (file) {
      deleteIndividualFile({
        collectionId,
        fileName: file,
      });
      if (ingestedFilesObj && ingestedFilesObj?.ingestedFiles?.length === 1) {
        navigate(PATHS.FILE_COLLECTIONS);
      }
    }
  };

  const handleCancelDeleteClick = () => {
    setOpenDeleteFile(false);
  };

  const handleCloseDelete = () => {
    setOpenDeleteFile(false);
  };

  const handleManageMetadataProperties = () => {
    navigate(
      `${PATHS.FILE_COLLECTIONS}/${collectionId}/${PATHS.MANAGE_METADATA_PROPERTIES}`,
      {
        state: {
          collectionId,
          indexName: fileName,
        },
      },
    );
  };

  useEffect(() => {
    if (individualFileCollection) {
      setFileName(individualFileCollection?.name);
    }

    if (individualFileCollectionError?.status === 403) {
      setUnauthorized(true);
    }
  }, [individualFileCollection, individualFileCollectionError]);

  const openDeleteFileDialog = (e, props) => {
    e.stopPropagation();
    setOpenDeleteFile(true);
    setSelectedFile(props.row.fileName);
  };

  const fileStatus = (props) => {
    return (
      <>{props.row.status == 'SUCCESS' ? <SuccessIcon /> : <FailedIcon />}</>
    );
  };

  const getDates = (props) => {
    return <>{formatTimestamp(props.row.updatedAt)}</>;
  };

  const getSize = (props) => {
    const sizeInBytes = props.row.size;

    if (sizeInBytes < 1024 * 1024) {
      const sizeInKB = (sizeInBytes / 1024).toFixed(0);
      return sizeInKB + ' KB';
    } else if (sizeInBytes >= 1024 * 1024 && sizeInBytes < 1024 * 1024 * 1024) {
      const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(0);
      return sizeInMB + ' MB';
    } else {
      const sizeInGB = (sizeInBytes / (1024 * 1024 * 1024)).toFixed(0);
      return sizeInGB + ' GB';
    }
  };
  const openFile = (props) => {};

  function getColumnsForFilesDataGrid() {
    const filesDataGridColumns = [
      {
        field: 'fileName',
        headerName: t(`${translationJSONPrefix}.labels.fileName`),
        width: 250,
        sortable: false,
      },
      {
        field: 'success',
        headerName: 'Status',
        minWidth: 50,
        flex: 1,
        renderCell: fileStatus,
        sortable: false,
      },

      {
        field: 'size',
        headerName: 'Size',
        width: 100,
        renderCell: getSize,
        sortable: false,
      },
      {
        field: 'lastUpdatedDate',
        headerName: 'Updated At',
        width: 220,
        renderCell: (params) => (
          <DisplayTime
            time={params.row.lastUpdatedDate}
            format="D MMMM, YYYY hh.mm A"
          />
        ),
        sortable: false,
      },
    ];

    filesDataGridColumns.push({
      field: 'Delete',
      headerName: t(`${translationJSONPrefix}.labels.actions`),
      sortable: false,
      width: 150,
      headerAlign: 'right',
      align: 'right',
      renderCell: (props) => {
        return (
          <>
            <ButtonGroup>
              {/* opening of file not implemented yet  */}
              {/* <LoadingButton
                color="primary"
                variant="text"
                onClick={(e) => openFile(e, props.row)}
                disabled
              >
                <FileOpen />
              </LoadingButton> */}
              <LoadingButton
                color="primary"
                variant="text"
                loading={
                  isDeleteFileLoading && props.row.fileName == selectedFile
                }
                onClick={(e) => openDeleteFileDialog(e, props)}
              >
                <Delete />
              </LoadingButton>
            </ButtonGroup>
          </>
        );
      },
    });

    return filesDataGridColumns;
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    let hour = date.getHours();
    const minute = date.getMinutes();
    const meridiem = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
    return `${day} ${month}, ${year} ${hour
      .toString()
      .padStart(2, '0')}.${minute.toString().padStart(2, '0')} ${meridiem}`;
  };

  const handleNavigateToSettings = () => {
    navigate(
      `${PATHS.FILE_COLLECTIONS}/${collectionId}${PATHS.SETTINGS}${FILES_COLLECTION_PATH['GENERAL'].endpoint}`,
    );
  };

  const getSearchEngineLabel = (languageModelId) => {
    const searchEngine = searchEngines?.find(
      (se) => se.value === languageModelId,
    );
    return searchEngine
      ? searchEngine.label
      : t('fileCollectionsPage.dataGrid.notFound');
  };

  useDebounce(
    () => {
      if (collectionId)
        getIngestedFiles({
          collectionId: collectionId,
          pageNo: pageNo,
          pageSize: pageSize,
          query: searchQuery,
        });
    },
    [pageNo, pageSize, searchQuery],
    DEBOUNCE_DELAY,
  );

  if (
    ingestedFilesError?.status === 403 ||
    individualFileCollectionError?.status === 403 ||
    searchEngineError?.status === 403
  )
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <AppShadowBox>
        {isGetIndividualFileCollectionLoading && (
          <>
            <LinearProgress />
            <LoadingTypography variant="h5">
              {t(`${translationJSONPrefix}.pageLoading`)}
            </LoadingTypography>
          </>
        )}
        {isGetIndividualFileCollectionSuccess && (
          <>
            <TableHeader>
              <FileNameContainer>
                <FileName disablePadding disableGutters>
                  <FileNameText
                    primary={
                      <Typography variant="h2">
                        {individualFileCollection.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="CAPTION TEXT">
                        {individualFileCollection.id}
                      </Typography>
                    }
                  ></FileNameText>
                </FileName>
                {individualFileCollection?.languageModelId && (
                  <TagsChip
                    sx={{ minWidth: '200px' }}
                    icon={<Engineering />}
                    label={getSearchEngineLabel(
                      individualFileCollection?.languageModelId,
                    )}
                  />
                )}
                {!individualFileCollection.isEnabled && (
                  <TagsChip icon={<Block />} label="Disabled" />
                )}
                {!individualFileCollection.isVisible && (
                  <TagsChip icon={<VisibilityOff />} label="Hidden" />
                )}
              </FileNameContainer>

              <>
                <ButtonsContainer>
                  <ButtonStyled
                    size="medium"
                    variant="outlined"
                    startIcon={<Settings />}
                    onClick={handleNavigateToSettings}
                  >
                    {t(`${translationJSONPrefix}.buttonTexts.settings`)}
                  </ButtonStyled>
                </ButtonsContainer>
                <ButtonsOnlyContainer>
                  <SettingsButton onClick={handleNavigateToSettings}>
                    <Settings sx={{ fontSize: '30px' }} />
                  </SettingsButton>
                </ButtonsOnlyContainer>
              </>
            </TableHeader>
            <DescriptionContainer>
              {individualFileCollection.description ? (
                <Typography variant="h5">
                  {individualFileCollection.description}
                </Typography>
              ) : (
                <Typography>
                  {t(`${translationJSONPrefix}.labels.noDescription`)}
                </Typography>
              )}
            </DescriptionContainer>
            <Box>
              {individualFileCollection?.tags?.length > 0 ? (
                individualFileCollection.tags.map((tag, index) => (
                  <TagsChip key={index} label={tag} />
                ))
              ) : (
                <Typography>
                  {t(`${translationJSONPrefix}.labels.tagsNotFound`)}
                  {` `}
                </Typography>
              )}
            </Box>
            <AlignmentBox>
              <Typography>
                {individualFileCollection.lastUpdatedBy ? (
                  <>
                    {t(`${translationJSONPrefix}.labels.updatedFound`)}
                    {` `}
                    {individualFileCollection.lastUpdatedBy}
                    {` `}
                    {t(`${translationJSONPrefix}.labels.at`)}
                    <DisplayTime
                      time={individualFileCollection.lastUpdatedDate}
                      format="D MMMM, YYYY hh.mm A"
                    />
                  </>
                ) : (
                  <>{t(`${translationJSONPrefix}.labels.updateNotFound`)}</>
                )}
              </Typography>
            </AlignmentBox>
            <AlignmentBox>
              {!individualFileCollection?.isStoreCreated && (
                <FilesNotAddedAlert severity="warning">
                  <Typography>
                    {t(`${translationJSONPrefix}.labels.filesNotProcessed`)}
                  </Typography>
                </FilesNotAddedAlert>
              )}
            </AlignmentBox>
            <Grid container spacing={1} marginTop={2}>
              <Grid item xs={12}>
                {isGetIngestedFilesLoading ? (
                  <LinearProgress />
                ) : (
                  <DataGridTable
                    row={ingestedFiles}
                    rowId={`fileName`}
                    column={getColumnsForFilesDataGrid()}
                    isPaginationRequired={true}
                    serverSideSearch={true}
                    isSearching={isGetIngestedFilesFetching}
                    onSearch={(e) => setSearchQuery(e)}
                    handleRow={(props) => handleRowClick(props.row)}
                    isManagePropertiesNeeded={
                      individualFileCollection?.isMetadataEnabled &&
                      isMetadataFunctionalityEnabledInApp
                    }
                    buttonTextForManageProperties={t(
                      `addMetadataPage.manageMetadataProperties`,
                    )}
                    handleNavigate={handleManageMetadataProperties}
                    customPageNo={pageNo}
                    customPageSize={pageSize}
                    total={ingestedFilesObj.total}
                    onCustomPageChange={(newPage) => setPageNo(newPage)}
                    onCustomPageSizeChange={(newPageSize) =>
                      setPageSize(newPageSize)
                    }
                    isNextPageLoading={isGetIngestedFilesFetching}
                  />
                )}
              </Grid>
              <ConfirmationDialog
                open={openDeleteFile}
                title={t(`${translationJSONPrefix}.labels.deleteTitle`)}
                messageLine1={t(
                  `${translationJSONPrefix}.alertMessages.deleteAlertTitle`,
                  {
                    fileName: selectedFile,
                  },
                )}
                messageLine2={t(
                  `${translationJSONPrefix}.labels.deleteMessage1`,
                )}
                alertSeverity={t(
                  `${translationJSONPrefix}.alertMessages.warning`,
                )}
                yesButtonLabel={t(
                  `${translationJSONPrefix}.buttonTexts.deleteButton`,
                )}
                noButtonLabel={t(
                  `${translationJSONPrefix}.buttonTexts.cancelButton`,
                )}
                onYesClick={() => handleDeleteFile(selectedFile)}
                onNoClick={handleCancelDeleteClick}
              />
            </Grid>
          </>
        )}
      </AppShadowBox>
    </PageContainer>
  );
};
IndividualFileCollectionPage.propTypes = {
  row: PropTypes.array,
};

export default IndividualFileCollectionPage;
