import React, { useState, useEffect } from 'react';
import { Typography, Link, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShareIcon } from '../../assets/answer-share-icon.svg';
import {
  StyleShareTypography,
  StyledIconButton,
  FeedBackWrapper,
  AnswerComponentBottomWrapper,
  ShareWrapper,
} from './answer.styled';
import { LoadingButton } from '@mui/lab';

const AnswerFooter = ({
  title,
  description,
  meta,
  feedback,
  feedbackAPI,
  query,
  result,
}) => {
  const { t } = useTranslation();

  const [feedbackResponse, setFeedbackResponse] = useState('');

  const [shareIconColor, setShareIconColor] = useState('secondary');

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const {
    feedbackData,
    isAddFeedbackError,
    isAddFeedbackLoading,
    isAddFeedbackSuccess,
  } = feedbackAPI;

  useEffect(() => {
    setFeedbackSubmitted(isAddFeedbackSuccess);
  }, [isAddFeedbackSuccess]);

  useEffect(() => {
    setFeedbackSubmitted(false);
  }, [title]);

  const subjectOfMail = t('answer.emailSubject', {
    query: query,
  });

  const bodyOfMail = t('answer.emailBody', {
    title: title,
    description: description,
    meta: meta,
    downloadUriPart: meta?.url,
  });

  const handleFeedbackSend = (bool) => {
    setFeedbackResponse(bool ? 'y' : 'n');
    feedback(result, bool);
  };

  return (
    <AnswerComponentBottomWrapper>
      <FeedBackWrapper>
        <Box>
          <Typography sx={{ fontSize: '18px', fontWeight: 300 }}>
            {feedbackSubmitted
              ? t('answer.afterFeedbackText')
              : t('answer.feedbackText')}
          </Typography>
        </Box>
        {!feedbackSubmitted && (
          <>
            <LoadingButton
              variant="contained"
              loading={feedbackResponse === 'y' && isAddFeedbackLoading}
              onClick={() => handleFeedbackSend(true)}
              sx={{ marginRight: '10px', marginLeft: '10px' }}
            >
              Yes
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              loading={feedbackResponse === 'n' && isAddFeedbackLoading}
              onClick={() => handleFeedbackSend(false)}
            >
              No
            </LoadingButton>
          </>
        )}
      </FeedBackWrapper>

      <ShareWrapper>
        <Link
          href={`mailto:?subject=${subjectOfMail}&body=${bodyOfMail}`}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          <StyledIconButton
            color={shareIconColor}
            size="small"
            sx={{ borderRadius: 0 }}
            onClick={() => setShareIconColor('primary')}
          >
            <ShareIcon
              alt={t('questionAnswerPage.answerFeedback.thumbsDownImgAlt')}
            />
            <StyleShareTypography variant="subtitle1">
              {t('answer.shareButton')}
            </StyleShareTypography>
          </StyledIconButton>
        </Link>
      </ShareWrapper>
    </AnswerComponentBottomWrapper>
  );
};

AnswerFooter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,
  feedback: PropTypes.func,
  feedbackAPI: PropTypes.object,
  query: PropTypes.string,
  result: PropTypes.object,
};

export default AnswerFooter;
