/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { React, useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import {
  StyledGridContainer,
  DefineNewPropertyButton,
  HeaderGrid,
  StyledDeleteButton,
} from './manageMetadataProperties.styled';
import { useDeleteMetaDataIndexMutation } from '../../../../redux/services/speciphicAsk';
import ConfirmationDialog from '../../../../components/confirmationDialog';
import * as PATHS from '../../../../constants/path';

const ManageMetadataHeaderBar = ({
  handleDefineNewProperty,
  collectionId,
  indexName,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDeleteIndexDialogOpen, setIsDeleteIndexDialogOpen] = useState(false);
  const [
    deleteMetadataIndex,
    {
      data: index,
      isSuccess: isDeleteIndexSuccess,
      isLoading: isDeleteIndexLoading,
      isError: isDeleteIndexError,
    },
  ] = useDeleteMetaDataIndexMutation();

  const isMdScreenOrLarger = () => {
    return useMediaQuery((theme) => theme.breakpoints.up('md'));
  };

  const isMdOrLarger = isMdScreenOrLarger();

  const handleCloseDeleteIndexDialog = () => {
    setIsDeleteIndexDialogOpen(false);
  };

  const handleOpenDeleteIndexDialog = (e) => {
    e.stopPropagation();
    setIsDeleteIndexDialogOpen(true);
  };

  const handleDeleteIndex = () => {
    deleteMetadataIndex({
      collectionId: collectionId,
    });
  };

  useEffect(() => {
    if (isDeleteIndexSuccess) {
      setIsDeleteIndexDialogOpen(false);
      navigate(`${PATHS.FILE_COLLECTIONS}/${collectionId}`, {
        state: {
          id: collectionId,
        },
      });
    }
  }, [isDeleteIndexSuccess]);

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4">
          {' '}
          {indexName} - {t('manageMetadataPage.headerBar.metadataProperties')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
        <DefineNewPropertyButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleDefineNewProperty}
        >
          {t('manageMetadataPage.headerBar.defineNewPropertyButtonLabel')}
        </DefineNewPropertyButton>

        <StyledDeleteButton
          onClick={(e) => handleOpenDeleteIndexDialog(e)}
          variant="contained"
          color="primary"
          startIcon={
            <DeleteIcon
              sx={{
                fontSize: 28,
              }}
            />
          }
        >
          {t('manageMetadataPage.headerBar.metadataProperties')}
        </StyledDeleteButton>
      </Grid>

      <Grid>
        <ConfirmationDialog
          open={isDeleteIndexDialogOpen}
          title={t('manageMetadataPage.headerBar.deleteConfirmationTitle')}
          messageLine1={t(
            'manageMetadataPage.headerBar.deleteConfirmationMessageLine1',
            {
              displayName: indexName,
            },
          )}
          messageLine2=""
          onYesClick={handleDeleteIndex}
          onNoClick={handleCloseDeleteIndexDialog}
          yesButtonLoading={isDeleteIndexLoading}
        />
      </Grid>
    </Grid>
  );
};

ManageMetadataHeaderBar.propTypes = {
  handleDefineNewProperty: PropTypes.func.isRequired,
  collectionId: PropTypes.string,
  indexName: PropTypes.string,
};

export default ManageMetadataHeaderBar;
