/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

import {
  CircularProgress,
  styled,
  Chip,
  Box,
  Skeleton,
  IconButton,
  Grid,
  Autocomplete,
  Card,
  Alert,
  Avatar,
  Divider,
  LinearProgress,
} from '@mui/material';
export const LoadingProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  width: '25px !important',
  height: '25px !important',
  right: '20px',
  top: '15px',
}));

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: '80%',
}));

export const PdfDownloadLoading = styled(CircularProgress)(({ theme }) => ({
  width: '25px !important',
  height: '25px !important',
  position: 'absolute',
  right: '20px',
  top: '20px',
}));

export const StatusTag = styled(Chip)(({ theme }) => ({
  height: '25px !important',
}));

export const PdfContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const PdfSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  height: 60,
  [theme.breakpoints.up('sm')]: {
    width: '75%',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  top: '15px',
  right: '10px',
}));

export const GridBox = styled(Grid)(({ theme }) => ({
  width: '100%',
}));

export const StockWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const AutoCompleteWrapper = styled(Box)(({ theme, isMargin }) => ({
  display: 'flex',
  justifyContent: 'center',
  my: isMargin ? 3 : 0,
}));

export const AutoComplete = styled(Autocomplete)(({ theme }) => ({
  '.MuiInputBase-root': { borderRadius: 1 },
  width: '75%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const CardContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const CardBox = styled(Card)(({ theme }) => ({
  width: '75%',
}));

export const AlertComplete = styled(Alert)(({ theme }) => ({
  width: '75%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const SecondaryActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ReportSourcesAvatar = styled(Avatar)(({ theme }) => ({
  width: '24px',
  height: '24px',
  margin: '2px',
}));

export const SecondaryActionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}));

export const ItemDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
