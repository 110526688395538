import {
  AWS_S3_TYPE,
  AZURE_BLOB_TYPE,
  FTP_TYPE,
  SHAREPOINT_TYPE,
  SERVICENOW_TYPE,
} from '../../constants/dataSources';
import AWSForm from './awsForm';
import AzureBlobForm from './azureBlobForm';
import SharepointForm from './sharepointForm';
import ServiceNowForm from './serviceNowForm';
import FTPForm from './ftpForm';
import PropTypes from 'prop-types';

const DataSourceForm = ({ sourceType }) => {
  switch (sourceType) {
    case AZURE_BLOB_TYPE:
      return <AzureBlobForm storageContainer={AZURE_BLOB_TYPE} />;
    case SHAREPOINT_TYPE:
      return <SharepointForm storageContainer={SHAREPOINT_TYPE} />;
    case SERVICENOW_TYPE:
      return <ServiceNowForm storageContainer={SERVICENOW_TYPE} />;
    case AWS_S3_TYPE:
      return <AWSForm storageContainer={AWS_S3_TYPE} />;
    case FTP_TYPE:
      return <FTPForm />;
    default:
      return <AzureBlobForm />;
  }
};

DataSourceForm.propTypes = {
  sourceType: PropTypes.string,
};

export default DataSourceForm;
