/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

export default (builder) =>
  builder.query({
    query: ({ fileCollectionId }) => {
      return {
        url: `analytics/file-collections/${fileCollectionId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseHandler: async (response) => {
          let filename = '';

          const contentDisposition = response?.headers?.get(
            'content-disposition',
          );
          if (contentDisposition.split(`"`).length > 1)
            filename = contentDisposition.split(`"`)[1];

          return { filename, response: await response.text() };
        },
      };
    },
  });
