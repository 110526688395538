/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const muiToolbar = {
  styleOverrides: {
    root: {
      padding: '4px 0',
      minHeight: `${70}px !important`,
    },
  },
};

export { muiToolbar };
