/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import * as PATHS from '../../constants/path';
import {
  StyledFooterPaper,
  StyledNavigationSection,
  StyledLeftSection,
  FooterLink,
  LogoWrap,
  LogoBox,
} from './app.footer.style';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';

const AppFooter = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [footerFile, setFooterFile] = useState('');

  const { getSiteConfig, isUnauthorized } = useContext(SiteConfigContext);

  useEffect(() => {
    const siteConfig = getSiteConfig();

    if (siteConfig) {
      setFooterFile(siteConfig?.footerLogoFile);
    }
  }, [getSiteConfig()]);

  if (isUnauthorized()) return <></>;

  return (
    <StyledFooterPaper ref={ref}>
      <StyledNavigationSection>
        <StyledLeftSection>
          <FooterLink to={PATHS.COOKIES}>
            {t('appFooter.cookieLink')}
          </FooterLink>
          <FooterLink to={PATHS.PRIVACY_POLICY}>
            {t('appFooter.privacyPolicyLink')}
          </FooterLink>
          <FooterLink to={PATHS.TERMS_OF_USE}>
            {t('appFooter.termsOfUseLink')}
          </FooterLink>
        </StyledLeftSection>
        <LogoBox>
          {/* <StyledTypography variant="caption">
            {t('appFooter.poweredByText')}
          </StyledTypography> */}
          <LogoWrap>
            {footerFile ? (
              <img
                src={footerFile}
                alt="Footer SVG"
                style={{ height: '25px' }}
              />
            ) : (
              <></>
            )}
          </LogoWrap>
        </LogoBox>
      </StyledNavigationSection>
    </StyledFooterPaper>
  );
});

AppFooter.displayName = 'AppFooter';

export default AppFooter;
