/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import {
  FormBox,
  FormGrid,
  PopoverBox,
  StyledIconButton,
} from './miReportSourcePopover.styled';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import ReportIconSvg from '../../../assets/report-icon';
import DeleteIconSvg from '../../../assets/delete-icon';

const MiReportSourcePopover = ({
  open,
  anchorEl,
  handleClose,
  initialData,
  onSubmit,
  mode,
  handleNameChange,
  handleStatusChange,
  handleUrlChange,
  handleAddUrl,
  handleDeleteUrl,
  requestReceived,
  isLoading,
  handleAddCompanyLogo,
  handleAddCompanyStockSymbol,
  marketIntelligenceFeature,
  isAddCompanyStockSymbolRequired,
  sourceLabel,
}) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialData);
  const nameType = sourceLabel ? 'Source' : 'Company';

  const getLabel = (key) =>
    t(`reportSourcesPage.popover.form.${key}`, { nameType });

  useEffect(() => {
    setFormValues(initialData);
  }, [initialData, formValues]);

  const handleClosePopover = () => {
    handleClose();
  };

  const urlRegex = new RegExp(
    /((https?:\/\/)?([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.w-]*)?\??(?:[-+=&;%@.w_]*)#?(?:\w*)?)/,
  );

  const id = open ? 'simple-popover' : undefined;

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      sx={{ marginTop: 1 }}
    >
      <PopoverBox>
        <Typography variant="h6">
          {mode === 'add'
            ? t('reportSourcesPage.popover.addHeader')
            : t('reportSourcesPage.popover.editHeader')}
        </Typography>
        <IconButton onClick={handleClosePopover}>
          <Close />
        </IconButton>
      </PopoverBox>
      <Box sx={{ margin: 3, maxWidth: '450px' }}>
        <Grid container rowSpacing={2}>
          {requestReceived && mode === 'add' ? (
            <Grid item xs={12}>
              <Alert severity="success" sx={{ width: '100%' }}>
                {t('reportSourcesPage.popover.requestReceived')}
              </Alert>
            </Grid>
          ) : (
            <>
              <FormControl fullWidth>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 2,
                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    variant="filled"
                    fullWidth
                    label={getLabel('companyName')}
                    value={formValues.companyName}
                    onChange={handleNameChange}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: '4px',
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 2,
                  }}
                  xs={12}
                >
                  <TextField
                    size="small"
                    variant="filled"
                    fullWidth
                    label={getLabel('logoUrl')}
                    value={formValues.companyLogoUrl}
                    onChange={handleAddCompanyLogo}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: '4px',
                      },
                    }}
                  />
                </Grid>
                {isAddCompanyStockSymbolRequired && (
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 2,
                    }}
                    xs={12}
                  >
                    <TextField
                      size="small"
                      variant="filled"
                      fullWidth
                      label={getLabel('stock')}
                      value={formValues.companyStockSymbol}
                      onChange={handleAddCompanyStockSymbol}
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: '4px',
                        },
                      }}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 2,
                  }}
                  xs={12}
                >
                  <TextField
                    aria-readonly
                    size="small"
                    variant="filled"
                    fullWidth
                    label={t('reportSourcesPage.popover.form.feature')}
                    value={marketIntelligenceFeature}
                    onChange={handleAddCompanyStockSymbol}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: '4px',
                      },
                    }}
                  />
                </Grid>

                {mode === 'edit' && (
                  <Grid item xs={12} sx={{ marginBottom: 2 }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {t('reportSourcesPage.popover.form.status')}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="status"
                      name="status"
                      value={formValues.status}
                      onChange={handleStatusChange}
                    >
                      <FormControlLabel
                        checked={formValues.status === 'pending'}
                        value="pending"
                        control={<Radio />}
                        label={t('reportSourcesPage.popover.form.pending')}
                      />
                      <FormControlLabel
                        value="approved"
                        checked={formValues.status === 'approved'}
                        control={<Radio color="success" />}
                        label={t('reportSourcesPage.popover.form.approve')}
                      />
                      <FormControlLabel
                        value="rejected"
                        checked={formValues.status === 'rejected'}
                        control={<Radio color="error" />}
                        label={t('reportSourcesPage.popover.form.reject')}
                      />
                    </RadioGroup>
                  </Grid>
                )}
                <FormGrid item xs={12}>
                  {formValues.sourceUrls.map((url, index) => (
                    <FormBox key={index}>
                      <TextField
                        key={index}
                        label={t('reportSourcesPage.popover.form.sourceUrl')}
                        size="small"
                        variant="filled"
                        fullWidth
                        value={url}
                        error={url.length > 0 && !urlRegex.test(url)}
                        type="url"
                        helperText={
                          url.length > 0 &&
                          !urlRegex.test(url) &&
                          'Enter valid url'
                        }
                        onChange={(e) => handleUrlChange(index, e.target.value)}
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: '4px',
                          },
                        }}
                      />
                      <StyledIconButton
                        onClick={handleAddUrl}
                        color="primary"
                        disabled={
                          !urlRegex.test(
                            formValues.sourceUrls[
                              formValues.sourceUrls.length - 1
                            ],
                          ) ||
                          formValues.sourceUrls[
                            formValues.sourceUrls.length - 1
                          ].length === 0
                        }
                      >
                        <AddIcon />
                      </StyledIconButton>
                      <StyledIconButton
                        disabled={formValues.sourceUrls.length <= 1}
                        onClick={() => handleDeleteUrl(index)}
                        color="error"
                      >
                        <DeleteIconSvg />
                      </StyledIconButton>
                    </FormBox>
                  ))}
                </FormGrid>
                <Grid item xs={12}>
                  <Typography variant="body">
                    {mode === 'add'
                      ? t('reportSourcesPage.popover.requestAlert.add')
                      : t('reportSourcesPage.popover.requestAlert.edit')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={'flex'}
                  marginTop={2}
                  justifyContent={'right'}
                  gap={2}
                >
                  <Button
                    variant="outlined"
                    sx={{ textAlign: 'right', borderColor: 'transparent' }}
                    onClick={handleClosePopover}
                  >
                    {t('reportSourcesPage.popover.buttons.cancel')}
                  </Button>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    sx={{ textAlign: 'right' }}
                    onClick={handleSubmit}
                    disabled={
                      formValues.companyName.length <= 2 ||
                      formValues.sourceUrls.length === 0 ||
                      (formValues.sourceUrls.length === 1 &&
                        formValues.sourceUrls[0] === '') ||
                      formValues.sourceUrls.some((url) => !urlRegex.test(url))
                    }
                    startIcon={<ReportIconSvg />}
                  >
                    {mode === 'add'
                      ? t('reportSourcesPage.popover.buttons.requestNew')
                      : t('reportSourcesPage.popover.buttons.requestEdits')}
                  </LoadingButton>
                </Grid>
              </FormControl>
            </>
          )}
        </Grid>
      </Box>
    </Popover>
  );
};

MiReportSourcePopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
  initialData: PropTypes.shape({
    companyName: PropTypes.string,
    sourceUrls: PropTypes.array,
  }),
  mode: PropTypes.oneOf(['add', 'edit']),
  handleNameChange: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleUrlChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleAddUrl: PropTypes.func,
  requestReceived: PropTypes.bool,
  handleDeleteUrl: PropTypes.func,
  isLoading: PropTypes.bool,
  handleAddCompanyLogo: PropTypes.func,
  handleAddCompanyStockSymbol: PropTypes.func,
  marketIntelligenceFeature: PropTypes.string,
  isAddCompanyStockSymbolRequired: PropTypes.bool,
  sourceLabel: PropTypes.string,
};

export default MiReportSourcePopover;
