import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Stack,
  Grid,
  Typography,
  LinearProgress,
} from '@mui/material';
import {
  Collections,
  Download,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DropdownOne from '../../../components/dropdownone';
import PageContainer from '../../../components/pageContainer';
import MetricCard from '../../../components/metricCard';
import {
  CustomGrid,
  DataGridBox,
  MetricsGrid,
  MetricsInnerContainer,
  StyledDataGrid,
} from '../dashboard.page.styled';
import { DRAWER_WIDTH, SECTIONS } from '../../../constants/drawer';
import { LOAD_TITLE, triggerFileDownload } from '../../../utils/functions';
import {
  useLazyDownloadFileCollectionAnalyticsQuery,
  useLazyGetAnalyticsFileCollectionsQuery,
} from '../../../redux/services/speciphicAsk';
import Loader from '../../../components/loader';
import colors from '../../../themes/colors';
import {
  DownloadProgress,
  PaginationTextTypography,
  ProgressBar,
} from './fileCollections.page.styled';
import DisplayTime from '../../../components/timezone.component';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { RowPerPageOptions } from '../../../constants/rowPerPageOptions';
import UnauthorizedMessage from '../../../components/unauthorized';
import CustomToolbar from '../components/customToolbar';

const translationJSONPrefix = 'analyticsSection.fileCollectionsPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const data_schema = (t) => {
  const translationPrefix = `${translationJSONPrefix}.dataSchemaTitles`;

  return {
    metrics: [
      {
        id: 'collectionTotal',
        label: t(`${translationPrefix}.collectionsTotal`),
        icon: (props) => <Collections {...props} />,
        value: '...',
        indicator: 'up',
      },
    ],
    tableData: [],
  };
};

const columns = (t, handleDownload) => {
  const translationPrefix = `${translationJSONPrefix}.columns`;

  return [
    {
      field: 'name',
      headerName: t(`${translationPrefix}.name`),
      minWidth: 150,
      flex: 1.2,
    },
    {
      field: 'uniqueFilesCount',
      headerName: t(`${translationPrefix}.filesCount`),
      type: 'number',
      valueGetter: (params) => `${params.row?.ingestedUniqueFilesCount || 0}`,
      minWidth: 100,
      flex: 0.7,
    },
    {
      field: 'queriesAskedCount',
      headerName: t(`${translationPrefix}.totalQueries`),
      minWidth: 100,
      type: 'number',
      flex: 0.7,
      renderCell: (params) => {
        return <span style={{ color: colors.blue.B400 }}>{params.value}</span>;
      },
    },
    {
      field: 'isHelpfulTrueCount',
      headerName: t(`${translationPrefix}.totalPositiveFeedback`),
      minWidth: 100,
      type: 'number',
      flex: 0.7,
      renderCell: (params) => {
        return <span style={{ color: colors.green.G400 }}>{params.value}</span>;
      },
    },
    {
      field: 'isHelpfulFalseCount',
      headerName: t(`${translationPrefix}.totalNegativeFeedback`),
      minWidth: 100,
      type: 'number',
      flex: 0.7,
      renderCell: (params) => {
        return <span style={{ color: colors.red.R400 }}>{params.value}</span>;
      },
    },
    {
      field: 'createdBy',
      headerName: t(`${translationPrefix}.createdBy`),
      minWidth: 120,
      flex: 1.2,
    },
    {
      field: 'lastUpdatedDate',
      headerName: t(`${translationPrefix}.lastUpdatedDate`),
      type: 'date',
      renderCell: (params) => (
        <DisplayTime time={params.value} format="DD/MM/YYYY hh:mm A" />
      ),
      minWidth: 200,
      flex: 1,
    },
    {
      headerAlign: 'center',
      align: 'center',
      headerName: t(`${translationPrefix}.download`),
      sortable: false,
      renderCell: handleDownload,
      minWidth: 50,
      flex: 1,
    },
  ];
};

export const AnalyticsFileCollections = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  useEffect(
    () => LOAD_TITLE(document, t(`${translationJSONPrefix}.title`)),
    [],
  );

  const [pageData, setPageData] = useState(data_schema(t));
  const [pageDrawerOpen, setIsPageDrawerOpen] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const [
    getFileCollections,
    {
      data: fileCollections = {},
      error,
      isSuccess: isGetFileCollectionsSuccess,
      isLoading: isGetFileCollectionsLoading,
      isError: isGetFileCollectionsError,
      isFetching: isGetFileCollectionsFetching,
    },
  ] = useLazyGetAnalyticsFileCollectionsQuery();

  useEffect(() => {
    if (isGetFileCollectionsSuccess) {
      const updatedPageData = { ...pageData };
      updatedPageData.metrics[0].value = fileCollections?.totalCollections;
      updatedPageData.metrics[0].indicator = '';
      updatedPageData.tableData = fileCollections.data;

      setPageData(updatedPageData);
    }
  }, [fileCollections, isGetFileCollectionsSuccess]);

  useEffect(() => {
    getFileCollections({
      pageNo: pageNo + 1,
      pageSize: pageSize,
    });
  }, []);

  useEffect(() => {
    getFileCollections({
      pageNo: pageNo + 1,
      pageSize: pageSize,
    });
  }, [pageSize]);

  const [
    downloadAnalytics,
    {
      data: analytics,
      isError: isDownloadAnalyticsError,
      isLoading: isDownloadAnalyticsLoading,
      isSuccess: isDownloadAnalyticsSuccess,
    },
  ] = useLazyDownloadFileCollectionAnalyticsQuery();

  useEffect(() => {
    if (isDownloadAnalyticsSuccess && analytics) {
      triggerFileDownload(
        analytics?.response,
        analytics?.filename || 'analytics.csv',
        'text/csv',
      );
    }
  }, [isDownloadAnalyticsSuccess, analytics]);

  const handleDownload = (values) => {
    setSelectedId(values.id);

    downloadAnalytics({
      fileCollectionId: values.id,
    });
  };

  const downloadFeedback = (props) => {
    const values = props.row;
    return (
      <>
        {isDownloadAnalyticsLoading && values.id == selectedId ? (
          <>
            <DownloadProgress />
          </>
        ) : (
          <IconButton onClick={() => handleDownload(values)}>
            <Download />
          </IconButton>
        )}
      </>
    );
  };

  const handlePageChange = (newPageNo) => {
    setPageNo(newPageNo);
    getFileCollections({
      pageNo: newPageNo + 1,
      pageSize: pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageNo(0);
  };

  const CustomPagination = () => (
    <Stack direction="row" spacing={3} alignItems="center">
      <Grid container alignItems="center" spacing={1} sx={{ pb: 1 }}>
        <Grid item>
          <Typography variant="body1">
            {' '}
            {t(`${translationJSONPrefix}.columns.rows`)}
          </Typography>
        </Grid>
        <Grid item alignItems="center">
          <DropdownOne
            value={pageSize}
            onChange={(value) => handlePageSizeChange(value)}
            label=""
            items={RowPerPageOptions}
          />
        </Grid>
      </Grid>

      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => handlePageChange(pageNo - 1)}
          disabled={pageNo === 0}
        >
          <KeyboardArrowLeft />
        </IconButton>

        <Stack
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="row"
        >
          <PaginationTextTypography>
            {pageNo * pageSize + 1}{' '}
          </PaginationTextTypography>
          <PaginationTextTypography> - </PaginationTextTypography>
          <PaginationTextTypography>
            {Math.min(
              (pageNo + 1) * pageSize,
              fileCollections.totalCollections,
            )}
          </PaginationTextTypography>
          <PaginationTextTypography>
            {t(`${translationJSONPrefix}.columns.of`)}
          </PaginationTextTypography>
          <PaginationTextTypography>
            {fileCollections.totalCollections}
          </PaginationTextTypography>
        </Stack>

        <IconButton
          onClick={() => handlePageChange(pageNo + 1)}
          disabled={(pageNo + 1) * pageSize >= fileCollections.totalCollections}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Stack>
    </Stack>
  );

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={pageDrawerOpen}
        drawer={SECTIONS.ANALYTICS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsPageDrawerOpen(res)}
      drawer={SECTIONS.ANALYTICS}
    >
      {isGetFileCollectionsLoading && (
        <Loader
          label={t(`${translationJSONPrefix}.loadingMessage.loadingData`)}
        />
      )}

      <MetricsGrid
        style={{ width: width - (pageDrawerOpen ? DRAWER_WIDTH : 0) - 50 }}
      >
        <MetricsInnerContainer>
          {pageData?.metrics?.map((item, index) => (
            <CustomGrid item key={index}>
              <MetricCard
                icon={item.icon}
                indicator={item.indicator}
                value={item?.value?.toString()}
                label={item?.label}
                valueColor={
                  item.indicator === 'down'
                    ? colors.red.R300
                    : colors.green.G400
                }
              />
            </CustomGrid>
          ))}
        </MetricsInnerContainer>
      </MetricsGrid>

      {pageData?.tableData?.length > 0 && (
        <DataGridBox>
          {isGetFileCollectionsFetching && <ProgressBar />}
          <StyledDataGrid
            autoHeight
            rows={pageData?.tableData || []}
            columns={columns(t, downloadFeedback)}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
            }}
            disableRowSelectionOnClick
          />
        </DataGridBox>
      )}
    </PageContainer>
  );
};

AnalyticsFileCollections.propTypes = {
  row: PropTypes.object,
  id: PropTypes.string,
};

export default AnalyticsFileCollections;
