/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { object, string, array } from 'yup';
import { Form } from 'formik';
import { Chip } from '@mui/material';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import AddPageHeader from '../../../components/form/addPageHeader.component';
import { ACCESS_CONTROL } from '../../../constants/path';
import { GROUPS } from '../../../constants/accessControlPaths';
import AppForm, {
  AppAutocompleteField,
  AppSelectField,
  AppTextField,
  FormButtons,
} from '../../../components/form';
import { getChangedValues } from '../../../components/form/functions';
import { FormContainer, InputsContainer } from '../styled';
import { AlertStyled } from '../../settings/filesCollectionSettingsPage/styled';
import {
  RBAC_GROUPS_ENDPOINT,
  RBAC_POLICIES_ENDPOINT,
  RBAC_ROLES_ENDPOINT,
} from '../../../constants/accessControl';
import {
  useCreateRBACMutation,
  useLazyGetAllRBACQuery,
  useLazyGetOneRBACQuery,
  useUpdateRBACMutation,
} from '../../../redux/services/speciphicAsk';
import Loader from '../../../components/loader/loader.component';

const mainPageRoute = `${ACCESS_CONTROL}${GROUPS.endpoint}`;

const translationJSONPrefix = 'accessControlSection.groups';

const defaultDataState = {
  name: '',
  roleId: '',
  policies: [],
  description: '',
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.form.fields.${label}.errorMessages`;

  return object({
    name: string()
      .required(t(`${translationPrefix('name')}.required`))
      .min(4, t(`${translationPrefix('name')}.min`))
      .max(50, t(`${translationPrefix('name')}.max`)),
    roleId: string().required(t(`${translationPrefix('role')}.required`)),
    policies: array().required(t(`${translationPrefix('policies')}.required`)),
    description: string()
      .min(4, t(`${translationPrefix('description')}.min`))
      .max(250, t(`${translationPrefix('description')}.max`)),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  rolesData,
  policiesData,
  rolesLoading,
  policiesLoading,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.form.fields`;

  return (
    <Form>
      <InputsContainer>
        <AppTextField
          name="name"
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          name="description"
          label={t(`${translationPrefix}.description.label`)}
          placeholder={t(`${translationPrefix}.description.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          multiline
          minRows={3}
        />
      </InputsContainer>

      <InputsContainer>
        <AppSelectField
          name="roleId"
          label={t(`${translationPrefix}.role.label`)}
          placeholder={
            rolesLoading
              ? t(`${translationPrefix}.role.loading`)
              : t(`${translationPrefix}.role.placeholder`)
          }
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          menuData={rolesData.map((ro) => {
            return {
              label: `${ro?.name} (${ro?.policyCount} polic${
                ro?.policyCount > 1 ? 'ies' : 'y'
              })`,
              value: ro?.id,
            };
          })}
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          name="policies"
          label={t(`${translationPrefix}.policies.label`)}
          placeholder={
            policiesLoading
              ? t(`${translationPrefix}.policies.loading`)
              : t(`${translationPrefix}.policies.placeholder`)
          }
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          options={policiesData}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </InputsContainer>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const AccessControlAddGroupsPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [entityId, setEntityId] = useState('');
  const [rolesData, setRolesData] = useState([]);
  const [policiesData, setPoliciesData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [
    createRBAC,
    {
      data: createdData,
      error: createError,
      isError: isCreateError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateRBACMutation();

  const [
    updateRBAC,
    {
      data: updatedData,
      error: updateError,
      isError: isUpdateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateRBACMutation();

  const [
    getRBAC,
    {
      data: rbacData,
      isError: isDataError,
      isLoading: isDataLoading,
      isSuccess: isDataSuccess,
    },
  ] = useLazyGetOneRBACQuery();

  const [
    getRoles,
    {
      data: rolesDataDB,
      isError: isGetRolesError,
      isLoading: isGetRolesLoading,
      isSuccess: isGetRolesSuccess,
    },
  ] = useLazyGetAllRBACQuery();

  const [
    getPolicies,
    {
      data: policiesDataDB,
      isError: isGetPoliciesError,
      isLoading: isGetPoliciesLoading,
      isSuccess: isGetPoliciesSuccess,
    },
  ] = useLazyGetAllRBACQuery();

  useEffect(() => {
    const tempEntityId = searchParams.get('id');

    if (tempEntityId) {
      setIsEdit(true);
      getRBAC({ endpoint: RBAC_GROUPS_ENDPOINT, id: tempEntityId });
      setEntityId(tempEntityId);
    }

    getRoles({ endpoint: RBAC_ROLES_ENDPOINT });
    getPolicies({ endpoint: RBAC_POLICIES_ENDPOINT });
  }, []);

  useEffect(() => {
    if (isGetRolesSuccess) setRolesData(rolesDataDB);
  }, [rolesDataDB]);

  useEffect(() => {
    if (isGetPoliciesSuccess) setPoliciesData(policiesDataDB);
  }, [policiesDataDB]);

  useEffect(() => {
    if (isDataSuccess && rbacData && policiesDataDB) {
      const tempFormData = {
        name: rbacData?.name,
        description: rbacData?.description,
        roleId: rbacData?.roleId,
        policies: policiesDataDB.filter((p) =>
          rbacData?.policies?.includes(p.id),
        ),
      };

      setFormData(tempFormData);
      setInitialFormData(tempFormData);
    }
  }, [rbacData, policiesDataDB]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateError || isUpdateError) {
      tempAlert.formUpdateError = true;
      tempAlert.formUpdateSuccess = false;
      const tempErrorMessage =
        createError?.data?.message ||
        updateError?.data?.message ||
        t(`${translationJSONPrefix}.form.errorAlertLabel`);
      setErrorMessage(tempErrorMessage);
      setShowAlert(tempAlert);
    }
  }, [isCreateError, isUpdateError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateSuccess || isUpdateSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
      getRBAC({
        endpoint: RBAC_GROUPS_ENDPOINT,
        id: createdData?.id || updatedData?.id,
      });
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  return (
    <PageContainer drawer={SECTIONS.ACCESS_CONTROL}>
      <AddPageHeader
        routes={[
          {
            title: t(`${translationJSONPrefix}.mainPage.title`),
            endpoint: mainPageRoute,
          },
        ]}
        isEdit={isEdit}
      />

      <FormContainer>
        <AlertStyled
          onClose={closeAlert}
          severity={showAlert.formUpdateError ? 'error' : 'success'}
          style={{
            marginTop:
              showAlert.formUpdateError || showAlert.formUpdateSuccess
                ? 10
                : -60,
          }}
        >
          {showAlert.formUpdateSuccess &&
            (isEdit
              ? t(`${translationJSONPrefix}.form.updatedAlertLabel`)
              : t(`${translationJSONPrefix}.form.createdAlertLabel`))}
          {showAlert.formUpdateError && errorMessage}
        </AlertStyled>

        {isDataLoading ? (
          <Loader label={t(`${translationJSONPrefix}.form.loadingLabel`)} />
        ) : (
          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSend = isEdit
                ? getChangedValues(values, initialFormData)
                : { ...values };

              if (valuesToSend?.policies) {
                valuesToSend.policies = valuesToSend.policies.map((p) => p.id);
              }

              if (isEdit)
                updateRBAC({
                  endpoint: RBAC_GROUPS_ENDPOINT,
                  id: entityId,
                  update: valuesToSend,
                });
              else
                createRBAC({
                  endpoint: RBAC_GROUPS_ENDPOINT,
                  body: valuesToSend,
                });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isCreateLoading || isUpdateLoading,
                rolesData,
                policiesData,
                isGetRolesLoading,
                isGetPoliciesLoading,
              )
            }
          />
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default AccessControlAddGroupsPage;
