const {
  REACT_APP_ENABLE_MARKET_INTELLIGENCE,
  REACT_APP_ENABLE_MARKET_INTELLIGENCE_COMPANY_LOGO,
} = process.env;

let isMarketIntelligenceEnabled = false;
let isMarketIntelligenceLogoEnabled = false;

if (
  REACT_APP_ENABLE_MARKET_INTELLIGENCE &&
  REACT_APP_ENABLE_MARKET_INTELLIGENCE?.toLowerCase() === 'true'
) {
  isMarketIntelligenceEnabled = true;
}

if (
  REACT_APP_ENABLE_MARKET_INTELLIGENCE_COMPANY_LOGO &&
  REACT_APP_ENABLE_MARKET_INTELLIGENCE_COMPANY_LOGO?.toLowerCase() === 'true'
) {
  isMarketIntelligenceLogoEnabled = true;
}

export const MARKET_INTELLIGENCE_FEATURE = 'market-intelligence';

export const LISTED_COMPANIES_LABEL = 'Listed Companies';
export const UNLISTED_COMPANIES_LABEL = 'Unlisted Companies';
export const ASK_DB_FILE_COLLECTION_NAME = 'Mi Reports File Collection ';

export const MARKET_INTELLIGENCE_LISTED_FEATURE = 'market-intelligence-listed';
export const MARKET_INTELLIGENCE_UNLISTED_FEATURE =
  'market-intelligence-unlisted';

export const API_FETCH_TIME_PERIOD = 30000; // 30 seconds

export default isMarketIntelligenceEnabled;
export { isMarketIntelligenceLogoEnabled };
