import { Box, CircularProgress, styled } from '@mui/material';
import colors from '../../../themes/colors';
import { InsertPageBreak } from '@mui/icons-material';
export const StyledWholePdfBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowY: 'scroll',
}));

export const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  backgroundColor: colors.neutralLight.N0,
}));

export const StyledCircularIcon = styled(CircularProgress)(({ theme }) => ({
  marginLeft: '40%',
  marginTop: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#D3D3D3',
}));

export const StyledFileBreak = styled(InsertPageBreak)(({ theme }) => ({
  marginLeft: '40%',
  marginTop: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#D3D3D3',
  fontSize: 60,
}));

export const PdfCanvas = styled(Box)(({ theme }) => ({
  textAlign: 'center',
}));

export const PreviewContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
}));
