/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { styled, Select, Grid, InputLabel } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));

export const GridSelectContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingRight: 3,
}));

export const GridContainer = styled(Grid)(
  ({ theme, propertiesData, isMdOrLarger }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: isMdOrLarger
      ? propertiesData.length % 2 === 0
        ? 'flex-start'
        : 'flex-end'
      : 'space-around',

    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  }),
);

export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  alignItems: 'center',
  fontSize: 14,
  marginTop: -1,
}));
