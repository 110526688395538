import React from 'react';
import ReactQuill from 'react-quill';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';

export const markdownToHtml = (markdownText) => {
  const file = remark().use(remarkHtml).processSync(markdownText);
  return String(file);
};

const MyEditor = ({ value, onChange }) => {
  return <ReactQuill theme="snow" value={value} onChange={onChange} />;
};

MyEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default MyEditor;
