/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, Box } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  maxWidth: 500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  gap: 50,
  marginTop: 150,
  padding: '20px 30px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
}));

export const Title = styled(Box)(({ theme }) => ({
  fontSize: 30,
  fontWeight: '600',
}));

export const Description = styled(Box)(({ theme }) => ({
  fontSize: 16,
}));
