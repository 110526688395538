/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  styled,
  Button,
  Grid,
  TextField,
  Typography,
  Alert,
  FormControl,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const InputField = styled(TextField)(({ theme }) => ({
  minWidth: '100%',
  margin: theme.spacing(1),
}));

export const FieldGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const AddSummaryTopicButton = styled(LoadingButton)(({ theme }) => ({
  //  width: 180,
  marginBottom: theme.spacing(1),
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  // width: 180,
  marginBottom: theme.spacing(2),
}));

export const ValidationAlert = styled(Alert)(({ theme }) => ({
  padding: 0,
}));

export const SummaryFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: '100%',
}));
