/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CardContent,
  Button,
} from '@mui/material';
import { Download, Topic, ScatterPlot } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import PageContainer from '../../components/pageContainer';
import * as PATHS from '../../constants/path';
import { pushToast } from '../../redux/reducers/toasts.slice';
import {
  LoadingProgress,
  StyledIconButton,
  StockWrapper,
  GridBox,
  AutoCompleteWrapper,
  AutoComplete,
  AlertComplete,
  PdfContainer,
  PdfSkeleton,
  CardBox,
  CardContentBox,
  PdfDownloadLoading,
  StatusWrapper,
} from './notListedCompaniesReport.styled';
import {
  useAddPreferenceMutation,
  useDeletePreferenceMutation,
  useLazyGetFinancialReportQuery,
  useLazyGetFinancialReportPdfQuery,
  useLazyGetStockSymbolQuery,
  useLazyGetPreferencesQuery,
  useAddNewReportMutation,
} from '../../redux/services/speciphicAsk/speciphic.ask.api';
import DisplayTime from '../../components/timezone.component';
import {
  MARKET_INTELLIGENCE_UNLISTED_FEATURE,
  API_FETCH_TIME_PERIOD,
} from '../../constants/marketIntelligence';
import { isMarketIntelligenceLogoEnabled } from '../../constants/marketIntelligence';
import CompanyList from '../../components/companyList';
import {
  DoneIcon,
  PendingIcon,
  ErrorIcon,
  PlannedIcon,
} from '../../components/companyList/companyList.styled';
import AppShadowBox from '../../components/app.shadowbox';
import UnauthorizedMessage from '../../components/unauthorized';

const statusConfig = {
  READY: { title: 'Report Ready', icon: <DoneIcon /> },
  ERROR: { title: 'Report Error', icon: <ErrorIcon /> },
  CREATING: { title: 'Creating Report', icon: <PendingIcon /> },
  PLANNED: { title: 'Planned Report', icon: <PlannedIcon /> },
  null: { title: null, icon: null },
};

const NotListedCompaniesReportPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accordionContainerRef = useRef(null);
  const [stocksSummary, setStocksSummary] = useState({});
  const [reportInfo, setReportInfo] = useState({});
  const [input, setInput] = useState('');
  const [preferences, setPreferences] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [reportSources, setReportSources] = useState([]);
  const navigate = useNavigate();

  const handleUserInput = (event) => {
    setInput(event.target.value);
  };

  const [
    deleteReportSourcePreference,
    {
      isSuccess: isDeleteReportSourcePreferenceSuccess,
      isLoading: isDeleteReportSourcePreferenceLoading,
      isError: isDeleteReportSourcePreferenceError,
    },
  ] = useDeletePreferenceMutation();

  const [
    fetchFinancialReport,
    {
      data: result = {},
      error,
      isSuccess: isGetFinancialReportSuccess,
      isLoading: isGetFinancialReportLoading,
      isFetching: isGetFinancialReportFetching,
    },
  ] = useLazyGetFinancialReportQuery();

  const [
    fetchFinancialReportPDf,
    {
      data: pdfResult = {},
      isSuccess: isGetPdfResultSuccess,
      isLoading: isGetPdfResultLoading,
      isFetching: isGetPdfResultFetching,
    },
  ] = useLazyGetFinancialReportPdfQuery();

  const [
    getReportSourcePreferences,
    {
      data: reportSourcePreferences = [],
      error: preferenceError,
      isSuccess: isGetReportSourcePreferencesSuccess,
      isLoading: isGetReportSourcePreferencesLoading,
      isFetching: isGetReportSourcePreferencesFetching,
    },
  ] = useLazyGetPreferencesQuery();

  useEffect(() => {
    getReportSourcePreferences({
      feature: MARKET_INTELLIGENCE_UNLISTED_FEATURE,
    });

    // Interval logic
    const intervalId = setInterval(() => {
      getReportSourcePreferences({
        feature: MARKET_INTELLIGENCE_UNLISTED_FEATURE,
      });
      setLoadingStates({});
    }, API_FETCH_TIME_PERIOD);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isGetReportSourcePreferencesSuccess && reportSourcePreferences) {
      setPreferences(reportSourcePreferences);
    }
  }, [isGetReportSourcePreferencesSuccess, reportSourcePreferences]);

  const [
    getStockSymbol,
    {
      data: newSources = [],
      error: stockSymbolError,
      isSuccess: isGetStockSymbolSuccess,
      isLoading: isGetStockSymbolLoading,
      isFetching: isGetStockSymbolFetching,
    },
  ] = useLazyGetStockSymbolQuery();

  useEffect(() => {
    if (isGetStockSymbolSuccess) {
      setReportSources(newSources);
    }
  }, [isGetStockSymbolSuccess, newSources]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getStockSymbol({
        query: '',
        feature: MARKET_INTELLIGENCE_UNLISTED_FEATURE,
        status: 'approved',
      });
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [input]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getStockSymbol({
        query: '',
        feature: MARKET_INTELLIGENCE_UNLISTED_FEATURE,
        status: 'approved',
      });
    }, API_FETCH_TIME_PERIOD);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleAddReportSourcePreference = (event, value) => {
    if (
      value &&
      !reportSourcePreferences.some((option) => option.key === value.id)
    ) {
      addPreference({
        feature: MARKET_INTELLIGENCE_UNLISTED_FEATURE,
        key: value.key,
      });
    }
  };

  const [
    addPreference,
    {
      isSuccess: isAddPreferenceSuccess,
      isLoading: isAddPreferenceLoading,
      isError: isAddPreferenceError,
    },
  ] = useAddPreferenceMutation();

  const [
    generateReportSource,
    {
      isSuccess: isGenerateReportSourceSuccess,
      isLoading: isGenerateReportSourceLoading,
      isError: isGenerateReportSourceError,
    },
  ] = useAddNewReportMutation();

  const handleDeletePreference = (key) => {
    deleteReportSourcePreference({
      key: key,
      feature: MARKET_INTELLIGENCE_UNLISTED_FEATURE,
    });
    setPreferences(preferences.filter((report) => report.key !== key));
    dispatch(
      pushToast({
        message: t(
          'marketIntelligencePage.unListedCompanyPage.alertMessages.successDelete',
        ),
        severity: 'success',
      }),
    );
  };

  const filterOptions = (options, { inputValue }) => {
    const searchString = inputValue.toLowerCase();
    return options.filter(
      (option) =>
        option.companyName.toLowerCase().includes(searchString) ||
        (option.companyName &&
          option.companyName.toLowerCase().includes(searchString)),
    );
  };

  const handleGenerateReport = (symbol) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [symbol]: true,
    }));
    generateReportSource({
      report_key: symbol,
      feature: MARKET_INTELLIGENCE_UNLISTED_FEATURE,
    });
  };

  useEffect(() => {
    if (isGenerateReportSourceSuccess) {
      setLoadingStates({});
    }
  }, [isGenerateReportSourceSuccess]);

  useEffect(() => {
    if (isGetFinancialReportSuccess && reportInfo) {
      setStocksSummary({
        symbol: reportInfo.symbol,
        summary: result.summary,
        reportId: reportInfo.reportId,
      });
      accordionContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isGetFinancialReportSuccess, reportInfo, result]);

  const handleShowReportClick = (reportId, symbol) => {
    if (reportId) {
      setReportInfo({ reportId, symbol });
      fetchFinancialReport({ report_id: reportId });
    } else {
      dispatch(
        pushToast({
          message: t(
            'marketIntelligencePage.listedCompanyPage.alertMessages.errorSelect',
          ),
          severity: 'error',
        }),
      );
    }
  };

  // PDF generation function
  const handleDownload = (reportId, symbol) => {
    fetchFinancialReportPDf({
      report_id: reportId,
      report_format: 'pdf',
      download_report: true,
    })
      .unwrap()
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = symbol;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  };

  const handleNavigate = (feature, type) => {
    if (type === 'report-sources') {
      navigate(PATHS.REPORT_SOURCES, {
        state: {
          feature: feature,
        },
      });
    } else if (type === 'report-topics') {
      navigate(PATHS.REPORT_SUMMARY_TOPICS, {
        state: {
          feature: feature,
        },
      });
    }
  };

  if (
    error?.status === 403 ||
    preferenceError?.status === 403 ||
    stockSymbolError?.status === 403
  )
    return (
      <UnauthorizedMessage
        description={t(`marketIntelligencePage.unauthorizedMessages.list`)}
      />
    );

  return (
    <PageContainer>
      <AppShadowBox>
        <GridBox container rowSpacing={2}>
          <Grid container item xs={12} justifyContent={'space-between'}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4">
                {t('marketIntelligencePage.unListedCompanyPage.title')}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              spacing={2}
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <StockWrapper>
                  <Button
                    startIcon={<ScatterPlot />}
                    onClick={() =>
                      handleNavigate(
                        MARKET_INTELLIGENCE_UNLISTED_FEATURE,
                        'report-sources',
                      )
                    }
                    variant="contained"
                  >
                    {t('marketIntelligencePage.sourceButton')}
                  </Button>
                  <Button
                    startIcon={<Topic />}
                    onClick={() =>
                      handleNavigate(
                        MARKET_INTELLIGENCE_UNLISTED_FEATURE,
                        'report-topics',
                      )
                    }
                    variant="contained"
                    sx={{ marginLeft: 1 }}
                  >
                    {t('marketIntelligencePage.reportButton')}
                  </Button>
                </StockWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <AutoCompleteWrapper isMargin={true}>
              <AutoComplete
                options={reportSources}
                onChange={handleAddReportSourcePreference}
                getOptionLabel={(option) => option.companyName}
                popupIcon={<></>}
                blurOnSelect={true}
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <ListItem {...props} key={option.id}>
                    {isMarketIntelligenceLogoEnabled && (
                      <ListItemAvatar>
                        <Avatar src={option.companyLogoUrl}></Avatar>
                      </ListItemAvatar>
                    )}

                    <ListItemText
                      primary={
                        <StatusWrapper>
                          {option.key}
                          {option.status in statusConfig && (
                            <>
                              <Tooltip
                                title={statusConfig[option.status].title}
                              >
                                {statusConfig[option.status].icon}
                              </Tooltip>
                              <Typography fontSize={12}>
                                <DisplayTime
                                  time={option.lastUpdatedDate}
                                  format="ddd, D MMM YYYY HH:mm"
                                />
                              </Typography>
                            </>
                          )}
                        </StatusWrapper>
                      }
                      secondary={option.companyName}
                    ></ListItemText>
                  </ListItem>
                )}
                renderInput={(params) => (
                  <Box sx={{ position: 'relative' }}>
                    <TextField
                      size="medium"
                      {...params}
                      label={t(
                        'marketIntelligencePage.unListedCompanyPage.label',
                      )}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                        style: { fontSize: '20px', padding: '16px' },
                      }}
                      onChange={handleUserInput}
                    />
                    {isGetStockSymbolLoading && <LoadingProgress />}
                  </Box>
                )}
              />
            </AutoCompleteWrapper>
          </Grid>

          <Grid item xs={12}>
            <AutoCompleteWrapper isMargin={false}>
              <Typography variant="h4">
                {t('marketIntelligencePage.unListedCompanyPage.stock')}
              </Typography>
            </AutoCompleteWrapper>
          </Grid>

          <Grid item xs={12}>
            <AutoCompleteWrapper isMargin={false}>
              {(preferences.length === 0) &
              isGetReportSourcePreferencesSuccess ? (
                <AlertComplete severity="info">
                  {t('marketIntelligencePage.listedCompanyPage.alert')}
                </AlertComplete>
              ) : (
                <CompanyList
                  listOfCompanies={preferences}
                  isGenerateReportLoading={isGenerateReportSourceLoading}
                  isGetFinancialReportLoading={false}
                  handleGenerateReport={handleGenerateReport}
                  handleShowReportClick={handleShowReportClick}
                  handleDeletePreference={handleDeletePreference}
                  isGetPreferencesFetching={
                    isGetReportSourcePreferencesFetching ||
                    isAddPreferenceLoading
                  }
                  loadingStates={loadingStates}
                />
              )}
            </AutoCompleteWrapper>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {isGetFinancialReportFetching ? (
                <PdfContainer>
                  <PdfSkeleton variant="rounded" />
                </PdfContainer>
              ) : (
                <>
                  {isGetFinancialReportSuccess && (
                    <PdfContainer ref={accordionContainerRef}>
                      <CardBox variant="outlined" sx={{ position: 'relative' }}>
                        <CardContent>
                          <CardContentBox>
                            {isGetPdfResultFetching ? (
                              <>
                                <PdfDownloadLoading />
                              </>
                            ) : (
                              <Tooltip
                                title={t(
                                  'marketIntelligencePage.listedCompanyPage.export',
                                )}
                              >
                                <StyledIconButton
                                  variant="contained"
                                  onClick={() => {
                                    handleDownload(
                                      stocksSummary.reportId,
                                      stocksSummary.symbol,
                                    );
                                  }}
                                >
                                  <Download />
                                </StyledIconButton>
                              </Tooltip>
                            )}
                          </CardContentBox>
                          <ReactMarkdown>{stocksSummary.summary}</ReactMarkdown>
                        </CardContent>
                      </CardBox>
                    </PdfContainer>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </GridBox>
      </AppShadowBox>
    </PageContainer>
  );
};

export default NotListedCompaniesReportPage;
