/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const muiIconButton = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      let paletteColor = theme.palette[ownerState?.color || 'primary'];
      if (!paletteColor) {
        paletteColor = theme.palette['primary'];
      }
      return {
        '& .MuiIcon-root': {
          color: paletteColor.main,
          fill: paletteColor.main,
        },
      };
    },
  },
};

export { muiIconButton };
