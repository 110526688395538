import { Box, FormControl, styled, Switch } from '@mui/material';

export const ParentLayoutBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingY: theme.spacing(2),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  paddingTop: theme.spacing(4),
  backgroundColor: '#F7FAFF',
  overflowY: 'auto',
  flexDirection: 'column',
}));

export const ArchiveBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
});

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  minWidth: 120,
}));
