/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Grid } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { StyledToolbarGrid } from '../dashboard.page.styled';

const CustomToolbar = () => {
  return (
    <StyledToolbarGrid container>
      <Grid item xs={12} sm={4} md={'auto'}>
        <GridToolbarQuickFilter />
      </Grid>
    </StyledToolbarGrid>
  );
};

export default CustomToolbar;
