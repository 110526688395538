/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.query({
    query: ({ status, key }) => {
      const params = new URLSearchParams();
      if (status) {
        params.append('status', status);
      }
      if (key) {
        params.append('key', key);
      }
      return {
        url: `/reports?${params}`,
        method: 'GET',
      };
    },
  });
