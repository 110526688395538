import React, { useEffect, useState } from 'react';
import {
  PeopleAlt,
  Collections,
  ScreenSearchDesktop,
  FolderCopy,
  Chat,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LineChart,
  PieChart,
  Pie,
  AreaChart,
  Area,
  Cell,
  Line,
} from 'recharts';
import { MenuItem, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import PageContainer from '../../components/pageContainer';
import MetricCard from '../../components/metricCard';
import {
  ChartGrid,
  CustomGrid,
  MetricsGrid,
  MetricsInnerContainer,
  StatsSpan,
  StyledToolTip,
  CustomTooltipWrapper,
  DataDisplay,
  CategoryIcon,
} from './dashboard.page.styled';
import { DRAWER_WIDTH, SECTIONS } from '../../constants/drawer';
import { LOAD_TITLE, nFormatter } from '../../utils/functions';
import {
  useGetAnalyticsTotalCountsQuery,
  useGetAnalyticsFilesCollectionChartMutation,
  useGetAnalyticsChartsMutation,
} from '../../redux/services/speciphicAsk';
import Loader from '../../components/loader';
import { ANALYTICS as ANALYTICS_PATH } from '../../constants/path';
import {
  SEARCH_PERFORMANCE,
  FILE_COLLECTIONS,
  USERS,
} from '../../constants/analyticsPaths';
import colors from '../../themes/colors';
import { toTitleCase } from '../../utils/functions';
import ChartBox from '../../components/chartBox/chartBox.component';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import isMarketIntelligenceEnabled from '../../constants/marketIntelligence';
import UnauthorizedMessage from '../../components/unauthorized';

const translationJSONPrefix = 'analyticsSection.dashboardPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const data_schema = (t) => {
  const translationPrefix = `${translationJSONPrefix}.dataSchemaTitles`;

  return [
    {
      id: 'usersTotal',
      label: t(`${translationPrefix}.usersTotal`),
      icon: (props) => <PeopleAlt {...props} />,
      value: '...',
      indicator: 'up',
      navigateTo: USERS.endpoint,
    },
    {
      id: 'collectionsTotal',
      label: t(`${translationPrefix}.collectionsTotal`),
      icon: (props) => <FolderCopy {...props} />,
      value: '...',
      indicator: 'up',
      navigateTo: FILE_COLLECTIONS.endpoint,
    },
    {
      id: 'totalUniqueIngestedFiles',
      label: t(`${translationPrefix}.totalUniqueIngestedFiles`),
      icon: (props) => <Collections {...props} />,
      value: '...',
      indicator: 'up',
      navigateTo: '',
    },
    {
      id: 'searchesTotal',
      label: t(`${translationPrefix}.searchesTotal`),
      icon: (props) => <ScreenSearchDesktop {...props} />,
      value: '...',
      indicator: 'down',
      navigateTo: SEARCH_PERFORMANCE.endpoint,
    },
    {
      id: 'conversationsTotal',
      label: t(`${translationPrefix}.conversationsTotal`),
      icon: (props) => <Chat {...props} />,
      value: '...',
      indicator: 'up',
      navigateTo: SEARCH_PERFORMANCE.endpoint,
    },
    {
      id: 'readyReportsTotal',
      label: t(`${translationPrefix}.readyReportsTotal`),
      icon: (props) => <ScreenSearchDesktop {...props} />,
      value: '...',
      indicator: 'up',
      navigateTo: '',
    },
  ];
};

const chartsDataSchema = {
  queries: {
    hour: {
      timeInterval: 'hour',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.hourStatTitle`,
      data: [],
      stats: {},
    },
    day: {
      timeInterval: 'day',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.dayStatTitle`,
      data: [],
      stats: {},
    },
    week: {
      timeInterval: 'week',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.weekStatTitle`,
      data: [],
      stats: {},
    },
    month: {
      timeInterval: 'month',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.monthStatTitle`,
      data: [],
      stats: {},
    },
  },
  users: {
    hour: {
      timeInterval: 'hour',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.hourStatTitle`,
      data: [],
      stats: {},
    },
    day: {
      timeInterval: 'day',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.dayStatTitle`,
      data: [],
      stats: {},
    },
    week: {
      timeInterval: 'week',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.weekStatTitle`,
      data: [],
      stats: {},
    },
    month: {
      timeInterval: 'month',
      statsTitle: `${translationJSONPrefix}.charts.queriesCountChart.monthStatTitle`,
      data: [],
      stats: {},
    },
  },
  filesCollection: {
    dataType: {
      queriesAsked: `${translationJSONPrefix}.charts.filesCollectionChart.queriesAsked`,
      // filesCount: `${translationJSONPrefix}.charts.filesCollectionChart.filesCount`,
    },
    majorCounts: {
      5: {
        data: [],
        stats: {},
      },
      10: {
        data: [],
        stats: {},
      },
      15: {
        data: [],
        stats: {},
      },
      All: {
        data: [],
        stats: {},
      },
    },
  },
};

const CustomTooltip = ({ active, payload, valueKey }) => {
  if (active && payload && payload.length) {
    const dataItem = payload[0].payload;
    return (
      <StyledToolTip>
        {`${dataItem.collectionName} : ${dataItem[valueKey]}`}
      </StyledToolTip>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  valueKey: PropTypes.string,
};

const Dashboard = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  useEffect(
    () => LOAD_TITLE(document, t(`${translationJSONPrefix}.title`)),
    [],
  );

  const navigate = useNavigate();
  const [pageData, setPageData] = useState(data_schema(t));
  const [pageDrawerOpen, setIsPageDrawerOpen] = useState(true);
  const isLargeScreen = window.innerWidth >= 768;

  const { data, isError, isLoading, isSuccess } =
    useGetAnalyticsTotalCountsQuery();

  const [chartsData, setChartsData] = useState(chartsDataSchema);
  const [timeInterval, setTimeInterval] = useState(
    chartsDataSchema.queries.day.timeInterval,
  );
  const [usersTimeInterval, setUsersTimeInterval] = useState(
    chartsDataSchema.users.day.timeInterval,
  );

  // files collection chart data type - filesCount | queriesAsked
  const [fcChartDataType, setFCChartDataType] = useState(
    Object.keys(chartsDataSchema.filesCollection.dataType)[0],
  ); // setting initial state to filesCount

  // files collection chart major count - 5 | 10 | -1, All means -1
  const [fcMajorCount, setFCMajorCount] = useState(
    Object.keys(chartsDataSchema.filesCollection.majorCounts)[0],
  ); // setting initial state to 10

  const [fcPieCellHover, setFCPieCellHover] = useState(null);
  const [thresholds, setThresholds] = useState({ top: 0, middle: 0 });

  useEffect(() => {
    if (data) {
      const updatedPageData = [...pageData];
      const users_index = updatedPageData.findIndex(
        (d) => d.id === 'usersTotal',
      );
      const collections_index = updatedPageData.findIndex(
        (d) => d.id === 'collectionsTotal',
      );
      const unique_ingested_files_index = updatedPageData.findIndex(
        (d) => d.id === 'totalUniqueIngestedFiles',
      );
      const searches_index = updatedPageData.findIndex(
        (d) => d.id === 'searchesTotal',
      );
      const conversation_index = updatedPageData.findIndex(
        (d) => d.id === 'conversationsTotal',
      );
      const reports_index = updatedPageData.findIndex(
        (d) => d.id === 'readyReportsTotal',
      );

      updatedPageData[users_index].value = data?.totalUsers;
      updatedPageData[collections_index].value = data?.totalCollections;
      updatedPageData[unique_ingested_files_index].value =
        data?.totalUniqueIngestedFiles;
      updatedPageData[searches_index].value = data?.totalSearches;
      updatedPageData[conversation_index].value = data?.totalConversations;
      updatedPageData[reports_index].value = data?.totalReadyReports;

      updatedPageData.forEach((t, i) => (updatedPageData[i].indicator = ''));

      setPageData(updatedPageData);
    }
  }, [isLoading]);

  const [
    getQueriesChart,
    {
      data: chartDataDB,
      error: chartDataError,
      isError: chartsIsError,
      isLoading: chartIsLoading,
      isSuccess: chartsIsSuccess,
    },
  ] = useGetAnalyticsChartsMutation();

  const [
    getUsersChart,
    {
      data: usersDataDB,
      error: usersDataDBError,
      isError: usersIsError,
      isLoading: usersIsLoading,
      isSuccess: usersIsSuccess,
    },
  ] = useGetAnalyticsChartsMutation();

  const [
    findFCChartData,
    {
      data: fcChartAPIData,
      error: fcChartAPIError,
      isSuccess: isFCChartAPISuccess,
      isLoading: isFCChartDataLoading,
      isError: isFCChartAPIError,
    },
  ] = useGetAnalyticsFilesCollectionChartMutation();

  useEffect(() => {
    if (chartDataDB?.data?.length > 0) {
      const temp_data = { ...chartsData };

      temp_data.queries[timeInterval].data = chartDataDB.data;
      temp_data.queries[timeInterval].stats = chartDataDB.stats;

      temp_data.queries[timeInterval].sortedData = [...chartDataDB.data].sort(
        (a, b) => b?.counts - a?.counts,
      );
      setChartsData(temp_data);
    }
  }, [chartDataDB, timeInterval]);

  useEffect(() => {
    if (usersDataDB?.data?.length > 0) {
      const temp_data = { ...chartsData };

      temp_data.users[usersTimeInterval].data = usersDataDB.data;
      temp_data.users[usersTimeInterval].stats = usersDataDB.stats;

      temp_data.users[usersTimeInterval].sortedData = [
        ...usersDataDB.data,
      ].sort((a, b) => b?.counts - a?.counts);

      setChartsData(temp_data);
    }
  }, [usersDataDB]);

  useEffect(() => {
    if (fcChartAPIData?.data?.length > 0) {
      const temp_data = { ...chartsData };

      let mac = fcChartAPIData.majorCount; // mac: major count

      if (mac === -1) mac = 'All';

      temp_data.filesCollection.majorCounts[mac].data = fcChartAPIData.data;

      setChartsData(temp_data);
    }
  }, [fcChartAPIData]);

  useEffect(() => {
    getQueriesChart({
      entity: 'queries',
      timeInterval: chartsDataSchema.queries.day.timeInterval,
    });

    getUsersChart({
      entity: 'users',
      timeInterval: chartsDataSchema.users.day.timeInterval,
    });

    const mac = Object.keys(chartsDataSchema.filesCollection.majorCounts)[1];

    setFCMajorCount(mac);

    findFCChartData({
      majorCount: mac,
    });
  }, []);

  const chartTitleExtraContent = (statData) => {
    return Object.keys(statData.stats).map((statKey, i) => (
      <span key={i}>
        {i === 0 ? `${t(statData.statsTitle)} ${statKey} ` : `, ${statKey} `}
        <StatsSpan>{statData.stats[statKey]}</StatsSpan>
      </span>
    ));
  };

  const handleChangeTimeInterval = (e) => {
    setTimeInterval(e.target.value);

    // if (chartsData.queries[e.target.value].data.length === 0)
    getQueriesChart({ entity: 'queries', timeInterval: e.target.value });
  };

  const handleChangeUsersTimeInterval = (e) => {
    setUsersTimeInterval(e.target.value);

    // if (chartsData.users[e.target.value].data.length === 0)
    getUsersChart({ entity: 'users', timeInterval: e.target.value });
  };

  const handleFCMajorCountChange = (e) => {
    setFCMajorCount(e.target.value);

    let mac = e.target.value;

    if (mac === 'All') mac = -1;

    if (
      chartsData.filesCollection.majorCounts[e.target.value].data.length === 0
    )
      findFCChartData({
        majorCount: mac,
      });
  };

  const formatXAxis = (tickItem, timeInterval) => {
    return tickItem;
  };

  const CustomTooltip = ({
    active,
    payload: outerPayload,
    label,
    timeInterval,
  }) => {
    let formattedTime = '';
    if (active && outerPayload && outerPayload.length) {
      const { value, payload: innerPayload } = outerPayload[0];
      formattedTime = label;

      return (
        <CustomTooltipWrapper>
          <Typography>{formattedTime}</Typography>
          {innerPayload && (
            <Typography>{innerPayload?.collectionName}</Typography>
          )}
          {innerPayload?.percentage ? (
            <Typography
              sx={{ color: '#003E51' }}
            >{`Percentage: ${innerPayload?.percentage}`}</Typography>
          ) : (
            <Typography sx={{ color: 'blue' }}>{`Counts: ${value}`}</Typography>
          )}
        </CustomTooltipWrapper>
      );
    }

    return null;
  };

  const NoDataDisplay = () => {
    return (
      <DataDisplay>
        <CategoryIcon />
        <Typography variant="h4">{`${translationJSONPrefix}.charts.insufficientDataMessage.title`}</Typography>
        <Typography variant="caption text">
          {`${translationJSONPrefix}.charts.insufficientDataMessage.subtitle`}
        </Typography>
      </DataDisplay>
    );
  };

  if (
    chartDataError?.status === 403 ||
    usersDataDBError?.status === 403 ||
    fcChartAPIError?.status === 403
  )
    return (
      <UnauthorizedMessage
        drawerOpen={pageDrawerOpen}
        drawer={SECTIONS.ANALYTICS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsPageDrawerOpen(res)}
      drawer={SECTIONS.ANALYTICS}
    >
      {isLoading && (
        <Loader
          label={t(
            `${translationJSONPrefix}.loadingMessage.loadingTotalCounts`,
          )}
        />
      )}

      <MetricsGrid
        style={{ width: width - (pageDrawerOpen ? DRAWER_WIDTH : 0) - 50 }}
      >
        <Grid container spacing={2}>
          {pageData.map((item, index) => (
            <>
              {isMarketIntelligenceEnabled ? (
                <Grid item sm={4} md={3} lg={2} key={index}>
                  <CustomGrid item key={index}>
                    <MetricCard
                      icon={item.icon}
                      indicator={item.indicator}
                      value={nFormatter(item.value)}
                      label={item.label}
                      valueColor={
                        item.indicator === 'down'
                          ? colors.red.R300
                          : colors.blue.B400
                      }
                    />
                  </CustomGrid>
                </Grid>
              ) : (
                <>
                  {item.id !== 'readyReportsTotal' && (
                    <Grid item sm={4} md={3} lg={2.4} key={index}>
                      <CustomGrid item key={index}>
                        <MetricCard
                          icon={item.icon}
                          indicator={item.indicator}
                          value={nFormatter(item.value)}
                          label={item.label}
                          valueColor={
                            item.indicator === 'down'
                              ? colors.red.R300
                              : colors.blue.B400
                          }
                        />
                      </CustomGrid>
                    </Grid>
                  )}
                </>
              )}
            </>
          ))}
        </Grid>
      </MetricsGrid>

      <ChartBox
        isLoading={chartIsLoading}
        chartTitle={t(
          `${translationJSONPrefix}.charts.queriesCountChart.title`,
        )}
        titleExtraContent={chartTitleExtraContent(
          chartsData.queries[timeInterval],
        )}
        enableSelector={true}
        selectors={[
          {
            value: timeInterval,
            onChange: handleChangeTimeInterval,
            menuItems: Object.keys(chartsDataSchema.queries).map((ti, i) => (
              <MenuItem key={i} value={ti}>
                {toTitleCase(ti)}
              </MenuItem>
            )),
          },
        ]}
      >
        {chartsData.queries[timeInterval].data.length > 2 ? (
          <LineChart data={chartsData.queries[timeInterval].data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time_unit"
              tickFormatter={(tickItem) => formatXAxis(tickItem, timeInterval)}
            />
            <YAxis>
              <Label
                value={t(
                  `${translationJSONPrefix}.charts.queriesCountChart.queriesCount`,
                )}
                angle={-90}
                position="insideLeft"
              />
            </YAxis>
            {timeInterval === 'hour' ? (
              <Tooltip
                content={<CustomTooltip timeInterval={timeInterval} />}
              />
            ) : (
              <Tooltip />
            )}
            <Line
              type="linear"
              dataKey="counts"
              stroke={'#40798d'}
              fill={'#40798d'}
              activeDot={{
                r: 8,
                strokeWidth: 2,
              }}
            />
          </LineChart>
        ) : (
          <LineChart data={[]}>
            <CartesianGrid strokeDasharray="3 3" />
          </LineChart>
        )}

        {chartsData.queries[timeInterval].data.length <= 2 && <NoDataDisplay />}
      </ChartBox>

      <ChartGrid>
        <ChartBox
          isLoading={isFCChartDataLoading}
          chartTitle={t(
            `${translationJSONPrefix}.charts.filesCollectionChart.title`,
          )}
          pie={true}
          isLargeScreen={isLargeScreen}
          pieLegends={chartsData.filesCollection.majorCounts[
            fcMajorCount
          ].data?.map((fc, i, arr) => {
            const totalQueries = arr.reduce(
              (total, item) => total + item.queriesAsked,
              0,
            );
            const percentage = ((fc.queriesAsked / totalQueries) * 100).toFixed(
              2,
            );
            return { title: fc?.collectionName, count: `${percentage}%` };
          })}
          titleExtraContent={<></>}
          enableSelector={true}
          selectors={
            [
              // {
              //   value: fcMajorCount,
              //   onChange: handleFCMajorCountChange,
              //   menuItems: Object.keys(
              //     chartsDataSchema.filesCollection.majorCounts,
              //   ).map((ti, i) => (
              //     <MenuItem key={i} value={ti}>
              //       {toTitleCase(ti)}
              //     </MenuItem>
              //   )),
              // },
              // {
              //   value: fcChartDataType,
              //   onChange: (e) => setFCChartDataType(e.target.value),
              //   menuItems: Object.keys(
              //     chartsDataSchema.filesCollection.dataType,
              //   ).map((ti, i) => (
              //     <MenuItem key={i} value={ti}>
              //       {t(chartsDataSchema.filesCollection.dataType[ti])}
              //     </MenuItem>
              //   )),
              // },
            ]
          }
          fcPieCellHover={fcPieCellHover}
          setPieCellHover={setFCPieCellHover}
        >
          <PieChart>
            <Pie
              dataKey={fcChartDataType}
              isAnimationActive={true}
              data={chartsData.filesCollection.majorCounts[fcMajorCount].data}
              outerRadius={100}
              fill={colors.pieChartGradient}
            >
              {chartsData.filesCollection.majorCounts[fcMajorCount].data.map(
                (e, i, arr) => {
                  const totalQueries = arr.reduce(
                    (total, item) => total + item.queriesAsked,
                    0,
                  );
                  const percentage = (
                    (e.queriesAsked / totalQueries) *
                    100
                  ).toFixed(2);
                  return (
                    <Cell
                      key={`cell-${i}`}
                      fill={
                        colors.pieChartGradient[
                          i % colors.pieChartGradient.length
                        ]
                      }
                      opacity={
                        fcPieCellHover !== null
                          ? fcPieCellHover === i
                            ? 1
                            : 0.7
                          : 1
                      }
                      style={{ transition: 'opacity ease-in 0.3s' }}
                      percentage={`${percentage}%`}
                    />
                  );
                },
              )}
            </Pie>
            <Tooltip content={<CustomTooltip valueKey={fcChartDataType} />} />
          </PieChart>
        </ChartBox>

        <ChartBox
          isLoading={usersIsLoading}
          chartTitle={t(
            `${translationJSONPrefix}.charts.usersEngagementChart.title`,
          )}
          titleExtraContent={chartTitleExtraContent(
            chartsData.users[usersTimeInterval],
          )}
          enableSelector={true}
          selectors={[
            {
              value: usersTimeInterval,
              onChange: handleChangeUsersTimeInterval,
              menuItems: Object.keys(chartsDataSchema.users).map((ti, i) => (
                <MenuItem key={i} value={ti}>
                  {toTitleCase(ti)}
                </MenuItem>
              )),
            },
          ]}
        >
          {chartsData.users[usersTimeInterval].data.length > 2 ? (
            <LineChart data={chartsData.users[usersTimeInterval].data}>
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  {colors.blueGradient.map((color, index) => (
                    <stop
                      key={index}
                      offset={`${
                        (index / (colors.blueGradient.length - 1)) * 100
                      }%`}
                      stopColor={color}
                    />
                  ))}
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time_unit" />
              <YAxis>
                <Label
                  value={t(
                    `${translationJSONPrefix}.charts.usersEngagementChart.usersCount`,
                  )}
                  angle={-90}
                  position="insideLeft"
                />
              </YAxis>
              <Tooltip />
              <Line
                type="linear"
                dataKey="counts"
                stroke={'#95c6d5'}
                fill={'#95c6d5'}
              />
            </LineChart>
          ) : (
            <LineChart data={[]}>
              <CartesianGrid strokeDasharray="3 3" />
            </LineChart>
          )}

          {chartsData.users[usersTimeInterval].data.length <= 2 && (
            <NoDataDisplay />
          )}
        </ChartBox>
      </ChartGrid>
    </PageContainer>
  );
};
Dashboard.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  timeInterval: PropTypes.string,
};

export default Dashboard;
