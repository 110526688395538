/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import fontWeights from './fontWeights';
import palette from '../red/palette';

const typography = {
  fontFamily: 'source-sans-pro',
  textTransform: 'none',
  fontSize: 12,
  htmlFontSize: 12,
  h1: {
    color: palette.text.primary,
    fontSize: '26px',
    fontWeight: fontWeights.semiBold,
    lineHeight: '28px',
  },
  h2: {
    color: palette.text.primary,
    fontSize: '25px',
    fontWeight: fontWeights.semiBold,
    lineHeight: '28px',
  },
  h3: {
    color: palette.text.primary,
    fontSize: '24px',
    fontWeight: fontWeights.medium,
    lineHeight: '28px',
  },
  h4: {
    color: palette.text.primary,
    fontSize: '22px',
    fontWeight: fontWeights.regular,
    lineHeight: '28px',
  },
  h5: {
    color: palette.text.primary,
    fontSize: '16px',
    fontWeight: fontWeights.regular,
    lineHeight: '24px',
  },
  h6: {
    color: palette.text.primary,
    fontSize: '14px',
    fontWeight: fontWeights.regular,
    lineHeight: '24px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '10px',
    fontWeight: fontWeights.medium,
    lineHeight: '16px',
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: '10px',
    fontWeight: fontWeights.bold,
    lineHeight: '15px',
  },
  button: {
    fontSize: '12px',
    fontWeight: fontWeights.regular,
    lineHeight: '16px',
    textTransform: 'none',
  },
  body1: {
    color: palette.text.secondary,
    fontSize: '12px',
    fontWeight: fontWeights.regular,
    lineHeight: '17px',
  },
  body2: {
    color: `${palette.text.light} !important`,
    fontSize: '12px',
    fontWeight: fontWeights.regular,
    lineHeight: '16px',
  },
  caption: {
    color: palette.text.caption,
    fontSize: '0.785rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.142rem',
  },
};

export default typography;
