/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, Box, ListItemIcon, List } from '@mui/material';
import { renderToStaticMarkup } from 'react-dom/server';

import colors from '../../themes/colors';
import SvgBackground from '../../assets/onboardingLeftbgSvg';
import * as APP_CONST from '../../constants/drawer';
import LeftArrowSVG from '../../assets/leftArrow';

const SvgStringBackground = encodeURIComponent(
  renderToStaticMarkup(<SvgBackground />),
);

export const PageWrapper = styled(Box)(({ theme, background = 'primary' }) => {
  // secondary for new mi
  const backgroundStyles = {
    primary: {
      backgroundImage: `url("data:image/svg+xml,${SvgStringBackground}")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionY: '130px',
    },
    secondary: {
      backgroundColor: '#E7EDF5',
    },
  };

  return {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '100%',
    boxSizing: 'border-box',
    ...backgroundStyles[background],
  };
});

export const DrawerPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh',
  backgroundImage: `url("data:image/svg+xml,${SvgStringBackground}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '130px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const PageDrawer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'fixed',
  width: APP_CONST.DRAWER_WIDTH,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: colors.neutralLight.N0,
  transition: 'margin-left 0.1s ease-in-out',
  zIndex: 1,

  [theme.breakpoints.down('sm')]: {
    height: APP_CONST.TAB_HEIGHT,
    justifyContent: 'center',
    width: '100%',
    overflow: 'scroll',
  },
}));

export const TabBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 700,
  },
}));

export const DrawerList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
}));

export const PageMain = styled('div')(({ theme }) => ({
  flexGrow: 1,
  margin: 20,
  transition: 'margin-left 0.1s ease-in-out',

  [theme.breakpoints.down('sm')]: {
    margin: 10,
    marginTop: APP_CONST.TAB_HEIGHT + 20,
  },
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  marginRight: 16,
}));

export const DrawerKnock = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  transform: 'translateY(-50%)',
  marginLeft: '103%',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const LeftArrowStyled = styled(LeftArrowSVG)(({ theme }) => ({
  transition: 'opacity 0.3s',
}));

export const DrawerButton = styled(Box)(({ theme }) => ({
  width: 5,
  height: 20,
  borderRadius: 20,
  transition: 'opacity 0.3s',
  position: 'absolute',
}));
