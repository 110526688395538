/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  styled,
  Toolbar,
} from '@mui/material';

export const ItemBox = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

export const HeaderGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ReportLabelGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
});

export const HeaderAppBar = styled(AppBar)({
  display: 'flex',
  position: 'relative',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  zIndex: 0,
  boxShadow: 'none',
});

export const ReportLayoutGrid = styled(Grid)({
  display: 'flex',
  flexGrow: 1,
  overflowY: 'hidden',
  height: '100%',
  backgroundColor: '#F7FAFF',
  justifyContent: 'space-between',
});

export const ReportGrid = styled(Grid)({
  display: 'flex',
  height: '58vh',
  backgroundColor: '#F7FAFF',
  //  overflowY: 'auto',
  flexGrow: 0,
  justifyContent: 'space-between',
});

export const ShadowBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  width: '100%',
}));

export const PaperBox = styled(Box)({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

export const StyledPaper = styled(Paper)({
  display: 'flex',
  height: '75vh',
  width: '100vw',
  paddingLeft: 1,
  marginLeft: 1,
  overflow: 'hidden',
});

export const BottomToolbar = styled(Toolbar)(({ theme, layout }) => ({
  bottom: 0,
  width: '100%',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: layout === 'askDb' ? 'center' : 'flex-end',
  boxShadow: '0 -2px 1px rgba(0, 0, 0, 0.05)',
}));

export const StyledButton = styled(Button)({
  '&.MuiButton-outlined	': {
    borderColor: 'transparent',
  },
});

export const StyledGrid = styled(Grid)({
  borderLeft: `1px solid #ccc`,
});

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  '&.MuiButton-outlined	': {
    borderColor: 'transparent',
  },
  ':hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.light,
  },
}));
