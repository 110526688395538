import { Card, Typography, styled, Box } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import colors from '../../themes/colors';

export const StyledCard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  padding: '10px 15px',
  paddingRight: 8,
  margin: '10px 0',
  background: colors.neutralLight.N0,
  borderRadius: 0,
  backgroundColor: colors.neutralLight.N10,
  border: `1px solid ${colors.neutralLight.N50}`,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  fontWeight: '500',

  [theme.breakpoints.down(1350)]: {
    fontSize: '1.5rem',
  },
}));

export const StyledIcon = styled(Box)(({ theme }) => ({
  background: colors.neutralLight.N20,
  color: colors.neutralLight.N500,
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    width: '50px',
    height: '50px',
  },
}));

export const TrendingUpIcon = styled(TrendingUp)(({ theme }) => ({
  color: colors.green.G400,
  fontSize: '1rem',
  marginLeft: '5px',
}));

export const TrendingDownIcon = styled(TrendingDown)(({ theme }) => ({
  color: colors.red.R300,
  fontSize: '1rem',
  marginLeft: '5px',
}));

export const CardMetricContent = styled('div')(({ theme }) => ({
  margin: 5,
  padding: 5,
  textAlign: 'left',
}));

export const LabelTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: '500',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textWrap: 'nowrap',
  width: 90,
}));
