/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Typography, styled, Box, Button, Grid, Link } from '@mui/material';
import colors from '../../../../themes/colors';

export const StyledGridContainer = styled(Grid)(({ theme, isMdOrLarger }) => ({
  position: 'sticky',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingLeft: isMdOrLarger ? theme.spacing(3) : theme.spacing(1),
  paddingRight: isMdOrLarger ? theme.spacing(3) : theme.spacing(1),
}));

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  float: 'right',
  display: 'flex',
  width: 'auto',
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  marginLeft: theme.spacing(1),
}));

export const DefineNewPropertyButton = styled(Button)(({ theme }) => ({
  float: 'right',
  display: 'flex',
  width: 'auto',
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const HeaderGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'left',
  flex: 1,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));
