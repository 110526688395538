/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { React, useState, useEffect } from 'react';
import { Grid, LinearProgress, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ManageMetadataHeaderBar from './manageMetadataHeaderBar.page';
import AppShadowBox from '../../../../components/app.shadowbox';
import DefineNewProperty from './components/defineNewProperty/defineNewProperty.component';
import {
  useGetMetaDataPropertiesQuery,
  useAddMetaDataPropertiesMutation,
  useDeleteDefinedMetadataPropertyMutation,
  useDeleteMetaDataIndexMutation,
} from '../../../../redux/services/speciphicAsk';
import ManageMetadataGrid from './components/manageMetadataGrid.component.jsx';
import ConfirmationDialog from '../../../../components/confirmationDialog';
import * as PATHS from '../../../../constants/path';
import PageContainer from '../../../../components/pageContainer/pageContainer.component.jsx';

const ManageMetadataProperties = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const collectionId = state.collectionId;
  const [propertiesData, setPropertiesData] = useState([]);
  const [propertyData, setPropertyData] = useState({});
  const [isAddProperty, setIsAddProperty] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [editProperties, setEditProperties] = useState();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [definedMDProperties, setDefinedMDProperties] = useState([]);

  const {
    data: definedMetaProperties = [],
    isError: isGetMetaPropertiesError,
    isLoading: isGetMetaPropertiesLoading,
    isSuccess: isGetMetaPropertiesSuccess,
    isFetching: isGetMetaPropertiesFetching,
  } = useGetMetaDataPropertiesQuery({
    collectionId: collectionId,
  });

  useEffect(() => {
    if (definedMetaProperties && isGetMetaPropertiesSuccess) {
      setDefinedMDProperties(definedMetaProperties);
    }
  }, [definedMetaProperties, isGetMetaPropertiesSuccess]);

  useEffect(() => {
    if (isGetMetaPropertiesError) {
      setDefinedMDProperties([]);
    }
  }, [isGetMetaPropertiesError]);

  const [
    addMetaProperties,
    {
      data: updatedMetaDataProperties,
      isSuccess: isAddMetaDataPropertiesSuccess,
      isLoading: isAddMetaDataPropertiesLoading,
      isError: isAddMetaDataPropertiesError,
    },
  ] = useAddMetaDataPropertiesMutation();

  const [
    deleteProperty,
    {
      data: property,
      isSuccess: isDeletePropertySuccess,
      isLoading: isDeletePropertyLoading,
      isError: isDeletePropertyError,
    },
  ] = useDeleteDefinedMetadataPropertyMutation();

  const [
    deleteMetadataIndex,
    {
      data: metadataIndex,
      isSuccess: isDeleteMetadataIndexSuccess,
      isLoading: isDeleteMetadataIndexLoading,
      isError: isDeleteMetadataIndexError,
    },
  ] = useDeleteMetaDataIndexMutation();

  const handleOpenDeletePropertyDialog = (propertyItem) => {
    setDeleteDialogOpen(true);
    setSelectedProperty(propertyItem);
  };

  useEffect(() => {
    const defaultStateForEdit = { ...definedMDProperties };
    for (const key in defaultStateForEdit) {
      defaultStateForEdit[key] = {
        ...defaultStateForEdit[key],
        isEdit: false,
      };
    }
    setEditProperties(defaultStateForEdit);
  }, [isGetMetaPropertiesSuccess]);

  useEffect(() => {
    if (isDeleteMetadataIndexSuccess)
      navigate(`${PATHS.FILE_COLLECTIONS}/${collectionId}`);
  }, [isDeleteMetadataIndexSuccess]);

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleInputChange = (propertyName, fieldName, value) => {
    setPropertyData((prevPropertyData) => ({
      ...prevPropertyData,
      [fieldName]: value,
    }));
    setEditProperties((prevEditProperties) => ({
      ...prevEditProperties,
      [propertyName]: {
        ...prevEditProperties[propertyName],
        [fieldName]: value,
      },
    }));
  };

  const handleDeleteProperty = () => {
    setDeleteDialogOpen(false);
    if (!selectedProperty) {
      return;
    }
    const updatedPropertiesOnChange = { ...editProperties };
    delete updatedPropertiesOnChange[selectedProperty.propertyName];
    setEditProperties(updatedPropertiesOnChange);

    deleteProperty({
      collectionId: collectionId,
      propertyName: selectedProperty.propertyName,
    });
  };

  const handleSaveProperty = (propertyName) => {
    addMetaProperties({
      collectionId: collectionId,
      metaDataProperty: {
        [propertyName]: propertyData,
      },
    });
  };

  const handleSaveClick = (propertyData) => {
    addMetaProperties({
      collectionId: collectionId,
      metaDataProperty: {
        [propertyData.propertyName]: {
          dataType: propertyData?.dataType,
          displayName: propertyData?.displayName,
          mutation: propertyData?.mutation || false,
        },
      },
    });
  };

  const handleDefineNewProperty = () => {
    setIsAddProperty(true);
    setPropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
      propertyValue: '',
      mutation: true,
    });
  };

  return (
    <PageContainer>
      <AppShadowBox>
        <Grid container item>
          <ManageMetadataHeaderBar
            handleDefineNewProperty={handleDefineNewProperty}
            collectionId={collectionId}
            indexName={state.indexName}
          />

          {isAddProperty && (
            <Grid>
              <DefineNewProperty
                isAddPropertyOpen={isAddProperty}
                onCloseAddProperty={setIsAddProperty}
                propertiesData={propertiesData}
                onChangePropertiesData={setPropertiesData}
                propertyData={propertyData}
                onChangePropertyData={setPropertyData}
                collectionId={collectionId}
                isGetMetaPropertiesSuccess={isGetMetaPropertiesSuccess}
                definedMetaProperties={definedMDProperties}
                addMetaProperties={handleSaveClick}
                isAddMetaDataPropertiesSuccess={isAddMetaDataPropertiesSuccess}
                isAddMetaDataPropertiesLoading={isAddMetaDataPropertiesLoading}
                isAddMetaDataPropertiesError={isAddMetaDataPropertiesError}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
                onChangeSubmitButtonDisabled={setIsSubmitButtonDisabled}
              />
            </Grid>
          )}
        </Grid>
        <Box sx={{ paddingTop: 2 }}>
          {isGetMetaPropertiesLoading ? (
            <LinearProgress />
          ) : (
            editProperties && (
              <ManageMetadataGrid
                propertiesData={definedMDProperties}
                handleInputChange={handleInputChange}
                handleSave={handleSaveProperty}
                handleOpenDeletePropertyDialog={handleOpenDeletePropertyDialog}
                propertyData={propertyData}
                editProperties={editProperties}
                onChangeEditProperties={setEditProperties}
                onChangePropertyData={setPropertyData}
                isAddMetaDataPropertiesLoading={
                  isAddMetaDataPropertiesLoading || isDeletePropertyLoading
                }
              />
            )
          )}
        </Box>
        <Grid>
          <ConfirmationDialog
            open={isDeleteDialogOpen}
            title={t('manageMetadataPage.deleteConfirmationTitle')}
            messageLine1={t(
              'manageMetadataPage.deleteConfirmationMessageLine1',
              {
                propertyName: selectedProperty.displayName,
              },
            )}
            messageLine2=""
            onYesClick={handleDeleteProperty}
            onNoClick={handleCloseDeleteDialog}
          />
        </Grid>
      </AppShadowBox>
    </PageContainer>
  );
};

export default ManageMetadataProperties;
