/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { StyledAlert } from './app.toast.styled';

const AUTO_HIDE_DURATION = 3000;

const AppToast = ({ message, open, onClose, alertSeverity }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
    >
      <StyledAlert onClose={handleClose} severity={alertSeverity}>
        {message}
      </StyledAlert>
    </Snackbar>
  );
};

AppToast.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  alertSeverity: PropTypes.string,
};

export default AppToast;
