/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { React, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import TextField from '../../../../components/textField';
import DateTextField from '../../../../components/dateTextField';
import { useGetMetaDataPropertiesQuery } from '../../../../redux/services/speciphicAsk';
import { useTranslation } from 'react-i18next';
import { CustomGridContainer, CustomGridItem } from './filterForm.styled';
import PropTypes from 'prop-types';

const FilterForm = ({
  open,
  filterData,
  onChangeFilterData,
  handleSubmitForm,
  isRetrieveAllFiles,
  collectionId,
}) => {
  const { t } = useTranslation();
  const {
    data: definedMetaProperties = [],
    isError: isGetMetaPropertiesError,
    isLoading: isGetMetaPropertiesLoading,
    isSuccess: isGetMetaPropertiesSuccess,
    refetch: refetchMetaDataProperties,
  } = useGetMetaDataPropertiesQuery({
    collectionId: collectionId,
  });

  const handleInputChange = (field, value) => {
    onChangeFilterData({ ...filterData, [field]: value });
  };

  const renderFormField = (
    fieldName,
    fieldDataType,
    displayName,
    fieldValue,
    disabled,
  ) => {
    switch (fieldDataType) {
      case t('searchMetadataPage.components.filterForm.dataTypes.string'):
      case t('searchMetadataPage.components.filterForm.dataTypes.float'):
      case t('searchMetadataPage.components.filterForm.dataTypes.integer'):
        return (
          <TextField
            label={displayName}
            value={fieldValue}
            disabled={disabled}
            onChange={(value) => handleInputChange(fieldName, value)}
          />
        );
      case t('searchMetadataPage.components.filterForm.dataTypes.date'):
        return (
          <DateTextField
            label={displayName}
            value={fieldValue}
            disabled={disabled}
            placeholder={t(
              'searchMetadataPage.components.filterForm.dateTextField.placeholder',
            )}
            onChange={(value) => handleInputChange(fieldName, value)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container item xs={12}>
      {open && Object.values(definedMetaProperties).length > 0 && (
        <CustomGridContainer
          container
          xs={12}
          definedMetaProperties={definedMetaProperties}
        >
          <>
            <CustomGridItem container item xs={12} md={11.3}>
              <Typography variant="h3">
                {t('searchMetadataPage.components.filterForm.header')}
              </Typography>
            </CustomGridItem>

            {Object?.values(definedMetaProperties).length > 0 &&
              Object?.entries(definedMetaProperties).map(
                ([fieldName, fieldProperties]) => (
                  <Grid key={fieldName} xs={12} md={5.5}>
                    {renderFormField(
                      fieldName,
                      fieldProperties.dataType,
                      fieldProperties.displayName,
                      filterData[fieldName] || '',
                      isRetrieveAllFiles,
                    )}
                  </Grid>
                ),
              )}

            <Grid
              container
              item
              xs={12}
              md={
                Object?.values(definedMetaProperties).length % 2 === 0
                  ? 11.3
                  : 5.5
              }
              display="flex"
              justifyContent={
                Object?.values(definedMetaProperties).length % 2 === 0
                  ? { xs: 'center', md: 'flex-start' }
                  : { xs: 'center', md: 'flex-end' }
              }
              sx={{
                py: 3,
                mt: {
                  md:
                    Object?.values(definedMetaProperties).length % 2 !== 0
                      ? 1.8
                      : 0,
                },
              }}
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={handleSubmitForm}
                sx={{ px: 5 }}
              >
                {t('searchMetadataPage.components.filterForm.submit')}
              </Button>
            </Grid>
          </>
        </CustomGridContainer>
      )}
    </Grid>
  );
};

FilterForm.propTypes = {
  open: PropTypes.bool,
  filterData: PropTypes.object,
  onChangeFilterData: PropTypes.func,
  handleSubmitForm: PropTypes.func,
  isRetrieveAllFiles: PropTypes.bool,
  collectionId: PropTypes.string,
};

export default FilterForm;
