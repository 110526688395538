import * as React from 'react';
const SendIconSvg = (props) => (
  <svg
    width={32}
    height={33}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.606445} width={32} height={32} rx={4} fill="#EEF3FA" />
    <path
      d="M16.875 23.6064V12.9577L21.7663 17.8489L23 16.6064L16 9.60645L9 16.6064L10.2337 17.8402L15.125 12.9577L15.125 23.6064H16.875Z"
      fill="currentColor"
    />
  </svg>
);
export default SendIconSvg;
