/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { Typography, styled, LinearProgress } from '@mui/material';

export const PaginationTextTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(0.3),
}));

export const ProgressBar = styled(LinearProgress)({
  width: '100%',
});
