/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useAuth } from 'react-oidc-context';

import {
  DrawerList,
  PageWrapper,
  DrawerPageWrapper,
  PageDrawer,
  PageMain,
  ListItemIconStyled,
  TabBox,
  DrawerKnock,
  LeftArrowStyled,
  DrawerButton,
} from './pageContainer.styled';
import {
  ANALYTICS as ANALYTICS_DRAWER_ITEMS,
  FILES_COLLECTION_SETTINGS as FILES_COLLECTION_SETTINGS_DRAWER_ITEMS,
  APP_CONFIG as APP_CONFIG_DRAWER_ITEMS,
  ACCESS_CONTROL as ACCESS_CONTROL_DASHBOARD_ITEMS,
} from './drawerItems';
import * as DRAWER_CONSTANTS from '../../constants/drawer';
import * as PATHS from '../../constants/path';
import colors from '../../themes/colors';
import { useLazyGetSiteConfigurationQuery } from '../../redux/services/speciphicAsk';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import LoadingPage from '../../pages/configurationLoadingPage';
import { GENERAL } from '../../constants/appConfigurationPaths';
import UnauthorizedMessage from '../unauthorized/unauthorized.component';

const DRAWER_ITEMS = (section) => {
  switch (section) {
    case DRAWER_CONSTANTS.SECTIONS.ANALYTICS:
      return ANALYTICS_DRAWER_ITEMS;

    case DRAWER_CONSTANTS.SECTIONS.FILES_COLLECTION_SETTINGS:
      return FILES_COLLECTION_SETTINGS_DRAWER_ITEMS;

    case DRAWER_CONSTANTS.SECTIONS.APP_CONFIGURATION:
      return APP_CONFIG_DRAWER_ITEMS;

    case DRAWER_CONSTANTS.SECTIONS.ACCESS_CONTROL:
      return ACCESS_CONTROL_DASHBOARD_ITEMS;

    default:
      break;
  }
};

const PageContainer = (props) => {
  const ref = useRef(null);
  const tabRef = useRef(null);
  const drawerRef = useRef(null);
  const path = useLocation();
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { setSiteConfig, setMarketIntelligenceConfig, setUnauthorized } =
    useContext(SiteConfigContext);

  const [showCurvedLeftArrow, setShowCurvedLeftArrow] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawerHeight, setDrawerHeight] = useState(0);
  const location = PATHS.APP_CONFIGURATION + GENERAL.endpoint;

  const [
    getSiteConfig,
    {
      data: configurationResult,
      error: configurationError,
      isError: isGetConfigurationsError,
      isLoading: isGetConfigurationsLoading,
      isSuccess: isConfigurationsSuccess,
    },
  ] = useLazyGetSiteConfigurationQuery();

  useEffect(() => {
    if (auth?.isAuthenticated) getSiteConfig();
  }, [auth?.isAuthenticated]);

  useEffect(() => {
    if (props.isDrawerOpen) props.isDrawerOpen(drawerOpen);
  }, [props?.isDrawerOpen, drawerOpen]);

  useEffect(() => {
    if (configurationResult?.id) {
      setSiteConfig(configurationResult?.siteConfiguration || {});
      setMarketIntelligenceConfig(configurationResult?.marketIntelligence);
      document.title =
        configurationResult?.siteConfiguration?.applicationName || '';
    }
  }, [configurationResult]);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      tabRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [path.pathname]);

  useEffect(() => {
    setDrawerHeight(drawerRef?.current?.clientHeight || 0);
  }, []);

  const handleNavigate = (navigateTo) => {
    navigate(navigateTo);
  };

  useEffect(() => {
    if (configurationError?.status === 403) setUnauthorized(true);
  }, [configurationError?.status]);

  if (isGetConfigurationsLoading) return <LoadingPage />;
  else if (configurationError?.status === 403)
    return <UnauthorizedMessage isUnauthorizedApp={true} />;
  else if (configurationResult == {} && location != path.pathname) {
    navigate(location);

    return <></>;
  } else if (props.drawer)
    return (
      <DrawerPageWrapper ref={ref}>
        <PageDrawer
          ref={drawerRef}
          style={{
            marginLeft: drawerOpen
              ? 0
              : smallScreen
              ? 0
              : -1 * DRAWER_CONSTANTS.DRAWER_WIDTH,
          }}
        >
          <TabBox
            style={{
              width: smallScreen
                ? 175 * DRAWER_ITEMS(props.drawer)(t, props.collectionId).length
                : '100%',
            }}
          >
            <DrawerList>
              {DRAWER_ITEMS(props.drawer)(t, props.collectionId).map(
                (item, index) => (
                  <ListItemButton
                    key={index}
                    ref={path.pathname === item.end_point ? tabRef : null}
                    onClick={() => handleNavigate(item.end_point)}
                    selected={path.pathname === item.end_point}
                  >
                    <ListItemIconStyled>{item.icon}</ListItemIconStyled>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                ),
              )}
            </DrawerList>
          </TabBox>

          <DrawerKnock
            style={{
              marginTop: drawerHeight / 2.4,
            }}
            onMouseEnter={() => setShowCurvedLeftArrow(true)}
            onMouseLeave={() => setShowCurvedLeftArrow(false)}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <LeftArrowStyled
              style={{
                opacity: showCurvedLeftArrow ? 1 : 0,
                transform: drawerOpen ? 'none' : 'scaleX(-1)',
              }}
            />

            <DrawerButton
              style={{
                backgroundColor: colors.neutralLight.N70,
                opacity: showCurvedLeftArrow ? 0 : 1,
              }}
            />
          </DrawerKnock>
        </PageDrawer>

        <PageMain
          style={{
            marginLeft: smallScreen
              ? 10
              : drawerOpen
              ? DRAWER_CONSTANTS.DRAWER_WIDTH + 30
              : 30,
          }}
        >
          {props.children}
        </PageMain>
      </DrawerPageWrapper>
    );

  return (
    <PageWrapper background={props.background || 'primary'} ref={ref}>
      {props.children}
    </PageWrapper>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  drawer: PropTypes.string,
  collectionId: PropTypes.string,
  isDrawerOpen: PropTypes.func,
  background: PropTypes.string,
};
export default PageContainer;
