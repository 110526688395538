import { useEffect, useRef } from 'react';

/**
 * Custom hook for debouncing function calls
 *
 * @param {Function} callback - The function to debounce
 * @param {Array} dependencies - The dependencies array for the effect
 * @param {number} delay - The debounce delay in milliseconds
 */
function useDebounce(callback, dependencies, delay) {
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [...dependencies, delay]);
}

export default useDebounce;
