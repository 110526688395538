/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import { getDefaultStyles } from './markdown.component.styled';
import remarkGfm from 'remark-gfm';

const MarkdownComponent = ({
  markdown,
  markdownStyle,
  isConfigurationsSuccess,
}) => {
  const [mergedStyles, setMergedStyles] = useState({});
  let styles = {};
  const theme = useTheme();
  const defaultStyle = getDefaultStyles(theme);

  const mergeStyles = (target, source) => {
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        if (Object.prototype.hasOwnProperty.call(target, key)) {
          if (
            typeof target[key] === 'object' &&
            typeof source[key] === 'object'
          ) {
            target[key] = mergeStyles(target[key], source[key]);
          } else {
            if (typeof target[key] !== 'object') {
              target[key] = source[key];
            }
          }
        } else {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

  useEffect(() => {
    if (isConfigurationsSuccess && markdownStyle) {
      const defaultStyles = getDefaultStyles(theme);
      styles = mergeStyles({ ...defaultStyles }, markdownStyle);
      setMergedStyles(styles);
    }
  }, [isConfigurationsSuccess, markdownStyle]);

  const components = {
    p: (props) => (
      <p style={mergedStyles?.p ? mergedStyles.p : defaultStyle.p} {...props} />
    ),
    h1: (props) => (
      <h2
        style={mergedStyles?.h1 ? mergedStyles.h1 : defaultStyle.h1}
        {...props}
      />
    ),
    h2: (props) => (
      <h2
        style={mergedStyles?.h2 ? mergedStyles.h2 : defaultStyle.h2}
        {...props}
      />
    ),
    h3: (props) => (
      <h3
        style={mergedStyles?.h3 ? mergedStyles.h3 : defaultStyle.h3}
        {...props}
      />
    ),
    h4: (props) => (
      <h4
        style={mergedStyles?.h4 ? mergedStyles.h4 : defaultStyle.h4}
        {...props}
      />
    ),
    h5: (props) => (
      <h5
        style={mergedStyles?.h5 ? mergedStyles.h5 : defaultStyle.h5}
        {...props}
      />
    ),
    h6: (props) => (
      <h6
        style={mergedStyles?.h6 ? mergedStyles.h6 : defaultStyle.h6}
        {...props}
      />
    ),
    table: (props) => (
      <table
        style={mergedStyles?.table ? mergedStyles.table : defaultStyle.table}
        {...props}
      />
    ),
    td: (props) => (
      <td
        style={mergedStyles?.td ? mergedStyles.td : defaultStyle.td}
        {...props}
      />
    ),
    th: (props) => (
      <th
        style={mergedStyles?.th ? mergedStyles.th : defaultStyle.th}
        {...props}
      />
    ),
  };

  return (
    <>
      <Markdown remarkPlugins={[remarkGfm]} components={components}>
        {markdown}
      </Markdown>
    </>
  );
};

MarkdownComponent.propTypes = {
  markdown: PropTypes.string,
  markdownStyle: PropTypes.object,
  isConfigurationsSuccess: PropTypes.bool,
};
export default MarkdownComponent;
