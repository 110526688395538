/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const getResourcePermissionEPFactory = (builder) =>
  builder.query({
    query: ({ resourceName }) => ({
      url: `permissions/${resourceName}`,
      method: 'GET',
    }),
    providesTags: ['resource-permission'],
  });

export default (builder) =>
  builder.query({
    query: () => ({
      url: `permissions`,
      method: 'GET',
    }),
    providesTags: ['permissions'],
  });
