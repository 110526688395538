/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, Typography, styled } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2),
}));

export const StyledPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '70vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(7),
}));
