/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ content }) => ({
      url: `/content-processor/pdf`,
      method: 'POST',
      body: {
        content,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      responseHandler: async (response) => {
        const blob = await response.blob();
        return blob;
      },
    }),
  });
