/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material/styles';

let { shadows } = createTheme();

shadows = [
  ...shadows,
  '0px 0px 13px rgba(0, 0, 0, 0.1)',
  '0px 4px 11px rgba(0, 0, 0, 0.05)',
  '0px 0px 12px rgba(0, 0, 0, 0.25)',
];

export default shadows;
