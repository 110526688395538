/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Modal,
  Fade,
  Tooltip,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../../../../redux/reducers/toasts.slice';
import TextField from '../../../../../../components/textField';
import SelectField from '../../../../../../components/selectField';
import BooleanField from '../../../../../../components/booleanField';
import { StyledModalGrid } from './defineNewProperty.styled';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import { dataTypes } from '../../../../../../constants/dataTypes';

const DefineNewProperty = ({
  isAddPropertyOpen,
  onCloseAddProperty,
  propertyData,
  onChangePropertyData,
  collectionId,
  definedMetaProperties,
  addMetaProperties,
  isAddMetaDataPropertiesSuccess,
  isAddMetaDataPropertiesError,
  isSubmitButtonDisabled,
  onChangeSubmitButtonDisabled,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isAllPropertyValuesTaken, setIsAllPropertyValuesTaken] =
    useState(false);
  const [isDefiningDuplicateProperty, setIsDefiningDuplicateProperty] =
    useState(false);

  useEffect(() => {
    setIsAllPropertyValuesTaken(
      !Object.entries(propertyData).some(([field, value]) => {
        if (
          field !==
          t('addMetadataPage.components.defineNewProperty.fields.mutation')
        )
          value === '' || value === undefined || value === null;
      }),
    );
  }, [propertyData]);

  useEffect(() => {
    if (isDefiningDuplicateProperty) {
      dispatch(
        pushToast({
          message: t(
            'addMetadataPage.components.defineNewProperty.toasts.duplicatePropertyError',
          ),
          severity: 'error',
        }),
      );
    }
  }, [isDefiningDuplicateProperty]);

  useEffect(() => {
    if (isAddMetaDataPropertiesSuccess) {
      dispatch(
        pushToast({
          message: t(
            'addMetadataPage.components.defineNewProperty.toasts.propertyAddedSuccess',
            {
              propertyName: propertyData.displayName,
            },
          ),
          severity: 'success',
        }),
      );
    }
    onChangePropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
    });
  }, [isAddMetaDataPropertiesSuccess]);

  useEffect(() => {
    if (isAddMetaDataPropertiesError) {
      dispatch(
        pushToast({
          message: t(
            'addMetadataPage.components.defineNewProperty.toasts.propertyAddedError',
            {
              propertyName: propertyData.displayName,
            },
          ),
          severity: 'error',
        }),
      );
    }
    onChangePropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
    });
  }, [isAddMetaDataPropertiesError]);

  useEffect(() => {
    onChangePropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
    });
  }, []);

  useEffect(() => {
    const isEmpty = ['propertyName', 'displayName', 'dataType'].some(
      (key) => !propertyData[key] || propertyData[key] === '',
    );
    onChangeSubmitButtonDisabled(isEmpty);
  }, [propertyData]);

  const handleInputChange = (field, value) => {
    setIsDefiningDuplicateProperty(
      Object.entries(definedMetaProperties).some(([propertyName, property]) => {
        if (
          field ===
          t('addMetadataPage.components.defineNewProperty.fields.propertyName')
        ) {
          return propertyName === value;
        } else if (
          field ===
          t('addMetadataPage.components.defineNewProperty.fields.displayName')
        ) {
          return property.displayName === value;
        }
        return false;
      }),
    );

    onChangePropertyData((prevData) => {
      let updatedData = { ...prevData, [field]: value };
      if (
        field ===
        t('addMetadataPage.components.defineNewProperty.fields.dataType')
      ) {
        updatedData.propertyValue = getInitialValue(value);
      }
      return updatedData;
    });
  };

  const getInitialValue = (dataType) => {
    switch (dataType) {
      case t('addMetadataPage.components.defineNewProperty.dataTypes.string'):
      case t('addMetadataPage.components.defineNewProperty.dataTypes.integer'):
      case t('addMetadataPage.components.defineNewProperty.dataTypes.float'):
      case t('addMetadataPage.components.defineNewProperty.dataTypes.date'):
      case t('addMetadataPage.components.defineNewProperty.dataTypes.dateTime'):
      case t('addMetadataPage.components.defineNewProperty.dataTypes.time'):
        return '';
      case t('addMetadataPage.components.defineNewProperty.dataTypes.boolean'):
        return false;
      default:
        return '';
    }
  };

  const handleCancelButtonClick = () => {
    onChangePropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
      mutation: false,
    });
    onCloseAddProperty(false);
  };

  const handleSaveClick = () => {
    addMetaProperties(propertyData);
    onCloseAddProperty(false);
  };

  const handleCancelClick = () => {
    onCloseAddProperty(false);
  };

  return (
    <Modal
      open={isAddPropertyOpen}
      onClose={onCloseAddProperty}
      closeAfterTransition
    >
      <Fade in={isAddPropertyOpen} timeout={500}>
        <StyledModalGrid display="flex" container>
          <Grid
            container
            item
            xs={6}
            display="flex"
            sx={{ alignItems: 'center' }}
          >
            <Typography variant="h4" component="div" gutterBottom>
              {t('addMetadataPage.components.defineNewProperty.header')}
            </Typography>
          </Grid>
          <Grid xs={6} container item display="flex" justifyContent="flex-end">
            <IconButton onClick={handleCancelClick} color="error">
              <Cancel />
            </IconButton>
          </Grid>
          <Grid xs={12} sx={{ pt: 2 }}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            flexDirection="row"
            sx={{ mb: 2 }}
          >
            <Grid container item xs={12} sx={{ py: 1.5 }}>
              <TextField
                label={
                  <Typography
                    variant="h5"
                    sx={{ fontSize: 18, mt: 0.3 }}
                    display="flex"
                    alignItems="center"
                  >
                    {t(
                      'addMetadataPage.components.defineNewProperty.fieldsName.propertyName',
                    )}
                  </Typography>
                }
                fullWidth
                value={propertyData.propertyName}
                onChange={(value) =>
                  handleInputChange(
                    t(
                      'addMetadataPage.components.defineNewProperty.fields.propertyName',
                    ),
                    value,
                  )
                }
                isLabelAndFieldOnHorizontalLine={true}
              />
            </Grid>
            <Grid container item xs={12} sx={{ py: 1 }}>
              <SelectField
                label={
                  <Typography
                    variant="h5"
                    sx={{ fontSize: 18, mt: 0.3 }}
                    display="flex"
                    alignItems="center"
                  >
                    {t(
                      'addMetadataPage.components.defineNewProperty.fieldsName.dataType',
                    )}
                  </Typography>
                }
                fullWidth
                value={propertyData.dataType}
                onChange={(value) =>
                  handleInputChange(
                    t(
                      'addMetadataPage.components.defineNewProperty.fields.dataType',
                    ),
                    value,
                  )
                }
                options={dataTypes}
                isLabelAndFieldOnHorizontalLine={true}
              />
            </Grid>

            <Grid xs={12} sx={{ py: 1 }}>
              <TextField
                label={
                  <Typography
                    variant="h5"
                    sx={{ fontSize: 18, mt: 0.3 }}
                    display="flex"
                    alignItems="center"
                  >
                    {t(
                      'addMetadataPage.components.defineNewProperty.fieldsName.displayName',
                    )}
                  </Typography>
                }
                fullWidth
                value={propertyData.displayName}
                onChange={(value) =>
                  handleInputChange(
                    t(
                      'addMetadataPage.components.defineNewProperty.fields.displayName',
                    ),
                    value,
                  )
                }
                isLabelAndFieldOnHorizontalLine={true}
              />
            </Grid>
            <Grid xs={12} sx={{ py: 1, pt: 1.5 }}>
              <BooleanField
                label={
                  <Typography
                    variant="h5"
                    sx={{ fontSize: 18, mt: 0.3 }}
                    display="flex"
                    alignItems="center"
                  >
                    {t(
                      'addMetadataPage.components.defineNewProperty.fieldsName.mutation',
                    )}
                    <Tooltip
                      title={t(
                        'addMetadataPage.components.defineNewProperty.mutation.tooltip',
                      )}
                    >
                      <InfoIcon
                        sx={{
                          marginLeft: '0.5rem',
                          cursor: 'pointer',

                          fontSize: 18,
                        }}
                      />
                    </Tooltip>
                  </Typography>
                }
                value={true && propertyData?.mutation}
                onChange={(value) =>
                  handleInputChange(
                    t(
                      'addMetadataPage.components.defineNewProperty.fields.mutation',
                    ),
                    value,
                  )
                }
                isLabelAndFieldOnHorizontalLine={true}
                isBooleanFieldInForms={true}
              />
            </Grid>
          </Grid>

          <Grid
            container
            display="flex"
            xs={12}
            flexDirection="row"
            alignItems="center"
          >
            <Grid container item xs={7} display="flex">
              <Typography variant="h6" sx={{ fontSize: 18 }}>
                {t(
                  'addMetadataPage.components.defineNewProperty.confirmationDialog.saveMessage',
                )}
              </Typography>
            </Grid>
            <Grid container item xs={2.5} display="flex" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
                disabled={isSubmitButtonDisabled || isDefiningDuplicateProperty}
                sx={{ width: '100%', ml: 2, py: 1 }}
              >
                {t(
                  'addMetadataPage.components.defineNewProperty.confirmationDialog.save',
                )}
              </Button>
            </Grid>
            <Grid xs={2.5} container item display="flex" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancelButtonClick}
                sx={{ width: '100%', ml: 2, py: 1 }}
              >
                {t(
                  'addMetadataPage.components.defineNewProperty.confirmationDialog.cancel',
                )}
              </Button>
            </Grid>
          </Grid>
        </StyledModalGrid>
      </Fade>
    </Modal>
  );
};

DefineNewProperty.propTypes = {
  isAddPropertyOpen: PropTypes.bool,
  onCloseAddProperty: PropTypes.func,
  propertyData: PropTypes.any,
  onChangePropertyData: PropTypes.func,
  collectionId: PropTypes.string,
  definedMetaProperties: PropTypes.array,
  addMetaProperties: PropTypes.func,
  isAddMetaDataPropertiesSuccess: PropTypes.bool,
  isAddMetaDataPropertiesError: PropTypes.bool,
  isSubmitButtonDisabled: PropTypes.bool,
  onChangeSubmitButtonDisabled: PropTypes.func,
};

export default DefineNewProperty;
