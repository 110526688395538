import * as React from 'react';
const AddIconSvg = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3C7.032 3 3 7.032 3 12C3 16.968 7.032 21 12 21C16.968 21 21 16.968 21 12C21 7.032 16.968 3 12 3ZM16.5 12.9H12.9V16.5H11.1V12.9H7.5V11.1H11.1V7.5H12.9V11.1H16.5V12.9Z"
      fill="currentColor"
    />
  </svg>
);
export default AddIconSvg;
