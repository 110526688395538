/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { MARKET_INTELLIGENCE_UNLISTED_FEATURE } from '../../../../constants/marketIntelligence';

export default (builder) =>
  builder.mutation({
    query: (report_key) => ({
      url: `/reports/market-intelligence/${report_key}?feature=${MARKET_INTELLIGENCE_UNLISTED_FEATURE}`,
      method: 'POST',
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['preferences'],
  });
