/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Loop, Preview } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  StyledIconButton,
  SecondaryActionWrapper,
  ItemDivider,
  DoneIcon,
  ErrorIcon,
  PendingIcon,
  StatusWrapper,
  CircularProgressIcon,
  PlannedIcon,
  CompanyListGrid,
  LoadingWrapper,
} from './companyList.styled';
import DisplayTime from '../timezone.component';
import { isMarketIntelligenceLogoEnabled } from '../../constants/marketIntelligence';

const statusConfig = {
  READY: { title: 'Report Ready', icon: <DoneIcon /> },
  ERROR: { title: 'Report Error', icon: <ErrorIcon /> },
  CREATING: { title: 'Creating Report', icon: <PendingIcon /> },
  PLANNED: { title: 'Planned Report', icon: <PlannedIcon /> },
};

const CompanyListItem = ({
  company,
  index,
  isGenerateReportLoading,
  isGetFinancialReportLoading,
  handleGenerateReport,
  handleShowReportClick,
  handleDelete,
  loadingStates,
}) => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (!company.status == 'READY') {
      handleGenerateReport(company.symbol || company.key);
    } else {
      handleShowReportClick(company.reportId, company.symbol || company.key);
    }
  };

  const formateDate = (dateString) => {
    if (typeof dateString === 'string') {
      return dateString.slice(0, -7);
    }
    return '';
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={{ display: { sm: 'block', md: 'flex' } }}
      >
        <ListItem
          key={index}
          secondaryAction={
            <SecondaryActionWrapper>
              {!(company.status == 'READY') ? (
                <Tooltip title={t('financialReportPage.companyList.tooltip')}>
                  <StyledIconButton
                    key={index}
                    onClick={() =>
                      handleGenerateReport(company.symbol || company.key)
                    }
                  >
                    {loadingStates[company.symbol || company.key] ? (
                      <CircularProgressIcon />
                    ) : (
                      <Loop />
                    )}
                  </StyledIconButton>
                </Tooltip>
              ) : (
                <Box>
                  <Tooltip title={t('financialReportPage.companyList.tooltip')}>
                    <StyledIconButton
                      key={index}
                      onClick={() =>
                        handleGenerateReport(company.symbol || company.key)
                      }
                    >
                      {loadingStates[company.symbol || company.key] ? (
                        <CircularProgressIcon />
                      ) : (
                        <Loop />
                      )}
                    </StyledIconButton>
                  </Tooltip>
                  <Tooltip
                    title={t(
                      'financialReportPage.companyList.viewReportButton',
                    )}
                  >
                    <StyledIconButton
                      key={index}
                      variant="contained"
                      onClick={handleButtonClick}
                    >
                      <Preview />
                    </StyledIconButton>
                  </Tooltip>
                </Box>
              )}
              <Tooltip title="Delete">
                <StyledIconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(company.key)}
                >
                  <Delete />
                </StyledIconButton>
              </Tooltip>
            </SecondaryActionWrapper>
          }
        >
          {isMarketIntelligenceLogoEnabled && (
            <ListItemAvatar>
              <Avatar
                alt={company.symbol || company.companyName}
                src={company.logoUrl || company.companyLogoUrl}
              />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={
              <StatusWrapper>
                {company.symbol || company.key}
                {company.status in statusConfig && (
                  <>
                    <Tooltip title={statusConfig[company.status].title}>
                      {statusConfig[company.status].icon}
                    </Tooltip>
                    <Typography fontSize={12}>
                      <DisplayTime
                        time={company.lastUpdatedDate}
                        format="ddd, D MMM YYYY HH:mm"
                      />
                    </Typography>
                  </>
                )}
              </StatusWrapper>
            }
            secondary={company.description || company.companyName}
          />
        </ListItem>
        <Divider />
        {index % 2 === 0 && (
          <ItemDivider orientation="vertical" variant="middle" flexItem />
        )}
      </Grid>
    </>
  );
};
const CompanyList = ({
  listOfCompanies,
  isGenerateReportLoading,
  isGetFinancialReportLoading,
  handleGenerateReport,
  handleShowReportClick,
  handleDeletePreference,
  isGetPreferencesFetching,
  loadingStates,
}) => {
  const { t } = useTranslation();
  const handleDelete = (symbol) => {
    handleDeletePreference(symbol);
  };

  return (
    <CompanyListGrid container>
      {isGetPreferencesFetching && (
        <LoadingWrapper>
          <LinearProgress style={{ width: '100%' }} />
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            {t('financialReportPage.companyList.loading')}
          </Typography>
        </LoadingWrapper>
      )}
      {listOfCompanies.map((company, index) => (
        <>
          <CompanyListItem
            key={index}
            company={company}
            index={index}
            isGenerateReportLoading={isGenerateReportLoading}
            isGetFinancialReportLoading={isGetFinancialReportLoading}
            handleGenerateReport={handleGenerateReport}
            handleShowReportClick={handleShowReportClick}
            handleDelete={handleDelete}
            handleDeletePreference={handleDeletePreference}
            loadingStates={loadingStates}
          />
        </>
      ))}
    </CompanyListGrid>
  );
};

CompanyListItem.propTypes = {
  company: PropTypes.object,
  index: PropTypes.number,
  isGenerateReportLoading: PropTypes.bool,
  isGetFinancialReportLoading: PropTypes.bool,
  handleGenerateReport: PropTypes.func,
  handleShowReportClick: PropTypes.func,
  handleDelete: PropTypes.func,
  loadingStates: PropTypes.object,
};

CompanyList.propTypes = {
  listOfCompanies: PropTypes.array,
  isGenerateReportLoading: PropTypes.bool,
  isGetFinancialReportLoading: PropTypes.bool,
  handleGenerateReport: PropTypes.func,
  handleShowReportClick: PropTypes.func,
  handleDeletePreference: PropTypes.func,
  isGetPreferencesFetching: PropTypes.bool,
  loadingStates: PropTypes.object,
};

export default CompanyList;
