/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const muiListItemIcon = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 36,
      color: theme.palette.primary.main,
      '& svg': {
        height: 19,
        width: 19,
      },
    }),
  },
};

export { muiListItemIcon };
