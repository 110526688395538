export const GENERAL = {
  title: 'general',
  endpoint: '/',
};
export const IAM = {
  title: 'iam',
  endpoint: '/iam',
  addEndpoint: '/add',
};
export const DATA_CONFIG = {
  title: 'dataConfig',
  endpoint: '/data-config',
};
export const SEARCH_RESULT_CONFIG = {
  title: 'searchResultConfig',
  endpoint: '/search-result-config',
};

export const METADATA_CONFIG = {
  title: 'metaDataConfig',
  endpoint: '/metadata-config',
};
export const PREPROCESSOR_CONFIG = {
  title: 'preprocessorConfig',
  endpoint: '/preprocessor-config',
};
export const EMBEDDING_CONFIG = {
  title: 'embeddingConfig',
  endpoint: '/embedding-config',
};
export const RANKER_CONFIG = {
  title: 'rankerConfig',
  endpoint: '/ranker-config',
};
export const LLM_CONFIG = {
  title: 'llmConfig',
  endpoint: '/llm-config',
};
export const PROMPT_CONFIG = {
  title: 'promptConfig',
  endpoint: '/prompt-config',
};
