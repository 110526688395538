/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const getAll = (builder) =>
  builder.query({
    query: ({ endpoint }) => ({
      url: `rbac/${endpoint}`,
      method: 'GET',
    }),
    providesTags: ['rbac-get-all'],
  });

export const get = (builder) =>
  builder.query({
    query: ({ endpoint, id }) => {
      return {
        url: `rbac/${endpoint}/${id}`,
        method: 'GET',
      };
    },
    invalidatesTags: ['rbac-get'],
  });

export const create = (builder) =>
  builder.mutation({
    query: ({ endpoint, body }) => {
      return {
        url: `rbac/${endpoint}`,
        method: 'POST',
        body: body,
      };
    },
    invalidatesTags: ['rbac-create'],
  });

export const update = (builder) =>
  builder.mutation({
    query: ({ endpoint, update, id }) => {
      return {
        url: `rbac/${endpoint}/${id}`,
        method: 'PATCH',
        body: update,
      };
    },
    invalidatesTags: ['rbac-update'],
  });

export const deleteMany = (builder) =>
  builder.mutation({
    query: ({ endpoint, ids, names }) => {
      return {
        url: `rbac/${endpoint}`,
        method: 'DELETE',
        body: { ids, names },
      };
    },
    invalidatesTags: ['rbac-delete'],
  });
