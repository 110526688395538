/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React, { useState, useEffect } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemButton,
  LinearProgress,
  ListItemIcon,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import QueueIcon from '@mui/icons-material/Queue';
import { useTranslation } from 'react-i18next';

import * as PATHS from '../../../../constants/path';
import {
  ChatHistoryContainer,
  TextContainer,
  SideBar,
} from './chatHistoryComponent.styled';
import DisplayTime from '../../../../components/timezone.component';

const ChatHistoryComponent = ({
  open,
  onToggleDrawer,
  collectionId,
  setConversation,
  fileCollectionConversationHistory,
  isGetFileCollectionConversationHistorySuccess,
  isGetFileCollectionConversationHistoryFetching,
}) => {
  const [collectionHistory, setCollectionHistory] = useState([]);
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (fileCollectionConversationHistory?.length === 0) {
      setCollectionHistory([]);
    } else if (fileCollectionConversationHistory?.length > 0) {
      const sortedDocs = [...fileCollectionConversationHistory].sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      );
      setCollectionHistory(sortedDocs);
    }
  }, [
    fileCollectionConversationHistory,
    isGetFileCollectionConversationHistorySuccess,
  ]);

  const handleShowConversation = (collection_Id, conversationId, index) => {
    setSelectedIndex(index);
    navigate(
      `${PATHS.FILE_COLLECTIONS}/${collection_Id}/${PATHS.CONVERSATION}/${conversationId}`,
    );
  };

  const handleNewConversation = () => {
    setSelectedIndex(null);
    setConversation([]);
    navigate(`${PATHS.FILE_COLLECTIONS}/${collectionId}/${PATHS.CONVERSATION}`);
  };

  return (
    <SideBar variant="persistent" anchor="left" open={open}>
      <ChatHistoryContainer>
        {isGetFileCollectionConversationHistoryFetching && <LinearProgress />}
        <List disablePadding>
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton onClick={onToggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            }
          >
            <ListItemButton onClick={handleNewConversation}>
              <ListItemAvatar>
                <QueueIcon />
              </ListItemAvatar>
              <ListItemText>
                <Typography>
                  <b>{t('conversationPage.newConversation')}</b>
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
          {collectionHistory.map(
            (item, index) =>
              item.title && (
                <>
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={() =>
                        handleShowConversation(
                          item.collection_id,
                          item.id,
                          index,
                        )
                      }
                    >
                      <ListItemIcon>
                        <QuestionAnswerIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={<TextContainer>{item.title}</TextContainer>}
                        secondary={
                          <>
                            <DisplayTime time={item.createdDate} />
                          </>
                        }
                      ></ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              ),
          )}
        </List>
      </ChatHistoryContainer>
    </SideBar>
  );
};

ChatHistoryComponent.propTypes = {
  open: PropTypes.bool,
  onToggleDrawer: PropTypes.func,
  collectionId: PropTypes.string,
  isFileCollectionChanged: PropTypes.bool,
  fileCollectionId: PropTypes.string,
  isConversationHistory: PropTypes.bool,
  setConversation: PropTypes.func,
  isConversationHistorySent: PropTypes.bool,
  fileCollectionConversationHistory: PropTypes.array,
  isGetFileCollectionConversationHistorySuccess: PropTypes.bool,
  isGetFileCollectionConversationHistoryFetching: PropTypes.bool,
};

export default ChatHistoryComponent;
