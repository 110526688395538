import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

import {
  StyledCard,
  StyledIcon,
  CardMetricContent,
  StyledTypography,
  TrendingUpIcon,
  TrendingDownIcon,
  LabelTypography,
} from './metricCard.styled';

const MetricCard = ({ icon, valueColor, indicator, value, label }) => {
  return (
    <StyledCard>
      <StyledIcon>{icon()}</StyledIcon>

      <CardMetricContent>
        <StyledTypography variant="h4" color={valueColor} component="div">
          {value}
          {indicator === 'up' && <TrendingUpIcon />}
          {indicator === 'down' && <TrendingDownIcon />}
        </StyledTypography>

        <Tooltip title={label} placement="bottom">
          <LabelTypography variant="h6" gutterBottom>
            {label}
          </LabelTypography>
        </Tooltip>
      </CardMetricContent>
    </StyledCard>
  );
};

MetricCard.propTypes = {
  icon: PropTypes.func,
  indicator: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  valueColor: PropTypes.string,
};

export default MetricCard;
