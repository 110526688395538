/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';

import { Grid, LinearProgress } from '@mui/material';
import AddMetaDataGrid from './components/addMetaDataGrid';
import PropTypes from 'prop-types';

const DisplayProperties = ({
  editProperties,
  propertiesData,
  handleOpenDeletePropertyDialog,
  handleSaveProperty,
  onChangeEditProperties,
  handleInputChange,
  handleEditProperty,
  propertyData,
  isGetMetaDataByFileNameLoading,
}) => {
  return (
    <Grid container item xs={12} display="flex" sx={{ py: 3, ml: 1 }}>
      <Grid xs={12} sx={{ px: 3 }}>
        {isGetMetaDataByFileNameLoading ? (
          <LinearProgress />
        ) : (
          <AddMetaDataGrid
            propertiesData={propertiesData}
            handleInputChange={handleInputChange}
            handleEdit={handleEditProperty}
            handleSave={handleSaveProperty}
            handleOpenDeletePropertyDialog={handleOpenDeletePropertyDialog}
            propertyData={propertyData}
            editProperties={editProperties}
            onChangeEditProperties={onChangeEditProperties}
          />
        )}
      </Grid>
    </Grid>
  );
};

DisplayProperties.propTypes = {
  editProperties: PropTypes.bool.isRequired,
  propertiesData: PropTypes.array.isRequired,
  handleOpenDeletePropertyDialog: PropTypes.func.isRequired,
  handleSaveProperty: PropTypes.func.isRequired,
  onChangeEditProperties: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleEditProperty: PropTypes.func.isRequired,
  propertyData: PropTypes.object.isRequired,
  isGetMetaDataByFileNameLoading: PropTypes.bool.isRequired,
};

export default DisplayProperties;
