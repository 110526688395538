export const POLICIES = {
  title: 'policies',
  endpoint: '/policies',
};
export const GROUPS = {
  title: 'groups',
  endpoint: '/groups',
};
export const USER_ROLE = {
  title: 'userRole',
  endpoint: '/user-role',
};
export const USERS_GROUP = {
  title: 'usersGroup',
  endpoint: '/users-group',
};
export const ROLES = {
  title: 'roles',
  endpoint: '/roles',
};
export const USERS = {
  title: 'users',
  endpoint: '/users',
};
export const ADD_PAGE = {
  endpoint: '/add',
};
