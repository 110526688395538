/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Grid, Link, styled, IconButton } from '@mui/material';

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  cursor: 'pointer',
});

export const ModalGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  maxHeight: '90%',
  overflow: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
}));

export const ModalGridContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '8px',
  right: '8px',
  color: theme.palette.error.dark,
}));
