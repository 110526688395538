import * as React from 'react';
const DeleteIconSvg = (props) => (
  <svg
    width={17}
    height={18}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_35_3342)">
      <path
        d="M11.325 17.8783H4.92503C4.28472 17.869 3.67149 17.6184 3.20799 17.1765C2.74448 16.7346 2.46486 16.1341 2.42503 15.4949L1.6667 3.96159C1.66281 3.87797 1.67554 3.79441 1.70415 3.71574C1.73275 3.63707 1.77667 3.56485 1.83337 3.50326C1.89177 3.43942 1.96248 3.38806 2.04125 3.35226C2.12001 3.31646 2.2052 3.29696 2.2917 3.29492H13.9584C14.0441 3.29473 14.1289 3.31217 14.2076 3.34615C14.2863 3.38013 14.3571 3.42993 14.4157 3.49246C14.4743 3.55498 14.5195 3.62889 14.5483 3.7096C14.5771 3.7903 14.5891 3.87608 14.5834 3.96159L13.8584 15.4949C13.8181 16.1398 13.5338 16.7451 13.0632 17.1878C12.5926 17.6305 11.9711 17.8774 11.325 17.8783ZM2.9917 4.54492L3.63337 15.4199C3.65457 15.7478 3.79996 16.0554 4.03993 16.2799C4.27991 16.5044 4.59642 16.6289 4.92503 16.6283H11.325C11.6531 16.627 11.9685 16.5017 12.208 16.2776C12.4476 16.0535 12.5936 15.7471 12.6167 15.4199L13.2917 4.58659L2.9917 4.54492Z"
        fill="currentColor"
      />
      <path
        d="M15.625 4.54492H0.625C0.45924 4.54492 0.300268 4.47907 0.183058 4.36186C0.0658479 4.24465 0 4.08568 0 3.91992C0 3.75416 0.0658479 3.59519 0.183058 3.47798C0.300268 3.36077 0.45924 3.29492 0.625 3.29492H15.625C15.7908 3.29492 15.9497 3.36077 16.0669 3.47798C16.1842 3.59519 16.25 3.75416 16.25 3.91992C16.25 4.08568 16.1842 4.24465 16.0669 4.36186C15.9497 4.47907 15.7908 4.54492 15.625 4.54492Z"
        fill="currentColor"
      />
      <path
        d="M10.625 4.54492H5.625C5.45991 4.54276 5.30219 4.47622 5.18545 4.35948C5.0687 4.24273 5.00216 4.08501 5 3.91992V2.41992C5.01056 1.99227 5.18516 1.58506 5.48765 1.28257C5.79013 0.980082 6.19735 0.805486 6.625 0.794922H9.625C10.0598 0.805794 10.4731 0.986177 10.7767 1.29757C11.0803 1.60896 11.2501 2.02669 11.25 2.46159V3.91992C11.2478 4.08501 11.1813 4.24273 11.0646 4.35948C10.9478 4.47622 10.7901 4.54276 10.625 4.54492ZM6.25 3.29492H10V2.46159C10 2.36213 9.96049 2.26675 9.89017 2.19642C9.81984 2.1261 9.72446 2.08659 9.625 2.08659H6.625C6.52554 2.08659 6.43016 2.1261 6.35983 2.19642C6.28951 2.26675 6.25 2.36213 6.25 2.46159V3.29492Z"
        fill="currentColor"
      />
      <path
        d="M10.625 14.5446C10.4599 14.5424 10.3022 14.4759 10.1854 14.3592C10.0687 14.2424 10.0022 14.0847 10 13.9196V7.25293C10 7.08717 10.0658 6.9282 10.1831 6.81099C10.3003 6.69378 10.4592 6.62793 10.625 6.62793C10.7908 6.62793 10.9497 6.69378 11.0669 6.81099C11.1842 6.9282 11.25 7.08717 11.25 7.25293V13.9196C11.2478 14.0847 11.1813 14.2424 11.0646 14.3592C10.9478 14.4759 10.7901 14.5424 10.625 14.5446Z"
        fill="currentColor"
      />
      <path
        d="M5.625 14.5446C5.45991 14.5424 5.30219 14.4759 5.18545 14.3592C5.0687 14.2424 5.00216 14.0847 5 13.9196V7.25293C5 7.08717 5.06585 6.9282 5.18306 6.81099C5.30027 6.69378 5.45924 6.62793 5.625 6.62793C5.79076 6.62793 5.94973 6.69378 6.06694 6.81099C6.18415 6.9282 6.25 7.08717 6.25 7.25293V13.9196C6.24784 14.0847 6.1813 14.2424 6.06455 14.3592C5.94781 14.4759 5.79009 14.5424 5.625 14.5446Z"
        fill="currentColor"
      />
      <path
        d="M8.125 14.5446C7.95991 14.5424 7.80219 14.4759 7.68545 14.3592C7.5687 14.2424 7.50216 14.0847 7.5 13.9196V7.25293C7.5 7.08717 7.56585 6.9282 7.68306 6.81099C7.80027 6.69378 7.95924 6.62793 8.125 6.62793C8.29076 6.62793 8.44973 6.69378 8.56694 6.81099C8.68415 6.9282 8.75 7.08717 8.75 7.25293V13.9196C8.74784 14.0847 8.6813 14.2424 8.56455 14.3592C8.44781 14.4759 8.29009 14.5424 8.125 14.5446Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_3342">
        <rect
          width={16.25}
          height={17.0833}
          fill="white"
          transform="translate(0 0.794922)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default DeleteIconSvg;
