/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  StyledFileUploadButton,
  PageTitleContainer,
} from './page.title.styled';

const PageTitle = ({
  title,
  subtitle,
  path,
  buttonText,
  isActionButtonNeeded,
}) => {
  return (
    <PageTitleContainer>
      <Box>
        <Typography variant="h4"> {title} </Typography>
        <Typography variant="caption text"> {subtitle} </Typography>
      </Box>

      {isActionButtonNeeded && (
        <StyledFileUploadButton
          variant="contained"
          size="medium"
          component={Link}
          to={path}
          startIcon={<Add />}
        >
          {buttonText}
        </StyledFileUploadButton>
      )}
    </PageTitleContainer>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.any,
  buttonText: PropTypes.string,
  isActionButtonNeeded: PropTypes.bool,
};

export default PageTitle;
