/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { StyledPageWrapper, StyledTypography } from './notFound.page.styled';
import { ReactComponent as NotFoundPoster } from '../../assets/not-found-404-poster.svg';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <StyledPageWrapper>
      <NotFoundPoster
        style={{ width: '100%', height: '100%' }}
        alt={t('notFoundPage.imgAlt')}
      />
      <StyledTypography variant="h5">
        {t('notFoundPage.notFoundText')}
      </StyledTypography>
      <Typography component={NavLink} to="/" variant="h5" color={'primary'}>
        {t('notFoundPage.homePageLink')}
      </Typography>
    </StyledPageWrapper>
  );
};

export default NotFoundPage;
