/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import Modal from '@mui/material/Modal';
import { CookieRounded } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  CookieBox,
  CookieButtons,
  CookieDocumentText,
  CookieHeadingText,
  CookieIconWrapper,
  CookiePageLinkText,
  CookieWelcomeText,
} from './app.cookie.styled';

const Cookie = ({
  handleAcceptCookie,
  handleBannerClose,
  handleDeclineCookie,
  open,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={handleBannerClose} hideBackdrop>
      <CookieBox>
        <CookieIconWrapper>
          <CookieRounded />
        </CookieIconWrapper>
        <CookieHeadingText variant="h3" display={'inline-block'}>
          {t('cookie.headText')}
        </CookieHeadingText>
        <CookieWelcomeText variant="overline">
          {t('cookie.welcomeText')}
        </CookieWelcomeText>
        <CookieDocumentText variant="caption">
          {t('cookie.introductionText')}
        </CookieDocumentText>
        <CookiePageLinkText variant="caption">
          <Trans
            i18nKey="cookie.linkText"
            components={{
              cookieLink: <Link to={'/cookie-policy'} title="cookie policy" />,
            }}
          />
          <br />
        </CookiePageLinkText>
        <CookieButtons
          size="small"
          variant="contained"
          onClick={handleAcceptCookie}
        >
          {t('cookie.buttonTexts.acceptButtonText')}
        </CookieButtons>
        <CookieButtons
          size="small"
          variant="outlined"
          onClick={handleDeclineCookie}
        >
          {t('cookie.buttonTexts.declineButtonText')}
        </CookieButtons>
      </CookieBox>
    </Modal>
  );
};

Cookie.propTypes = {
  handleAcceptCookie: PropTypes.func,
  handleBannerClose: PropTypes.func,
  handleDeclineCookie: PropTypes.func,
  open: PropTypes.bool,
};

export default Cookie;
