/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomToolbar from './manageMetadataCustomToolbar';
import PropTypes from 'prop-types';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Dropdown from '../../../../../../components/dropdown';
import {
  FieldValueTypography,
  StyledHeaderColumn,
  StyledDataGrid,
  StyledClearIcon,
  StyledSearchIcon,
  PropertyNameTypography,
  DataGridBox,
} from './manageMetadataGrid.styled';
import BooleanField from '../../../../../../components/booleanField';
import { useTranslation } from 'react-i18next';
import { dataTypes } from '../../../../../../constants/dataTypes';
import { RowPerPageOptions } from '../../../../../../constants/rowPerPageOptions';

const ManageMetadataGrid = ({
  propertiesData,
  handleInputChange,
  handleSave,
  handleOpenDeletePropertyDialog,
  propertyData,
  editProperties,
  onChangeEditProperties,
  onChangePropertyData,
  isAddMetaDataPropertiesLoading,
}) => {
  const { t } = useTranslation();
  let updatedPropertiesData = [];
  for (const key in propertiesData) {
    let propertyObject = propertiesData[key];
    propertyObject = { ...propertyObject, propertyName: key };
    updatedPropertiesData.push(propertyObject);
  }

  const handleEditClick = (props) => {
    const { propertyName, displayName, mutation, dataType } = props.row;

    onChangePropertyData({
      displayName: displayName,
      dataType: dataType,
      mutation: mutation,
    });

    const updatedEditProperties = { ...editProperties };
    updatedEditProperties[propertyName] = {
      ...updatedEditProperties[propertyName],
      isEdit: true,
    };
    onChangeEditProperties(updatedEditProperties);
  };

  const handleSaveClick = (props) => {
    handleSave(props.row.propertyName);
    const propertyName = props.row.propertyName;
    const updatedEditProperties = { ...editProperties };
    updatedEditProperties[propertyName] = { isEdit: false };
    onChangeEditProperties(updatedEditProperties);
    onChangePropertyData({});
  };

  const handleDeleteClick = (props) => {
    const propertyName = props.row.propertyName;
    handleOpenDeletePropertyDialog(props.row);
    onChangePropertyData({});
  };

  const handleCancelClick = (props) => {
    const propertyName = props.row.propertyName;
    const updatedEditProperties = { ...editProperties };
    updatedEditProperties[propertyName] = { isEdit: false };
    onChangeEditProperties(updatedEditProperties);
    onChangePropertyData({});
  };

  const handleChange = (props, e, fieldName) => {
    e.stopPropagation();
    e.preventDefault();
    handleInputChange(props.row.propertyName, fieldName, e.target.value);
  };

  const handleKeyDown = (event, fieldName, propertyName) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      handleInputChange(
        propertyName,
        fieldName,
        propertyData?.displayName + ' ',
      );
    }
  };

  const renderdataTypeCell = (props) => {
    const propertyName = props.row.propertyName;
    if (editProperties[propertyName]?.isEdit) {
      return (
        <Grid item sx={{ my: 'auto' }}>
          <Dropdown
            value={propertyData?.dataType}
            onChange={(value) =>
              handleInputChange(
                props.row.propertyName,
                t(
                  'addMetadataPage.components.defineNewProperty.fields.dataType',
                ),
                value,
              )
            }
            items={dataTypes}
          />
        </Grid>
      );
    } else {
      return (
        <Grid item>
          <FieldValueTypography variant="h5">
            {
              dataTypes.find(
                (dataType) => dataType.value === props?.row?.dataType,
              )?.label
            }
          </FieldValueTypography>
        </Grid>
      );
    }
  };

  const renderMutationCell = (props) => {
    const propertyName = props.row.propertyName;

    if (editProperties[propertyName]?.isEdit) {
      return (
        <Grid item sx={{ mt: 2 }}>
          <BooleanField
            value={propertyData?.mutation}
            onChange={(value) =>
              handleInputChange(
                props.row.propertyName,
                t(
                  'addMetadataPage.components.defineNewProperty.fields.mutation',
                ),
                value,
              )
            }
          />
        </Grid>
      );
    } else {
      return (
        <Grid item>
          <FieldValueTypography>
            {props?.row?.mutation ? 'True' : 'False'}
          </FieldValueTypography>
        </Grid>
      );
    }
  };

  const renderPropertyNameCell = (props) => {
    return (
      <Grid item>
        <PropertyNameTypography variant="h5">
          {props?.row?.propertyName}
        </PropertyNameTypography>
      </Grid>
    );
  };

  const renderDisplayNameCell = (props) => {
    const propertyName = props.row.propertyName;
    if (editProperties[propertyName]?.isEdit) {
      return (
        <Grid item>
          <TextField
            value={propertyData?.displayName}
            onChange={(e) =>
              handleChange(
                props,
                e,
                t(
                  'addMetadataPage.components.defineNewProperty.fields.displayName',
                ),
              )
            }
            variant="outlined"
            size="small"
            onKeyDown={(e) =>
              handleKeyDown(
                e,
                t(
                  'addMetadataPage.components.defineNewProperty.fields.displayName',
                ),
                propertyName,
              )
            }
          />
        </Grid>
      );
    } else {
      return (
        <Grid item>
          <FieldValueTypography variant="h5">
            {props?.row?.displayName}
          </FieldValueTypography>
        </Grid>
      );
    }
  };

  const columns = [
    {
      field: t(
        'manageMetadataPage.components.metadataGrid.columns.propertyName.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          {t(
            'manageMetadataPage.components.metadataGrid.columns.propertyName.headerName',
          )}
        </StyledHeaderColumn>
      ),
      width: 200,
      headerAlign: 'left',
      align: 'left',
      headerClassName: t(
        'manageMetadataPage.components.metadataGrid.columns.propertyName.headerClassName',
      ),
      renderCell: renderPropertyNameCell,
    },
    {
      field: t(
        'manageMetadataPage.components.metadataGrid.columns.displayName.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          {t(
            'manageMetadataPage.components.metadataGrid.columns.displayName.headerName',
          )}
        </StyledHeaderColumn>
      ),
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: renderDisplayNameCell,
    },

    {
      field: t(
        'manageMetadataPage.components.metadataGrid.columns.dataType.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          {t(
            'manageMetadataPage.components.metadataGrid.columns.dataType.headerName',
          )}
        </StyledHeaderColumn>
      ),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      headerClassName: t(
        'manageMetadataPage.components.metadataGrid.columns.dataType.headerClassName',
      ),
      renderCell: renderdataTypeCell,
    },
    {
      field: t(
        'manageMetadataPage.components.metadataGrid.columns.mutation.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          {t(
            'manageMetadataPage.components.metadataGrid.columns.mutation.headerName',
          )}
        </StyledHeaderColumn>
      ),
      width: 120,
      headerAlign: 'left',
      flex: 1,
      align: 'left',
      headerClassName: t(
        'manageMetadataPage.components.metadataGrid.columns.mutation.headerClassName',
      ),
      renderCell: renderMutationCell,
    },
    {
      field: t(
        'manageMetadataPage.components.metadataGrid.columns.actions.field',
      ),
      type: t(
        'manageMetadataPage.components.metadataGrid.columns.actions.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          {t(
            'manageMetadataPage.components.metadataGrid.columns.actions.headerName',
          )}
        </StyledHeaderColumn>
      ),
      width: 120,
      cellClassName: t(
        'manageMetadataPage.components.metadataGrid.columns.actions.field',
      ),
      getActions: (props) => {
        const { propertyName } = props.row;

        if (editProperties[propertyName]?.isEdit) {
          return [
            <GridActionsCellItem
              key={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.save.key',
              )}
              icon={<SaveIcon />}
              label={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.save.label',
              )}
              color="success"
              onClick={() => handleSaveClick(props)}
            />,
            <GridActionsCellItem
              key={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.cancel.key',
              )}
              icon={<CancelIcon />}
              label={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.cancel.label',
              )}
              className="textPrimary"
              onClick={() => handleCancelClick(props)}
              color="error"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              key={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.edit.key',
              )}
              icon={<EditIcon />}
              label={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.edit.label',
              )}
              className="textPrimary"
              onClick={() => handleEditClick(props)}
              color="primary"
            />,
            <GridActionsCellItem
              key={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.delete.key',
              )}
              icon={<DeleteIcon />}
              label={t(
                'manageMetadataPage.components.metadataGrid.columns.actions.delete.label',
              )}
              onClick={() => handleDeleteClick(props)}
              color="primary"
            />,
          ];
        }
      },
    },
  ];

  return (
    <DataGridBox>
      <StyledDataGrid
        rows={updatedPropertiesData}
        columns={columns}
        pageSize={10}
        autoHeight
        getRowId={(row) => row.displayName}
        getRowHeight={() => 48}
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSelector
        disableRowSelectionOnClick
        loading={isAddMetaDataPropertiesLoading}
        components={{
          Toolbar: CustomToolbar,
          QuickFilterIcon: StyledSearchIcon,
          QuickFilterClearIcon: StyledClearIcon,
        }}
      />
    </DataGridBox>
  );
};

ManageMetadataGrid.propTypes = {
  rows: PropTypes.array,
  row: PropTypes.object,
  propertiesData: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  handleOpenDeletePropertyDialog: PropTypes.func,
  propertyData: PropTypes.object,
  editProperties: PropTypes.object,
  onChangeEditProperties: PropTypes.func,
  onChangePropertyData: PropTypes.func,
  isAddMetaDataPropertiesLoading: PropTypes.bool,
};

export default ManageMetadataGrid;
