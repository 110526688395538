/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { React, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Select,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import {
  AddSummaryTopicButton,
  CancelButton,
  FieldGrid,
  GridContainer,
  HeadingTypography,
  InputField,
} from './addReportSummaryTopic.styled';
import { useTranslation } from 'react-i18next';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useAddReportSummaryTopicMutation } from '../../../redux/services/speciphicAsk';
import { MARKET_INTELLIGENCE_FEATURE } from '../../../constants/marketIntelligence';

const AddSummaryTopic = ({ handleClose, feature, order }) => {
  const { t } = useTranslation();
  const [topicName, setTopicName] = useState('');
  const [topicDetails, setTopicDetails] = useState('');
  const [featureVal, setFeatureVal] = useState('');
  const dispatch = useDispatch();

  const [
    addNewTopic,
    {
      data: newSummaryTopic,
      isSuccess: isAddNewTopicSuccess,
      isLoading: isAddNewTopicLoading,
      isError: isAddNewTopicError,
    },
  ] = useAddReportSummaryTopicMutation();

  useEffect(() => {
    if (isAddNewTopicSuccess) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.addNew.successToast'),
          severity: 'success',
        }),
      );
      handleClose();
    }
  }, [isAddNewTopicSuccess]);

  useEffect(() => {
    if (isAddNewTopicError) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.addNew.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isAddNewTopicError]);

  const handleTopicNameChange = (e) => {
    setTopicName(e.target.value);
  };

  const handleTopicDetailsChange = (e) => {
    setTopicDetails(e.target.value);
  };

  const handleRemoveFieldDetails = () => {
    handleClose();
  };

  useEffect(() => {
    setFeatureVal(feature);
  }, []);

  const handleAddNewTopic = (event) => {
    event.preventDefault();

    addNewTopic({
      topicName: topicName,
      topicDetails: topicDetails,
      feature: feature,
    });
  };

  return (
    <Box component="form" onSubmit={handleAddNewTopic} sx={{ width: '100%' }}>
      <GridContainer container spacing={0}>
        <FieldGrid item xs={12}>
          <HeadingTypography variant="h5">
            {t('reportSummaryTopicsPage.addNew.heading')}
          </HeadingTypography>
        </FieldGrid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <FieldGrid item xs={12}>
          <InputField
            size="small"
            required
            sx={{ marginTop: 2 }}
            id="outlined-required"
            label={t('reportSummaryTopicsPage.addNew.form.topicName')}
            value={topicName}
            onChange={handleTopicNameChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <TextField
            id="outlined-multiline-flexible"
            label="Topic Details"
            multiline
            fullWidth
            rows={7}
            sx={{ marginBottom: 1 }}
            value={topicDetails}
            onChange={handleTopicDetailsChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <AddSummaryTopicButton
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            loadingPosition="end"
            onClick={handleAddNewTopic}
            loading={isAddNewTopicLoading}
            disabled={topicName.length <= 2}
          >
            {t('reportSummaryTopicsPage.addNew.form.addButton')}
          </AddSummaryTopicButton>
        </FieldGrid>
        <FieldGrid item xs={12}>
          <CancelButton
            variant="outlined"
            fullWidth
            color="error"
            onClick={handleRemoveFieldDetails}
          >
            {t('reportSummaryTopicsPage.addNew.form.cancelButton')}
          </CancelButton>
        </FieldGrid>
      </GridContainer>
    </Box>
  );
};

AddSummaryTopic.propTypes = {
  handleClose: PropTypes.func,
  feature: PropTypes.string,
  order: PropTypes.number,
};
export default AddSummaryTopic;
