/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { React, useState, useContext, useEffect } from 'react';
import {
  Grid,
  LinearProgress,
  Button,
  Box,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { SearchbarBase } from '../../components/searchbar';
import {
  ShadowBox,
  MetaDataPageWrapper,
  MetaDataFilterBox,
  FoundDataAlert,
  MetaDataGridBox,
  ErrorAlert,
  BooleanOperatorTypography,
  MessageTypography,
  AlertTypography,
  StyledLink,
  FlexLoadingContainer,
  Loader,
} from './searchMetaData.styled';
import PageContainer from '../../components/pageContainer';
import MetaDataGrid from '../../components/metadataGrid';
import FilterForm from './components/filterForm';
import FilterChip from './components/filterChip';
import { FileCollectionContext } from '../../hooks/useContext';
import {
  useAddMetaDataFiltersMutation,
  useGetMetaDataPropertiesQuery,
  useGetIndividualFileCollectionQuery,
} from '../../redux/services/speciphicAsk';
import Popup from '../../components/popUp';
import * as PATHS from '../../constants/path';
import UnauthorizedMessage from '../../components/unauthorized';

const translationJSONPrefix = 'searchMetadataPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const SearchMetaDataPage = () => {
  const { t } = useTranslation();
  const [languageCode, setLanguageCode] = useState(``);
  const navigate = useNavigate();
  const [input, setInput] = useState(``);
  const [inputValue, setInputValue] = useState(``);
  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false);
  const { fileCollectionId } = useParams();
  const [isRetrieveAllFiles, setIsRetrieveAllFiles] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [formattedFilterChipData, setFormattedFilterChipData] = useState([]);
  const [displayFilterInput, setDisplayFilterInput] = useState([]);
  const [isSearchMetadataInfoModalOpen, setIsSearchMetadataInforModalOpen] =
    useState(false);
  const { updateCollectionId, isFileCollectionChanged, getCollectionId } =
    useContext(FileCollectionContext);
  const [pageNo, setPageNo] = useState(1);
  const [gridSize, setGridSize] = useState(50);

  const [
    findMetaFilters,
    {
      data: response,
      isSuccess: isGetResultsSuccess,
      isLoading: isGetResultsLoading,
      error: getResultsError,
      isFetching: isGetResultsFetching,
    },
  ] = useAddMetaDataFiltersMutation();

  const {
    data: definedMetaProperties = [],
    error: getMetaPropertiesError,
    isLoading: isGetMetaPropertiesLoading,
    isSuccess: isGetMetaPropertiesSuccess,
  } = useGetMetaDataPropertiesQuery({
    collectionId: fileCollectionId,
  });

  const {
    data: individualFileCollection,
    error: getIndividualFileCollectionError,
    isLoading: isGetIndividualFileCollectionLoading,
    isSuccess: isGetIndividualFileCollectionSuccess,
  } = useGetIndividualFileCollectionQuery({
    collectionId: fileCollectionId,
  });

  useEffect(() => {
    findMetaFilters({
      collectionId: fileCollectionId,
      filters: filterData,
      query: input,
      size: gridSize,
      pageNo: pageNo,
    });
  }, [pageNo, gridSize, fileCollectionId]);

  useEffect(() => {
    updateCollectionId(fileCollectionId);
  }, []);

  useEffect(() => {
    if (
      individualFileCollection &&
      !individualFileCollection?.isMetadataEnabled
    ) {
      navigate(
        `${PATHS.SEARCH_METADATA_PAGE}/${fileCollectionId}/${PATHS.METADATA_NOT_ENABLED_ERROR_PAGE}`,
        {
          state: {
            collectionName: individualFileCollection.name,
          },
        },
      );
    }
  }, [individualFileCollection]);

  useEffect(() => {
    const initialFilterData = {};
    for (const key in definedMetaProperties) {
      initialFilterData[key] = ``;
    }
    setFilterData(initialFilterData);
  }, [isGetMetaPropertiesSuccess]);

  useEffect(() => {
    setInput(``);
    setInputValue(``);
    const initialFilterData = {};
    for (const key in definedMetaProperties) {
      initialFilterData[key] = ``;
    }
    setFilterData(initialFilterData);
    setFormattedFilterChipData(initialFilterData);
    setDisplayFilterInput([]);
    setPageNo(1);
    setGridSize(50);
  }, []);

  const handleLanguageChange = (value) => {
    setLanguageCode(value.code);
  };

  const handleSubmitForm = () => {
    setInputValue(input);
    setIsFilterFormOpen(false);

    setFormattedFilterChipData(
      Object.entries(filterData)
        .filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ``,
        )
        .map(([key, value]) => ({ [key]: value })),
    );

    setDisplayFilterInput(
      Object.entries(filterData)
        .filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ``,
        )
        .map(([key, value]) => ({ [key]: value })),
    );

    findMetaFilters({
      collectionId: fileCollectionId,
      filters: filterData,
      query: input,
      size: gridSize,
      pageNo: pageNo,
    });
  };

  const handleFilterClick = () => {
    setIsFilterFormOpen((prevValue) => !prevValue);
  };

  const handleSearchMetadataInfoModalOpen = () => {
    setIsSearchMetadataInforModalOpen((prevValue) => !prevValue);
  };

  const handleSearchClick = (input) => {
    setInputValue(input);
    setIsFilterFormOpen(false);

    setFormattedFilterChipData(
      Object.entries(filterData)
        .filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ``,
        )
        .map(([key, value]) => ({ [key]: value })),
    );

    setDisplayFilterInput(
      Object.entries(filterData)
        .filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ``,
        )
        .map(([key, value]) => ({ [key]: value })),
    );

    findMetaFilters({
      collectionId: fileCollectionId,
      filters: filterData,
      query: input,
      size: gridSize,
      pageNo: pageNo,
    });
  };

  const highlightKeywords = (text) => {
    const andRegex = /\b(AND)\b/g;
    const orRegex = /\b(OR)\b/g;
    const notRegex = /\b(NOT)\b/g;

    const parts = text.split(notRegex).map((part, index) => {
      if (part === t(`${translationJSONPrefix}.searchMetadataInfo.NOT`)) {
        return (
          <strong key={index}>
            {t(`${translationJSONPrefix}.searchMetadataInfo.NOT`)}
          </strong>
        );
      }
      return part.split(andRegex).map((innerPart, innerIndex) => {
        if (
          innerPart === t(`${translationJSONPrefix}.searchMetadataInfo.AND`)
        ) {
          return (
            <strong key={`${index}-${innerIndex}`}>
              {t(`${translationJSONPrefix}.searchMetadataInfo.AND`)}
            </strong>
          );
        }
        return innerPart.split(orRegex).map((subPart, subIndex) => {
          if (subPart === t(`${translationJSONPrefix}.searchMetadataInfo.OR`)) {
            return (
              <strong key={`${index}-${innerIndex}-${subIndex}`}>
                {t(`${translationJSONPrefix}.searchMetadataInfo.OR`)}
              </strong>
            );
          }
          return subPart;
        });
      });
    });

    return <span>{parts.flat()}</span>;
  };

  const searchMetadataInfo = () => {
    return (
      <Typography sx={{ p: 1 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ textAlign: `center`, fontSize: 16, fontWeight: `bold` }}
        >
          {highlightKeywords(
            t(`${translationJSONPrefix}.searchMetadataInfo.header`),
          )}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {highlightKeywords(
            t(`${translationJSONPrefix}.searchMetadataInfo.searchBar`),
          )}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {highlightKeywords(
            t(
              `${translationJSONPrefix}.searchMetadataInfo.searchBarDescription`,
            ),
          )}
          <br />
          {highlightKeywords(
            t(`${translationJSONPrefix}.searchMetadataInfo.searchBarExample`),
          )}

          <br />
        </Typography>

        <List sx={{ listStyleType: `disc`, pl: 2.5 }}>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(
                  `${translationJSONPrefix}.searchMetadataInfo.searchBarQuery1`,
                ),
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(
                  `${translationJSONPrefix}.searchMetadataInfo.searchBarQueryResult1`,
                ),
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(
                  `${translationJSONPrefix}.searchMetadataInfo.searchBarQuery2`,
                ),
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(
                  `${translationJSONPrefix}.searchMetadataInfo.searchBarQueryResult2`,
                ),
              )}
            </Typography>
          </ListItem>
        </List>

        <Typography variant="subtitle1" gutterBottom>
          {highlightKeywords(
            t(`${translationJSONPrefix}.searchMetadataInfo.filters`),
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {highlightKeywords(
            t(`${translationJSONPrefix}.searchMetadataInfo.filtersDescription`),
          )}
          <br />
          {highlightKeywords(
            t(`${translationJSONPrefix}.searchMetadataInfo.filtersExample`),
          )}
        </Typography>

        <List sx={{ listStyleType: `disc`, pl: 2.5 }}>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(`${translationJSONPrefix}.searchMetadataInfo.filtersCountry`),
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(`${translationJSONPrefix}.searchMetadataInfo.filtersAuthors`),
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(`${translationJSONPrefix}.searchMetadataInfo.filtersStatus`),
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: `list-item` }}>
            <Typography>
              {highlightKeywords(
                t(
                  `${translationJSONPrefix}.searchMetadataInfo.filtersSearchResult`,
                ),
              )}
            </Typography>
          </ListItem>
        </List>

        <Typography variant="subtitle1" gutterBottom>
          {highlightKeywords(
            t(
              `${translationJSONPrefix}.searchMetadataInfo.searchBarAndFilters`,
            ),
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {highlightKeywords(
            t(
              `${translationJSONPrefix}.searchMetadataInfo.searchBarAndFiltersDescription`,
            ),
          )}
        </Typography>
      </Typography>
    );
  };

  if (
    getResultsError?.status === 403 ||
    getMetaPropertiesError?.status === 403 ||
    getIndividualFileCollectionError?.status === 403
  )
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <MetaDataPageWrapper>
        <Grid>
          <ShadowBox>
            <Grid container item display="flex" justifyContent="center">
              <Typography
                variant="h2"
                color="primary"
                sx={{ textAlign: `center`, fontSize: 32 }}
              >
                {t(`${translationJSONPrefix}.header`)}
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="center"
              sx={{ paddingX: { xs: 1, sm: 10, lg: 20 } }}
            >
              <Grid item xs={12} display="block">
                <Grid display="flex" justifyContent="flex-end" sx={{ pb: 1 }}>
                  <StyledLink
                    variant="contained"
                    onClick={handleSearchMetadataInfoModalOpen}
                  >
                    {t(
                      `${translationJSONPrefix}.placeholders.searchMetadataInfo`,
                    )}
                  </StyledLink>
                  <Popup
                    isModalOpen={isSearchMetadataInfoModalOpen}
                    content={searchMetadataInfo()}
                    onChangeModalOpen={setIsSearchMetadataInforModalOpen}
                  />
                </Grid>
                <SearchbarBase
                  onSearchClick={handleSearchClick}
                  value={input}
                  setQueryLanguage={handleLanguageChange}
                  onChange={setInput}
                  placeholder={t(
                    `${translationJSONPrefix}.placeholders.searchBar`,
                  )}
                  isFilters={formattedFilterChipData.length > 0}
                  isMetaSearchBar={true}
                />
                <Grid container item display="flex" xs={12}>
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    justifyContent="flex-start"
                    sx={{ marginTop: 2 }}
                  >
                    {formattedFilterChipData.length > 0 && (
                      <FilterChip
                        filters={formattedFilterChipData}
                        onFiltersChange={setFormattedFilterChipData}
                        filterData={filterData}
                        onChangeFilterData={setFilterData}
                        definedMetaProperties={definedMetaProperties}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ marginTop: 2 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<FilterList />}
                      onClick={handleFilterClick}
                    >
                      {t(`${translationJSONPrefix}.labels.advancedFilters`)}
                    </Button>
                  </Grid>
                </Grid>
                <>
                  {isFilterFormOpen && (
                    <Grid
                      container
                      item
                      xs={12}
                      md={12}
                      flex="1"
                      display="block"
                      sx={{
                        paddingTop: 4,
                        width: `100%`,
                      }}
                    >
                      <MetaDataFilterBox>
                        <FilterForm
                          open={isFilterFormOpen}
                          filterData={filterData}
                          setOpen={setIsFilterFormOpen}
                          onChangeFilterData={setFilterData}
                          handleSubmitForm={handleSubmitForm}
                          isRetrieveAllFiles={isRetrieveAllFiles}
                          onChangeRetrieveAllFiles={setIsRetrieveAllFiles}
                          collectionId={fileCollectionId}
                        />
                      </MetaDataFilterBox>
                    </Grid>
                  )}
                </>
              </Grid>
            </Grid>
          </ShadowBox>
          <Grid sx={{ paddingX: { xs: 1, sm: 10, lg: 20 }, py: 3 }}>
            {isGetResultsLoading || isGetResultsFetching ? (
              <FlexLoadingContainer>
                <Loader />
                <>{t(`${translationJSONPrefix}.alerts.searching`)}</>
              </FlexLoadingContainer>
            ) : isGetResultsSuccess && !response?.results.length ? (
              <ErrorAlert severity="error">
                <AlertTypography variant="h5">
                  {t(`${translationJSONPrefix}.alerts.noResults`)}
                  <>
                    {inputValue ? (
                      <MessageTypography>
                        {t(`${translationJSONPrefix}.labels.query`)}{' '}
                        {inputValue}
                      </MessageTypography>
                    ) : (
                      ``
                    )}
                    {displayFilterInput &&
                      displayFilterInput?.map((filter, index) =>
                        Object.entries(filter).map(
                          ([filterType, filterValue]) => (
                            <>
                              {inputValue || index !== 0 ? (
                                <BooleanOperatorTypography>
                                  {t(`${translationJSONPrefix}.labels.AND`)}
                                </BooleanOperatorTypography>
                              ) : (
                                ``
                              )}

                              <MessageTypography key={index}>
                                {definedMetaProperties[filterType].displayName}:
                                {` `}
                                {filterValue}
                              </MessageTypography>
                            </>
                          ),
                        ),
                      )}
                  </>
                </AlertTypography>
              </ErrorAlert>
            ) : isGetResultsSuccess && response?.results.length ? (
              <>
                <FoundDataAlert severity="success">
                  <AlertTypography variant="h5">
                    {!inputValue &&
                    Object.keys(displayFilterInput).length === 0 &&
                    individualFileCollection
                      ? t(
                          `${translationJSONPrefix}.alerts.fetchAll`,

                          { name: individualFileCollection?.name },
                        )
                      : t(`${translationJSONPrefix}.alerts.success`)}
                    <>
                      {inputValue ? (
                        <MessageTypography>
                          {t(`${translationJSONPrefix}.labels.query`)}
                          {inputValue}
                        </MessageTypography>
                      ) : (
                        ``
                      )}
                      {displayFilterInput &&
                        displayFilterInput?.map((filter, index) =>
                          Object.entries(filter).map(
                            ([filterType, filterValue]) => (
                              <>
                                {inputValue || index !== 0 ? (
                                  <BooleanOperatorTypography>
                                    {t(`${translationJSONPrefix}.labels.AND`)}
                                  </BooleanOperatorTypography>
                                ) : (
                                  ``
                                )}
                                <MessageTypography key={index}>
                                  {
                                    definedMetaProperties[filterType]
                                      .displayName
                                  }
                                  : {filterValue}
                                </MessageTypography>
                              </>
                            ),
                          ),
                        )}
                    </>
                  </AlertTypography>
                </FoundDataAlert>
                <MetaDataGridBox>
                  <Grid>
                    <Box sx={{ paddingTop: 2 }}>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: `bold`, fontSize: 20 }}
                      >
                        {t(`${translationJSONPrefix}.labels.yourResults`)}
                      </Typography>
                    </Box>

                    <MetaDataGrid
                      files={response?.results}
                      definedMetaProperties={definedMetaProperties}
                      pageSize={gridSize}
                      onPageSizeChange={setGridSize}
                      pageNo={pageNo}
                      onPageNoChange={setPageNo}
                      totalPages={response?.total_size}
                      noRowsMessage={t(
                        `${translationJSONPrefix}.metadataGrid.noRowsMessage`,
                      )}
                    />
                  </Grid>
                </MetaDataGridBox>
              </>
            ) : null}
          </Grid>
        </Grid>
      </MetaDataPageWrapper>
    </PageContainer>
  );
};

SearchMetaDataPage.propTypes = {
  response: PropTypes.object,
};

export default SearchMetaDataPage;
