/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

const getReportSourcePreferences = (builder) =>
  builder.query({
    query: () => ({
      url: `me/report-sources/preferences`,
      method: 'GET',
    }),
    providesTags: ['preferences', 'report-sources'],
  });

const createReportSourcePreferences = (builder) =>
  builder.mutation({
    query: ({ reportSourceId, companyName }) => ({
      url: `report-sources/preferences/${reportSourceId}`,
      method: 'POST',
      body: {
        companyName: companyName,
      },
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['preferences'],
  });

const deleteReportSourcePreference = (builder) =>
  builder.mutation({
    query: ({ reportSourceId }) => ({
      url: `report-sources/preferences/${reportSourceId}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['preferences'],
  });
export const getReportSourcesPreferencesEPFactory = (builder) =>
  getReportSourcePreferences(builder);
export const createReportSourcesPreferencesEPFactory = (builder) =>
  createReportSourcePreferences(builder);
export const deleteReportSourcePreferenceEPFactory = (builder) =>
  deleteReportSourcePreference(builder);
