/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import PropTypes from 'prop-types';
import { ReportProblem } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useAuth } from 'react-oidc-context';

import PageContainer from '../pageContainer';
import { Container, Description, Title } from './unauthorized.styled';

const translationJSONPrefix = 'unauthorized.component';

const UnauthorizedMessage = ({
  description,
  isUnauthorizedApp = false,
  ...props
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();

  const handleLogOut = () => {
    auth.signoutRedirect();
    if (auth.isAuthenticated) {
      auth.removeUser();
    }
    localStorage.clear();
  };

  if (isUnauthorizedApp)
    return (
      <Container>
        <ReportProblem style={{ fontSize: 50 }} />
        <Title>{t(`${translationJSONPrefix}.title`)}</Title>
        <Description>
          {`${t(`${translationJSONPrefix}.unauthorizedApp`)} ${t(
            `${translationJSONPrefix}.descriptionSuffix`,
          )}`}
        </Description>
        <Button variant="contained" onClick={handleLogOut}>
          {t(`${translationJSONPrefix}.buttons.logout`)}
        </Button>
      </Container>
    );

  return (
    <PageContainer {...props}>
      <Container>
        <ReportProblem style={{ fontSize: 50 }} />
        <Title>{t(`${translationJSONPrefix}.title`)}</Title>
        <Description>
          {`${
            description ||
            t(`${translationJSONPrefix}.defaultDescriptionPrefix`)
          } ${t(`${translationJSONPrefix}.descriptionSuffix`)}`}
        </Description>
        <Button variant="contained" onClick={() => navigate(-1)}>
          {t(`${translationJSONPrefix}.buttons.back`)}
        </Button>
      </Container>
    </PageContainer>
  );
};

UnauthorizedMessage.propTypes = {
  description: PropTypes.string,
  drawerOpen: PropTypes.bool,
  isUnauthorizedApp: PropTypes.bool,
};

export default UnauthorizedMessage;
