const {
  REACT_APP_DOC_URL_PREFIX,
  REACT_APP_CLOUD_PREVIEW_SUPPORT_EXTENSIONS,
  REACT_APP_IFRAME_PREVIEW_SUPPORT_EXTENSIONS,
} = process.env;

export const viewerSupportExtensions =
  REACT_APP_CLOUD_PREVIEW_SUPPORT_EXTENSIONS?.split(',');
export const iframeSupportExtensions =
  REACT_APP_IFRAME_PREVIEW_SUPPORT_EXTENSIONS?.split(',');

export const docViewerUrlPrefix = REACT_APP_DOC_URL_PREFIX;
