/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState } from 'react';
import { LinearProgress, Slide, useMediaQuery, useTheme } from '@mui/material';
import PdfPreview from '../pdfPreview/pdfPreview';
import { Cancel } from '@mui/icons-material';
import {
  DialogHeader,
  DocPreviewWrapper,
  IframeStyled,
  StyledCancelButton,
  StyledDialog,
  StyledDialogTitle,
} from './pdfDialogBox.styled';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import isSignedUrlSupportEnabled from '../../../constants/signedUrlConfiguration';

const LARGE_VIEW = 1.4;
const SMALL_VIEW = 0.6;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * PdfDialogBox Component
 * Displays a PDF preview or iframe in a dialog box.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.handleClose - Function to handle closing the dialog.
 * @param {Object} props.file - File object containing metadata and preview URL.
 * @param {boolean} props.open - Boolean indicating if the dialog is open.
 * @param {number} props.pageNumber - Page number for PDF preview (only if isSignedUrlSupportEnabled is false).
 * @param {string} props.dataUri - Data URI for the PDF (only if isSignedUrlSupportEnabled is false).
 *
 * @returns {JSX.Element} The rendered component.
 */
const PdfDialogBox = ({ handleClose, file, open, pageNumber, dataUri }) => {
  const { height, width } = useWindowDimensions();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  return (
    <StyledDialog
      fullWidth
      onClose={handleClose}
      open={open}
      maxWidth={false}
      TransitionComponent={Transition}
    >
      <DialogHeader>
        <StyledDialogTitle color="primary">{file?.fileName}</StyledDialogTitle>

        <StyledCancelButton onClick={handleClose} color="error">
          <Cancel />
        </StyledCancelButton>
      </DialogHeader>

      {isSignedUrlSupportEnabled ? (
        <>
          {loading && <LinearProgress />}

          <DocPreviewWrapper height={height}>
            <IframeStyled
              src={`${file?.preview_url}#page=${file.page}&scrollbar=0`}
              onLoad={() => setLoading(false)}
              onError={() => setError(true)}
            />
          </DocPreviewWrapper>
        </>
      ) : (
        <PdfPreview
          pageNumber={pageNumber}
          dataUri={dataUri}
          scale={matches ? LARGE_VIEW : SMALL_VIEW}
          showWholePdf={true}
        />
      )}
    </StyledDialog>
  );
};

PdfDialogBox.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  url: PropTypes.string,
  file: PropTypes.element,
  pageNumber: PropTypes.number,
  dataUri: PropTypes.string,
};
export default PdfDialogBox;
