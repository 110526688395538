import * as React from 'react';
const CollapsibleIconSvg = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.77778 16.6064C1.28889 16.6064 0.87037 16.4324 0.522222 16.0842C0.174074 15.7361 0 15.3176 0 14.8287V2.38422C0 1.89533 0.174074 1.47682 0.522222 1.12867C0.87037 0.780519 1.28889 0.606445 1.77778 0.606445H14.2222C14.7111 0.606445 15.1296 0.780519 15.4778 1.12867C15.8259 1.47682 16 1.89533 16 2.38422V14.8287C16 15.3176 15.8259 15.7361 15.4778 16.0842C15.1296 16.4324 14.7111 16.6064 14.2222 16.6064H1.77778ZM8 14.8287H14.2222V2.38422H8V14.8287Z"
      fill="currentColor"
    />
  </svg>
);
export default CollapsibleIconSvg;
