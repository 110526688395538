import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import PropTypes from 'prop-types';
import { Box, IconButton, LinearProgress } from '@mui/material';
import {
  NavigateBefore,
  NavigateNext,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';

import {
  StyledButtonBox,
  StyledWholePdfBox,
  StyledCircularIcon,
  PdfCanvas,
  PreviewContainer,
  StyledFileBreak,
} from './pdfPreview.styled';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const SCALE_LOWER_LIMIT = 0.6;
const SCALE_UPPER_LIMIT = 2.0;

const PdfPreview = ({ pageNumber, scale, showWholePdf, dataUri }) => {
  const [page, setPage] = useState(pageNumber);
  const [previewScale, setPreviewScale] = useState(scale);
  const canvasRef = useRef(null);

  const { height } = useWindowDimensions();

  const { pdfDocument } = usePdf({
    file: dataUri || 'data.pdf', // to prevent from application break if the dataUri is null | undefined
    page,
    canvasRef,
    scale: previewScale,
  });

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handleZoomIn = () => {
    setPreviewScale(previewScale + 0.2);
  };

  const handleZoomOut = () => {
    setPreviewScale(previewScale - 0.2);
  };

  return (
    <PreviewContainer>
      {showWholePdf ? (
        <StyledWholePdfBox style={{ height: height * 0.8 }}>
          {dataUri === 'ERROR' ? (
            <StyledFileBreak />
          ) : pdfDocument && Boolean(pdfDocument?.numPages) ? (
            <>
              <canvas ref={canvasRef} />

              <StyledButtonBox>
                <IconButton
                  disabled={previewScale <= SCALE_LOWER_LIMIT}
                  onClick={handleZoomOut}
                  color="primary"
                >
                  <ZoomOut />
                </IconButton>
                <IconButton
                  disabled={page === 1}
                  onClick={handlePrevPage}
                  color="primary"
                >
                  <NavigateBefore />
                </IconButton>
                <Box>{`${page} / ${pdfDocument.numPages}`}</Box>
                <IconButton
                  disabled={page === pdfDocument.numPages}
                  onClick={handleNextPage}
                  color="primary"
                >
                  <NavigateNext />
                </IconButton>
                <IconButton
                  disabled={previewScale >= SCALE_UPPER_LIMIT}
                  onClick={handleZoomIn}
                  color="primary"
                >
                  <ZoomIn />
                </IconButton>
              </StyledButtonBox>
            </>
          ) : (
            <LinearProgress />
          )}
        </StyledWholePdfBox>
      ) : (
        <PdfCanvas style={{ height: height * 0.8 }}>
          {dataUri === 'ERROR' ? (
            <StyledFileBreak />
          ) : pdfDocument ? (
            <canvas ref={canvasRef} />
          ) : (
            <StyledCircularIcon color="secondary" />
          )}
        </PdfCanvas>
      )}
    </PreviewContainer>
  );
};

PdfPreview.propTypes = {
  pageNumber: PropTypes.number,
  pdfUrl: PropTypes.string,
  scale: PropTypes.number,
  showWholePdf: PropTypes.bool,
  dataUri: PropTypes.string,
};

PdfPreview.defaultProps = {
  pageNumber: 1,
  scale: 1,
};

export default PdfPreview;
