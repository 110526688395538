/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, FormControl } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  fullWidth: true,
  display: 'flex',
  wrap: 'nowrap',
}));

export const StyledDesktopDatePicker = styled(DesktopDatePicker)(
  ({ theme }) => ({
    '.MuiIconButton-root': {
      marginRight: theme.spacing(0.5),
    },
  }),
);
