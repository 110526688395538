/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material';
import { muiButton } from './miComponents/muiButton';
import { muiOutlinedInput } from './miComponents/muiOutlinedInput';
import { muiInputLabel } from './miComponents/muiInputLabel';
import { muiSelect } from './miComponents/muiSelect';
import { muiListItemIcon } from './miComponents/muiListItemIcon';
import { muiIconButton } from './miComponents/muiIconButton';
import { muiListItemText } from './miComponents/muiListItemText';
import { muiToolbar } from './miComponents/muiToolbar';
import { muiTextField } from './miComponents/muiTextField';
import palette from './palette';
import fontWeights from './fontWeights';
import shadows from './shadows';
import typography from './typography';

export const themeOptions = {
  fontWeights,
  palette,
  typography,
  shadows,
  components: {
    MuiOutlinedInput: muiOutlinedInput,
    MuiInputLabel: muiInputLabel,
    MuiSelect: muiSelect,
    MuiButton: muiButton,
    MuiToolbar: muiToolbar,
    MuiIconButton: muiIconButton,
    MuiListItemIcon: muiListItemIcon,
    MuiTextField: muiTextField,
    MuiListItemText: muiListItemText,
  },
};

const theme = createTheme(themeOptions);

export default theme;
