import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { object, string, number, array, bool } from 'yup';
import { Box, CircularProgress } from '@mui/material';

import {
  AlertStyled,
  DescriptionStyled,
  FormContainer,
  InputsContainer,
  PageHeader,
  ToogleButtonsContainer,
} from './styled';
import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import { LOAD_TITLE } from '../../../utils/functions';
import Loader from '../../../components/loader';
import AppForm, {
  ToogleContainer,
  AppAutocompleteField,
  AppTextField,
  AppSelectField,
  FormButtons,
} from '../../../components/form';
import {
  useLazyGetFileCollectionSettingsQuery,
  useGetFileCollectionsQuery,
  useUpdateFileCollectionSettingsMutation,
  useLazyGetIndividualFileCollectionQuery,
} from '../../../redux/services/speciphicAsk';
import { GENERAL_CONFIG as API_ENDPOINT } from '../../../constants/filesCollectionSettings';
import { getChangedValues } from '../../../components/form/functions';
import UnauthorizedMessage from '../../../components/unauthorized';

const translationJSONPrefix = 'filesCollectionSettingsPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const FILES_COLLECTION = `${translationJSONPrefix}.filesCollection`;
const SETTINGS = `${translationJSONPrefix}.settings`;
const PAGE_TITLE_SUFFIX = `${translationJSONPrefix}.pageTitleSuffix`;

const PAGE_TITLE = (t) =>
  `${t(`${translationJSONPrefix}.general.drawerMenuTitle`)} ${t(
    SETTINGS,
  )} | ${t(FILES_COLLECTION)} | ${t(PAGE_TITLE_SUFFIX)}`;

const defaultDataState = {
  name: '',
  description: '',
  tags: [],
  isVisible: false,
  isEnabled: false,
  displayOrder: '',
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.general.form.${label}.errorMessages`;

  return object({
    name: string().required(t(`${translationPrefix('name')}.required`)),
    description: string(),
    tags: array(),
    isVisible: bool(),
    isEnabled: bool(),
    displayOrder: number(),
  });
};

const orderOptions = (len) => {
  const result = [];

  for (let i = 1; i <= len; i++) {
    result.push({
      label: i.toString(),
      value: i.toString(),
    });
  }

  return result;
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending, // temporary param, to be removed ...
  orderOptions,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.general.form`;

  return (
    <Form>
      <ToogleButtonsContainer>
        <ToogleContainer
          name="isVisible"
          label={t(`${translationPrefix}.isVisible.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <ToogleContainer
          name="isEnabled"
          label={t(`${translationPrefix}.isEnabled.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </ToogleButtonsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '70%' }}
          name="name"
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <AppSelectField
          style={{ width: '25%' }}
          name="displayOrder"
          label={t(`${translationPrefix}.order.label`)}
          placeholder={t(`${translationPrefix}.order.placeholder`)}
          tooltipTitle={t(`${translationPrefix}.order.tooltipTitle`)}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          menuData={orderOptions}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="description"
          label={t(`${translationPrefix}.description.label`)}
          placeholder={t(`${translationPrefix}.description.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          multiline
          minRows={3}
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="tags"
          label={t(`${translationPrefix}.tags.label`)}
          placeholder={t(`${translationPrefix}.tags.placeholder`)}
          options={[]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          values={values}
        />
      </InputsContainer>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const FilesCollectionSettings = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => LOAD_TITLE(document, PAGE_TITLE(t)), []);

  const [collectionId, setCollectionId] = useState();
  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [
    getFormData,
    {
      data: formDataDB,
      error: formError,
      isError: isGetFormError,
      isLoading: isGetFormLoading,
      isSuccess: isGetFormSuccess,
    },
  ] = useLazyGetFileCollectionSettingsQuery();

  const [
    getFilesCollectionInfo,
    {
      data: filesCollectionInfo,
      error: filesCollectionError,
      isError: isGetFCError,
      isLoading: isGetFCLoading,
      isSuccess: isGetFCSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  useEffect(() => {
    if (params?.collectionId) {
      setCollectionId(params?.collectionId);
      getFilesCollectionInfo({ collectionId: params?.collectionId });
      getFormData({
        collectionId: params.collectionId,
        endpoint: API_ENDPOINT,
      });
    }
  }, [params?.collectionId]);

  const [
    updateSettings,
    {
      data: formDataUpdateDB,
      isError: isUpdateFormError,
      isLoading: isUpdateFormLoading,
      isSuccess: isUpdateFormSuccess,
    },
  ] = useUpdateFileCollectionSettingsMutation();

  const {
    data: allFilesCollections,
    isError: isGetFileCollectionsError,
    isLoading: isGetFileCollectionsLoading,
    isSuccess: isGetFileCollectionsSuccess,
  } = useGetFileCollectionsQuery({});

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    tempAlert.formUpdateError = isUpdateFormError;
    setShowAlert(tempAlert);
  }, [isUpdateFormError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    tempAlert.formUpdateSuccess = isUpdateFormSuccess;
    setShowAlert(tempAlert);
  }, [isUpdateFormSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  useEffect(() => {
    if (isGetFormSuccess && formDataDB?.name) {
      const {
        name,
        description = '',
        tags = [],
        isVisible = false,
        isEnabled = false,
        displayOrder = '',
      } = formDataDB;

      const data = {
        name,
        description,
        tags,
        isVisible,
        isEnabled,
        displayOrder,
      };

      setFormData(data);
      setInitialFormData(data);
    }
  }, [formDataDB]);

  if (formError?.status === 403 || filesCollectionError?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
      collectionId={collectionId}
    >
      <PageHeader>
        {`${filesCollectionInfo?.name || '...'} / ${t(
          `${translationJSONPrefix}.general.pageHeader`,
        )}`}
      </PageHeader>

      <DescriptionStyled>{collectionId}</DescriptionStyled>

      {collectionId && !isGetFormLoading ? (
        <FormContainer>
          <AlertStyled
            onClose={closeAlert}
            severity={showAlert.formUpdateError ? 'error' : 'success'}
            style={{
              marginTop:
                showAlert.formUpdateError || showAlert.formUpdateSuccess
                  ? 10
                  : -60,
            }}
          >
            {showAlert.formUpdateError &&
              t(`${translationJSONPrefix}.general.alert.updateFormError`)}
            {showAlert.formUpdateSuccess &&
              t(`${translationJSONPrefix}.general.alert.updateFormSuccess`)}
          </AlertStyled>

          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSend = getChangedValues(values, initialFormData);
              updateSettings({
                collectionId,
                data: valuesToSend,
                endpoint: API_ENDPOINT,
              });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isUpdateFormLoading, // temporary param, to be removed ...
                orderOptions(allFilesCollections?.length || 0),
              )
            }
          />
        </FormContainer>
      ) : (
        <Loader label={t(`${translationJSONPrefix}.loadingMessage`)} />
      )}
    </PageContainer>
  );
};

export default FilesCollectionSettings;
