/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
export const getSiteConfigurationEPFactory = (builder) =>
  builder.query({
    query: () => ({
      url: `site-configuration`,
      method: 'GET',
    }),
    providesTags: ['site-configuration'],
  });

export const addSiteConfigurationEPFactory = (builder) =>
  builder.mutation({
    query: (values) => {
      const formData = new FormData();

      Object.keys(values).forEach((v) => {
        formData.append(v, values[v]);
      });

      return {
        url: `site-configuration`,
        method: 'POST',
        body: formData,
      };
    },
    invalidatesTags: ['site-configuration'],
  });

export const updateSiteConfigurationEPFactory = (builder) =>
  builder.mutation({
    query: (changedFields) => {
      const formData = new FormData();

      Object.keys(changedFields).forEach((key) =>
        formData.append(key, changedFields[key]),
      );

      return {
        url: `site-configuration`,
        method: 'PUT',
        body: formData,
      };
    },
    invalidatesTags: ['site-configuration'],
  });
