/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { react, useState, useEffect } from 'react';
import {
  Grid,
  Divider,
  Typography,
  ButtonGroup,
  List,
  ListItem,
  Tooltip,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import AddPropertyDropdown from './components/addNewPropertyDropdown';
import {
  DefineNewPropertyButton,
  HeaderGrid,
  StyledGridContainer,
  DividerContainer,
  StyledLink,
  StyledDeleteButton,
  HeaderTypography,
} from './addMetaData.styled';
import {
  useDeleteIndividualFileMutation,
  useDeleteMetaDataByFileNameMutation,
} from '../../../../redux/services/speciphicAsk';
import ConfirmationDialog from '../../../../components/confirmationDialog';
import * as PATHS from '../../../../constants/path';
import Popup from '../../../../components/popUp';

const AddMetaDataHeaderBar = ({
  fileName,
  isMdOrLarger,
  handleDefineNewProperty,
  definedMetaProperties,
  propertiesData,
  onChangePropertiesData,
  metaData,
  onChangeMetaData,
  editProperties,
  onChangeEditProperties,
  collectionId,
  remainingProperties,
  onChangeremainingProperties,
  isGetMetaPropertiesLoading,
  isAddMetaDataPropertiesLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDeleteFileDialogOpen, setIsDeleteFileDialogOpen] = useState(false);
  const [isAddMetadataInfoModalOpen, setIsAddMetadataInforModalOpen] =
    useState(false);

  const [
    deleteIndividualFile,
    {
      data: deleteFile,
      isSuccess: isDeleteFileSuccess,
      isLoading: isDeleteFileLoading,
      isError: isDeleteFileError,
    },
  ] = useDeleteIndividualFileMutation();

  const [
    deleteMetaDataByFileName,
    {
      data: metaDataByFileName,
      isSuccess: isDeleteMetaDataFileSuccess,
      isLoading: isDeleteMetaDataFileLoading,
      isError: isDeleteMetaDataFileError,
    },
  ] = useDeleteMetaDataByFileNameMutation();

  useEffect(() => {
    if (isDeleteMetaDataFileSuccess) {
      navigate(`${PATHS.FILE_COLLECTIONS}/${collectionId}`, {
        state: {
          id: collectionId,
        },
      });
    }
  }, [isDeleteMetaDataFileSuccess, metaDataByFileName]);

  const handleDeleteFile = () => {
    deleteMetaDataByFileName({
      collectionId: collectionId,
      fileName: fileName,
    });
  };

  const handleCloseDeleteFileDialog = () => {
    setIsDeleteFileDialogOpen(false);
  };

  const handleOpenDeleteFileDialog = (e) => {
    e.stopPropagation();
    setIsDeleteFileDialogOpen(true);
  };

  const handleAddMetadataInfoModalOpen = () => {
    setIsAddMetadataInforModalOpen((prevValue) => !prevValue);
  };

  const addMetadataInfo = () => {
    return (
      <Box>
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            color: 'primary',
            fontWeight: 'bold',
            fontSize: 18,
          }}
        >
          {t('addMetadataPage.headerBar.addMetadataInfo.header')}
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 2.5 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography>
              {t('addMetadataPage.headerBar.addMetadataInfo.step1')}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography>
              {t('addMetadataPage.headerBar.addMetadataInfo.step2')}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography>
              {t('addMetadataPage.headerBar.addMetadataInfo.step3')}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography>
              {t('addMetadataPage.headerBar.addMetadataInfo.step4')}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography>
              {t('addMetadataPage.headerBar.addMetadataInfo.step5')}
            </Typography>
          </ListItem>
        </List>
      </Box>
    );
  };

  return (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} sm={6}>
        <HeaderTypography color="black">{fileName}</HeaderTypography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <AddPropertyDropdown
          definedMetaProperties={definedMetaProperties}
          propertiesData={propertiesData}
          onChangePropertiesData={onChangePropertiesData}
          metaData={metaData}
          onChangeMetaData={onChangeMetaData}
          isMdOrLarger={isMdOrLarger}
          editProperties={editProperties}
          onChangeEditProperties={onChangeEditProperties}
          collectionId={collectionId}
          fileName={fileName}
          remainingProperties={remainingProperties}
          onChangeremainingProperties={onChangeremainingProperties}
          isGetMetaPropertiesLoading={isGetMetaPropertiesLoading}
          isAddMetaDataPropertiesLoading={isAddMetaDataPropertiesLoading}
        />
        <DefineNewPropertyButton
          size="medium"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleDefineNewProperty}
        >
          {t('addMetadataPage.headerBar.defineNewPropertyButtonLabel')}
        </DefineNewPropertyButton>

        <StyledDeleteButton
          variant="contained"
          onClick={(e) => handleOpenDeleteFileDialog(e)}
          size="medium"
          startIcon={
            <DeleteIcon
              sx={{
                fontSize: 28,
              }}
            />
          }
        >
          {t('addMetadataPage.headerBar.deleteButtonText')}
        </StyledDeleteButton>
        <IconButton
          variant="contained"
          onClick={handleAddMetadataInfoModalOpen}
        >
          <Tooltip
            title={t('addMetadataPage.headerBar.addMetadataInfo.header')}
          >
            <InfoIcon
              color="primary"
              sx={{
                cursor: 'pointer',
                fontSize: 32,
              }}
            />
          </Tooltip>
        </IconButton>
        <Popup
          isModalOpen={isAddMetadataInfoModalOpen}
          content={addMetadataInfo()}
          onChangeModalOpen={setIsAddMetadataInforModalOpen}
        />
      </Grid>

      <Grid>
        <ConfirmationDialog
          open={isDeleteFileDialogOpen}
          title={t('addMetadataPage.headerBar.deleteConfirmationTitle')}
          messageLine1={t(
            'addMetadataPage.headerBar.deleteConfirmationMessageLine1',
            {
              fileName,
            },
          )}
          messageLine2=""
          onYesClick={() => handleDeleteFile()}
          onNoClick={handleCloseDeleteFileDialog}
        />
      </Grid>
    </Grid>
  );
};

AddMetaDataHeaderBar.propTypes = {
  fileName: PropTypes.string.isRequired,
  isMdOrLarger: PropTypes.bool.isRequired,
  handleDefineNewProperty: PropTypes.func.isRequired,
  definedMetaProperties: PropTypes.array.isRequired,
  propertiesData: PropTypes.array.isRequired,
  onChangePropertiesData: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired,
  onChangeMetaData: PropTypes.func.isRequired,
  editProperties: PropTypes.bool.isRequired,
  onChangeEditProperties: PropTypes.func.isRequired,
  collectionId: PropTypes.string.isRequired,
  remainingProperties: PropTypes.array.isRequired,
  onChangeremainingProperties: PropTypes.func.isRequired,
  isGetMetaPropertiesLoading: PropTypes.bool.isRequired,
  isAddMetaDataPropertiesLoading: PropTypes.bool.isRequired,
};

export default AddMetaDataHeaderBar;
