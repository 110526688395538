/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
export default (builder) =>
  builder.mutation({
    query: ({ collectionId, prompt, name, description, tags }) => {
      return {
        url: `/file-collections/update-fields/${collectionId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt, name, description, tags }),
      };
    },
    invalidatesTags: ['individualFileCollections', 'fileCollections'],
  });
