/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import {
  Box,
  Chip,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { array, bool, object, string } from 'yup';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import AddPageHeader from '../../../components/form/addPageHeader.component';
import { ACCESS_CONTROL } from '../../../constants/path';
import { POLICIES } from '../../../constants/accessControlPaths';
import AppForm, {
  AppTextField,
  FormButtons,
  ToogleContainer,
  AppAutocompleteField,
} from '../../../components/form';
import { getChangedValues } from '../../../components/form/functions';
import {
  FormContainer,
  InputsContainer,
  ToogleButtonsContainer,
} from '../styled';
import {
  useCreateRBACMutation,
  useLazyGetOneRBACQuery,
  useLazyGetPermissionsQuery,
  useUpdateRBACMutation,
} from '../../../redux/services/speciphicAsk';
import { RBAC_POLICIES_ENDPOINT } from '../../../constants/accessControl';
import { AlertStyled } from '../../settings/filesCollectionSettingsPage/styled';
import Loader from '../../../components/loader/loader.component';
import colors from '../../../themes/colors';

const mainPageRoute = `${ACCESS_CONTROL}${POLICIES.endpoint}`;

const translationJSONPrefix = 'accessControlSection.policies';

const defaultDataState = {
  name: '',
  effect: false,
  description: '',
  resource: '',
  actions: [],
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.form.fields.${label}.errorMessages`;

  return object({
    name: string()
      .required(t(`${translationPrefix('name')}.required`))
      .min(3, t(`${translationPrefix('name')}.min`))
      .max(250, t(`${translationPrefix('name')}.max`)),
    effect: bool(),
    description: string()
      .min(4, t(`${translationPrefix('description')}.min`))
      .max(250, t(`${translationPrefix('description')}.min`)),
    resource: string().required(t(`${translationPrefix('resource')}.required`)),
    actions: array().min(1, t(`${translationPrefix('actions')}.min`)),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  isEdit,
  fetchingPermissions,
  permissionOptions,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.form.fields`;

  const renderOption = (props, option) => (
    <ListItem
      {...props}
      key={`${option?.resource}-${option?.group}-${option?.action}`}
      component="div"
      button
    >
      <ListItemText
        primary={
          option?.resource === '*'
            ? '*'
            : `${option?.resource} - ${option?.action}`
        }
        secondary={
          <>
            <Typography component="span" variant="body2" color="text.primary">
              {option?.group}
            </Typography>
            {` — ${option?.description}`}
          </>
        }
      />
    </ListItem>
  );

  return (
    <Form>
      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="name"
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          inputProps={{ readOnly: isEdit }}
        />
      </InputsContainer>

      <ToogleButtonsContainer>
        <ToogleContainer
          name="effect"
          label={t(`${translationPrefix}.effect.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          tooltipTitle={t(`${translationPrefix}.effect.tooltipTitle`)}
        />
        <Box
          style={{
            color: values['effect'] ? colors.green.G400 : colors.red.R400,
          }}
        >
          {values['effect']
            ? t(`${translationPrefix}.effect.allow`)
            : t(`${translationPrefix}.effect.deny`)}
        </Box>
      </ToogleButtonsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="description"
          label={t(`${translationPrefix}.description.label`)}
          placeholder={t(`${translationPrefix}.description.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          multiline
          minRows={3}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="resource"
          label={t(`${translationPrefix}.resource.label`)}
          placeholder={t(`${translationPrefix}.resource.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          style={{ width: '100%' }}
          name="actions"
          label={t(`${translationPrefix}.actions.label`)}
          placeholder={
            fetchingPermissions
              ? t(`${translationPrefix}.actions.dropdownDataLoading`)
              : `${t(`${translationPrefix}.actions.placeholder`)}${
                  values['actions']?.length === 0
                    ? t(`${translationPrefix}.actions.placeholderExtension`)
                    : ''
                }`
          }
          options={permissionOptions.filter((op) => {
            const resource = values['resource'].split(':')[0];

            if (resource === '*') return true;
            return op?.resource === resource;
          })}
          getOptionLabel={(option) =>
            `${option?.resource} - ${option?.group} - ${option?.action}`
          }
          errors={errors}
          touched={touched}
          onChange={(e, value) =>
            setFieldValue(
              'actions',
              value.filter((v) => v?.resource?.length > 0),
            )
          }
          values={values}
          renderOption={renderOption}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          isOptionEqualToValue={(option, value) =>
            option?.action === value?.action &&
            option?.resource === value?.resource
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={`${option?.resource}-${option?.group}-${option?.action}`}
                label={
                  option?.resource === '*'
                    ? '*'
                    : `${option?.resource}:${option?.action}`
                }
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </InputsContainer>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const AccessControlAddPoliciesPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const transformPermissionsToOptions = (permissions) => {
    const transformedOptions = [];
    for (const resource in permissions) {
      for (const group in permissions[resource].actions) {
        permissions[resource].actions[group].forEach((action) => {
          transformedOptions.push({
            resource,
            group,
            action: action.action,
            description: action.description,
          });
        });
      }
    }
    return transformedOptions;
  };

  const [
    getPermissions,
    {
      data: permissionsData,
      isFetching: fetchingPermissions,
      isError: isPermissionsError,
      isLoading: isPermissionsLoading,
      isSuccess: isPermissionsSuccess,
    },
  ] = useLazyGetPermissionsQuery();

  const [
    createRBAC,
    {
      data: createdData,
      error: createError,
      isError: isCreateError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateRBACMutation();

  const [
    updateRBAC,
    {
      data: updatedData,
      error: updateError,
      isError: isUpdateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateRBACMutation();

  const [
    getRBAC,
    {
      data: rbacData,
      isError: isDataError,
      isLoading: isDataLoading,
      isSuccess: isDataSuccess,
    },
  ] = useLazyGetOneRBACQuery();

  useEffect(() => {
    getPermissions();
    const tempEntityId = searchParams.get('n');

    if (tempEntityId) {
      setIsEdit(true);
      getRBAC({ endpoint: RBAC_POLICIES_ENDPOINT, id: tempEntityId });
    }
  }, []);

  useEffect(() => {
    if (permissionsData)
      setPermissionOptions(transformPermissionsToOptions(permissionsData));
  }, [permissionsData]);

  useEffect(() => {
    if (isDataSuccess && rbacData && permissionOptions) {
      const tempFormData = {
        name: rbacData?.name,
        effect: rbacData?.effect === 'allow' ? true : false,
        resource: rbacData?.srn || [],
        description: rbacData?.description || '',
        actions: rbacData?.actions.map((act) => {
          if (act === '*')
            return permissionOptions?.filter((po) => po?.resource === '*')[0];

          return permissionOptions?.filter(
            (po) => act === `${po?.resource}:${po?.action}`,
          )[0];
        }),
      };

      setFormData(tempFormData);
      setInitialFormData(tempFormData);
    }
  }, [rbacData, permissionOptions]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
    setErrorMessage('');
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateError || isUpdateError) {
      const tempErrorMessage =
        createError?.data?.message ||
        updateError?.data?.message ||
        t(`${translationJSONPrefix}.form.errorAlertLabel`);
      setErrorMessage(tempErrorMessage);
      setTimeout(() => {
        tempAlert.formUpdateError = true;
        tempAlert.formUpdateSuccess = false;
        setShowAlert(tempAlert);
      }, 500);
    }
  }, [isCreateError, isUpdateError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateSuccess || isUpdateSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
      getRBAC({
        endpoint: RBAC_POLICIES_ENDPOINT,
        id: createdData?.name || updatedData?.name,
      });
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  return (
    <PageContainer drawer={SECTIONS.ACCESS_CONTROL}>
      <AddPageHeader
        routes={[
          {
            title: t(`${translationJSONPrefix}.mainPage.title`),
            endpoint: mainPageRoute,
          },
        ]}
        isEdit={isEdit}
      />

      <FormContainer>
        <AlertStyled
          onClose={closeAlert}
          severity={showAlert.formUpdateError ? 'error' : 'success'}
          style={{
            marginTop:
              showAlert.formUpdateError || showAlert.formUpdateSuccess
                ? 10
                : -60,
          }}
        >
          {showAlert.formUpdateSuccess &&
            (isEdit
              ? t(`${translationJSONPrefix}.form.updatedAlertLabel`)
              : t(`${translationJSONPrefix}.form.createdAlertLabel`))}
          {showAlert.formUpdateError &&
            (errorMessage ||
              t(`${translationJSONPrefix}.form.errorAlertLabel`))}
        </AlertStyled>

        {isDataLoading ? (
          <Loader label={t(`${translationJSONPrefix}.form.loadingLabel`)} />
        ) : (
          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSend = isEdit
                ? getChangedValues(values, initialFormData)
                : { ...values };

              if (!isEdit || initialFormData.effect !== values.effect)
                valuesToSend.effect = values.effect ? 'allow' : 'deny';

              if (valuesToSend.actions) {
                if (
                  valuesToSend['actions'].some((act) => act?.resource === '*')
                ) {
                  valuesToSend['actions'] = ['*']; // Replace with '*' if any action has resource '*'
                  values['actions'] = values['actions'].filter(
                    (act) => act?.resource === '*',
                  );
                } else {
                  // Create a new array to avoid modifying original valuesToSend['actions']
                  valuesToSend['actions'] = valuesToSend['actions'].map(
                    (act) =>
                      `${act?.resource}:${
                        act?.action === '*' ? '*' : act?.action
                      }`,
                  );
                }
              }

              if (isEdit)
                updateRBAC({
                  endpoint: RBAC_POLICIES_ENDPOINT,
                  id: values['name'],
                  update: { ...valuesToSend },
                });
              else
                createRBAC({
                  endpoint: RBAC_POLICIES_ENDPOINT,
                  body: {
                    ...valuesToSend,
                    type: t(`${translationJSONPrefix}.form.defaultType`),
                  },
                });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isCreateLoading || isUpdateLoading,
                isEdit,
                fetchingPermissions,
                permissionOptions,
              )
            }
          />
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default AccessControlAddPoliciesPage;
