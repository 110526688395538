/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { isRejectedWithValue } from '@reduxjs/toolkit';
import { pushToast } from './reducers/toasts.slice';

export const rtkQueryErrorMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      switch (action.payload.status) {
        case 401: {
          console.error('Unauthorized !');
          dispatch(
            pushToast({
              message:
                'Your Session has timed out. Kindly relogin to continue.',
              severity: 'error',
            }),
          );
          break;
        }
        case 403: {
          console.error('Insufficient Permission !');
          dispatch(
            pushToast({
              message:
                'You do not have sufficient permissions to carry out the request.',
              severity: 'error',
            }),
          );
          break;
        }
        case 500: {
          console.error('Internal Server Error');
          dispatch(
            pushToast({
              message: 'Oops! Some error has occured. Please try again later.',
              severity: 'error',
            }),
          );
          break;
        }
        default: {
          console.error('Internal Server Error');
          break;
        }
      }
    }

    return next(action);
  };
