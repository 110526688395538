/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, Button, Dialog, DialogTitle, styled } from '@mui/material';

export const StyledCancelButton = styled(Button)({});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('sm')]: {
    width: '85%',
  },
}));

export const PdfBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'auto',
});

export const DialogHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const DocPreviewWrapper = styled(Box)(({ theme, height }) => ({
  height: height * 0.8,
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const IframeStyled = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: 'none',
  margin: 'auto',
  display: 'block',
  textAlign: 'center',
}));
