/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
export default (builder) =>
  builder.mutation({
    query: ({
      dataSourceId,
      dataFileCollectionId,
      reportFileCollectionId,
    }) => ({
      url: `site-configuration/market-intelligence`,
      method: 'PATCH',
      body: {
        dataSourceId: dataSourceId,
        dataFileCollectionId: dataFileCollectionId,
        reportFileCollectionId: reportFileCollectionId,
      },
      'Content-Type': 'application/json',
    }),
  });
