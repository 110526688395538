/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { useState } from 'react';
import { Dialog, Grid, Box } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  StyledToolbarGrid,
  StyledFileUploadButton,
} from './reportSummaryTopics.styled';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AddSummaryTopic from './addReportSummaryTopic.component/addSummaryTopic.component';
const CustomToolbar = ({ feature }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <StyledToolbarGrid container>
        <Grid item xs={12} sm={4} md={'auto'}>
          <GridToolbarQuickFilter />
        </Grid>
        <Grid item xs={12} sm={4} md={'auto'}>
          <StyledFileUploadButton
            variant="contained"
            size="medium"
            onClick={handleOpen}
            startIcon={<Add />}
          >
            {t('reportSummaryTopicsPage.dataGrid.customToolbar.add')}
          </StyledFileUploadButton>
        </Grid>
      </StyledToolbarGrid>

      <Dialog
        onClose={handleClose}
        open={open}
        style={{ width: '600px', margin: 'auto' }}
      >
        <AddSummaryTopic handleClose={handleClose} feature={feature} />
      </Dialog>
    </Box>
  );
};

CustomToolbar.propTypes = {
  feature: PropTypes.string,
};

export default CustomToolbar;
