import { Box, Chip, Typography, styled } from '@mui/material';
import fontWeights from '../../../themes/fontWeights';

export const SuggestionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: fontWeights.medium,
  paddingTop: theme.spacing(0.5),
}));

export const SuggestionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
  marginTop: theme.spacing(1),
  marginX: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& #nested-list-subheader': {
    fontWeight: 300,
    fontSize: '26px',
    marginBottom: '20px',
    backgroundColor: 'transparent',
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  backgroundColor: 'inherit',
  fontSize: theme.typography.fontSize,
  borderRadius: '25px',
  height: 'auto',
  cursor: 'pointer',
  '& .MuiChip-label': {
    display: 'block',
    whiteSpace: 'normal',
  },
  '&.MuiChip-root': {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));
