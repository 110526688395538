/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import {
  Box,
  styled,
  Avatar,
  Typography,
  TextField,
  List,
  IconButton,
  ListItem,
} from '@mui/material';
import colors from '../../themes/colors';
import { ListItemIconStyled } from '../../components/pageContainer/pageContainer.styled';

export const ConversationContainer = styled(Box)(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  width: '55%',
  height: '100%',
  minHeight: '80vh',
  paddingBottom: '50px',
  paddingLeft: open ? '100px' : 0,
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

export const ConversationList = styled(Box)(({ theme }) => ({
  width: '100%',
}));

export const TextFieldContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  paddingBottom: '30px',
  [theme.breakpoints.up('md')]: {
    bottom: '45px',
  },
  [theme.breakpoints.down('md')]: {
    bottom: '80px',
    width: '80%',
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: colors.neutralLight.N0,
  marginRight: theme.spacing(2),
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  height: '100%',
  paddingBottom: '24px',
  paddingTop: 0,
  paddingLeft: 0,
  paddingRight: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}));

export const StyledListItem = styled(ListItem)(({ theme, role }) => ({
  paddingRight: role === 'user' ? 0 : '70px',
  alignItems: role === 'user' ? 'center' : 'flex-start',
}));

export const AvatarLetterWrap = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 'bold',
  color: theme.palette.primary.contrastText,
}));

export const ExitButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '50px',
  color: theme.palette.primary.main,
}));

export const CloseDrawerButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '0px',
  color: theme.palette.primary.main,
  top: '70px',
}));

export const OpenDrawerButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '50px',
  color: theme.palette.primary.main,
}));

export const LoadingTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));
