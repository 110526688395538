/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Box,
  ThemeProvider,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import StorageIcon from '@mui/icons-material/Storage';
import {
  LoadingIndicator,
  MenuBox,
  ShadowBox,
  StyledFormControl,
  TagsChip,
} from './miConfiguration.styled';
import PageContainer from '../../../components/pageContainer';
import MiTabs from '../../../components/marketIntelligence/miTabs';
import {
  useAddMarketIntelligenceConfigurationMutation,
  useGetDataSourceQuery,
  useLazyGetAllFileCollectionsForTableQuery,
} from '../../../redux/services/speciphicAsk';
import { SiteConfigContext } from '../../../hooks/useSiteConfigContext';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import UnauthorizedMessage from '../../../components/unauthorized';
import { AWS_S3_TYPE, AZURE_BLOB_TYPE } from '../../../constants/dataSources';
import { SECTIONS } from '../../../constants/drawer';
import MiRelatedEvents from '../../../components/marketIntelligence/miRelatedEvents';
import MiNavigationDropdown from '../../../components/marketIntelligence/miNavigationDropdown';
import MiReportStats from '../../../components/marketIntelligence/miReportStats';
import { getTheme } from '../../../themes';
import DialogComponent from '../../../components/app.dialog';

const translationJSONPrefix = `appConfigurationSection.marketIntelligence`;
const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const MiConfigurationPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dataSourceList, setDataSourceList] = useState([]);
  const [reportFileCollectionList, setReportFileCollectionList] = useState([]);
  const [dataFileCollectionList, setDataFileCollectionList] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    dataSourceId: '',
    reportFileCollectionId: '',
    dataFileCollectionId: '',
  });
  const [marketConfig, setMarketConfig] = useState({});
  const { getMarketIntelligenceConfig } = useContext(SiteConfigContext);

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  useEffect(() => {
    const marketIntelligenceConfig = getMarketIntelligenceConfig();

    if (marketIntelligenceConfig) {
      setMarketConfig(marketIntelligenceConfig);
      setSelectedValues({
        dataSourceId: marketIntelligenceConfig?.process_data_source_id,
        reportFileCollectionId:
          marketIntelligenceConfig?.process_report_file_collection_id,
        dataFileCollectionId:
          marketIntelligenceConfig?.process_data_file_collection_id,
      });
    }
  }, [getMarketIntelligenceConfig()]);

  useEffect(() => {
    if (marketConfig) {
      setSelectedValues({
        dataSourceId: marketConfig.process_data_source_id || '',
        dataFileCollectionId:
          marketConfig.process_data_file_collection_id || '',
        reportFileCollectionId:
          marketConfig.process_report_file_collection_id || '',
      });
    }
  }, [marketConfig]);

  const {
    data: dataSources,
    error: dataSourcesError,
    isSuccess: isGetDataSourceSuccess,
    isLoading: isGetDataSourceLoading,
    isError: isGetDataSourceError,
  } = useGetDataSourceQuery({});

  const [
    getFileCollections,
    {
      data: fileCollections = [],
      error: fileCollectionsError,
      isError: isGetFileCollectionsError,
      isLoading: isGetFileCollectionsLoading,
      isSuccess: isGetFileCollectionsSuccess,
    },
  ] = useLazyGetAllFileCollectionsForTableQuery();

  useEffect(() => {
    getFileCollections({
      query: '',
      pageNo: 1,
      pageSize: 150,
    });
  }, []);

  const [
    addMarketIntelligenceConfiguration,
    {
      data: marketIntelligenceConfiguration,
      error: marketIntelligenceConfigError,
      isSuccess: isAddMarketIntelligenceConfigurationSuccess,
      isLoading: isAddMarketIntelligenceConfigurationLoading,
      isError: isAddMarketIntelligenceConfigurationError,
    },
  ] = useAddMarketIntelligenceConfigurationMutation();

  useEffect(() => {
    if (dataSources) {
      const filteredDataSources = dataSources?.data
        ?.filter(
          (source) =>
            source.type === AZURE_BLOB_TYPE || source.type === AWS_S3_TYPE,
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      setDataSourceList(filteredDataSources);
    }
  }, [dataSources]);

  useEffect(() => {
    if (fileCollections) {
      setDataFileCollectionList(fileCollections?.data);
      setReportFileCollectionList(fileCollections?.data);
    }
  }, [fileCollections, isGetFileCollectionsSuccess]);

  useEffect(() => {
    if (isAddMarketIntelligenceConfigurationSuccess) {
      dispatch(
        pushToast({
          message: t(`${translationJSONPrefix}.alertMessages.configSuccess`),
          severity: 'success',
        }),
      );
    }
  }, [isAddMarketIntelligenceConfigurationSuccess]);

  const handleValueChange = (event, stateVariable) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [stateVariable]: event.target.value,
    }));
  };

  const handleFinalSubmit = () => {
    addMarketIntelligenceConfiguration({
      dataSourceId: selectedValues?.dataSourceId,
      dataFileCollectionId: selectedValues?.dataFileCollectionId,
      reportFileCollectionId: selectedValues?.reportFileCollectionId,
    });

    setAlertDialogOpen(false);
  };

  const handleSubmitForm = () => {
    if (marketConfig?.process_data_source_id !== selectedValues?.dataSourceId) {
      setAlertDialogOpen(true);
      return;
    }

    handleFinalSubmit();
  };

  const isValuesChanged = () => {
    return (
      marketConfig?.process_data_source_id !== selectedValues?.dataSourceId ||
      marketConfig?.process_report_file_collection_id !==
        selectedValues?.reportFileCollectionId ||
      marketConfig?.process_data_file_collection_id !==
        selectedValues?.dataFileCollectionId
    );
  };

  const formatDataSourceName = (dataSource) => {
    const formattedType = dataSource
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return formattedType;
  };
  if (
    dataSourcesError?.status === 403 ||
    fileCollectionsError?.status === 403 ||
    marketIntelligenceConfigError?.status === 403
  )
    return (
      <UnauthorizedMessage
        drawer={SECTIONS.APP_CONFIGURATION}
        //isDrawerOpen={isDrawerOpen}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <ThemeProvider theme={getTheme('newMi')}>
      <PageContainer background={'secondary'}>
        <DialogComponent
          open={alertDialogOpen}
          setOpen={setAlertDialogOpen}
          title={t(`${translationJSONPrefix}.alertDialog.title`)}
          content={t(`${translationJSONPrefix}.alertDialog.content`)}
          onSubmit={(e) => {
            handleFinalSubmit();
          }}
          isDeleteLoading={isAddMarketIntelligenceConfigurationLoading}
          cancelBtnLabel={t(
            `${translationJSONPrefix}.alertDialog.buttons.cancel`,
          )}
          submitBtnLabel={t(`${translationJSONPrefix}.alertDialog.buttons.yes`)}
        />
        <ShadowBox>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item>
              <MiTabs title={'Market Intelligence'} disableTabs />
            </Grid>
            <Grid item>
              <MiNavigationDropdown />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} p={{ lg: 1 }}>
              <Paper
                elevation={2}
                square={false}
                sx={{ backgroundColor: 'white', borderRadius: 2 }}
              >
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginX: 2,
                  }}
                >
                  <Typography variant="h4" sx={{ margin: 2 }}>
                    {t(`newMarketIntelligencePage.configuration.label`)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledFormControl fullWidth size="small">
                      <InputLabel>
                        {t(`${translationJSONPrefix}.dataSourceInputLabel`)}
                      </InputLabel>
                      <Select
                        value={selectedValues?.dataSourceId}
                        onChange={(event) =>
                          handleValueChange(event, 'dataSourceId')
                        }
                        fullWidth
                        label={t(
                          `${translationJSONPrefix}.processDataSourcesLabel`,
                        )}
                        size="small"
                        sx={{ backgroundColor: '#EDF2FA', borderRadius: 2 }}
                      >
                        {dataSourceList.map((source, index) => (
                          <MenuItem key={index} value={source?.id}>
                            <MenuBox>
                              <Typography>{source?.name}</Typography>
                              <TagsChip
                                icon={<StorageIcon />}
                                label={formatDataSourceName(source.type)}
                              />
                            </MenuBox>
                          </MenuItem>
                        ))}
                      </Select>
                      {isGetDataSourceLoading && <LoadingIndicator />}
                    </StyledFormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledFormControl fullWidth size="small">
                      <InputLabel>
                        {t(`${translationJSONPrefix}.fileInputLabel`)}
                      </InputLabel>
                      <Select
                        value={selectedValues?.dataFileCollectionId}
                        onChange={(event) =>
                          handleValueChange(event, 'dataFileCollectionId')
                        }
                        fullWidth
                        label={t(
                          `${translationJSONPrefix}.processDataFileCollection`,
                        )}
                        size="small"
                        sx={{ backgroundColor: '#EDF2FA', borderRadius: 2 }}
                      >
                        {dataFileCollectionList?.map((source, index) => (
                          <MenuItem key={index} value={source?.id}>
                            {source?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {isGetFileCollectionsLoading && <LoadingIndicator />}
                    </StyledFormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledFormControl fullWidth size="small">
                      <InputLabel>
                        {t(`${translationJSONPrefix}.reportInputLabel`)}
                      </InputLabel>
                      <Select
                        value={selectedValues?.reportFileCollectionId}
                        onChange={(event) =>
                          handleValueChange(event, 'reportFileCollectionId')
                        }
                        fullWidth
                        label={t(
                          `${translationJSONPrefix}.reportFileCollection`,
                        )}
                        size="small"
                        sx={{ backgroundColor: '#EDF2FA', borderRadius: 2 }}
                      >
                        {reportFileCollectionList?.map((source, index) => (
                          <MenuItem key={index} value={source?.id}>
                            {source?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {isGetFileCollectionsLoading && <LoadingIndicator />}
                    </StyledFormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ float: 'right' }}>
                      <LoadingButton
                        size="small"
                        variant="contained"
                        disabled={!isValuesChanged()}
                        onClick={handleSubmitForm}
                        loading={isAddMarketIntelligenceConfigurationLoading}
                      >
                        {t(`${translationJSONPrefix}.submitButton`)}
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {/* <Grid
              container
              item
              lg={3}
              xs={12}
              spacing={1}
              justifyContent={'flex-start'}
            >
              <Grid item p={{ lg: 1 }}>
                <MiReportStats />
              </Grid>
              <Grid item p={{ lg: 1 }}>
                <MiRelatedEvents />
              </Grid>
            </Grid> */}
          </Grid>
        </ShadowBox>
      </PageContainer>
    </ThemeProvider>
  );
};

export default MiConfigurationPage;
