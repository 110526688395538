/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import palette from './red/palette';
import fontWeights from './fontWeights';

const typography = {
  fontFamily: 'source-sans-pro',
  textTransform: 'none',
  fontSize: 14,
  htmlFontSize: 14,
  h1: {
    color: palette.text.primary,
    fontSize: '3.571rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '5.357rem',
  },
  h2: {
    color: palette.text.primary,
    fontSize: '2.142rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '3.214rem',
  },
  h3: {
    color: palette.text.primary,
    fontSize: '1.785rem',
    fontWeight: fontWeights.medium,
    lineHeight: '2.714rem',
  },
  h4: {
    color: palette.text.primary,
    fontSize: '1.714rem',
    fontWeight: fontWeights.regular,
    lineHeight: '2.571rem',
  },
  h5: {
    color: palette.text.primary,
    fontSize: '1rem',
    fontWeight: fontWeights.medium,
    lineHeight: '1.5rem',
  },
  h6: {
    color: palette.text.primary,
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.5rem',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '0.857rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '1.143rem',
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: '0.786rem',
    fontWeight: fontWeights.bold,
    lineHeight: '1.143rem',
  },
  button: {
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.5rem',
    textTransform: 'none',
  },
  body1: {
    color: palette.text.secondary,
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.428rem',
  },
  body2: {
    color: `${palette.text.light} !important`,
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.428rem',
  },
  caption: {
    color: palette.text.caption,
    fontSize: '0.785rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.142rem',
  },
};

export default typography;
