/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { React, useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import AppShadowBox from '../../../../components/app.shadowbox';
import DefinePropertyPage from './components/defineNewPropertyDialog';
import ConfirmationDialog from '../../../../components/confirmationDialog';
import { useDeleteIndividualFileMutation } from '../../../../redux/services/speciphicAsk';
import * as PATHS from '../../../../constants/path';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../../redux/reducers/toasts.slice';
import {
  useGetMetaDataPropertiesQuery,
  useAddMetaDataPropertiesMutation,
  useAddMetaDataMutation,
  useGetMetaDataByFileNameQuery,
  useDeleteMetaDataPropertyMutation,
} from '../../../../redux/services/speciphicAsk';
import MetaDataHeaderBar from './addMetaDataHeaderBar';
import DisplayProperties from './displayProperties';
import PageContainer from '../../../../components/pageContainer';

const createPropertiesData = (metaData) => {
  const propertiesData = [];

  if (metaData && typeof metaData === 'object') {
    Object.entries(metaData)?.forEach(([propertyName, propertyDetails]) => {
      const { dataType, displayName, mutation, propertyValue } =
        propertyDetails;
      const mutationValue = mutation !== undefined ? mutation : true;
      if (propertyValue !== undefined && propertyValue !== null) {
        propertiesData.push({
          propertyName,
          displayName,
          dataType,
          mutation: mutationValue,
          propertyValue,
        });
      }
    });
  }

  return propertiesData;
};

const AddMetadataPage = () => {
  const { collectionId, fileName } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isRefetchMetaDataProperties, setIsRefetchMetaDataProperties] =
    useState(false);
  const [isAddProperty, setIsAddProperty] = useState(false);
  const [propertyData, setPropertyData] = useState({
    propertyName: '',
    displayName: '',
    dataType: '',
    propertyValue: '',
    mutation: true,
  });
  const [selectedProperty, setSelectedProperty] = useState({});
  const [propertiesData, setPropertiesData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [editProperties, setEditProperties] = useState({});
  const [remainingProperties, setremainingProperties] = useState({});
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const {
    data: definedMetaProperties = [],
    isError: isGetMetaPropertiesError,
    isLoading: isGetMetaPropertiesLoading,
    isSuccess: isGetMetaPropertiesSuccess,
    refetch: refetchGetMetaDataProperties,
  } = useGetMetaDataPropertiesQuery(
    {
      collectionId: collectionId,
    },
    {
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      refetchOnWindowFocus: 'always',
    },
  );
  const [
    addMetaData,
    {
      data: emptyResult = [],
      isError: isAddMetaDataError,
      isLoading: isAddMetaDataLoading,
      isSuccess: isAddMetaDataSuccess,
    },
  ] = useAddMetaDataMutation();

  const [
    deleteIndividualFile,
    {
      data: deleteFile,
      isSuccess: isDeleteFileSuccess,
      isLoading: isDeleteFileLoading,
      isError: isDeleteFileError,
    },
  ] = useDeleteIndividualFileMutation();

  const [
    deleteProperty,
    {
      data: property,
      isSuccess: isDeletePropertySuccess,
      isLoading: isDeletePropertyLoading,
      isError: isDeletePropertyError,
    },
  ] = useDeleteMetaDataPropertyMutation();

  const {
    data: metaDataByFileName = [],
    isError: isGetMetaDataByFileNameError,
    isLoading: isGetMetaDataByFileNameLoading,
    isSuccess: isGetMetaDataByFileNameSuccess,
  } = useGetMetaDataByFileNameQuery({
    collectionId,
    fileName,
  });

  const [
    addMetaProperties,
    {
      data: updatedMetaDataProperties,
      isSuccess: isAddMetaDataPropertiesSuccess,
      isLoading: isAddMetaDataPropertiesLoading,
      isError: isAddMetaDataPropertiesError,
    },
  ] = useAddMetaDataPropertiesMutation();

  useEffect(() => {
    if (isGetMetaDataByFileNameSuccess && isGetMetaPropertiesSuccess) {
      const newPropertiesData = createPropertiesData(metaDataByFileName);

      setPropertiesData(newPropertiesData);
    }
  }, [
    isGetMetaDataByFileNameSuccess,
    isGetMetaPropertiesSuccess,
    metaDataByFileName,
  ]);

  useEffect(() => {
    if (isAddMetaDataPropertiesSuccess) {
      refetchGetMetaDataProperties();
    }
  }, [isAddMetaDataPropertiesSuccess, isAddMetaDataPropertiesLoading]);

  useEffect(() => {
    const updatedPropertiesOnChange = {};
    propertiesData?.forEach((property) => {
      updatedPropertiesOnChange[property.propertyName] = {
        isEdit: false,
      };
    });
    setEditProperties(updatedPropertiesOnChange);
  }, [propertiesData]);

  useEffect(() => {
    if (isGetMetaPropertiesSuccess) {
      const newremainingProperties = { ...definedMetaProperties };
      propertiesData?.forEach((property) => {
        delete newremainingProperties[property.propertyName];
      });
      setremainingProperties(newremainingProperties);
    }
  }, [isGetMetaPropertiesSuccess, definedMetaProperties, propertiesData]);

  useEffect(() => {
    if (isAddMetaDataPropertiesSuccess) {
      dispatch(
        pushToast({
          message: t('addMetadataPage.addPropertySuccessMessage', {
            displayName: propertyData.displayName,
          }),
          severity: 'success',
        }),
      );
    }
    setPropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
    });
  }, [isAddMetaDataPropertiesSuccess, updatedMetaDataProperties]);

  useEffect(() => {
    if (isAddMetaDataPropertiesError) {
      dispatch(
        pushToast({
          message: t('addMetadataPage.failedToAddPropertyMessage', {
            displayName: propertyData.displayName,
          }),
          severity: 'error',
        }),
      );
    }
    setPropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
    });
  }, [isAddMetaDataPropertiesError]);

  const isMdScreenOrLarger = () => {
    return useMediaQuery((theme) => theme.breakpoints.up('md'));
  };

  const isMdOrLarger = isMdScreenOrLarger();

  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const handleDeleteProperty = () => {
    if (!selectedProperty) {
      return;
    }
    const updatedPropertiesOnChange = { ...editProperties };
    delete updatedPropertiesOnChange[selectedProperty.propertyName];
    setEditProperties(updatedPropertiesOnChange);

    const updatedPropertiesData = propertiesData.filter(
      (property) => property.propertyName !== selectedProperty.propertyName,
    );
    setPropertiesData(updatedPropertiesData);
    setDeleteDialogOpen(false);
    setSelectedProperty({});

    deleteProperty({
      collectionId,
      fileName,
      propertyName: selectedProperty.propertyName,
    });
  };

  const handleOpenDeletePropertyDialog = (propertyItem) => {
    setDeleteDialogOpen(true);
    setSelectedProperty(propertyItem);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleEditProperty = (propertyItem) => {
    setPropertyData({
      propertyName: propertyItem.propertyName,
      propertyValue: propertyItem.propertyValue,
      displayName: propertyItem.displayName,
      dataType: propertyItem.dataType,
    });
    setEditProperties((prevProperties) => ({
      ...prevProperties,
      [propertyItem.propertyName]: {
        ...prevProperties[propertyItem.propertyName],
        isEdit: true,
      },
    }));
  };

  const handleSaveProperty = () => {
    const updatedPropertiesOnChange = { ...editProperties };
    propertiesData?.forEach((property) => {
      if (!updatedPropertiesOnChange[property.propertyName]) {
        updatedPropertiesOnChange[property.propertyName] = {
          isEdit: false,
        };
      } else {
        updatedPropertiesOnChange[property.propertyName].isEdit = false;
      }
    });
    setEditProperties(updatedPropertiesOnChange);

    const updatedPropertiesData = propertiesData.map((property) => {
      if (property.propertyName === propertyData.propertyName) {
        return {
          ...property,
          propertyValue: propertyData.propertyValue,
        };
      }
      return property;
    });

    addMetaData({
      collectionId: collectionId,
      metaData,
      fileName: fileName,
    });

    setPropertiesData(updatedPropertiesData);
    setPropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
      propertyValue: '',
      mutation: true,
    });
  };

  const handleInputChange = (propertyItem, propertyValue) => {
    setPropertyData((prevPropertyData) => ({
      ...prevPropertyData,
      propertyName: propertyItem.propertyName,
      propertyValue: propertyValue,
      displayName: propertyItem.displayName,
      dataType: propertyItem.dataType,
    }));
    const updatedMetaData = { ...metaData };
    updatedMetaData[propertyItem.propertyName] = propertyValue;
    setMetaData(updatedMetaData);
  };

  const handleDefineNewProperty = () => {
    setIsAddProperty(true);
    setPropertyData({
      propertyName: '',
      displayName: '',
      dataType: '',
      propertyValue: '',
      mutation: true,
    });
  };

  const handleDeleteFile = (e, file) => {
    e.stopPropagation();
    deleteIndividualFile({
      collectionId: collectionId,
      originalFileName: fileName,
    });

    navigate(PATHS.FILE_COLLECTIONS);
  };

  return (
    <PageContainer>
      <AppShadowBox>
        <Grid container item>
          <MetaDataHeaderBar
            fileName={fileName}
            isMdOrLarger={isMdOrLarger}
            isXsScreen={isXsScreen}
            handleDefineNewProperty={handleDefineNewProperty}
            handleDeleteFile={handleDeleteFile}
            definedMetaProperties={definedMetaProperties}
            propertiesData={propertiesData}
            onChangePropertiesData={setPropertiesData}
            metaData={metaData}
            onChangeMetaData={setMetaData}
            editProperties={editProperties}
            onChangeEditProperties={setEditProperties}
            collectionId={collectionId}
            remainingProperties={remainingProperties}
            onChangeremainingProperties={setremainingProperties}
            isGetMetaPropertiesLoading={isGetMetaPropertiesLoading}
            isAddMetaDataPropertiesLoading={isAddMetaDataPropertiesLoading}
          />
        </Grid>
        {isAddProperty && (
          <Grid>
            <DefinePropertyPage
              isAddPropertyOpen={isAddProperty}
              onCloseAddProperty={setIsAddProperty}
              propertiesData={propertiesData}
              onChangePropertiesData={setPropertiesData}
              propertyData={propertyData}
              onChangePropertyData={setPropertyData}
              collectionId={collectionId}
              isGetMetaPropertiesSuccess={isGetMetaPropertiesSuccess}
              definedMetaProperties={definedMetaProperties}
              addMetaProperties={addMetaProperties}
              isAddMetaDataPropertiesSuccess={isAddMetaDataPropertiesSuccess}
              isAddMetaDataPropertiesLoading={isAddMetaDataPropertiesLoading}
              isAddMetaDataPropertiesError={isAddMetaDataPropertiesError}
              isSubmitButtonDisabled={isSubmitButtonDisabled}
              onChangeSubmitButtonDisabled={setIsSubmitButtonDisabled}
            />
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
        >
          <DisplayProperties
            editProperties={editProperties}
            onChangeEditProperties={setEditProperties}
            propertiesData={propertiesData}
            handleInputChange={handleInputChange}
            handleEditProperty={handleEditProperty}
            handleOpenDeletePropertyDialog={handleOpenDeletePropertyDialog}
            handleSaveProperty={handleSaveProperty}
            propertyData={propertyData}
            isGetMetaDataByFileNameLoading={isGetMetaDataByFileNameLoading}
          />
        </Grid>

        <Grid>
          <ConfirmationDialog
            open={isDeleteDialogOpen}
            title={t('addMetadataPage.deleteConfirmationTitle')}
            messageLine1={t('addMetadataPage.deleteConfirmationMessageLine1', {
              displayName: selectedProperty?.displayName,
            })}
            messageLine2=""
            onYesClick={handleDeleteProperty}
            onNoClick={handleCloseDeleteDialog}
          />
        </Grid>
      </AppShadowBox>
    </PageContainer>
  );
};

export default AddMetadataPage;
