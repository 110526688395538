/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState } from 'react';
import {
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material';
import {
  StyledSelect,
  GridSelectContainer,
} from './addNewPropertyDropdown.styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const AddPropertyDropdown = ({
  propertiesData,
  onChangePropertiesData,
  editProperties,
  onChangeEditProperties,
  remainingProperties,
  isGetMetaPropertiesLoading,
  isAddMetaDataPropertiesLoading,
}) => {
  const { t } = useTranslation();
  const [selectedProperty, setSelectedProperty] = useState({});

  const handleAddProperty = async (propertyName) => {
    setSelectedProperty({
      propertyName: propertyName,
      dataType: remainingProperties[propertyName]?.dataType,
      displayName: remainingProperties[propertyName]?.displayName,
      mutation: remainingProperties[propertyName]?.mutation && true,
    });
    await onChangeEditProperties({
      ...editProperties,
      [propertyName]: { isEdit: true },
    });

    onChangePropertiesData([
      ...propertiesData,
      {
        propertyName: propertyName,
        dataType: remainingProperties[propertyName]?.dataType,
        displayName: remainingProperties[propertyName]?.displayName,
        mutation: remainingProperties[propertyName]?.mutation && true,
      },
    ]);
  };

  return (
    <Grid container item xs={12} display="flex" justifyContent="flex-end">
      {remainingProperties && (
        <>
          <GridSelectContainer container item xs={12} md={10}>
            {Object.keys(remainingProperties).length > 0 && (
              <FormControl fullWidth xs={12} sx={{ paddingRight: 1 }}>
                <InputLabel sx={{ mt: -1 }}>
                  {t('addMetadataPage.components.addNewProperty.label')}
                </InputLabel>
                <StyledSelect
                  fullWidth
                  id="select-label"
                  value={selectedProperty?.propertyName}
                  onChange={(e) => handleAddProperty(e.target.value)}
                  label={t('addMetadataPage.components.addNewProperty.label')}
                >
                  {isGetMetaPropertiesLoading ||
                  isAddMetaDataPropertiesLoading ? (
                    <CircularProgress />
                  ) : (
                    Object.entries(remainingProperties).map(
                      ([propertyName, property]) => (
                        <MenuItem key={propertyName} value={propertyName}>
                          {property.displayName}
                        </MenuItem>
                      ),
                    )
                  )}
                </StyledSelect>
              </FormControl>
            )}
          </GridSelectContainer>
        </>
      )}
    </Grid>
  );
};

AddPropertyDropdown.propTypes = {
  propertiesData: PropTypes.array,
  onChangePropertiesData: PropTypes.func,
  editProperties: PropTypes.bool,
  onChangeEditProperties: PropTypes.func,
  remainingProperties: PropTypes.array,
  isGetMetaPropertiesLoading: PropTypes.bool,
  isAddMetaDataPropertiesLoading: PropTypes.bool,
};

export default AddPropertyDropdown;
