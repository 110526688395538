/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Alert, Button, Autocomplete } from '@mui/material';
import { List, Save, Cancel } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../../components/pageContainer';
import AppShadowBox from '../../../components/app.shadowbox';
import { BoxWrapper, ButtonLink, InputField } from './fileUploader.page.styled';
import {
  useAddFileCollectionsMutation,
  useGetFileCollectionsQuery,
  useGetSearchEnginesQuery,
  useGetDataSourceQuery,
  useLazyGetDataSourceQuery,
} from '../../../redux/services/speciphicAsk';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import * as PATHS from '../../../constants/path';
import DropdownOne from '../../../components/dropdownone';
import StorageConfiguration from './components/storageConfiguration';
import UnauthorizedMessage from '../../../components/unauthorized';
import useDebounce from '../../../hooks/useDebounce';
import { DEBOUNCE_DELAY } from '../../../constants/debounce';

const FileUploaderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [collectionName, setCollectionName] = useState('');
  const [searchEngineType, setSearchEngineType] = useState('');
  const [dataSource, setDataSource] = useState('');
  const [input, setInput] = useState('');
  const [description, setDescription] = useState('');
  const [indexName, setIndexName] = useState('');
  const [collectionNameValidation, setCollectionNameValidation] =
    useState(false);
  const [serviceValue, setServiceValue] = useState('0');
  const [tags, setTags] = useState([]);

  const [
    addEmptyCollection,
    {
      data: fileCollection,
      isSuccess: isAddEmptyCollectionSuccess,
      isLoading: isAddEmptyCollectionResultsLoading,
      isError: isAddEmptyCollectionError,
    },
  ] = useAddFileCollectionsMutation();

  useEffect(() => {
    if (isAddEmptyCollectionSuccess && fileCollection?.id) {
      navigate(`${PATHS.FILE_COLLECTIONS}/${fileCollection.id}`);
    }
  }, [fileCollection]);

  const {
    data: FileCollections = [],
    error: getFileCollectionsError,
    isLoading: isGetFileCollectionsLoading,
    isSuccess: isGetFileCollectionsSuccess,
  } = useGetFileCollectionsQuery({ include: ['name'] });

  const [
    getDataSources,
    {
      data: dataSources = [],
      isSuccess: isGetDataSourceSuccess,
      isLoading: isGetDataSourceLoading,
      isError: isGetDataSourceError,
      isFetching: isGetDataSourceFetching,
    },
  ] = useLazyGetDataSourceQuery();

  useDebounce(
    () => {
      getDataSources({
        pageSize: 150,
        query: input.length > 0 ? input : ``,
      });
    },
    [input],
    DEBOUNCE_DELAY,
  );

  const filterOptions = (options, { inputValue }) => {
    const searchString = inputValue.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(searchString),
    );
  };

  const handleUserInput = (event) => {
    setInput(event.target.value);
  };

  const {
    data: searchEngines = [],
    error: getSearchEnginesError,
    isLoading: isGetSearchEnginesLoading,
    isSuccess: isGetSearchEnginesSuccess,
  } = useGetSearchEnginesQuery();

  useEffect(() => {
    if (isAddEmptyCollectionSuccess) {
      dispatch(
        pushToast({
          message: t(
            'fileCollectionsPage.addFileCollections.alertMessages.success',
            { name: fileCollection.name },
          ),
          severity: 'success',
        }),
      );
    }
  }, [isAddEmptyCollectionSuccess]);

  const handleCollectionInputChange = (event) => {
    setCollectionName(event.target.value);
    handleGenerateInput(event.target.value);
    handleValidationCheck(event.target.value, serviceValue);
  };

  const handleGenerateInput = (name) => {
    var randomNumber = crypto.getRandomValues(new Uint8Array(1));
    setIndexName(
      name
        .trim()
        .replace(/\W+/g, '_')
        .concat(randomNumber)
        .replace(/\b_/, '')
        .toLowerCase(),
    );
  };

  const handleIndexInputChange = (event) => {
    setIndexName(event.target.value);
  };

  const handleDescriptionInputChange = (event) => {
    setDescription(event.target.value);
  };

  const handleTagChange = (_, newTags) => {
    setTags(newTags);
  };

  const handleAddEmptyCollection = (event) => {
    event.preventDefault();
    addEmptyCollection({
      name: collectionName,
      indexName: indexName,
      description: description,
      tags: tags,
      searchEngine: searchEngineType,
      dataSource: dataSource,
    });
  };

  const handleValidationCheck = (name) => {
    setCollectionNameValidation(() =>
      FileCollections?.map((collection) => {
        return collection.name;
      }).includes(name.trim()),
    );
  };

  const handleSearchEngine = (value) => {
    setSearchEngineType(value);
  };

  const handleDataSource = (value) => {
    setDataSource(value);
  };

  const handleClearFields = () => {
    setCollectionName('');
    setIndexName('');
    setDescription('');
    setTags([]);
    setSearchEngineType('');
    setDataSource('');
  };

  if (
    getFileCollectionsError?.status === 403 ||
    getSearchEnginesError?.status === 403
  )
    return (
      <UnauthorizedMessage
        description={t('fileCollectionsPage.unauthorizedMessages.list')}
      />
    );

  return (
    <PageContainer>
      <BoxWrapper>
        <AppShadowBox>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid container item xs={12}>
              <Grid item xs={9} sm={9}>
                <Typography variant="h4" color={'primary'}>
                  {t('fileUploaderPage.title')}
                </Typography>
                <Typography variant="body1">
                  {t('fileUploaderPage.subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <ButtonLink
                  size="medium"
                  variant="contained"
                  component={Link}
                  to={PATHS.FILE_COLLECTIONS}
                  startIcon={<List />}
                >
                  {t('fileUploaderPage.fileCollectionButton')}
                </ButtonLink>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <InputField
                      size="small"
                      fullWidth
                      required
                      error={collectionNameValidation}
                      id="outlined-required"
                      label={t(
                        'fileUploaderPage.fileCollection.textFieldLabel',
                      )}
                      onChange={handleCollectionInputChange}
                      helperText={
                        collectionNameValidation && (
                          <Alert variant="outlined" severity="error">
                            {t('fileUploaderPage.fileCollection.errorMessage')}
                          </Alert>
                        )
                      }
                      value={collectionName}
                    />
                  </Grid>
                  <Grid item>
                    <InputField
                      size="small"
                      required
                      fullWidth
                      id="outlined-required"
                      label={t('fileUploaderPage.index.textFieldLabel')}
                      value={indexName}
                      onChange={handleIndexInputChange}
                    />
                  </Grid>
                  <Grid item>
                    <InputField
                      size="small"
                      fullWidth
                      id="outlined-required"
                      label={t('fileUploaderPage.description.textFieldLabel')}
                      onChange={handleDescriptionInputChange}
                      value={description}
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      freeSolo
                      clearOnBlur
                      options={[]}
                      defaultValue={[]}
                      value={tags}
                      renderInput={(params) => (
                        <InputField
                          {...params}
                          label={t('fileUploaderPage.tags.title')}
                          placeholder={t('fileUploaderPage.tags.placeholder')}
                        />
                      )}
                      onChange={handleTagChange}
                    />
                  </Grid>
                  <Grid item>
                    <DropdownOne
                      onChange={handleSearchEngine}
                      items={searchEngines}
                      value={searchEngineType}
                      label={t('fileUploaderPage.dropdown.searchEngineLabel')}
                    />
                  </Grid>
                  <Grid item>
                    <StorageConfiguration
                      onStorageChange={handleDataSource}
                      storageItems={dataSources?.data || []}
                      value={dataSource || input}
                      label={t(
                        'fileUploaderPage.storageConfiguration.dataSourceDropdown',
                      )}
                      filterOptions={filterOptions}
                      handleUserInput={handleUserInput}
                      isLoading={isGetDataSourceFetching}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ justifyContent: 'space-between', marginTop: 2 }}
                >
                  <LoadingButton
                    size="medium"
                    variant="contained"
                    disabled={
                      indexName <= 0 ||
                      collectionName <= 0 ||
                      searchEngineType.length <= 0 ||
                      dataSource.length <= 0
                    }
                    onClick={handleAddEmptyCollection}
                    loading={isAddEmptyCollectionResultsLoading}
                    startIcon={<Save />}
                  >
                    {t('fileUploaderPage.fileCollectionSave')}
                  </LoadingButton>
                  <Button
                    size="medium"
                    variant="contained"
                    disabled={
                      indexName.length == 0 ||
                      collectionName.length == 0 ||
                      isAddEmptyCollectionResultsLoading
                    }
                    startIcon={<Cancel />}
                    onClick={handleClearFields}
                  >
                    {t('fileUploaderPage.fileCollectionClear')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AppShadowBox>
      </BoxWrapper>
    </PageContainer>
  );
};

FileUploaderPage.propTypes = {
  row: PropTypes.array,
};
export default FileUploaderPage;
