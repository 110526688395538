/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { React, useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import {
  CancelButton,
  FieldGrid,
  HeadingTypography,
  InputField,
  GridContainer,
  AddSummaryTopicButton,
} from './addReportSummaryTopic.styled.js';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  useGetReportSummaryTopicByIdQuery,
  useUpdateReportSummaryTopicMutation,
} from '../../../redux/services/speciphicAsk';

const EditSummaryTopic = ({ handleClose, topicId, topic, details, order }) => {
  const { t } = useTranslation();
  const [topicName, setTopicName] = useState(topic);
  const [topicDetails, setTopicDetails] = useState(details);
  const dispatch = useDispatch();

  const [
    updateTopic,
    {
      data: updatedTopic,
      isSuccess: isUpdateTopicSuccess,
      isLoading: isUpdateTopicLoading,
      isError: isUpdateTopicError,
    },
  ] = useUpdateReportSummaryTopicMutation();

  useEffect(() => {
    if (isUpdateTopicSuccess) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.edit.successToast'),
          severity: 'success',
        }),
      );
      handleClose();
    }
  }, [isUpdateTopicSuccess]);

  useEffect(() => {
    if (isUpdateTopicError) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.edit.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isUpdateTopicError]);

  const handleTopicNameChange = (e) => {
    setTopicName(e.target.value);
  };

  const handleTopicDetailsChange = (e) => {
    setTopicDetails(e.target.value);
  };

  const handleRemoveFieldDetails = () => {
    setTopicName('');
    handleClose();
  };

  const handleUpdateTopic = (event) => {
    event.preventDefault();

    updateTopic({
      topicName: topicName,
      topicId: topicId,
      topicDetails: topicDetails,
    });
  };

  return (
    <Box component="form" onSubmit={handleUpdateTopic}>
      <GridContainer container spacing={0}>
        <FieldGrid item xs={12}>
          <HeadingTypography variant="h5">
            {t('reportSummaryTopicsPage.edit.heading')}
          </HeadingTypography>
        </FieldGrid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <FieldGrid item xs={12}>
          <InputField
            size="small"
            required
            sx={{ marginTop: 2 }}
            id="outlined-required"
            label={t('reportSummaryTopicsPage.edit.form.topicName')}
            value={topicName}
            onChange={handleTopicNameChange}
          />
        </FieldGrid>

        <FieldGrid item xs={12}>
          <InputField
            size="small"
            required
            sx={{ marginTop: 0.5 }}
            id="outlined-required"
            label={t('reportSummaryTopicsPage.edit.form.topicDetails')}
            value={topicDetails}
            onChange={handleTopicDetailsChange}
            multiline
            minRows={4}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <InputField
            size="small"
            required
            sx={{ marginTop: 0.5 }}
            id="outlined-required"
            label="Topic Order"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
            }}
            value={order}
            disabled
          />
        </FieldGrid>

        <FieldGrid item xs={12}>
          <AddSummaryTopicButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            loadingPosition="end"
            onClick={handleUpdateTopic}
            loading={isUpdateTopicLoading}
          >
            {t('reportSummaryTopicsPage.edit.form.updateButton')}
          </AddSummaryTopicButton>
        </FieldGrid>
        <FieldGrid item xs={12}>
          <CancelButton
            variant="outlined"
            color="error"
            fullWidth
            onClick={handleRemoveFieldDetails}
          >
            {t('reportSummaryTopicsPage.edit.form.cancelButton')}
          </CancelButton>
        </FieldGrid>
      </GridContainer>
    </Box>
  );
};

EditSummaryTopic.propTypes = {
  handleClose: PropTypes.func,
  topic: PropTypes.string,
  topicId: PropTypes.string,
  details: PropTypes.string,
  order: PropTypes.string,
};

export default EditSummaryTopic;
