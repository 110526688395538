import * as React from 'react';
const AskBotIcon = (props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 58 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 46.0204L16.805 45.9636L17.1632 38.4536L5.56566 38.4934L0 46.0204Z"
      fill="#EF4F26"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5628 18.1466L15.054 25.7418L30.4377 67.3734L36.0659 60.1023L31.0801 46.606L28.0557 38.4195L20.5628 18.1466Z"
      fill="#EF4F26"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5924 28.3854L17.9647 21.6771L34.4798 0L35.9181 10.9494L22.5924 28.3854Z"
      fill="#EF4F26"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.4718 46.5151L56.5776 38.2831L33.5929 38.3627L36.6231 46.5492L50.4718 46.5151Z"
      fill="#EF4F26"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7414 21.751L43.2747 10.8073L29.8579 28.556L29.8409 28.5617L29.8466 28.5731L29.8409 28.5844L29.8466 28.5787L32.962 37.1859L40.8301 26.8562L40.8528 26.8505L44.7414 21.751Z"
      fill="#EF4F26"
    />
  </svg>
);
export default AskBotIcon;
