/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Tab, Tabs, styled } from '@mui/material';

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': { gap: 1, flexWrap: 'wrap' },
});

export const StyledTab = styled(Tab)(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&.Mui-selected': {
      color: '#fff',
    },
    borderRadius: 8,
  }),
}));
