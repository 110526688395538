/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React from 'react';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  LoadingTextWrapper,
  LoadingProgress,
} from './configuration.loading.styled';

const LoadingPage = () => {
  const { t } = useTranslation();
  return (
    <Dialog fullScreen open={true}>
      <LoadingTextWrapper>
        <LoadingProgress />
        <Typography variant="h3">
          {t('appConfigurationSection.general.loadingMessage')}
        </Typography>
      </LoadingTextWrapper>
    </Dialog>
  );
};

export default LoadingPage;
