/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import {
  Assistant,
  AutoGraph,
  BarChart,
  DatasetLinked,
  FolderCopy,
  Group,
  History,
  Logout,
  Quiz,
  ScatterPlot,
  Topic,
  HistoryToggleOff,
  Settings,
  Lock,
  ViewTimeline,
} from '@mui/icons-material';

import {
  StyledAvatar,
  AvatarLetterWrap,
  NavListWrap,
  AccountText,
  ListItemIconStyled,
} from './app.navbar.styled';
import isMarketIntelligenceEnabled from '../../constants/marketIntelligence';
import isAnalyticsEnabled from '../../constants/analytics';
import isConversationHistoryEnabled from '../../constants/conversationHistory';
import isReadingAssistantEnabled from '../../constants/readingAssistant';
import isAppConfigurationEnabled from '../../constants/appConfiguration';
import isAccessControlEnabled from '../../constants/accessControl';

export const AppNavbarProfile = ({
  userName,
  userEmail,
  profileClick,
  fileCollectionsClick,
  readingAssistantClick,
  logoutClick,
  accessControlClick,
  analyticsClick,
  dataSourcesClick,
  searchHistoryClick,
  marketIntelligenceListedClick,
  marketIntelligenceUnlistedClick,
  reportSourcesClick,
  reportSummaryClick,
  faqClick,
  appConfigurationClick,
  conversationHistoryClick,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <NavListWrap>
      <ListItem>
        <ListItemText
          primary="ACCOUNT"
          primaryTypographyProps={{ variant: 'h5' }}
        ></ListItemText>
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <StyledAvatar>
            <AvatarLetterWrap>
              {userName?.charAt(0) || auth.user.profile.oid?.charAt(0)}
            </AvatarLetterWrap>
          </StyledAvatar>
        </ListItemAvatar>
        <AccountText
          primary={userName || t('app.navbar.accountText.primary')}
          // secondary={userEmail || t('app.navbar.accountText.secondary')}
        ></AccountText>
      </ListItem>
      <Divider sx={{ marginTop: 1 }} />
      {/* <ListItemButton onClick={profileClick}>
        <ListItemText primary={t('app.navbar.profile')}></ListItemText>
      </ListItemButton> */}

      <ListItemButton onClick={searchHistoryClick}>
        <ListItemIconStyled>
          <History />
        </ListItemIconStyled>
        <ListItemText primary={t('app.navbar.searchHistory')}></ListItemText>
      </ListItemButton>

      {isConversationHistoryEnabled && (
        <ListItemButton onClick={conversationHistoryClick}>
          <ListItemIconStyled>
            <HistoryToggleOff />
          </ListItemIconStyled>
          <ListItemText
            primary={t('app.navbar.conversationHistory')}
          ></ListItemText>
        </ListItemButton>
      )}

      <ListItemButton onClick={fileCollectionsClick}>
        <ListItemIconStyled>
          <FolderCopy />
        </ListItemIconStyled>
        <ListItemText primary={t('app.navbar.fileCollections')}></ListItemText>
      </ListItemButton>

      <ListItemButton onClick={dataSourcesClick}>
        <ListItemIconStyled>
          <DatasetLinked />
        </ListItemIconStyled>
        <ListItemText primary={t('app.navbar.dataSources')}></ListItemText>
      </ListItemButton>

      {/* <Link
        style={{ textDecoration: 'none' }}
        to={PATHS.FINANCIAL_REPORT_LANDING}
      > */}
      {isMarketIntelligenceEnabled && (
        <>
          <ListItemButton onClick={marketIntelligenceListedClick}>
            <ListItemIconStyled>
              <BarChart />
            </ListItemIconStyled>
            <ListItemText
              primary={t('app.navbar.marketIntelligenceListed')}
            ></ListItemText>
          </ListItemButton>
          <ListItemButton onClick={marketIntelligenceUnlistedClick}>
            <ListItemIconStyled>
              <ViewTimeline />
            </ListItemIconStyled>
            <ListItemText
              primary={t('app.navbar.marketIntelligenceUnlisted')}
            ></ListItemText>
          </ListItemButton>
        </>
      )}

      <ListItemButton onClick={faqClick}>
        <ListItemIconStyled>
          <Quiz />
        </ListItemIconStyled>
        <ListItemText primary={t('app.navbar.faq')}></ListItemText>
      </ListItemButton>

      {isAccessControlEnabled && (
        <ListItemButton onClick={accessControlClick}>
          <ListItemIconStyled>
            <Lock />
          </ListItemIconStyled>
          <ListItemText primary={t('app.navbar.accessControl')}></ListItemText>
        </ListItemButton>
      )}

      {isReadingAssistantEnabled && (
        <ListItemButton onClick={readingAssistantClick}>
          <ListItemIconStyled>
            <Assistant />
          </ListItemIconStyled>
          <ListItemText
            primary={t('app.navbar.readingAssistant')}
          ></ListItemText>
        </ListItemButton>
      )}

      {isAnalyticsEnabled && (
        <ListItemButton onClick={analyticsClick}>
          <ListItemIconStyled>
            <AutoGraph />
          </ListItemIconStyled>
          <ListItemText primary={t('app.navbar.analytics')}></ListItemText>
        </ListItemButton>
      )}

      {isAppConfigurationEnabled && (
        <ListItemButton onClick={appConfigurationClick}>
          <ListItemIconStyled>
            <Settings />
          </ListItemIconStyled>
          <ListItemText
            primary={t('app.navbar.appConfiguration')}
          ></ListItemText>
        </ListItemButton>
      )}

      <Divider />
      <ListItemButton onClick={logoutClick}>
        <ListItemIconStyled>
          <Logout />
        </ListItemIconStyled>
        <ListItemText primary={t('app.navbar.logout')}></ListItemText>
      </ListItemButton>
    </NavListWrap>
  );
};

AppNavbarProfile.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  profileClick: PropTypes.func,
  analyticsClick: PropTypes.func,
  searchHistoryClick: PropTypes.func,
  fileCollectionsClick: PropTypes.func,
  readingAssistantClick: PropTypes.func,
  dataSourcesClick: PropTypes.func,
  logoutClick: PropTypes.func,
  usersClick: PropTypes.func,
  accessControlClick: PropTypes.func,
  marketIntelligenceListedClick: PropTypes.func,
  marketIntelligenceUnlistedClick: PropTypes.func,
  reportSourcesClick: PropTypes.func,
  reportSummaryClick: PropTypes.func,
  faqClick: PropTypes.func,
  appConfigurationClick: PropTypes.func,
  conversationHistoryClick: PropTypes.func,
};
