/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { StyledTab, StyledTabs } from './miTabs.styled';

const MiTabs = ({
  title,
  tabLabels,
  value,
  handleTabChange,
  disableTabs = false,
}) => {
  function a11yProps(index) {
    return {
      id: `mi-tab-${index}`,
      'aria-controls': `mi-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ marginY: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginRight: { xs: 0, md: 2 },
            marginBottom: { xs: 1, md: 0 },
            flexBasis: { xs: '100%', md: 'auto' },
          }}
        >
          {title}
        </Typography>
        {!disableTabs && (
          <StyledTabs
            value={value}
            onChange={handleTabChange}
            aria-label="mi-tabs"
          >
            {tabLabels.map((label, index) => (
              <StyledTab
                key={index}
                label={label}
                {...a11yProps(index)}
                selected={value === index}
              />
            ))}
          </StyledTabs>
        )}
      </Box>
    </Box>
  );
};

MiTabs.propTypes = {
  title: PropTypes.string,
  tabLabels: PropTypes.arrayOf(PropTypes.string),
  handleTabChange: PropTypes.func,
  value: PropTypes.number,
  disableTabs: PropTypes.bool,
};

export default MiTabs;
