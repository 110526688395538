/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { LoaderContainer, StyledTypography } from './loader.styled';
import AppShadowBox from '../app.shadowbox';

const Loader = ({ label = 'Loading...', variant = 'indeterminate' }) => {
  return (
    <LoaderContainer>
      <AppShadowBox>
        <LinearProgress variant={variant} />
        <StyledTypography variant="h5">{label}</StyledTypography>
      </AppShadowBox>
    </LoaderContainer>
  );
};

Loader.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
};

export default Loader;
