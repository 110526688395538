/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Category } from '@mui/icons-material';
import colors from '../../themes/colors';

export const CustomGrid = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'transform 0.2s ease',

  ':hover': {
    transform: 'scale(1.05)',
  },
}));

export const DataGridBox = styled(Box)({
  marginTop: 20,
  width: '100%',
  height: 'auto',
});

export const MetricsGrid = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingLeft: '5px',
  '&::-webkit-scrollbar': {
    height: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
    width: '100% !important',
  },
}));

export const MetricsInnerContainer = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  [theme.breakpoints.down(1000)]: {
    justifyContent: 'flex-start',
    '& > *': {
      width: '140px',
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

export const StatsSpan = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: colors.blue.B400,
  fontSize: 18,
}));

export const ChartGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 10,
  gridTemplateColumns: 'repeat(2, 1fr)',

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

export const StyledToolTip = styled(Box)({
  backgroundColor: colors.neutralLight.N0,
  padding: 5,
  border: `1px solid ${colors.neutralLight.N20}`,
});

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  background: colors.neutralLight.N0,
  padding: '0 10px',
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.dark,
    fontSize: 16,
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '.MuiDataGrid-row': {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const CustomTooltipWrapper = styled(Box)({
  background: 'white',
  border: '1px solid #C1C7D0',
  padding: '10px',
});

export const StyledToolbarGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

export const DataDisplay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100% !important',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down(1190)]: {
    width: '100% !important',
  },
}));

export const CategoryIcon = styled(Category)(({ theme }) => ({
  height: '50px',
  width: '50px',
  fill: theme.palette.primary.main,
}));
