/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Alert } from '@mui/material';
import FileCollectionDataGrid from './components/fileCollectionDataGrid';
import { LoadingTypography } from './filesCollectionPage.styled';
import PageContainer from '../../../components/pageContainer';
import AppShadowBox from '../../../components/app.shadowbox';
import { useLazyGetAllFileCollectionsForTableQuery } from '../../../redux/services/speciphicAsk';
import * as PATHS from '../../../constants/path';
import PageTitle from '../../../components/page.title';
import UnauthorizedMessage from '../../../components/unauthorized';
import { DEBOUNCE_DELAY } from '../../../constants/debounce';
import useDebounce from '../../../hooks/useDebounce';

const translationJSONPrefix = 'fileCollectionsPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const FileCollectionsPage = () => {
  const { t } = useTranslation();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [searchQuery, setSearchQuery] = useState('');

  const [
    getAllFileCollections,
    {
      data: fileCollections = [],
      error: fileCollectionsError,
      isError: isGetFileCollectionsError,
      isLoading: isGetFileCollectionsLoading,
      isSuccess: isGetFileCollectionsSuccess,
      isFetching: isGetFileCollectionsFetching,
    },
  ] = useLazyGetAllFileCollectionsForTableQuery();

  useDebounce(
    () => {
      getAllFileCollections({
        pageNo: pageNo + 1,
        pageSize: pageSize,
        query: searchQuery,
      });
    },
    [pageNo, pageSize, searchQuery],
    DEBOUNCE_DELAY,
  );

  const handlePageChange = (newPageNo, query) => {
    setPageNo(newPageNo);
  };

  const handlePageSizeChange = (newPageSize) => {
    setSearchQuery('');
    setPageSize(newPageSize);
    setPageNo(0);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  if (fileCollectionsError?.status === 403)
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <AppShadowBox>
        <PageTitle
          title={t(`${translationJSONPrefix}.title`)}
          subtitle={t(`${translationJSONPrefix}.subtitle`)}
          path={PATHS.FILE_COLLECTIONS_FILE_UPLOAD}
          buttonText={t(`${translationJSONPrefix}.buttonText.create`)}
          isActionButtonNeeded={true}
        />

        {isGetFileCollectionsLoading && (
          <>
            <LinearProgress />
            <LoadingTypography variant="h5">
              {t(
                `${translationJSONPrefix}.loadingMessage.loadingFileCollections`,
              )}
            </LoadingTypography>
          </>
        )}

        <>
          {isGetFileCollectionsSuccess && (
            <>
              <FileCollectionDataGrid
                fileCollections={fileCollections?.data}
                customPageNo={pageNo}
                customPageSize={pageSize}
                total={fileCollections?.total}
                onCustomPageChange={handlePageChange}
                onCustomPageSizeChange={handlePageSizeChange}
                isNextPageLoading={isGetFileCollectionsFetching}
                handleInputChange={handleInputChange}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                isSearching={isGetFileCollectionsFetching}
              />
            </>
          )}
          {isGetFileCollectionsError && (
            <>
              <Alert severity="error">
                {t(`${translationJSONPrefix}.alertNoCollection`)}
              </Alert>
            </>
          )}
        </>
      </AppShadowBox>
    </PageContainer>
  );
};

export default FileCollectionsPage;
