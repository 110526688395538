/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { HighlighedTextWrapper } from './dialog.styled';
import { LoadingButton } from '@mui/lab';

const FormDialog = ({
  open,
  setOpen,
  textField,
  setTextFieldValue,
  title,
  content,
  highlightedTitle,
  highlightedContent,
  onSubmit,
  cancelBtnLabel,
  submitBtnLabel,
  submitBtnDisabled,
  isDeleteLoading,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          if (setTextFieldValue) setTextFieldValue('');
        }}
      >
        <DialogTitle style={{ fontWeight: 'bold', fontSize: 18 }}>
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{content}</DialogContentText>

          {(highlightedTitle || highlightedContent) && (
            <HighlighedTextWrapper>
              {highlightedTitle && (
                <DialogContentText>{`${highlightedTitle} ${
                  highlightedTitle && '-'
                }`}</DialogContentText>
              )}

              {highlightedContent && (
                <DialogContentText style={{ fontWeight: 'bolder' }}>
                  {highlightedContent}
                </DialogContentText>
              )}
            </HighlighedTextWrapper>
          )}

          {textField && (
            <TextField
              required
              name={textField?.name}
              value={textField?.value}
              onChange={textField?.onChange}
              placeholder={textField?.placeholder}
              type={textField?.type || 'text'}
              margin="dense"
              fullWidth
              variant="outlined"
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              if (setTextFieldValue) setTextFieldValue('');
              setOpen(false);
            }}
          >
            {cancelBtnLabel || t('app.dialog.buttons.cancel')}
          </Button>

          <LoadingButton
            variant="contained"
            disabled={submitBtnDisabled}
            onClick={() => {
              onSubmit();
              if (setTextFieldValue) setTextFieldValue('');
            }}
            loading={isDeleteLoading}
          >
            {submitBtnLabel || t('app.dialog.buttons.submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  textField: PropTypes.object,
  setTextFieldValue: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  highlightedTitle: PropTypes.string,
  highlightedContent: PropTypes.string,
  onSubmit: PropTypes.func,
  cancelBtnLabel: PropTypes.string,
  submitBtnLabel: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
};

export default FormDialog;
