/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { ShadowBox } from './app.shadowbox.styled';
import PropTypes from 'prop-types';

const AppShadowBox = (props) => {
  return <ShadowBox>{props.children}</ShadowBox>;
};

AppShadowBox.propTypes = {
  children: PropTypes.node,
};

export default AppShadowBox;
