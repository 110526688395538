/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const colors = {
  red: {
    R50: '#FFEBE6',
    R75: '#FFBDAD',
    R100: '#FF8F73',
    R200: '#FF7452',
    R250: '#D11818',
    R300: '#FF5630',
    R400: '#DE350B',
    R500: '#BF2600',
  },
  yellow: {
    Y50: '#FFFAE6',
    Y75: '#FFF0B3',
    Y100: '#FFE380',
    Y200: '#FFC400',
    Y300: '#FFAB00',
    Y400: '#FF991F',
    Y500: '#FF8B00',
  },
  green: {
    G50: '#E3FCEF',
    G75: '#ABF5D1',
    G100: '#79F2C0',
    G200: '#57D9A3',
    G250: '#33C000',
    G300: '#36B37E',
    G400: '#00875A',
    G500: '#006644',
  },
  teal: {
    T50: '#E6FCFF',
    T75: '#B3F5FF',
    T100: '#79E2F2',
    T200: '#00C7E6',
    T300: '#00B8D9',
    T400: '#00A3BF',
    T500: '#008DA6',
  },
  blue: {
    B50: '#DEEBFF',
    B75: '#B3D4FF',
    B100: '#4C9AFF',
    B200: '#2684FF',
    B250: '#3AA5C6',
    B300: '#0065FF',
    B400: '#0052CC',
    B500: '#0747A6',
  },
  orange: {
    O50: '#f9f2e0',
    O75: '#f0ddb2',
    O100: '#e7c780',
    O200: '#deb14e',
    O300: '#d9a22b',
    O400: '#d5930e',
    O500: '#cc7a00',
    OLight: '#EF4F261A',
  },
  purple: {
    P50: '#EAE6FF',
    P75: '#C0B6F2',
    P100: '#998DD9',
    P200: '#8777D9',
    P300: '#6554C0',
    P400: '#5243AA',
    P500: '#403294',
  },
  neutralLight: {
    N0: '#FFFFFF',
    N10: '#FAFBFC',
    N20: '#F4F5F7',
    N30: '#EBECF0',
    N40: '#DFE1E6',
    N50: '#C1C7D0',
    N60: '#B3BAC5',
    N70: '#A5ADBA',
    N80: '#97A0AF',
    N90: '#8993A4',
    N100: '#7A869A',
    N200: '#6B778C',
    N300: '#5E6C84',
    N400: '#505F79',
    N500: '#42526E',
    N600: '#344563',
    N700: '#253858',
    N800: '#172B4D',
    N900: '#091E42',
  },
  neutralLightAlpha: {
    N10A: 'rgba(9, 30, 66, 0.02)',
    N20A: 'rgba(9, 30, 66, 0.04)',
    N30A: 'rgba(9, 30, 66, 0.08)',
    N40A: 'rgba(9, 30, 66, 0.13)',
    N50A: 'rgba(9, 30, 66, 0.25)',
    N60A: 'rgba(9, 30, 66, 0.31)',
    N70A: 'rgba(9, 30, 66, 0.36)',
    N80A: 'rgba(9, 30, 66, 0.42)',
    N90A: 'rgba(9, 30, 66, 0.48)',
    N100A: 'rgba(9, 30, 66, 0.54)',
    N200A: 'rgba(9, 30, 66, 0.60)',
    N300A: 'rgba(9, 30, 66, 0.66)',
    N400A: 'rgba(9, 30, 66, 0.71)',
    N500A: 'rgba(9, 30, 66, 0.77)',
    N600A: 'rgba(9, 30, 66, 0.82)',
    N700A: 'rgba(9, 30, 66, 0.89)',
    N800A: 'rgba(9, 30, 66, 0.95)',
    N900A: 'rgba(9, 30, 66, 1)',
  },
  bluePallet: [
    '#1f005c',
    '#4c005f',
    '#6d0061',
    '#8b0460',
    '#a51e5f',
    '#bc365d',
    '#cf4f5c',
    '#df675c',
    '#ed815e',
    '#f79b62',
    '#ffb56b',
  ],
  blueGradient: [
    '#002081',
    '#003491',
    '#0047a0',
    '#0059ad',
    '#006bb9',
    '#217ec5',
    '#3990cf',
    '#51a2d9',
    '#69b4e2',
    '#81c6ec',
    '#9bd8f5',
    '#b5eaff',
  ],
  pieChartGradient: [
    '#d1b9a7',
    '#40798d',
    '#956cd5',
    '#915991',
    '#a5c6a5',
    '#667579',
    '#d0b5d0',
    '#497749',
    '#aabcf4',
    '#7d6f64',
    '#9bd8f5',
    '#b5eaff',
  ],
};

export default colors;
