const { REACT_APP_ENABLE_READING_ASSISTANT } = process.env;

let isReadingAssistantEnabled = false;

if (
  REACT_APP_ENABLE_READING_ASSISTANT &&
  REACT_APP_ENABLE_READING_ASSISTANT?.toLowerCase() === 'true'
) {
  isReadingAssistantEnabled = true;
}

export const MARKET_INTELLIGENCE_FEATURE = 'market-intelligence';

export default isReadingAssistantEnabled;
