/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material';
import fontWeights from '../fontWeights';
import palette from './palette';
import typography from '../typography';
import shadows from '../shadows';
import { muiOutlinedInput } from '../components/muiOutlinedInput';
import { muiInputLabel } from '../components/muiInputLabel';
import { muiSelect } from '../components/muiSelect';
import { muiButton } from '../components/muiButton';
import { muiToolbar } from '../components/muiToolbar';
import { muiIconButton } from '../components/muiIconButton';
import { muiListItemIcon } from '../components/muiListItemIcon';
import { muiTextField } from '../components/muiTextField';

export const themeOptions = {
  fontWeights,
  palette,
  shadows,
  typography,
  components: {
    MuiOutlinedInput: muiOutlinedInput,
    MuiInputLabel: muiInputLabel,
    MuiSelect: muiSelect,
    MuiButton: muiButton,
    MuiToolbar: muiToolbar,
    MuiIconButton: muiIconButton,
    MuiListItemIcon: muiListItemIcon,
    MuiTextField: muiTextField,
  },
};

const theme = createTheme(themeOptions);

export default theme;
