/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createAction, createReducer } from '@reduxjs/toolkit';

const toggleSidebar = createAction('toggleSidebar');
const setVariant = createAction('setVariant');

const sidebarReducer = createReducer(
  { open: false, variant: 'temporary' },
  (builder) => {
    builder
      .addCase(toggleSidebar, (state, action) => {
        state.open =
          action.payload !== undefined ? action.payload : !state.open;
      })
      .addCase(setVariant, (state, action) => {
        state.variant = action.payload;
      });
  },
);

export { toggleSidebar, setVariant };
export default sidebarReducer;
