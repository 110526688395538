/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ collectionId, files }) => {
      const formData = new FormData();
      files?.forEach((file) => {
        formData.append('files', file);
      });
      return {
        url: `/file-collections/upload/${collectionId}`,
        method: 'POST',
        body: formData,
      };
    },
    invalidatesTags: ['individualFileCollections', 'fileCollections'],
  });
