const { REACT_APP_ENABLE_ANALYTICS } = process.env;

let isAnalyticsEnabled = false;

if (
  REACT_APP_ENABLE_ANALYTICS &&
  REACT_APP_ENABLE_ANALYTICS?.toLowerCase() === 'true'
) {
  isAnalyticsEnabled = true;
}

export default isAnalyticsEnabled;
