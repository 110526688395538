import React, { useRef } from 'react';
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
  ListItem,
  ListItemText,
  Divider,
  Popper,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as PATHS from '../../../../../constants/path';
import {
  AutoComplete,
  LoadingProgress,
  MenuList,
  BoxContainer,
} from '../../fileUploader.page.styled';

const StorageConfiguration = ({
  onStorageChange,
  storageItems,
  value,
  label,
  filterOptions,
  handleUserInput,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const createNewMenuItemRef = useRef(null);

  const handleStorageChange = (event, newValue) => {
    if (newValue && newValue.id === 'create-new-menu-item') {
      navigate(PATHS.ADD_DATA_SOURCE);
    } else {
      onStorageChange(newValue?.id);
    }
  };

  const handleCreateNewDataSource = (event) => {
    navigate(PATHS.ADD_DATA_SOURCE);
  };

  const CustomPopper = (props) => {
    return <Popper {...props} placement="top-start" />;
  };

  return (
    <AutoComplete
      options={storageItems}
      onChange={handleStorageChange}
      getOptionLabel={(option) => option.name}
      popupIcon={<></>}
      blurOnSelect={true}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      PopperComponent={CustomPopper}
      renderOption={(props, option) => (
        <>
          <MenuItem {...props} key={option.id}>
            <ListItemText primary={option.name}></ListItemText>
          </MenuItem>
        </>
      )}
      renderInput={(params) => (
        <BoxContainer>
          <TextField
            label={label}
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: `off`,
              style: { fontSize: `14px`, padding: `8px 16px` },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && (
                    <InputAdornment position="end">
                      <LoadingProgress size={20} />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            onChange={handleUserInput}
          />
        </BoxContainer>
      )}
      ListboxComponent={(props) => (
        <>
          <ul {...props} />
          <Divider />
          <MenuList
            {...props}
            id="create-new-menu-item"
            value=""
            ref={createNewMenuItemRef}
            onClick={handleCreateNewDataSource}
          >
            <Typography variant="h5">
              {t('fileUploaderPage.storageConfiguration.createNewOption')}
            </Typography>
          </MenuList>
        </>
      )}
    />
  );
};

StorageConfiguration.propTypes = {
  onStorageChange: PropTypes.func,
  storageItems: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  value: PropTypes.string,
  filterOptions: PropTypes.func,
  handleUserInput: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default StorageConfiguration;
