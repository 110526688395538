import React from 'react';
import PropTypes from 'prop-types';

import colors from '../themes/colors';

const LeftArrowSVG = ({
  size = 20,
  color = colors.neutralLight.N70,
  style,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <svg
        width={(9 / 20) * size}
        height={size}
        viewBox="0 0 9 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.38823 1.46825C3.75869 0.579151 4.62741 0 5.59061 0V0C7.24719 0 8.39961 1.64678 7.83218 3.20315L6.10028 7.95351C5.71168 9.01938 5.69712 10.1857 6.05898 11.261L7.93218 16.8271C8.45684 18.386 7.2971 20 5.65219 20V20C4.65681 20 3.76422 19.387 3.4069 18.4579L0.71895 11.4693C0.257472 10.2694 0.275859 8.93794 0.770293 7.7513L3.38823 1.46825Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

LeftArrowSVG.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default LeftArrowSVG;
