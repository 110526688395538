import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from '../settings/fileUploaderPage/fileUploader.page.styled';
import SubmitButtonGroup from './submitButtonGroup';
import PropTypes from 'prop-types';

const SharepointForm = (props) => {
  const [inputValues, setInputValues] = useState({
    name: '',
    accountType: props.storageContainer,
    params: {
      siteName: '',
      authTenantId: '',
      accessTokenUrl: '',
      authClientId: '',
      authClientSecret: '',
      authScope: '',
    },
  });
  const [validationErrorLabel, setValidationErrorLabel] = useState('');

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevState) => ({
      ...prevState,
      ...(fieldName.includes('.')
        ? { params: { ...prevState.params, [fieldName.split('.')[1]]: value } }
        : { [fieldName]: value }),
    }));
  };

  const clearValues = () => {
    setInputValues({
      name: '',
      type: props.storageContainer,
      params: {
        siteName: '',
        authTenantId: '',
        accessTokenUrl: '',
        authClientId: '',
        authClientSecret: '',
        authScope: '',
      },
    });
  };

  const { t } = useTranslation();
  return (
    <>
      <InputField
        size="small"
        required
        fullWidth
        name="name"
        label="Name"
        value={inputValues.name}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="siteName"
        label="Site Name"
        value={inputValues.params.siteName}
        onChange={(e) => handleInputChange('params.siteName', e.target.value)}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="authTenantId"
        label="Auth Tenant Id"
        value={inputValues.params.authTenantId}
        onChange={(e) =>
          handleInputChange('params.authTenantId', e.target.value)
        }
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="accessTokenUrl"
        label="Access Token Url"
        value={inputValues.params.accessTokenUrl}
        onChange={(e) =>
          handleInputChange('params.accessTokenUrl', e.target.value)
        }
      />

      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="authClientId"
        label="Auth Client Id"
        value={inputValues.params.authClientId}
        onChange={(e) =>
          handleInputChange('params.authClientId', e.target.value)
        }
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        name="authClientSecret"
        type="password"
        label="Auth Client Secret"
        value={inputValues.params.authClientSecret}
        onChange={(e) =>
          handleInputChange('params.authClientSecret', e.target.value)
        }
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        name="authScope"
        label="Auth Scope"
        value={inputValues.params.authScope}
        onChange={(e) => handleInputChange('params.authScope', e.target.value)}
      />
      <SubmitButtonGroup
        data={inputValues}
        onClear={clearValues}
        errorMessage={validationErrorLabel}
      />
    </>
  );
};

SharepointForm.propTypes = {
  storageContainer: PropTypes.string,
};

export default SharepointForm;
