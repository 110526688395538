/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)({
  '& .MuiSelect-outlined	': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRadius: 2,
  },
});
