/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import PropTypes from 'prop-types';
import { FormLabel, Switch, Grid } from '@mui/material';
import { StyledFormControl } from './booleanField.styled';

const BooleanField = ({
  label,
  value = false,
  required,
  disabled,
  onChange,
  isLabelAndFieldOnHorizontalLine,
  isBooleanFieldInForms = false,
}) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <StyledFormControl variant="standard" fullWidth>
      <Grid
        display={isLabelAndFieldOnHorizontalLine ? 'flex' : 'block'}
        justifyContent="space-between"
      >
        <Grid
          xs={
            isBooleanFieldInForms
              ? isLabelAndFieldOnHorizontalLine
                ? 6
                : 12
              : undefined
          }
          md={
            isBooleanFieldInForms
              ? isLabelAndFieldOnHorizontalLine
                ? 3
                : 12
              : undefined
          }
        >
          <FormLabel required={required} sx={{ fontSize: 16 }}>
            {label}
          </FormLabel>
        </Grid>
        <Grid
          xs={
            isBooleanFieldInForms
              ? isLabelAndFieldOnHorizontalLine
                ? 6
                : 12
              : undefined
          }
          md={
            isBooleanFieldInForms
              ? isLabelAndFieldOnHorizontalLine
                ? 9
                : 12
              : undefined
          }
          display="flex"
          justifyContent="flex-start"
          sx={{ px: 2 }}
        >
          <Switch
            checked={value}
            size="small"
            onChange={handleChange}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

BooleanField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLabelAndFieldOnHorizontalLine: PropTypes.bool,
  isBooleanFieldInForms: PropTypes.bool,
};

export default BooleanField;
