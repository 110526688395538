/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Checkbox,
  Alert,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { DialogActionsWrapper } from './confirmationDialog.styled';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

const ConfirmationDialog = ({
  open,
  title,
  messageLine1 = '',
  messageLine2 = '',
  messageLine3 = '',
  isAdditionalCheck,
  checked,
  onChange,
  yesButtonLabel,
  noButtonLabel,
  onYesClick,
  onNoClick,
  yesButtonLoading = false,
  alertSeverity = 'warning',
  isYesDisabled = false,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <Dialog open={open} onClose={onNoClick}>
      <DialogTitle fontSize={20}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          <Typography component={'div'} gutterBottom sx={{ fontSize: 16 }}>
            {messageLine1}
          </Typography>
          {messageLine2 && alertSeverity === 'warning' && (
            <Typography component={'div'} gutterBottom>
              <Alert severity={alertSeverity}>{messageLine2}</Alert>
            </Typography>
          )}
          {messageLine2 && alertSeverity === 'success' && (
            <Typography component={'div'} gutterBottom>
              <Alert severity={alertSeverity}>{messageLine2}</Alert>
            </Typography>
          )}

          {messageLine2 && alertSeverity === 'error' && (
            <Typography component={'div'} gutterBottom>
              <Alert severity={alertSeverity}>{messageLine2}</Alert>
            </Typography>
          )}

          {isAdditionalCheck && messageLine3 && (
            <DialogContentText>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={handleChange}
                    sx={{ alignItems: 'flex-start' }}
                    disabled={isYesDisabled}
                  />
                }
                label={
                  <Typography component="span" variant="h5" color="primary">
                    {messageLine3}
                  </Typography>
                }
              />
            </DialogContentText>
          )}
        </DialogContentText>
      </DialogContent>
      <Grid display="flex" justifyContent="flex-end" alignItems="flex-start">
        <DialogActionsWrapper>
          <Button variant="outlined" onClick={onNoClick}>
            {noButtonLabel || t('confirmationDialog.actions.no')}
          </Button>
        </DialogActionsWrapper>

        <DialogActionsWrapper>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={onYesClick}
            autoFocus
            loading={yesButtonLoading}
            disabled={isYesDisabled}
          >
            {yesButtonLabel || t('confirmationDialog.actions.yes')}
          </LoadingButton>
        </DialogActionsWrapper>
      </Grid>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  messageLine1: PropTypes.string,
  messageLine2: PropTypes.string,
  messageLine3: PropTypes.string,
  yesButtonLabel: PropTypes.string,
  noButtonLabel: PropTypes.string,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  isAdditionalCheck: PropTypes.bool,
  checked: PropTypes.string,
  onChange: PropTypes.func,
  yesButtonLoading: PropTypes.bool,
  alertSeverity: PropTypes.string,
  isDeleteDisabled: PropTypes.bool,
  isYesDisabled: PropTypes.bool,
};

export default ConfirmationDialog;
