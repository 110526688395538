export const GENERAL = {
  title: 'general',
  endpoint: '/',
};
export const MARKET_INTELLIGENCE = {
  title: 'marketIntelligence',
  endpoint: '/market-intelligence',
};
export const LANGUAGE_MODELS_CONFIG = {
  title: 'languageModelsConfig',
  endpoint: '/language-models-config',
};
export const ADD_PAGE = {
  endpoint: '/add',
};
