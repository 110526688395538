/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { FormLabel, OutlinedInput, Grid } from '@mui/material';
import { StyledFormControl } from './textField.styled';

const TextField = ({
  label,
  value,
  multiline,
  required,
  disabled,
  onChange,
  placeholder,
  isLabelAndFieldOnHorizontalLine = false,
}) => {
  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(event.target.value);
  };

  return (
    <StyledFormControl variant="standard" fullWidth>
      <Grid
        display={isLabelAndFieldOnHorizontalLine ? 'flex' : 'block'}
        justifyContent="space-between"
      >
        <Grid xs={isLabelAndFieldOnHorizontalLine ? 3 : 12} sx={{ my: 0.5 }}>
          <FormLabel required={required} sx={{ fontSize: 16 }}>
            {label}
          </FormLabel>
        </Grid>
        <Grid xs={isLabelAndFieldOnHorizontalLine ? 9 : 12}>
          <OutlinedInput
            value={value}
            label=""
            fullWidth
            placeholder={placeholder}
            margin="dense"
            required={required}
            multiline={multiline}
            size="small"
            disabled={disabled}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isLabelAndFieldOnHorizontalLine: PropTypes.bool,
};

export default TextField;
