/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Box, Grid, IconButton, styled, TextField } from '@mui/material';

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

export const StyledIconButton = styled(IconButton)({
  borderRadius: 0,
  marginLeft: 1,
  background: 'transparent',
});

export const FormGrid = styled(Grid)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

export const DetailsTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 18px) scale(1)',
    '&.Mui-focused': {
      transform: 'translate(14px, -6px) scale(0.75)',
      paddingTop: theme.spacing(2),
    },
    '&.MuiFormLabel-filled': {
      transform: 'translate(14px, -6px) scale(0.75)',
      paddingTop: theme.spacing(2),
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(3, 2, 2),
  },
  borderRadius: '4px',
  backgroundColor: theme.palette.background.light,
}));

export const FormContentBox = styled(Box)({
  margin: 3,
  maxWidth: '650px',
});

export const FlexGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  //marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const PopoverHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'primary.light',
  padding: 0,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1),
  borderTopLeftRadius: theme.spacing(4),
  borderTopRightRadius: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: theme.palette.background.light,
}));
