/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomToolbar from './addMetaDataGridCustomToolbar.component';
import DateTextField from '../../../../../../components/dateTextField';
import PropTypes from 'prop-types';
import { dataTypes } from '../../../../../../constants/dataTypes';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  StyledContentWrapper,
  FieldValueTypography,
  StyledHeaderColumn,
  StyledDataGrid,
  StyledClearIcon,
  StyledSearchIcon,
} from './addMetaDataGrid.styled';
import { useTranslation } from 'react-i18next';

const AddMetaDataGrid = ({
  propertiesData,
  handleInputChange,
  handleEdit,
  handleSave,
  handleOpenDeletePropertyDialog,
  propertyData,
  editProperties,
  onChangeEditProperties,
}) => {
  const { t } = useTranslation();
  let updatedPropertiesData = [];
  const [rows, setRows] = useState([...propertiesData]);

  const handleEditClick = (props) => {
    const propertyName = props.row.propertyName;

    handleEdit(props.row);
    onChangeEditProperties((prevProperties) => ({
      ...prevProperties,
      [propertyName]: {
        ...prevProperties[propertyName],
        isEdit: true,
      },
    }));
  };

  const handleSaveClick = (props) => {
    handleSave(props.row);
    const updatedPropertiesOnChange = { ...editProperties };
    propertiesData?.forEach((property) => {
      if (!updatedPropertiesOnChange[property.propertyName]) {
        updatedPropertiesOnChange[property.propertyName] = {
          isEdit: false,
        };
      } else {
        updatedPropertiesOnChange[property.propertyName].isEdit = false;
      }
    });
    onChangeEditProperties(updatedPropertiesOnChange);
  };

  const handleDeleteClick = (props) => {
    const propertyName = props.row.propertyName;
    setRows(rows.filter((row) => row.propertyName !== propertyName));
    handleOpenDeletePropertyDialog(props.row);
  };

  const handleCancelClick = (props) => {
    const updatedPropertiesOnChange = { ...editProperties };
    propertiesData?.forEach((property) => {
      if (!updatedPropertiesOnChange[property.propertyName]) {
        updatedPropertiesOnChange[property.propertyName] = {
          isEdit: false,
        };
      } else {
        updatedPropertiesOnChange[property.propertyName].isEdit = false;
      }
    });
    onChangeEditProperties(updatedPropertiesOnChange);
  };

  const handleChange = (e, params) => {
    e.stopPropagation();
    e.preventDefault();
    handleInputChange(params.row, e.target.value);
  };

  const handleKeyDown = (event, params) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      handleInputChange(params.row, propertyData.propertyValue + ' ');
    }
  };

  const renderEditCell = (params) => {
    const { dataType, propertyValue, propertyName } = params.row;
    if (editProperties[propertyName]?.isEdit || !propertyValue) {
      if (
        dataType === t('addMetadataPage.components.metadataGrid.dataTypes.date')
      ) {
        return (
          <Grid item>
            <DateTextField
              value={propertyData.propertyValue}
              onChange={(date) => handleInputChange(params.row, date)}
            />
          </Grid>
        );
      } else {
        return (
          <Grid item>
            <TextField
              value={propertyData.propertyValue}
              onChange={(e) => handleChange(e, params)}
              variant="outlined"
              size="small"
              sx={{ fontSize: 16 }}
              onKeyDown={(e) => handleKeyDown(e, params)}
            />
          </Grid>
        );
      }
    } else {
      return (
        <Grid item>
          <FieldValueTypography variant="h5" sx={{ fontSize: 18 }}>
            {propertyValue}
          </FieldValueTypography>
        </Grid>
      );
    }
  };

  const propertyNameColumn = (props) => {
    return (
      <StyledContentWrapper>
        <Typography variant="h5" sx={{ fontSize: 16 }}>
          {props?.row.propertyName}
          <br />
        </Typography>
      </StyledContentWrapper>
    );
  };

  const displayNameColumn = (props) => {
    return (
      <StyledContentWrapper>
        <Typography variant="h5" sx={{ fontSize: 16 }}>
          {props?.row.displayName}
          <br />
        </Typography>
      </StyledContentWrapper>
    );
  };

  const dataTypeColumn = (props) => {
    return (
      <StyledContentWrapper>
        <FieldValueTypography variant="h5">
          {
            dataTypes.find(
              (dataType) => dataType.value === props?.row?.dataType,
            )?.label
          }
        </FieldValueTypography>
      </StyledContentWrapper>
    );
  };

  const columns = [
    {
      field: t(
        'addMetadataPage.components.metadataGrid.columns.displayName.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          <Typography variant="h4" sx={{ fontSize: 18 }}>
            {t(
              'addMetadataPage.components.metadataGrid.columns.displayName.headerName',
            )}
          </Typography>
        </StyledHeaderColumn>
      ),
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: displayNameColumn,
    },
    {
      field: t(
        'addMetadataPage.components.metadataGrid.columns.propertyName.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          <Typography variant="h4" sx={{ fontSize: 18 }}>
            {t(
              'addMetadataPage.components.metadataGrid.columns.propertyName.headerName',
            )}
          </Typography>
        </StyledHeaderColumn>
      ),
      width: 200,
      headerAlign: 'left',
      align: 'left',
      headerClassName: t(
        'addMetadataPage.components.metadataGrid.columns.propertyName.headerClassName',
      ),
      renderCell: propertyNameColumn,
    },

    {
      field: t(
        'addMetadataPage.components.metadataGrid.columns.propertyValue.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          <Typography variant="h4" sx={{ fontSize: 18 }}>
            {t(
              'addMetadataPage.components.metadataGrid.columns.propertyValue.headerName',
            )}
          </Typography>
        </StyledHeaderColumn>
      ),
      minWidth: 300,
      headerAlign: 'left',
      align: 'left',

      renderCell: renderEditCell,
    },
    {
      field: t(
        'addMetadataPage.components.metadataGrid.columns.dataType.field',
      ),
      headerName: (
        <StyledHeaderColumn>
          <Typography variant="h4" sx={{ fontSize: 18 }}>
            {t(
              'addMetadataPage.components.metadataGrid.columns.dataType.headerName',
            )}
          </Typography>
        </StyledHeaderColumn>
      ),
      width: 120,
      headerAlign: 'left',
      flex: 1,
      align: 'left',
      headerClassName: t(
        'addMetadataPage.components.metadataGrid.columns.dataType.headerClassName',
      ),
      renderCell: dataTypeColumn,
    },
    {
      field: t('addMetadataPage.components.metadataGrid.columns.actions.field'),
      type: t('addMetadataPage.components.metadataGrid.columns.actions.field'),
      headerName: (
        <StyledHeaderColumn>
          <Typography variant="h4" sx={{ fontSize: 18 }}>
            {t(
              'addMetadataPage.components.metadataGrid.columns.actions.headerName',
            )}
          </Typography>
        </StyledHeaderColumn>
      ),
      width: 120,
      cellClassName: t(
        'addMetadataPage.components.metadataGrid.columns.actions.field',
      ),
      getActions: (props) => {
        const { propertyName, propertyValue } = props.row;

        if (editProperties[propertyName]?.isEdit || !propertyValue) {
          return [
            <GridActionsCellItem
              key={t(
                'addMetadataPage.components.metadataGrid.columns.actions.save.key',
              )}
              icon={<SaveIcon />}
              label={t(
                'addMetadataPage.components.metadataGrid.columns.actions.save.label',
              )}
              color="success"
              onClick={() => handleSaveClick(props)}
            />,
            <GridActionsCellItem
              key={t(
                'addMetadataPage.components.metadataGrid.columns.actions.cancel.key',
              )}
              icon={<CancelIcon />}
              label={t(
                'addMetadataPage.components.metadataGrid.columns.actions.cancel.label',
              )}
              className="textPrimary"
              onClick={() => handleCancelClick(props)}
              color="error"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={t(
              'addMetadataPage.components.metadataGrid.columns.actions.edit.key',
            )}
            icon={<EditIcon />}
            label={t(
              'addMetadataPage.components.metadataGrid.columns.actions.edit.label',
            )}
            className="textPrimary"
            onClick={() => handleEditClick(props)}
            color="primary"
            disabled={
              false ||
              (Object.prototype.hasOwnProperty.call(
                props.row,
                t('addMetadataPage.components.metadataGrid.mutation'),
              )
                ? !props?.row?.mutation
                : false)
            }
          />,
          <GridActionsCellItem
            key={t(
              'addMetadataPage.components.metadataGrid.columns.actions.delete.key',
            )}
            icon={<DeleteIcon />}
            label={t(
              'addMetadataPage.components.metadataGrid.columns.actions.delete.label',
            )}
            onClick={() => handleDeleteClick(props)}
            color="primary"
            disabled={
              false ||
              (Object.prototype.hasOwnProperty.call(
                props.row,
                t('addMetadataPage.components.metadataGrid.mutation'),
              )
                ? !props?.row?.mutation
                : false)
            }
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <StyledDataGrid
        rows={propertiesData}
        columns={columns}
        pageSize={10}
        autoHeight
        getRowId={(row) => row.propertyName}
        getRowHeight={() => 48}
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSelector
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
          QuickFilterIcon: StyledSearchIcon,
          QuickFilterClearIcon: StyledClearIcon,
        }}
      />
    </Box>
  );
};

AddMetaDataGrid.propTypes = {
  rows: PropTypes.array,
  row: PropTypes.object,
  propertiesData: PropTypes.array,
  handleInputChange: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  handleOpenDeletePropertyDialog: PropTypes.func,
  propertyData: PropTypes.object,
  editProperties: PropTypes.bool,
  onChangeEditProperties: PropTypes.func,
};

export default AddMetaDataGrid;
