/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { styled, FormControl, FormLabel } from '@mui/material';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  fullWidth: true,
  display: 'flex',
  wrap: 'nowrap',
}));
