import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link } from '@mui/material';

const QnA = ({ query, results }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { answer } = results;

  return (
    <Box paddingY={2}>
      <Typography variant="h5" gutterBottom>
        {query}
      </Typography>
      <Typography variant="body2">
        {isExpanded ? answer : answer.slice(0, 200)}&nbsp;
        {answer.length > 200 && (
          <Link
            type="button"
            component="button"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'view less' : 'view more'}
          </Link>
        )}
      </Typography>
    </Box>
  );
};

QnA.propTypes = {
  query: PropTypes.string.isRequired,
  results: PropTypes.object.isRequired,
};

export default QnA;
