/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomToolbar from '../components/customToolbar';
import Header from '../../../components/form/header.component';
import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import { ACCESS_CONTROL } from '../../../constants/path';
import { POLICIES, ADD_PAGE } from '../../../constants/accessControlPaths';
import { NoDataTitle, TableStyled } from '../styled';
import {
  useDeleteManyRBACMutation,
  useLazyGetAllRBACQuery,
} from '../../../redux/services/speciphicAsk';
import { RBAC_POLICIES_ENDPOINT } from '../../../constants/accessControl';
import Loader from '../../../components/loader/loader.component';
import DisplayTime from '../../../components/timezone.component';
import { filterByIds, sortByDateField } from '../../../utils/functions';
import UnauthorizedMessage from '../../../components/unauthorized';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import DialogComponent from '../../../components/app.dialog';

const addPageRoute = `${ACCESS_CONTROL}${POLICIES.endpoint}${ADD_PAGE.endpoint}`;

const translationJSONPrefix = `accessControlSection.policies.mainPage`;

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.table.columnHeaders`;

  return [
    {
      field: 'name',
      headerName: t(`${translationPrefix}.name`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.name}`,
    },
    {
      field: 'effect',
      headerName: t(`${translationPrefix}.effect`),
      flex: 0.2,
      minWidth: 100,
      valueGetter: (params) => `${params.row?.effect}`,
    },
    {
      field: 'description',
      headerName: t(`${translationPrefix}.description`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.description || '-'}`,
    },
    {
      field: 'pup',
      headerName: t(`${translationPrefix}.pup`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.pup}`,
    },
    {
      field: 'createdAt',
      headerName: t(`${translationPrefix}.createdAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => <DisplayTime time={params.row?.createdDate} />,
    },
    {
      field: 'updatedAt',
      headerName: t(`${translationPrefix}.updatedAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => (
        <DisplayTime time={params.row?.lastUpdatedDate} />
      ),
    },
  ];
};

const AccessControlPoliciesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogTFValue, setDeleteDialogTFValue] = useState('');
  const [dialogDeleteButtonDisabled, setDialogDeleteButtonDisabled] =
    useState(true);

  const [getAllRBAC, { data, error, isError, isLoading, isSuccess }] =
    useLazyGetAllRBACQuery();

  const [
    deleteRBACs,
    {
      data: deletedResponse,
      error: deleteError,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteManyRBACMutation();

  useEffect(() => {
    setDialogDeleteButtonDisabled(true);
    setDeleteDialogTFValue('');
  }, [deleteDialogOpen]);

  useEffect(() => {
    if (deleteError?.status === 403) {
      dispatch(
        pushToast({
          message: t(`${unauthorizedTranslationPrefix}.delete`),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteError]);

  useEffect(() => {
    getAllRBAC({
      endpoint: RBAC_POLICIES_ENDPOINT,
    });

    setSelectedPolicies([]);
  }, [deletedResponse]);

  const handleCellClick = (e) => {
    if (e?.field === 'name') navigate(`${addPageRoute}?n=${e?.row?.name}`);
  };

  useEffect(() => {
    setDeleteDialogOpen(false);
  }, [isDeleteSuccess]);

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.ACCESS_CONTROL}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.ACCESS_CONTROL}
    >
      <DialogComponent
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        setTextFieldValue={setDeleteDialogTFValue}
        textField={{
          name: 'name',
          value: deleteDialogTFValue,
          onChange: (e) => {
            setDeleteDialogTFValue(e.target.value);

            if (selectedPolicies.length > 1) {
              if (e.target.value === 'permanently delete')
                setDialogDeleteButtonDisabled(false);
              return;
            }

            const p_names = filterByIds(selectedPolicies, data).map(
              (p) => p.name,
            );

            setDialogDeleteButtonDisabled(!(e.target.value === p_names[0]));
          },
          placeholder:
            selectedPolicies.length > 1
              ? t(
                  `accessControlSection.policies.deleteDialog.textFieldPlaceholderPlural`,
                )
              : t(
                  `accessControlSection.policies.deleteDialog.textFieldPlaceholder`,
                ),
        }}
        title={`${t(`accessControlSection.policies.deleteDialog.title`)}`}
        content={
          selectedPolicies.length > 1
            ? t(`accessControlSection.policies.deleteDialog.contentPlural`)
            : `${t(`accessControlSection.policies.deleteDialog.content`)}`
        }
        highlightedTitle={
          selectedPolicies.length === 1
            ? `${t(
                `accessControlSection.policies.deleteDialog.highlightedTitle`,
              )}`
            : ''
        }
        highlightedContent={`${
          selectedPolicies.length === 1
            ? filterByIds(selectedPolicies, data)[0]?.name
            : ''
        }`}
        onSubmit={(e) => {
          deleteRBACs({
            endpoint: RBAC_POLICIES_ENDPOINT,
            names: filterByIds(selectedPolicies, data).map((p) => p.name),
          });
        }}
        submitBtnDisabled={dialogDeleteButtonDisabled}
        isDeleteLoading={isDeleteLoading}
        cancelBtnLabel={t(
          `accessControlSection.policies.deleteDialog.buttons.cancel`,
        )}
        submitBtnLabel={t(
          `accessControlSection.policies.deleteDialog.buttons.delete`,
        )}
      />
      <Header
        header={t(`${translationJSONPrefix}.title`)}
        addButtonLabel={t(`${translationJSONPrefix}.addButtonLabel`)}
        deleteButton={true}
        deleteButtonDisabled={selectedPolicies.length === 0}
        addButtonOnClick={() => navigate(addPageRoute)}
        deleteButtonOnClick={() => setDeleteDialogOpen(true)}
        isDeleteLoading={isDeleteLoading}
      />
      {isLoading ? (
        <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
      ) : data?.length > 0 ? (
        <TableStyled
          onCellClick={handleCellClick}
          rows={sortByDateField([...data], 'lastUpdatedDate')}
          columns={columns(t)}
          pageSize={10}
          checkboxSelection
          autoHeight
          onSelectionModelChange={setSelectedPolicies}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      ) : (
        <NoDataTitle>{t(`${translationJSONPrefix}.noDataLabel`)}</NoDataTitle>
      )}
    </PageContainer>
  );
};

export default AccessControlPoliciesPage;
