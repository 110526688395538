/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const getAll = (builder) =>
  builder.query({
    query: ({ collectionId }) => {
      return {
        url: `file-collections/${collectionId}/access-policies`,
        method: 'GET',
      };
    },
    providesTags: ['file-collection-access-policies'],
  });

export const getByName = (builder) =>
  builder.query({
    query: ({ collectionId, accessPolicyName }) => {
      return {
        url: `file-collections/${collectionId}/access-policies/${accessPolicyName}`,
        method: 'GET',
      };
    },
  });

export const create = (builder) =>
  builder.mutation({
    query: ({ collectionId, newPolicy }) => ({
      url: `/file-collections/${collectionId}/access-policies`,
      method: 'POST',
      body: newPolicy,
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  });

export const update = (builder) =>
  builder.mutation({
    query: ({ collectionId, accessPolicyName, updatedPolicy }) => ({
      url: `/file-collections/${collectionId}/access-policies/${accessPolicyName}`,
      method: 'PATCH',
      body: updatedPolicy,
      header: {
        'Content-Type': 'application/json',
      },
    }),
  });

export const deletePolicy = (builder) =>
  builder.mutation({
    query: ({ collectionId, accessPolicyName }) => ({
      url: `/file-collections/${collectionId}/access-policies/${accessPolicyName}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['file-collection-access-policies'],
  });

export const deletePolicies = (builder) =>
  builder.mutation({
    query: ({ collectionId, accessPoliciesNames }) => ({
      url: `/file-collections/${collectionId}/access-policies`,
      method: 'DELETE',
      body: { accessPoliciesNames },
    }),
    invalidatesTags: ['file-collection-access-policies'],
  });
