import * as React from 'react';
const ReportIconSvg = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.77778 20.7646C5.28889 20.7646 4.87037 20.5906 4.52222 20.2424C4.17407 19.8943 4 19.4758 4 18.9869V6.54243C4 6.05354 4.17407 5.63502 4.52222 5.28687C4.87037 4.93872 5.28889 4.76465 5.77778 4.76465H15.5556L20 9.20909V18.9869C20 19.4758 19.8259 19.8943 19.4778 20.2424C19.1296 20.5906 18.7111 20.7646 18.2222 20.7646H5.77778ZM7.55556 17.2091H16.4444V15.4313H7.55556V17.2091ZM7.55556 13.6535H16.4444V11.8758H7.55556V13.6535ZM14.6667 10.098H18.2222L14.6667 6.54243V10.098ZM7.55556 10.098H12V8.3202H7.55556V10.098Z"
      fill="currentColor"
    />
  </svg>
);
export default ReportIconSvg;
