/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, DialogActions, Grid, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import colors from '../../../themes/colors';
import fontWeights from '../../../themes/fontWeights';
import { LoadingButton } from '@mui/lab';

export const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  color: colors.neutralLight.N800,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const HeadingGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginX: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    //color: theme.palette.secondary.dark,
    backgroundColor: '#E7EDF5',
    fontSize: 16,
    fontWeight: fontWeights.extraBold,
    color: theme.palette.text.primary,
  },
  '&.MuiDataGrid-root': {
    borderRadius: 0,
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const ShadowBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  width: '100%',
}));

export const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const StyledLoadingButton = styled(LoadingButton)(() => ({
  minWidth: 2,
}));
