/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomToolbar from '../components/customToolbar';
import Header from '../../../components/form/header.component';
import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import { ACCESS_CONTROL } from '../../../constants/path';
import { USER_ROLE, ADD_PAGE } from '../../../constants/accessControlPaths';
import { NoDataTitle, TableStyled } from '../styled';
import {
  useDeleteManyRBACMutation,
  useLazyGetAllRBACQuery,
} from '../../../redux/services/speciphicAsk';
import { RBAC_USER_ROLES_ENDPOINT } from '../../../constants/accessControl';
import Loader from '../../../components/loader/loader.component';
import DisplayTime from '../../../components/timezone.component';
import { sortByDateField } from '../../../utils/functions';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import UnauthorizedMessage from '../../../components/unauthorized';

const addPageRoute = `${ACCESS_CONTROL}${USER_ROLE.endpoint}${ADD_PAGE.endpoint}`;

const translationJSONPrefix = `accessControlSection.userRole.mainPage`;

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.table.columnHeaders`;

  return [
    {
      field: 'role',
      headerName: t(`${translationPrefix}.role`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.name}`,
    },
    {
      field: 'users',
      headerName: t(`${translationPrefix}.users`),
      flex: 0.4,
      minWidth: 150,
      valueGetter: (params) => `${params.row?.userCount}`,
    },
    {
      field: 'createdBy',
      headerName: t(`${translationPrefix}.createdBy`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.createdBy}`,
    },
    {
      field: 'createdAt',
      headerName: t(`${translationPrefix}.createdAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => <DisplayTime time={params.row?.createdDate} />,
    },
    {
      field: 'updatedAt',
      headerName: t(`${translationPrefix}.updatedAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => (
        <DisplayTime time={params.row?.lastUpdatedDate} />
      ),
    },
  ];
};

const AccessControlPoliciesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [getAllRBAC, { data, error, isError, isLoading, isSuccess }] =
    useLazyGetAllRBACQuery();

  const [
    deleteRBACs,
    {
      data: deletedResponse,
      error: deleteError,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteManyRBACMutation();

  useEffect(() => {
    if (deleteError?.status === 403) {
      dispatch(
        pushToast({
          message: t(`${unauthorizedTranslationPrefix}.delete`),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteError]);

  const handleDelete = () => {
    deleteRBACs({
      endpoint: RBAC_USER_ROLES_ENDPOINT,
      ids: selectedRoles,
    });
  };

  useEffect(() => {
    getAllRBAC({
      endpoint: RBAC_USER_ROLES_ENDPOINT,
    });

    setSelectedRoles([]);
  }, [deletedResponse]);

  const handleCellClick = (e) => {
    if (e?.field === 'role') navigate(`${addPageRoute}?id=${e?.id}`);
  };

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.ACCESS_CONTROL}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.ACCESS_CONTROL}
    >
      <Header header={t(`${translationJSONPrefix}.title`)} />
      {isLoading ? (
        <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
      ) : data?.length > 0 ? (
        <TableStyled
          onCellClick={handleCellClick}
          rows={sortByDateField([...data], 'lastUpdatedDate')}
          columns={columns(t)}
          pageSize={10}
          checkboxSelection
          autoHeight
          onSelectionModelChange={setSelectedRoles}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      ) : (
        <NoDataTitle>{t(`${translationJSONPrefix}.noDataLabel`)}</NoDataTitle>
      )}
    </PageContainer>
  );
};

export default AccessControlPoliciesPage;
