import { Box, styled, Typography } from '@mui/material';

export const LoaderContainer = styled(Box)(({ theme }) => ({
  margin: 10,
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));
