/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const muiListItemText = {
  styleOverrides: {
    primary: ({ theme }) => ({
      color: theme.palette.text.primary,
    }),
  },
};

export { muiListItemText };
