/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
const { REACT_APP_ENABLE_CONVERSATION_FUNCTIONALITY } = process.env;

let isConversationHistoryEnabled = false;

if (
  REACT_APP_ENABLE_CONVERSATION_FUNCTIONALITY &&
  REACT_APP_ENABLE_CONVERSATION_FUNCTIONALITY.toLowerCase() === 'true'
) {
  isConversationHistoryEnabled = true;
}

export default isConversationHistoryEnabled;
