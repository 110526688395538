/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ collection_id, query, answer, meta }) => ({
      url: `chat-history`,
      method: 'POST',
      body: {
        collection_id: collection_id,
        query: query,
        answer: answer,
        meta: meta,
      },
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['chatHistory'],
  });
