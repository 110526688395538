/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
export default (builder) =>
  builder.query({
    query: ({ query, feature, status }) => {
      const params = new URLSearchParams();
      params.append('query', query);
      params.append('feature', feature);
      params.append('status', status);
      return {
        url: `/report-keys?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['preferences'],
  });
