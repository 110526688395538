import * as React from 'react';
const EditIconSvg = (props) => (
  <svg
    width={16}
    height={18}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2686 8.15958C11.2686 7.79853 10.986 7.50586 10.6374 7.50586H4.1363C3.78771 7.50586 3.50513 7.79853 3.50513 8.15958C3.50513 8.52063 3.78771 8.8133 4.1363 8.8133H10.6374C10.986 8.8133 11.2686 8.52063 11.2686 8.15958Z"
      fill="#525A66"
    />
    <path
      d="M4.1363 10.1206C3.78771 10.1206 3.50513 10.4133 3.50513 10.7743C3.50513 11.1354 3.78771 11.428 4.1363 11.428H8.08467C8.43327 11.428 8.71585 11.1354 8.71585 10.7743C8.71585 10.4133 8.43327 10.1206 8.08467 10.1206H4.1363Z"
      fill="#525A66"
    />
    <path
      d="M5.61954 16.3965H3.5086C2.81254 16.3965 2.24624 15.81 2.24624 15.0891V3.58363C2.24624 2.86271 2.81254 2.27619 3.5086 2.27619H11.2686C11.9646 2.27619 12.5309 2.86271 12.5309 3.58363V7.60401C12.5309 7.96506 12.8135 8.25773 13.1621 8.25773C13.5107 8.25773 13.7933 7.96506 13.7933 7.60401V3.58363C13.7933 2.14179 12.6607 0.96875 11.2686 0.96875H3.5086C2.11647 0.96875 0.983887 2.14179 0.983887 3.58363V15.0891C0.983887 16.5309 2.11647 17.704 3.5086 17.704H5.61954C5.96814 17.704 6.25072 17.4113 6.25072 17.0503C6.25072 16.6892 5.96814 16.3965 5.61954 16.3965Z"
      fill="#525A66"
    />
    <path
      d="M14.9113 10.4339C14.173 9.66927 12.9717 9.66923 12.2339 10.4334L8.76866 14.0145C8.69506 14.0905 8.64078 14.1842 8.61055 14.2872L7.85588 16.8604C7.78897 17.0886 7.84726 17.3363 8.00802 17.507C8.12845 17.6348 8.29206 17.7042 8.45985 17.7042C8.516 17.7042 8.57268 17.6964 8.62831 17.6804L11.1759 16.9495C11.2807 16.9195 11.3763 16.8619 11.4533 16.7822L14.9113 13.2075C15.6496 12.4428 15.6496 11.1986 14.9113 10.4339ZM10.679 15.7355L9.39731 16.1032L9.77239 14.8242L12.1106 12.4079L13.0034 13.3326L10.679 15.7355ZM14.0191 12.2826L13.8969 12.409L13.0042 11.4844L13.1261 11.3585C13.3722 11.1036 13.7726 11.1036 14.0187 11.3585C14.2648 11.6134 14.2648 12.0281 14.0191 12.2826Z"
      fill="#525A66"
    />
    <path
      d="M10.6374 4.89111H4.1363C3.78771 4.89111 3.50513 5.18378 3.50513 5.54483C3.50513 5.90588 3.78771 6.19855 4.1363 6.19855H10.6374C10.986 6.19855 11.2686 5.90588 11.2686 5.54483C11.2686 5.18378 10.986 4.89111 10.6374 4.89111Z"
      fill="#525A66"
    />
  </svg>
);
export default EditIconSvg;
