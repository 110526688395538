/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { Form } from 'formik';
import Favicon from 'react-favicon';
import PageContainer from '../../components/pageContainer';
import {
  useAddSiteConfigurationMutation,
  useUpdateSiteConfigurationMutation,
  useLazyGetSiteConfigurationQuery,
} from '../../redux/services/speciphicAsk';
import { SECTIONS } from '../../constants/drawer';
import {
  AlertStyled,
  InputsContainer,
  PageHeader,
} from '../settings/filesCollectionSettingsPage/styled';
import AppForm, { AppTextField, FormButtons } from '../../components/form';
import { getChangedValues } from '../../components/form/functions';
import Loader from '../../components/loader';
import { AppRadioGroup } from '../../components/form/form.component';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import { FormContainer, ImageSelectorContainer, ImageStyled } from './styled';
import UnauthorizedMessage from '../../components/unauthorized';

const translationJSONPrefix = `appConfigurationSection.general`;

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const defaultDataState = {
  applicationName: '',
  infographicsType: 'svg',
  infographicsText: '',
  infographicsFile: '',
  headerLogoFile: '',
  footerLogoFile: '',
  cookiePolicy: '',
  privacyPolicy: '',
  termsOfUse: '',
  markdownStyle: '',
  assistantLogoFile: '',
  favicon: '',
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.form.${label}.errorMessages`;

  return object({
    applicationName: string()
      .required(t(`${translationPrefix('name')}.required`))
      .min(2, t(`${translationPrefix('name')}.min`))
      .max(50, t(`${translationPrefix('name')}.max`)),
    infographicsType: string(),
    infographicsText: string(),
    infographicsFile: string(),
    headerLogoFile: string(),
    footerLogoFile: string(),
    assistantLogoFile: string(),
    favicon: string(),
    cookiePolicy: string().required(
      t(`${translationPrefix('cookiePolicy')}.required`),
    ),
    privacyPolicy: string().required(
      t(`${translationPrefix('privacyPolicy')}.required`),
    ),
    termsOfUse: string().required(
      t(`${translationPrefix('termsOfUse')}.required`),
    ),
    markdownStyle: string(),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  files,
  setFiles,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.form`;

  return (
    <Form>
      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name="applicationName"
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <InputsContainer>
        <AppRadioGroup
          style={{ width: '100%' }}
          name="infographicsType"
          label={t(`${translationPrefix}.infoType.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          row
          options={[
            {
              label: 'SVG',
              value: 'svg',
            },
            {
              label: 'Text',
              value: 'text',
            },
          ]}
        />
        {values['infographicsType'] === 'svg' ? (
          <ImageSelectorContainer>
            <ImageStyled
              src={files['infographicsFile']}
              alt="infographicsFile"
            />
            <AppTextField
              type="file"
              style={{ width: '100%' }}
              name="infographicsFile"
              tooltipTitle={t(`${translationPrefix}.infographics.tooltip`)}
              label={t(`${translationPrefix}.infographics.label`)}
              onChange={(e) => {
                setFiles({
                  ...files,
                  infographicsFile: URL.createObjectURL(
                    e.currentTarget.files[0],
                  ),
                });
                setFieldValue('infographicsFile', e.currentTarget.files[0]);
              }}
              inputProps={{ accept: '.svg' }}
              values={{
                infographicsFile: values['infographicsFile']?.image || '',
              }}
              errors={errors}
              touched={touched}
            />
          </ImageSelectorContainer>
        ) : (
          <AppTextField
            style={{ width: '100%' }}
            name="infographicsText"
            label={t(`${translationPrefix}.infographics.label`)}
            placeholder={t(`${translationPrefix}.infographics.placeholder`)}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        )}
      </InputsContainer>

      <InputsContainer>
        <ImageSelectorContainer>
          <ImageStyled src={files['headerLogoFile']} alt="headerLogoFile" />
          <AppTextField
            type="file"
            style={{ width: '100%' }}
            name="headerLogoFile"
            tooltipTitle={t(`${translationPrefix}.headerLogo.tooltip`)}
            label={t(`${translationPrefix}.headerLogo.label`)}
            onChange={(e) => {
              setFiles({
                ...files,
                headerLogoFile: URL.createObjectURL(e.currentTarget.files[0]),
              });
              setFieldValue('headerLogoFile', e.currentTarget.files[0]);
            }}
            inputProps={{ accept: '.svg' }}
            values={{
              headerLogoFile: values['headerLogoFile']?.image || '',
            }}
            errors={errors}
            touched={touched}
          />
        </ImageSelectorContainer>

        <ImageSelectorContainer>
          <ImageStyled src={files['footerLogoFile']} alt="footerLogoFile" />
          <AppTextField
            type="file"
            style={{ width: '100%' }}
            name="footerLogoFile"
            tooltipTitle={t(`${translationPrefix}.footerLogo.tooltip`)}
            label={t(`${translationPrefix}.footerLogo.label`)}
            onChange={(e) => {
              setFiles({
                ...files,
                footerLogoFile: URL.createObjectURL(e.currentTarget.files[0]),
              });
              setFieldValue('footerLogoFile', e.currentTarget.files[0]);
            }}
            inputProps={{ accept: '.svg' }}
            values={{
              footerLogoFile: values['footerLogoFile']?.image || '',
            }}
            errors={errors}
            touched={touched}
          />
        </ImageSelectorContainer>
      </InputsContainer>
      <ImageSelectorContainer>
        <ImageStyled src={files['assistantLogoFile']} alt="assistantLogoFile" />
        <AppTextField
          type="file"
          style={{ width: '100%' }}
          name="assistantLogoFile"
          tooltipTitle={t(`${translationPrefix}.assistantLogo.tooltip`)}
          label={t(`${translationPrefix}.assistantLogo.label`)}
          onChange={(e) => {
            setFiles({
              ...files,
              assistantLogoFile: URL.createObjectURL(e.currentTarget.files[0]),
            });
            setFieldValue('assistantLogoFile', e.currentTarget.files[0]);
          }}
          inputProps={{ accept: '.svg' }}
          values={{
            assistantLogoFile: values['assistantLogoFile']?.image || '',
          }}
          errors={errors}
          touched={touched}
        />
      </ImageSelectorContainer>
      <ImageSelectorContainer>
        <ImageStyled src={files['favicon']} alt="favicon" />
        <AppTextField
          type="file"
          style={{ width: '100%' }}
          name="favicon"
          tooltipTitle={t(`${translationPrefix}.favicon.tooltip`)}
          label={t(`${translationPrefix}.favicon.label`)}
          onChange={(e) => {
            setFiles({
              ...files,
              favicon: URL.createObjectURL(e.currentTarget.files[0]),
            });
            setFieldValue('favicon', e.currentTarget.files[0]);
          }}
          inputProps={{ accept: '.ico' }}
          values={{
            favicon: values['favicon']?.image || '',
          }}
          errors={errors}
          touched={touched}
        />
      </ImageSelectorContainer>

      {['cookiePolicy', 'privacyPolicy', 'termsOfUse', 'markdownStyle'].map(
        (n, i) => (
          <InputsContainer key={i}>
            <AppTextField
              style={{ width: '100%' }}
              name={n}
              label={t(`${translationPrefix}.${n}.label`)}
              placeholder={t(`${translationPrefix}.${n}.placeholder`)}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              multiline
              minRows={5}
            />
          </InputsContainer>
        ),
      )}

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const GeneralConfigPage = () => {
  const { t } = useTranslation();
  const { setSiteConfig } = useContext(SiteConfigContext);
  const { getSiteConfig } = useContext(SiteConfigContext);
  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isConfigured, setIsConfigured] = useState(true);
  const [favicon, setFavicon] = useState('');
  const [files, setFiles] = useState({
    infographicsFile: '',
    headerLogoFile: '',
    footerLogoFile: '',
    assistantLogoFile: '',
    favicon: '',
  });
  const [pageDrawerOpen, setPageDrawerOpen] = useState(true);
  const [
    getSiteConfiguration,
    {
      data: configurationResult,
      error,
      isError: isGetConfigurationsError,
      isLoading: isGetConfigurationsLoading,
      isSuccess: isConfigurationsSuccess,
    },
  ] = useLazyGetSiteConfigurationQuery();

  const [
    addSiteConfiguration,
    {
      data: addedConfiguration,
      isSuccess: isCreateSuccess,
      isLoading: isAddConfigurationLoading,
      isError: isCreateError,
    },
  ] = useAddSiteConfigurationMutation();

  const [
    updateSiteConfiguration,
    {
      data: updatedConfiguration,
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateConfigurationLoading,
      isError: isUpdateError,
    },
  ] = useUpdateSiteConfigurationMutation();

  useEffect(() => {
    const siteConfig = getSiteConfig();

    if (siteConfig) {
      setFavicon(siteConfig?.favicon);
    }
  }, [getSiteConfig()]);

  useEffect(() => {
    if (isConfigurationsSuccess) {
      let result = configurationResult?.siteConfiguration;

      if (!result) {
        setIsConfigured(false);
        return;
      }

      setSiteConfig(configurationResult?.siteConfiguration);

      const {
        applicationName,
        cookiePolicy,
        footerLogoFile,
        headerLogoFile,
        infographicsType,
        infographicsFile,
        infographicsText,
        isSiteConfigured,
        privacyPolicy,
        termsOfUse,
        markdownStyle,
        assistantLogoFile,
        favicon,
      } = result;

      setIsConfigured(isSiteConfigured ? true : false);

      const data = {
        applicationName: applicationName || '',
        infographicsType:
          infographicsType || (infographicsFile ? 'svg' : 'text'),
        infographicsText: infographicsText || '',
        infographicsFile: '', // from backend we are getting it as base64 url, so handling this in different state
        headerLogoFile: '', // from backend we are getting it as base64 url, so handling this in different state
        footerLogoFile: '', // from backend we are getting it as base64 url, so handling this in different state
        cookiePolicy: cookiePolicy || '',
        privacyPolicy: privacyPolicy || '',
        termsOfUse: termsOfUse || '',
        markdownStyle: markdownStyle || '',
        assistantLogoFile: '',
        favicon: '',
      };

      setFiles({
        infographicsFile: infographicsFile || '',
        headerLogoFile: headerLogoFile || '',
        footerLogoFile: footerLogoFile || '',
        assistantLogoFile: assistantLogoFile || '',
        favicon: favicon || '',
      });

      setFormData(data);
      setInitialFormData(data);
    } else getSiteConfiguration();
  }, [configurationResult]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateError || isUpdateError) {
      tempAlert.formUpdateError = true;
      tempAlert.formUpdateSuccess = false;
      setShowAlert(tempAlert);
      getSiteConfiguration();
    }
  }, [isCreateError, isUpdateError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateSuccess || isUpdateSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
      getSiteConfiguration();
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  if (error?.status === 403)
    return (
      <UnauthorizedMessage
        isDrawerOpen={pageDrawerOpen}
        drawer={isConfigured ? SECTIONS.APP_CONFIGURATION : null}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setPageDrawerOpen(res)}
      drawer={isConfigured ? SECTIONS.APP_CONFIGURATION : null}
    >
      <PageHeader>{t(`${translationJSONPrefix}.pageHeader`)}</PageHeader>
      {!isGetConfigurationsLoading ? (
        <FormContainer>
          <AlertStyled
            onClose={closeAlert}
            severity={showAlert.formUpdateError ? 'error' : 'success'}
            style={{
              marginTop:
                showAlert.formUpdateError || showAlert.formUpdateSuccess
                  ? 10
                  : -60,
            }}
          >
            {showAlert.formUpdateError &&
              (isConfigured
                ? t(`${translationJSONPrefix}.alert.updateFormError`)
                : t(`${translationJSONPrefix}.alert.addFormError`))}
            {showAlert.formUpdateSuccess &&
              (isConfigured
                ? t(`${translationJSONPrefix}.alert.updateFormSuccess`)
                : t(`${translationJSONPrefix}.alert.addFormSuccess`))}
          </AlertStyled>

          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              if (isConfigured) {
                const valuesToSend = getChangedValues(values, initialFormData);

                updateSiteConfiguration({
                  ...valuesToSend,
                });
              } else addSiteConfiguration({ ...values });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isAddConfigurationLoading || isUpdateConfigurationLoading,
                files,
                setFiles,
              )
            }
          />
        </FormContainer>
      ) : (
        <Loader label={t(`${translationJSONPrefix}.loadingMessage`)} />
      )}
    </PageContainer>
  );
};

export default GeneralConfigPage;
