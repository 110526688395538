/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createAction, createReducer } from '@reduxjs/toolkit';

const setSelectedFileCollection = createAction('select-fileCollection');

const fileCollectionReducer = createReducer({}, (builder) => {
  builder.addCase(setSelectedFileCollection, (state, action) => {
    return { ...action.payload };
  });
});

export { setSelectedFileCollection };
export default fileCollectionReducer;
