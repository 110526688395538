/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ feature, key }) => ({
      url: `my/report-keys`,
      method: 'POST',
      body: {
        feature: feature,
        key: key,
      },
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['preferences'],
  });
