/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  Popover,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import {
  DetailsTextField,
  FlexGrid,
  FormContentBox,
  PopoverHeaderBox,
  StyledTextField,
} from './reportTopicsPopover.styled';
import ReportIconSvg from '../../../../assets/report-icon';

const ReportTopicsPopover = ({
  open,
  anchorEl,
  handleClose,
  initialData,
  onSubmit,
  mode,
  handleTopicNameChange,
  handleTopicDetailsChange,
  handleTopicOrderChange,
  requestReceived,
  isLoading,
  sourceLabel,
  feature,
}) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialData);

  const getLabel = (key) => t(`reportSummaryTopicsPage.addNew.form.${key}`);
  useEffect(() => {
    setFormValues(initialData);
  }, [initialData]);

  const handleClosePopover = () => {
    handleClose();
  };

  const id = open ? 'simple-popover' : undefined;

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      sx={{ marginTop: 1 }}
    >
      <PopoverHeaderBox>
        <Typography variant="h6">
          {mode === 'add'
            ? t('reportSummaryTopicsPage.addNew.heading')
            : t('reportSummaryTopicsPage.edit.heading')}
        </Typography>
        <IconButton onClick={handleClosePopover}>
          <Close />
        </IconButton>
      </PopoverHeaderBox>
      <FormContentBox>
        <Grid container rowSpacing={2}>
          {requestReceived && mode === 'add' ? (
            <Grid item xs={12}>
              <Alert severity="success" sx={{ width: '100%' }}>
                {t('reportTopicsPage.popover.requestReceived')}
              </Alert>
            </Grid>
          ) : (
            <>
              <FormControl fullWidth>
                <FlexGrid item xs={12}>
                  <StyledTextField
                    size="small"
                    variant="filled"
                    fullWidth
                    required
                    label={getLabel('topicName')}
                    value={formValues.topicName}
                    onChange={handleTopicNameChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FlexGrid>
                <FlexGrid item xs={12}>
                  <DetailsTextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    label={getLabel('topicDetails')}
                    value={formValues.topicDetails}
                    onChange={handleTopicDetailsChange}
                    multiline
                    minRows={4}
                  />
                </FlexGrid>
                <FlexGrid item xs={12}>
                  <StyledTextField
                    size="small"
                    variant="filled"
                    fullWidth
                    label={getLabel('topicOrder')}
                    value={formValues.order}
                    onChange={handleTopicOrderChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    type="number"
                  />
                </FlexGrid>
                <FlexGrid item xs={12}>
                  <StyledTextField
                    aria-readonly
                    size="small"
                    variant="filled"
                    fullWidth
                    label={getLabel('feature')}
                    value={feature}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FlexGrid>

                <FlexGrid item xs={12} gap={2}>
                  <Button
                    variant="outlined"
                    sx={{ textAlign: 'right', borderColor: 'transparent' }}
                    onClick={handleClosePopover}
                  >
                    {t('reportSummaryTopicsPage.addNew.form.cancelButton')}
                  </Button>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    sx={{ textAlign: 'right' }}
                    onClick={handleSubmit}
                    disabled={
                      formValues.topicName?.length <= 2 ||
                      formValues.topicDetails?.length <= 2
                    }
                    startIcon={<ReportIconSvg />}
                  >
                    {mode === 'add'
                      ? t('reportSummaryTopicsPage.addNew.form.addButton')
                      : t('reportSummaryTopicsPage.edit.form.updateButton')}
                  </LoadingButton>
                </FlexGrid>
              </FormControl>
            </>
          )}
        </Grid>
      </FormContentBox>
    </Popover>
  );
};

ReportTopicsPopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
  initialData: PropTypes.shape({
    topicName: PropTypes.string,
    topicDetails: PropTypes.string,
    topicOrder: PropTypes.string,
  }),
  mode: PropTypes.oneOf(['add', 'edit']),
  handleTopicNameChange: PropTypes.func,
  handleTopicDetailsChange: PropTypes.func,
  handleTopicOrderChange: PropTypes.func,
  handleFeatureChange: PropTypes.func,
  onSubmit: PropTypes.func,
  requestReceived: PropTypes.bool,
  isLoading: PropTypes.bool,
  sourceLabel: PropTypes.string,
  feature: PropTypes.string,
};

export default ReportTopicsPopover;
