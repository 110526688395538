/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { StyledToolbarGrid } from '../../../../filesCollectionPage/addMetaDataPage/components/addMetaDataGrid/addMetaDataGrid.styled';
import { useTranslation } from 'react-i18next';

const CustomToolbar = () => {
  const { t } = useTranslation();

  return (
    <StyledToolbarGrid container item xs={12}>
      <Grid container item xs={4} md={'auto'}>
        <GridToolbarQuickFilter />
      </Grid>
    </StyledToolbarGrid>
  );
};

export default CustomToolbar;
