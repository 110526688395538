/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, Assessment, DeleteForever, Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

import * as PATHS from '../../constants/path';
import ReportSourcePopover from '../../components/reportSourcePopover';
import PageContainer from '../../components/pageContainer';
import AppShadowBox from '../../components/app.shadowbox';
import {
  useAddReportSourceMutation,
  useDeleteReportSourceMutation,
  useGetReportSourceQuery,
  useUpdateReportSourceMutation,
  useLazyGetReportSourceQuery,
} from '../../redux/services/speciphicAsk/speciphic.ask.api';
import { pushToast } from '../../redux/reducers/toasts.slice';
import {
  DialogActionsWrapper,
  StyledClearIcon,
  StyledDataGrid,
  StyledFileUploadButton,
  StyledLoadingButton,
  StyledSearchIcon,
  StyledToolbarGrid,
  StyledWrapper,
  ApprovedIcon,
} from './reportSources.styled';
import DisplayTime from '../../components/timezone.component';
import { MARKET_INTELLIGENCE_UNLISTED_FEATURE } from '../../constants/marketIntelligence';

const ReportSourcesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorElForNewSource, setAnchorElNewSource] = useState(null);
  const [editorData, setEditorData] = useState({
    companyName: '',
    sourceUrls: [''],
    status: '',
    companyLogoUrl: '',
  });
  const [mode, setMode] = useState('add');
  const [requestReceived, setRequestReceived] = useState(false);
  const [modificationId, setModificationId] = useState('');
  const [openDeleteSource, setOpenDeleteSource] = useState(false);
  const { state } = useLocation();
  const [feature, setFeature] = useState(state?.feature);

  const [
    getReportSource,
    {
      data: reportSources = [],
      isSuccess: isGetReportSourceSuccess,
      isLoading: isGetReportSourceLoading,
      isError: isGetReportSourceError,
      isFetching: isGetReportSourceFetching,
    },
  ] = useLazyGetReportSourceQuery();

  useEffect(() => {
    getReportSource({
      status: 'approved',
      feature: feature,
    });
  }, []);

  useEffect(() => {
    getReportSource({
      status: 'approved',
      feature: feature,
    });
  }, [feature]);

  const [
    addReportSource,
    {
      data: newSource,
      isSuccess: isAddNewSourceSuccess,
      isLoading: isAddNewSourceLoading,
      isError: isAddNewSourceError,
    },
  ] = useAddReportSourceMutation();

  useEffect(() => {
    if (isAddNewSourceSuccess) {
      setRequestReceived(true);
      dispatch(
        pushToast({
          message: t('reportSourcesPage.addNew.successToast'),
          severity: 'success',
        }),
      );
    }
  }, [isAddNewSourceSuccess]);

  useEffect(() => {
    if (isAddNewSourceError) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.addNew.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isAddNewSourceError]);

  const [
    updateReportSource,
    {
      data: updatedReportSource,
      isSuccess: isUpdateReportSourceSuccess,
      isLoading: isUpdateReportSourceLoading,
      isError: isUpdateReportSourceError,
    },
  ] = useUpdateReportSourceMutation();

  useEffect(() => {
    if (isUpdateReportSourceSuccess) {
      handleCloseSourcePopover();
      dispatch(
        pushToast({
          message: t('reportSourcesPage.edit.successToast'),
          severity: 'success',
        }),
      );
    }
  }, [isUpdateReportSourceSuccess]);

  useEffect(() => {
    if (isUpdateReportSourceError) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.edit.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isUpdateReportSourceError]);

  const [
    deleteReportSource,
    {
      isError: isDeleteSourceError,
      isLoading: isDeleteSourceLoading,
      isSuccess: isDeleteSourceSuccess,
    },
  ] = useDeleteReportSourceMutation();

  useEffect(() => {
    if (isDeleteSourceSuccess) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.delete.successToast'),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteSourceSuccess]);

  useEffect(() => {
    if (isDeleteSourceError) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.delete.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteSourceError]);

  const handleClickNewSourcePopover = () => {
    setAnchorElNewSource(event.currentTarget);
    setMode('add');
  };

  const closeDelete = () => {
    setOpenDeleteSource(false);
  };

  const handleDeleteSource = (id) => {
    setOpenDeleteSource(false);
    deleteReportSource({
      reportSourceId: id,
    });
  };

  const handleCloseSourcePopover = () => {
    setAnchorElNewSource(null);
    setEditorData({ companyName: '', sourceUrls: [''], companyLogoUrl: '' });
    setMode('add');
    setRequestReceived(false);
  };

  const handleOpenReportSourceEditor = (e, row) => {
    setEditorData({
      companyName: row.companyName,
      sourceUrls: row.sourceUrls,
      status: row.status,
      companyLogoUrl: row?.companyLogoUrl,
      companyStockSymbol: row?.key,
    });
    setModificationId(row.id);
    setAnchorElNewSource(event.currentTarget);
    setMode('edit');
  };

  const handleNameChange = (e) => {
    setEditorData((prevData) => ({ ...prevData, companyName: e.target.value }));
  };

  const handleStatusChange = (event) => {
    setEditorData((prevData) => ({
      ...prevData,
      status: event.target.value,
    }));
  };
  const handleUrlChange = (index, newValue) => {
    const newsourceUrls = [...editorData.sourceUrls];
    newsourceUrls[index] = newValue;
    setEditorData((prevState) => ({ ...prevState, sourceUrls: newsourceUrls }));
  };

  const handleAddUrl = () => {
    setEditorData((prevState) => ({
      ...prevState,
      sourceUrls: [...prevState.sourceUrls, ''],
    }));
  };

  const handleAddCompanyLogo = (e) => {
    setEditorData((prevData) => ({
      ...prevData,
      companyLogoUrl: e.target.value,
    }));
  };
  const handleSubmit = () => {
    if (mode === 'add') {
      addReportSource({
        companyName: editorData.companyName,
        sourceUrls: editorData.sourceUrls,
        companyLogoUrl: editorData.companyLogoUrl,
      });
    } else if (mode === 'edit') {
      updateReportSource({
        updateData: editorData,
        reportSourceId: modificationId,
      });
    }
  };

  const handleDeleteUrl = (index) => {
    const newsourceUrls = [...editorData.sourceUrls];
    newsourceUrls.splice(index, 1);
    setEditorData((prevState) => ({ ...prevState, sourceUrls: newsourceUrls }));
  };

  const open = Boolean(anchorElForNewSource);

  const getCompanyName = (props) => {
    return <StyledWrapper>{props.row.companyName}</StyledWrapper>;
  };

  const getStatus = (props) => {
    const status = props.row.status;
    const sentenceCaseStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    return <StyledWrapper>{sentenceCaseStatus}</StyledWrapper>;
  };

  const getLastUpdatedDate = (props) => {
    return `${props.row.lastUpdatedDate.slice(0, 11)}`;
  };
  const getLastUpdatedBy = (props) => {
    return <StyledWrapper>{props.row.lastUpdatedBy}</StyledWrapper>;
  };

  const openDeleteSourceDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteSource(true);
    setModificationId(row.id);
  };

  const handleLinkClick = () => {
    setFeature((prevFeature) =>
      prevFeature === 'market-intelligence-listed'
        ? 'market-intelligence-unlisted'
        : 'market-intelligence-listed',
    );
  };
  const actionColumn = (props) => {
    return (
      <>
        <Tooltip
          title={t('reportSourcesPage.dataGrid.actionButton.update')}
          arrow
        >
          <StyledLoadingButton
            onClick={(e) => handleOpenReportSourceEditor(e, props.row)}
          >
            <span>
              <Edit />
            </span>
          </StyledLoadingButton>
        </Tooltip>
        <Tooltip
          title={t('reportSourcesPage.dataGrid.actionButton.delete')}
          arrow
        >
          <StyledLoadingButton
            onClick={(e) => openDeleteSourceDialog(e, props.row)}
            loading={modificationId === props.row.id && isDeleteSourceLoading}
          >
            <span>
              <DeleteForever />
            </span>
          </StyledLoadingButton>
        </Tooltip>
      </>
    );
  };
  const columns = [
    {
      field: 'companyName',
      headerName: 'Source Name',
      width: 250,
      headerAlign: 'left',
      align: 'left',
      renderCell: getCompanyName,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: () => (
        <StyledWrapper>
          <Tooltip title="Approved">
            <ApprovedIcon />
          </Tooltip>
        </StyledWrapper>
      ),
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Updated By',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: getLastUpdatedBy,
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Updated Date',
      minWidth: 230,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <StyledWrapper>
          <DisplayTime time={params.row.lastUpdatedDate} />
        </StyledWrapper>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: actionColumn,
    },
  ];

  const DeleteDialogPopup = () => {
    return (
      <Dialog open={openDeleteSource}>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>
              {t('reportSourcesPage.dataGrid.deletePopup.heading')}
            </AlertTitle>
            <AlertTitle>
              <strong>
                {t('reportSourcesPage.dataGrid.deletePopup.warning')}
              </strong>
            </AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActionsWrapper>
          <Button variant="outlined" size="small" onClick={closeDelete}>
            {t('reportSourcesPage.dataGrid.deletePopup.cancelButtonText')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDeleteSource(modificationId)}
          >
            {t('reportSourcesPage.dataGrid.deletePopup.deleteButtonText')}
          </Button>
        </DialogActionsWrapper>
      </Dialog>
    );
  };

  const CustomToolbar = () => {
    return (
      <StyledToolbarGrid container>
        <Grid item xs={12} sm={4} md={'auto'}>
          <GridToolbarQuickFilter />
        </Grid>
        <Grid item xs={12} sm={4} md={'auto'}>
          <StyledFileUploadButton
            variant="contained"
            size="medium"
            onClick={handleClickNewSourcePopover}
            startIcon={<Add />}
          >
            {t('reportSourcesPage.buttons.addNewSource')}
          </StyledFileUploadButton>
        </Grid>
      </StyledToolbarGrid>
    );
  };

  return (
    <PageContainer>
      <AppShadowBox>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ alignItems: 'center' }}
        >
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4">
                {feature === 'market-intelligence-listed'
                  ? t('reportSourcesPage.listedCompanyPage.title')
                  : t('reportSourcesPage.unlistedCompanyPage.title')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                sx={{ float: 'right' }}
                component={Link}
                to={
                  feature === 'market-intelligence-listed'
                    ? PATHS.MARKET_INTELLIGENCE
                    : PATHS.UNLISTED_COMPANY_REPORTS
                }
                variant="outlined"
                startIcon={<Assessment />}
              >
                {t('reportSourcesPage.navigation.marketIntelligence')}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isGetReportSourceFetching ? (
              <>
                <LinearProgress />
                <Typography
                  variant="h5"
                  sx={{ textAlign: 'center', marginTop: 2 }}
                >
                  {t('reportSourcesPage.loading')}
                </Typography>
              </>
            ) : (
              <StyledDataGrid
                sx={{
                  borderColor: 'white',
                }}
                autoHeight
                pageSize={50}
                rows={isGetReportSourceSuccess ? reportSources : []}
                disableSelectionOnClick
                disableColumnMenu
                columns={columns}
                getRowId={(row) => row.id}
                components={{
                  Toolbar: CustomToolbar,
                  QuickFilterIcon: StyledSearchIcon,
                  QuickFilterClearIcon: StyledClearIcon,
                }}
              />
            )}

            <DeleteDialogPopup />
          </Grid>
          {/* Render ReportSourcePopover component */}
        </Grid>
        <ReportSourcePopover
          open={open}
          initialData={editorData}
          mode={mode}
          anchorEl={anchorElForNewSource}
          handleClose={handleCloseSourcePopover}
          handleNameChange={handleNameChange}
          handleStatusChange={handleStatusChange}
          handleUrlChange={handleUrlChange}
          handleAddUrl={handleAddUrl}
          handleDeleteUrl={handleDeleteUrl}
          onSubmit={handleSubmit}
          requestReceived={requestReceived}
          isLoading={isAddNewSourceLoading || isUpdateReportSourceLoading}
          handleAddCompanyLogo={handleAddCompanyLogo}
          marketIntelligenceFeature={feature}
          isAddCompanyStockSymbolRequired={
            feature === MARKET_INTELLIGENCE_UNLISTED_FEATURE ? true : false
          }
          sourceLabel={'Source'}
        />
      </AppShadowBox>
    </PageContainer>
  );
};

ReportSourcesPage.propTypes = {
  row: PropTypes.array,
};

export default ReportSourcesPage;
