/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';

import Header from '../../../../components/form/header.component';
import PageContainer from '../../../../components/pageContainer';
import { SECTIONS } from '../../../../constants/drawer';
import {
  FILE_COLLECTIONS,
  ADD_PAGE,
  SETTINGS,
} from '../../../../constants/path';
import { DescriptionStyled, NoDataTitle, TableStyled } from '../styled';
import {
  useDeleteFileCollectionAccessPoliciesMutation,
  useLazyGetIndividualFileCollectionQuery,
  useLazyGetFileCollectionAccessPoliciesQuery,
} from '../../../../redux/services/speciphicAsk';
import Loader from '../../../../components/loader/loader.component';
import { IAM } from '../../../../constants/filesCollectionSettingsPaths';
import { LOAD_TITLE } from '../../../../utils/functions';
import UnauthorizedMessage from '../../../../components/unauthorized';

const addPageRoute = (collectionId, policyName = null) =>
  `${FILE_COLLECTIONS}${`/${collectionId}`}${SETTINGS}${IAM.endpoint}${
    ADD_PAGE.endpoint
  }${policyName ? `?policy-name=${policyName}` : ''}`;

const translationJSONPrefix = 'filesCollectionSettingsPage.iam';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const TRANSLATION_MENU_TITLES = `${translationJSONPrefix}.drawerMenuTitle`;
const FILES_COLLECTION = `${translationJSONPrefix}.filesCollection`;

const PAGE_TITLE = (t) =>
  `${t(TRANSLATION_MENU_TITLES)} | ${t(FILES_COLLECTION)}`;

const DATE_TIME_FORMAT = 'DD-MM-YYYY, h:mm:ss a';

const getNamesByIds = (ids, objects) => {
  const idNameMap = objects.reduce((map, obj) => {
    map[obj.id] = obj.name;
    return map;
  }, {});

  const names = ids
    .map((id) => idNameMap[id])
    .filter((name) => name !== undefined);

  return names;
};

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.columnHeaders`;

  return [
    {
      field: 'name',
      headerName: t(`${translationPrefix}.name`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.name}`,
    },
    {
      field: 'effect',
      headerName: t(`${translationPrefix}.effect`),
      flex: 0.2,
      minWidth: 100,
      valueGetter: (params) => `${params.row?.effect}`,
    },
    {
      field: 'description',
      headerName: t(`${translationPrefix}.description`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.description || '-'}`,
    },
    {
      field: 'pup',
      headerName: t(`${translationPrefix}.pup`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => `${params.row?.pup}`,
    },
    {
      field: 'cup',
      headerName: t(`${translationPrefix}.cup`),
      flex: 0.2,
      minWidth: 100,
      valueGetter: (params) => `${params.row?.cup?.length}`,
    },
    {
      field: 'createdAt',
      headerName: t(`${translationPrefix}.createdAt`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) =>
        `${moment(params.row?.createdDate).format(DATE_TIME_FORMAT)}`,
    },
    {
      field: 'updatedAt',
      headerName: t(`${translationPrefix}.updatedAt`),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) =>
        `${moment(params.row?.lastUpdatedDate).format(DATE_TIME_FORMAT)}`,
    },
  ];
};

const AccessControlPoliciesPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => LOAD_TITLE(document, PAGE_TITLE(t)), []);

  const [selectedAccessPolicies, setSelectedAccessPolicies] = useState([]);
  const [collectionId, setCollectionId] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [
    getFilesCollectionInfo,
    {
      data: filesCollectionInfo,
      error: filesCollectionError,
      isError: isGetFCError,
      isLoading: isGetFCLoading,
      isSuccess: isGetFCSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  useEffect(() => {
    if (params?.collectionId) {
      setCollectionId(params?.collectionId);
      getFilesCollectionInfo({ collectionId: params?.collectionId });
    }
  }, [params?.collectionId]);

  const [
    getAllFCAccessPolicies,
    { data, error, isError, isLoading, isSuccess },
  ] = useLazyGetFileCollectionAccessPoliciesQuery();

  const [
    deleteFCAccessPolicies,
    {
      data: deletedResponse,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteFileCollectionAccessPoliciesMutation();

  const handleDelete = () => {
    deleteFCAccessPolicies({
      collectionId,
      accessPoliciesNames: getNamesByIds(selectedAccessPolicies, data),
    });
  };

  useEffect(() => {
    getAllFCAccessPolicies({
      collectionId: params?.collectionId,
    });

    setSelectedAccessPolicies([]);
  }, [deletedResponse]);

  const handleCellClick = (e) => {
    if (e?.field === 'name')
      navigate(`${addPageRoute(collectionId, e?.row?.name)}`);
  };

  if (error?.status === 403 || filesCollectionError?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
      collectionId={collectionId}
    >
      <Header
        header={`${filesCollectionInfo?.name || '...'} / ${t(
          `${translationJSONPrefix}.pageHeader`,
        )}`}
        addButtonLabel={t(`${translationJSONPrefix}.addButtonLabel`)}
        deleteButton={true}
        deleteButtonDisabled={selectedAccessPolicies.length === 0}
        addButtonOnClick={() => navigate(addPageRoute(collectionId))}
        deleteButtonOnClick={handleDelete}
        isDeleteLoading={isDeleteLoading}
      />

      <DescriptionStyled>{collectionId}</DescriptionStyled>

      {isLoading ? (
        <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
      ) : data?.length > 0 ? (
        <TableStyled
          onCellClick={handleCellClick}
          rows={data.map((d, i) => {
            return { id: i, ...d };
          })}
          columns={columns(t)}
          pageSize={10}
          checkboxSelection
          autoHeight
          onSelectionModelChange={setSelectedAccessPolicies}
        />
      ) : (
        <NoDataTitle>{t(`${translationJSONPrefix}.noDataLabel`)}</NoDataTitle>
      )}
    </PageContainer>
  );
};

export default AccessControlPoliciesPage;
