/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import {
  styled,
  IconButton,
  Chip,
  Box,
  Divider,
  CircularProgress,
  Grid,
} from '@mui/material';
import {
  Done,
  Error,
  Pending,
  WatchLater,
  Verified,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import colors from '../../themes/colors';
export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  '& .MuiButton-endIcon>:nth-of-type(1)': {
    color: 'white',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StatusTag = styled(Chip)(({ theme }) => ({
  height: '25px !important',
}));

export const SecondaryActionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}));

export const LoadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
}));

export const ItemDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const CompanyListGrid = styled(Grid)(({ theme }) => ({
  width: '75%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const DoneIcon = styled(Done)(({ theme }) => ({
  color: colors.green.G400,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export const ApprovedIcon = styled(Verified)(({ theme }) => ({
  color: colors.green.G400,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export const ErrorIcon = styled(Error)(({ theme }) => ({
  color: colors.red.R250,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export const PendingIcon = styled(Pending)(({ theme }) => ({
  color: colors.green.G400,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export const StatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const CircularProgressIcon = styled(CircularProgress)(({ theme }) => ({
  height: '20px !important',
  width: '20px !important',
}));

export const PlannedIcon = styled(WatchLater)(({ theme }) => ({
  color: colors.yellow.Y400,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));
