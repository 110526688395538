export const DASHBOARD = {
  title: 'dashboard',
  endpoint: '/',
};
export const SEARCH_PERFORMANCE = {
  title: 'searchPerformance',
  endpoint: '/search-performance',
};
export const FILE_COLLECTIONS = {
  title: 'fileCollections',
  endpoint: '/file-collections',
};
export const USERS = {
  title: 'usersEngagement',
  endpoint: '/users-engagement',
};
