/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
export default (builder) =>
  builder.query({
    query: ({ feature } = { feature: 'market-intelligence-listed' }) => ({
      url:
        feature === 'market-intelligence-listed'
          ? 'report-summary-topics'
          : 'report-summary-topics?feature=market-intelligence-unlisted',
      method: 'GET',
    }),
    providesTags: ['report-summary-topics'],
  });
