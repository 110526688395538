/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { DataGrid } from '@mui/x-data-grid';
import { Box, styled, Grid, Typography } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import colors from '../../../../../../themes/colors';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.dark,
    fontSize: 16,
  },
  '&.MuiGrid-root.MuiGrid-item': {
    height: `100%`,
  },

  '&.MuiDataGrid-root .MuiGrid-root.MuiGrid-item': {
    height: `100%`,
    paddingTop: theme.spacing(0.5),
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '.MuiDataGrid-row': {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const FieldValueTypography = styled(Typography)(({ theme }) => ({
  color: colors.blue.B250,
  padding: theme.spacing(1),
  borderRadius: '4px',
  fontSize: 18,
}));

export const PropertyNameTypography = styled(Typography)(({ theme }) => ({
  color: colors.neutralLight.N800,
  fontSize: 18,
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

export const StyledContentWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledHeaderColumn = styled(Grid)({
  fontSize: 18,
});

export const StyledToolbarGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DataGridBox = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .actions': {
    color: 'text.secondary',
  },
  '& .textPrimary': {
    color: 'text.primary',
  },
}));

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));
export const StyledClearIcon = styled(Clear)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));
