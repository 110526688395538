/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const DropdownOne = ({ items, onChange, label, value, defaultValue }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel size="small">{label}</InputLabel>
      <Select
        label={label}
        size="small"
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
      >
        {items.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DropdownOne.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

DropdownOne.defaultProps = {
  items: [],
};

export default DropdownOne;
