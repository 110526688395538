/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  ButtonGroup,
  Grid,
  styled,
  Button,
  Typography,
  DialogActions,
  Slider,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import colors from '../../../themes/colors';

export const FilesAddedAlert = styled(Alert)({
  width: '300px',
});
export const FilesNotAddedAlert = styled(Alert)(({ theme }) => ({
  width: '300px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const SuccessIcon = styled(CheckCircle)(({ theme }) => ({
  color: colors.green.G100,
}));

export const FailedIcon = styled(Cancel)(({ theme }) => ({
  color: colors.red.R100,
}));

export const FileName = styled(ListItem)(({ theme }) => ({
  marginTop: '-10px',
  marginRight: theme.spacing(2),
}));

export const FileNameText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
}));

export const AppTitleBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const AlignmentBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const FileNameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const LoadingTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  justifyContent: 'left',
  width: 'auto',
}));

export const MobileButton = styled(LoadingButton)(({ theme }) => ({
  display: 'none',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const TableHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(3),
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
export const ButtonsOnlyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const SettingsButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.primary.main,
}));

export const ButtonStyled = styled(LoadingButton)(({ theme }) => ({
  width: '110px',
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  width: '75%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const AddButton = styled(LoadingButton)(({ theme }) => ({
  float: 'right',
  display: 'flex',
  width: 'auto',
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export const StyledButtonLeft = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const StyledButtonRight = styled(LoadingButton)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.up('xs')]: {
    width: '70%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const FileCollectionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2),
  },
  marginBottom: theme.spacing(2),
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '10px',
  },
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const FlexGridItemWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SliderElement = styled(Slider)(({ theme }) => ({
  width: '100px',
  marginBottom: '4px',
  marginTop: '4px',
}));

export const TagsChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: '110px',
  width: 'fit-content',
}));

export const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const PaginationTextTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(0.3),
}));

export const ProgressBar = styled(LinearProgress)({
  width: '100%',
});
