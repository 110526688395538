import { Box, DialogActions, styled, Typography } from '@mui/material';

export const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const LoadingTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));

export const StyledNameWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '15px',
  marginBottom: '15px',
}));
