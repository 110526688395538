const { REACT_APP_MAX_UPLOAD_SIZE } = process.env;
export const ALLOWED_FILE_TYPES = {
  jpeg: { 'image/jpeg': ['.jpeg', 'jpg'] },
  png: { 'image/png': ['.png'] },
  text: { 'text/plain': ['.txt', '.text'] },
  pdf: { 'text/pdf': ['.pdf'] },
  html: { 'text/html': ['.htm', '.html'] },
  docx: {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
  },
  md: { 'text/markdown': ['.md'] },
  zip: { 'application/zip': ['.zip'] },
  video: { 'video/mp4': ['.mp4'] },
  json: { 'application/json': ['.json'] },
  tiff: { 'image/tiff': ['.tiff'] },
  bmp: { 'image/bmp': ['.bmp'] },
};
export const ALLOWED_FILE_SIZE = REACT_APP_MAX_UPLOAD_SIZE;
