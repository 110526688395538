/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/pageContainer';

const LegalPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Typography color="primary" variant="h4">
        {t('legalPage.title')}
      </Typography>
      <Typography variant="body1">{t('legalPage.wip')}</Typography>
    </PageContainer>
  );
};

export default LegalPage;
