/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { MARKET_INTELLIGENCE_FEATURE } from '../../../../constants/marketIntelligence';

export default (builder) =>
  builder.mutation({
    query: ({ report_key, feature }) => ({
      url: `/reports/${MARKET_INTELLIGENCE_FEATURE}/${report_key}?feature=${feature}`,
      method: 'POST',
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['preferences'],
  });
