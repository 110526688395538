import { useState } from 'react';
import { Button, Grid, List, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppShadowBox from '../../components/app.shadowbox';
import PageContainer from '../../components/pageContainer';
import * as PATHS from '../../constants/path';
import {
  BoxWrapper,
  ButtonLink,
} from '../settings/fileUploaderPage/fileUploader.page.styled';
import DropdownOne from '../../components/dropdownone/dropdownone';
import {
  AWS_S3_TYPE,
  AZURE_BLOB_TYPE,
  SHAREPOINT_TYPE,
  SERVICENOW_TYPE,
  FTP_TYPE,
} from '../../constants/dataSources';
import DataSourceForm from './dataSourceForm';
const storageConfigs = [
  { label: 'Azure Blob', value: AZURE_BLOB_TYPE },
  { label: 'Sharepoint', value: SHAREPOINT_TYPE },
  { label: 'Service Now', value: SERVICENOW_TYPE },
  { label: 'AWS S3', value: AWS_S3_TYPE },
];

storageConfigs.sort((a, b) => a.label.localeCompare(b.label));

const AddDataSourcePage = () => {
  const [configType, setConfigType] = useState(AZURE_BLOB_TYPE);

  const { t } = useTranslation();
  const handleStorageConfigChange = (value) => {
    setConfigType(value);
  };
  return (
    <PageContainer>
      <BoxWrapper>
        <AppShadowBox>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h4" color={'primary'}>
                  {t('addDataSourcePage.title')}
                </Typography>
                <Typography variant="body1">
                  {t('addDataSourcePage.subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ justifyContent: 'flex-end' }}>
                <ButtonLink
                  size="medium"
                  variant="contained"
                  component={Link}
                  to={PATHS.DATA_SOURCES}
                  startIcon={<List />}
                >
                  {t('addDataSourcePage.buttons.dataSourcesButton')}
                </ButtonLink>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <DropdownOne
                  onChange={handleStorageConfigChange}
                  defaultValue={AZURE_BLOB_TYPE}
                  label={t('addDataSourcePage.dropdownLabel.storageType')}
                  value={configType}
                  items={storageConfigs}
                  sx={{ marginTop: 2 }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <DataSourceForm sourceType={configType} />
              </Grid>
            </Grid>
          </Grid>
        </AppShadowBox>
      </BoxWrapper>
    </PageContainer>
  );
};

export default AddDataSourcePage;
