const { REACT_APP_SUPPORT_SIGNED_URL } = process.env;

let isSignedUrlSupportEnabled = false;

if (
  REACT_APP_SUPPORT_SIGNED_URL &&
  REACT_APP_SUPPORT_SIGNED_URL?.toLowerCase() === 'true'
) {
  isSignedUrlSupportEnabled = true;
}

export default isSignedUrlSupportEnabled;
