/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Box, styled, Button, Grid } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';

import colors from '../../themes/colors';

export const PageHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const PageHeader = styled(Box)(({ theme }) => ({
  fontSize: 25,
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ButtonWithMargin = styled(LoadingButton)(({ theme }) => ({
  marginRight: 10,
}));

export const LinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const LinkTitle = styled(Box)(({ theme }) => ({
  fontSize: 18,
  cursor: 'pointer',
  textDecoration: 'underline',
  color: colors.blue.B400,
}));

export const TitleText = styled(Box)(({ theme }) => ({
  fontSize: 18,
}));

export const RightIcon = styled(KeyboardArrowRight)(({ theme }) => ({
  margin: '0 5px',
}));

export const TableStyled = styled(DataGrid)(({ theme }) => ({
  margin: '15px 0',
  backgroundColor: colors.neutralLight.N0,

  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.dark,
    fontSize: 16,
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '.MuiDataGrid-row': {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const InputsContainer = styled(Box)(({ theme }) => ({
  margin: '20px 0',
}));

export const NoDataTitle = styled(Box)({
  textAlign: 'center',
  marginTop: '20%',
});

export const FormContainer = styled(Box)(({ theme }) => ({
  margin: '20px 0',
  width: '65%',
  fontSize: 16,
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0 10px',
  },
}));

export const ToogleButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0',
}));

export const StyledToolbarGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));
