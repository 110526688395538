/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Grid, styled } from '@mui/material';

export const CustomGridContainer = styled(Grid)(
  ({ theme, definedMetaProperties }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    columnGap: theme.spacing(3),
    marginBottom:
      Object?.values(definedMetaProperties).length % 2 !== 0
        ? theme.spacing(2)
        : 0,
  }),
);

export const CustomGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
}));
