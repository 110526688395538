/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import * as React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { IconButton, Grid } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { ModalGrid, ModalGridContainer, CloseButton } from './popUp.styled';
import PropTypes from 'prop-types';

const Popup = ({ isModalOpen, content, onChangeModalOpen }) => {
  const handleClose = () => {
    onChangeModalOpen(false);
  };

  const id = 'simple-modal';

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal"
    >
      <ModalGridContainer container>
        <ModalGrid item xs={11} sm={8} md={6} lg={5} xl={4}>
          <CloseButton aria-label="close" onClick={handleClose}>
            <Cancel />
          </CloseButton>
          <Typography sx={{ mt: 2 }}>{content}</Typography>
        </ModalGrid>
      </ModalGridContainer>
    </Modal>
  );
};

Popup.propTypes = {
  isModalOpen: PropTypes.bool,
  content: PropTypes.string.isRequired,
  onChangeModalOpen: PropTypes.func,
};

export default Popup;
