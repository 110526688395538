import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import { object, number, string } from 'yup';

import {
  AlertStyled,
  DescriptionStyled,
  FormContainer,
  InputsContainer,
  PageHeader,
  ToogleButtonsContainer,
} from './styled';
import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import { LOAD_TITLE } from '../../../utils/functions';
import Loader from '../../../components/loader';
import AppForm, {
  AppTextField,
  FormButtons,
  AppSlider,
  AppSelectField,
} from '../../../components/form';
import {
  useLazyGetFileCollectionSettingsQuery,
  useGetSearchEnginesQuery,
  useUpdateFileCollectionSettingsMutation,
  useLazyGetIndividualFileCollectionQuery,
} from '../../../redux/services/speciphicAsk';
import { LLM_CONFIG as API_ENDPOINT } from '../../../constants/filesCollectionSettings';
import { getChangedValues } from '../../../components/form/functions';
import UnauthorizedMessage from '../../../components/unauthorized';

const translationJSONPrefix = 'filesCollectionSettingsPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const FILES_COLLECTION = `${translationJSONPrefix}.filesCollection`;
const PAGE_TITLE_SUFFIX = `${translationJSONPrefix}.pageTitleSuffix`;

const PAGE_TITLE = (t) =>
  `${t(`${translationJSONPrefix}.llmConfig.drawerMenuTitle`)} | ${t(
    FILES_COLLECTION,
  )} | ${t(PAGE_TITLE_SUFFIX)}`;

const defaultDataState = {
  maxLength: '',
  maxTokens: '',
  presencePenalty: '',
  frequencyPenalty: '',
  temperature: '',
  languageModelId: '',
};

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 0.3,
    label: '0.3',
  },
  {
    value: 0.7,
    label: '0.7',
  },
  {
    value: 1,
    label: '1',
  },
];

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.llmConfig.form.${label}.errorMessages`;

  return object({
    maxLength: number().min(0, t(`${translationPrefix('maxLength')}.required`)),
    maxTokens: number().min(0, t(`${translationPrefix('maxTokens')}.required`)),
    presencePenalty: number(),
    frequencyPenalty: number(),
    temperature: number(),
    languageModelId: string(),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  languageModelOptions,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.llmConfig.form`;

  return (
    <Form>
      <InputsContainer>
        <AppSelectField
          style={{ width: '100%' }}
          name="languageModelId"
          label={t(`${translationPrefix}.languageModel.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          menuData={languageModelOptions}
        />
      </InputsContainer>

      <ToogleButtonsContainer style={{ justifyContent: 'space-between' }}>
        <AppTextField
          style={{ width: '40%' }}
          name="maxLength"
          label={t(`${translationPrefix}.maxLength.label`)}
          placeholder={t(`${translationPrefix}.maxLength.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          type="number"
        />
        <AppTextField
          style={{ width: '40%' }}
          name="maxTokens"
          label={t(`${translationPrefix}.maxTokens.label`)}
          placeholder={t(`${translationPrefix}.maxTokens.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          type="number"
        />
      </ToogleButtonsContainer>

      <InputsContainer>
        <AppSlider
          style={{ width: '30%' }}
          name="presencePenalty"
          label={t(`${translationPrefix}.presencePenalty.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          step={0.1}
          min={0}
          max={1}
          marks={marks}
          valueLabelDisplay="auto"
        />
        <AppSlider
          style={{ width: '30%' }}
          name="frequencyPenalty"
          label={t(`${translationPrefix}.frequencyPenalty.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          step={0.1}
          min={0}
          max={1}
          marks={marks}
          valueLabelDisplay="auto"
        />
        <AppSlider
          style={{ width: '30%' }}
          name="temperature"
          label={t(`${translationPrefix}.temperature.label`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          step={0.1}
          min={0}
          max={1}
          marks={marks}
          valueLabelDisplay="auto"
        />
      </InputsContainer>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const LLMConfigPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => LOAD_TITLE(document, PAGE_TITLE(t)), []);

  const [collectionId, setCollectionId] = useState();
  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [languageModelOptions, setLanguageModelOptions] = useState([]);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [
    getFormData,
    {
      data: formDataDB,
      error: formError,
      isError: isGetFormError,
      isLoading: isGetFormLoading,
      isSuccess: isGetFormSuccess,
    },
  ] = useLazyGetFileCollectionSettingsQuery();

  const [
    getFilesCollectionInfo,
    {
      data: filesCollectionInfo,
      error: filesCollectionError,
      isError: isGetFCError,
      isLoading: isGetFCLoading,
      isSuccess: isGetFCSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  useEffect(() => {
    if (params?.collectionId) {
      setCollectionId(params?.collectionId);
      getFilesCollectionInfo({ collectionId: params?.collectionId });
      getFormData({
        collectionId: params.collectionId,
        endpoint: API_ENDPOINT,
      });
    }
  }, [params?.collectionId]);

  const [
    updateSettings,
    {
      data: formDataUpdateDB,
      isError: isUpdateFormError,
      isLoading: isUpdateFormLoading,
      isSuccess: isUpdateFormSuccess,
    },
  ] = useUpdateFileCollectionSettingsMutation();

  const { data: languageModelsDB } = useGetSearchEnginesQuery();

  useEffect(() => {
    if (languageModelsDB?.length > 0) {
      setLanguageModelOptions(languageModelsDB);
    }
  }, [languageModelsDB]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    tempAlert.formUpdateError = isUpdateFormError;
    setShowAlert(tempAlert);
  }, [isUpdateFormError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    tempAlert.formUpdateSuccess = isUpdateFormSuccess;
    setShowAlert(tempAlert);
  }, [isUpdateFormSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  useEffect(() => {
    if (isGetFormSuccess) {
      const {
        frequencyPenalty = '',
        languageModelId = '',
        maxLength = '',
        maxTokens = '',
        presencePenalty = '',
        temperature = '',
      } = formDataDB;

      const data = {
        frequencyPenalty,
        languageModelId,
        maxLength,
        maxTokens,
        presencePenalty,
        temperature,
      };

      setFormData(data);
      setInitialFormData(data);
    }
  }, [formDataDB]);

  if (formError?.status === 403 || filesCollectionError?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
      collectionId={collectionId}
    >
      <PageHeader>
        {`${filesCollectionInfo?.name || '...'} / ${t(
          `${translationJSONPrefix}.llmConfig.pageHeader`,
        )}`}
      </PageHeader>

      <DescriptionStyled>{collectionId}</DescriptionStyled>

      {collectionId && !isGetFormLoading ? (
        <FormContainer>
          <AlertStyled
            onClose={closeAlert}
            severity={showAlert.formUpdateError ? 'error' : 'success'}
            style={{
              marginTop:
                showAlert.formUpdateError || showAlert.formUpdateSuccess
                  ? 10
                  : -60,
            }}
          >
            {showAlert.formUpdateError &&
              t(`${translationJSONPrefix}.llmConfig.alert.updateFormError`)}
            {showAlert.formUpdateSuccess &&
              t(`${translationJSONPrefix}.llmConfig.alert.updateFormSuccess`)}
          </AlertStyled>

          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSend = getChangedValues(values, initialFormData);

              updateSettings({
                collectionId,
                data: valuesToSend,
                endpoint: API_ENDPOINT,
              });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isUpdateFormLoading,
                languageModelOptions,
              )
            }
          />
        </FormContainer>
      ) : (
        <Loader label={t(`${translationJSONPrefix}.loadingMessage`)} />
      )}
    </PageContainer>
  );
};

export default LLMConfigPage;
