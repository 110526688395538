/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Home, SentimentDissatisfied } from '@mui/icons-material';
import { Chip, Icon, Typography, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ErrorTypography,
  StyledPageWrapper,
  InstructionTextTypography,
} from './metaDataNotEnabledError.styled';
import ErrorImage from '../../../assets/oops-error-image.png';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../../components/pageContainer';

const MetaDataNotEnabledErrorPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const collectionName = state.collectionName;

  return (
    <PageContainer>
      <Grid display="block">
        <StyledPageWrapper container>
          <Grid container display="block">
            <Grid item xs={12} display="flex" justifyContent="center">
              <img src={ErrorImage} alt="Oops" width="600" height="250" />
            </Grid>

            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              container
              item
            >
              <Grid display="flex" justifyContent="center">
                <ErrorIcon color="error" sx={{ fontSize: 30, mt: 2 }} />
                <ErrorTypography variant="h2" color={'error'}>
                  {t('searchMetadataPage.components.metadataNotEnabled.title')}
                </ErrorTypography>
              </Grid>
              <Grid container item justifyContent="center" sx={{ mt: 1 }}>
                <Typography variant="h5" color={'primary'} gutterBottom>
                  {t(
                    'searchMetadataPage.components.metadataNotEnabled.instructions',
                    { collectionName },
                  )}
                  <br />
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="center" sx={{ mt: 1 }}>
                <Home color="primary" sx={{ fontSize: 20, mt: 0.8 }} />
                <ErrorTypography variant="inherit" color="textPrimary">
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    &rarr;
                  </InstructionTextTypography>
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    {t(
                      'searchMetadataPage.components.metadataNotEnabled.profile',
                    )}
                  </InstructionTextTypography>
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    &rarr;
                  </InstructionTextTypography>
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    {t(
                      'searchMetadataPage.components.metadataNotEnabled.fileCollections',
                    )}
                  </InstructionTextTypography>
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    &rarr;
                  </InstructionTextTypography>
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="textPrimary"
                  >
                    {collectionName}
                  </InstructionTextTypography>
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    &rarr;
                  </InstructionTextTypography>
                  <InstructionTextTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    {t(
                      'searchMetadataPage.components.metadataNotEnabled.setMetadataEnabled',
                    )}
                  </InstructionTextTypography>
                </ErrorTypography>

                <br />
              </Grid>
              <Grid
                container
                item
                display="flex"
                justifyContent="center"
                sx={{ mt: 1 }}
              >
                <Icon color="error">
                  <SentimentDissatisfied />
                </Icon>
              </Grid>
              <Grid container item justifyContent="center">
                <Chip
                  label={t(
                    'searchMetadataPage.components.metadataNotEnabled.goToHome',
                  )}
                  component="a"
                  href="/"
                  clickable
                  color="primary"
                  icon={<Home color="primary" sx={{ fontSize: 18 }} />}
                  sx={{ p: 2, mt: 3 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </StyledPageWrapper>
      </Grid>
    </PageContainer>
  );
};

export default MetaDataNotEnabledErrorPage;
