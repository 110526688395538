/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { styled, Box } from '@mui/material';

export const BackgroundColoredBox = styled(Box)({
  margin: 0,
  width: '100%',
  height: '100%',
});

export const ScrollBox = styled(Box)(({ headerheight, footerheight }) => {
  return {
    pb: { xs: 9, md: 5 },
    overflowY: 'scroll',
    height: `calc(100vh - ${headerheight}px - ${footerheight}px)`,
  };
});
