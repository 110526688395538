/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.
 
 */
import React from 'react';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  StyledToolbarGrid,
  StyledSearchIcon,
  StyledClearIcon,
  StyledDataGrid,
  StyledFileUploadButton,
} from './datagrid.styled';
import { Add } from '@mui/icons-material';

const DataGridTable = ({
  row,
  rowId,
  column,
  pageSize,
  handleRow,
  rowHeightAuto,
  sortingOrder,
  sortingField,
  openDialog,
  buttonText,
}) => {
  const CustomToolbar = () => {
    return (
      <StyledToolbarGrid container>
        <Grid item xs={12} sm={4} md={'auto'}>
          <GridToolbarQuickFilter />
        </Grid>

        <Grid item xs={12} sm={5} md={'auto'}>
          <StyledFileUploadButton
            variant="contained"
            size="medium"
            startIcon={<Add />}
            onClick={openDialog}
          >
            {buttonText}
          </StyledFileUploadButton>
        </Grid>
      </StyledToolbarGrid>
    );
  };

  return (
    <StyledDataGrid
      getRowId={(row) => row[rowId]}
      autoHeight
      pageSize={pageSize}
      initialState={{
        sorting: {
          sortModel: [{ field: sortingField, sort: sortingOrder }],
        },
      }}
      rows={row}
      columns={column}
      disableColumnMenu
      disableSelectionOnClick
      onRowClick={handleRow}
      components={{
        Toolbar: CustomToolbar,
        QuickFilterIcon: StyledSearchIcon,
        QuickFilterClearIcon: StyledClearIcon,
      }}
      getRowHeight={() => (rowHeightAuto ? 'auto' : null)}
    />
  );
};

DataGridTable.propTypes = {
  row: PropTypes.array,
  rowId: PropTypes.string,
  column: PropTypes.array,
  pageSize: PropTypes.number,
  handleRow: PropTypes.func,
  rowHeightAuto: PropTypes.bool,
  sortingOrder: PropTypes.string,
  sortingField: PropTypes.string,
  path: PropTypes.any,
  buttonText: PropTypes.string,
  openDialog: PropTypes.bool,
};

export default DataGridTable;
