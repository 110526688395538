/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { Autocomplete, Box, CircularProgress, styled } from '@mui/material';

export const AutoComplete = styled(Autocomplete)(({ theme }) => ({}));

export const LoadingProgress = styled(CircularProgress)({
  position: 'absolute',
  width: '25px !important',
  height: '25px !important',
  right: '20px',
  top: '15px',
});

export const StatusWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
});
