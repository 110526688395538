// Copyright Flexday Solutions LLC, Inc - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// See file LICENSE.txt for full license details.
//

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Send, ChevronRight } from '@mui/icons-material';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import PageContainer from '../../components/pageContainer';
import ChatHistoryComponent from './components/drawerComponent';
import MarkdownComponent from '../../components/markdown.component';
import BotLogo from '../../assets/flexdayLogo.png';
import {
  ConversationContainer,
  StyledAvatar,
  AvatarLetterWrap,
  TextFieldContainer,
  StyledTextField,
  StyledList,
  CloseDrawerButton,
  StyledListItem,
} from './conversation.page.styled';
import * as PATHS from '../../constants/path';
import {
  useAddConversationMutation,
  useLazyGetConversationCollectionHistoryQuery,
  useLazyGetConversationHistoryQuery,
} from '../../redux/services/speciphicAsk';
import { FileCollectionContext } from '../../hooks/useContext';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import { INPUT_FORMAT } from '../../constants/timeFormats';
import UnauthorizedMessage from '../../components/unauthorized';

const translationJSONPrefix = 'conversationPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const ConversationPage = () => {
  const conversationListRef = useRef(null);
  const { collectionId, conversationId } = useParams();
  const [userInput, setUserInput] = useState('');
  const [conversation, setConversation] = useState([]);
  const [chatId, setChatId] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();
  const { isFileCollectionChanged, getCollectionId } = useContext(
    FileCollectionContext,
  );
  const { getSiteConfig } = useContext(SiteConfigContext);
  const [assistantLogoFile, setAssistantLogoFile] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const siteConfig = getSiteConfig();

    if (siteConfig) {
      setAssistantLogoFile(siteConfig?.assistantLogoFile);
    }
  }, [getSiteConfig()]);

  const [
    getFileCollectionConversationHistory,
    {
      data: fileCollectionConversationHistory = [],
      error: fileCollectionConversationHistoryError,
      isLoading: isGetFileCollectionConversationHistoryLoading,
      isSuccess: isGetFileCollectionConversationHistorySuccess,
      isFetching: isGetFileCollectionConversationHistoryFetching,
    },
  ] = useLazyGetConversationCollectionHistoryQuery();

  useEffect(() => {
    if (collectionId) {
      getFileCollectionConversationHistory({
        Id: collectionId,
      });
    }
  }, [collectionId]);

  useEffect(() => {
    const tempCollectionId = getCollectionId();

    if (tempCollectionId && tempCollectionId !== collectionId) {
      setConversation([]);

      navigate(
        `${PATHS.FILE_COLLECTIONS}/${tempCollectionId}/${PATHS.CONVERSATION}`,
      );
    }
  }, [isFileCollectionChanged]);

  const [
    findAnswers,
    {
      data: result = [],
      isSuccess: isGetResultsSuccess,
      isLoading: isGetResultsLoading,
      isError: isGetResultsError,
    },
  ] = useAddConversationMutation();

  const [
    getConversation,
    {
      data: conversationIdHistory = [],
      error: conversationHistoryError,
      isLoading: isGetConversationHistoryLoading,
      isSuccess: isGetConversationHistorySuccess,
      isFetching: isGetConversationHistoryFetching,
    },
  ] = useLazyGetConversationHistoryQuery();

  useEffect(() => {
    if (collectionId && conversationId) {
      getConversation({
        collectionId,
        conversationId,
      });
    }
  }, [collectionId, conversationId]);

  useEffect(() => {
    if (conversationIdHistory?.length > 0) {
      const interleavedConversation = [];
      for (let i = 0; i < conversationIdHistory.length; i++) {
        const queryTime = conversationIdHistory[i].queryCreatedAt
          ? moment
              .utc(conversationIdHistory[i].queryCreatedAt, INPUT_FORMAT)
              .local()
          : undefined;
        const answerTime = conversationIdHistory[i].timestamp
          ? moment.utc(conversationIdHistory[i].timestamp, INPUT_FORMAT).local()
          : undefined;
        const userQuery = {
          role: 'user',
          query: conversationIdHistory[i].query,
          time: queryTime?.format('h:mm A'),
          timestamp: queryTime?.fromNow(),
        };
        interleavedConversation.push(userQuery);

        if (conversationIdHistory[i].results) {
          const assistantAnswer = {
            role: 'assistant',
            answer: conversationIdHistory[i].results.answer,
            pdfMeta: conversationIdHistory[i].results.meta || null,
            time: answerTime?.format('h:mm A'),
            timestamp: answerTime?.fromNow(),
          };
          interleavedConversation.push(assistantAnswer);
        }
      }
      setConversation(interleavedConversation);
    } else setConversation([]);
  }, [conversationIdHistory]);

  useEffect(() => {
    if (result && isGetResultsSuccess) {
      const currentTime = moment();
      setChatId(result.conversationId);
      const newBotMessage = {
        role: 'assistant',
        answer: result.hasError != true ? result.answer : result.message,
        pdfMeta: result?.meta,
        time: currentTime.format('h:mm A'),
        timestamp: currentTime.fromNow(),
      };
      setConversation([...conversation, newBotMessage]);
    }
  }, [isGetResultsSuccess, result]);

  useEffect(() => {
    if (isGetResultsError) {
      const currentTime = moment();
      setChatId(result.conversationId);
      const newBotMessage = {
        role: 'assistant',
        answer: t('conversationPage.errorMessage'),
        time: currentTime.format('h:mm A'),
        timestamp: currentTime.fromNow(),
      };
      setConversation([...conversation, newBotMessage]);
    }
  }, [isGetResultsError]);

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleUserInputSubmit = () => {
    if (!isGetResultsLoading && userInput.trim()) {
      const currentTime = moment();
      const newUserMessage = {
        role: 'user',
        query: userInput,
        time: currentTime.format('h:mm A'),
        timestamp: currentTime.fromNow(),
      };
      setConversation([...conversation, newUserMessage]);
      findAnswers({
        collectionId,
        query: userInput,
        acceptLanguage: 'en-US',
        includeMetadata: true,
        conversationId,
        chatHistoryId: null,
      });
      setUserInput('');
    }
  };

  useEffect(() => {
    if (conversationId && chatId) {
      navigate(
        `${PATHS.FILE_COLLECTIONS}/${collectionId}/${PATHS.CONVERSATION}/${chatId}`,
      );
    }
  }, [chatId]);

  useEffect(() => {
    if (isGetResultsLoading) {
      const interval = setInterval(() => {
        setShowCursor((prev) => !prev);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isGetResultsLoading]);

  useEffect(() => {
    if (conversationListRef.current) {
      conversationListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (
    fileCollectionConversationHistoryError?.status === 403 ||
    conversationHistoryError?.status === 403
  )
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      {isGetConversationHistoryFetching && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <ConversationContainer open={drawerOpen}>
        <ChatHistoryComponent
          open={drawerOpen}
          onToggleDrawer={toggleDrawer}
          collectionId={collectionId}
          setConversation={setConversation}
          fileCollectionConversationHistory={fileCollectionConversationHistory}
          isGetFileCollectionConversationHistorySuccess={
            isGetFileCollectionConversationHistorySuccess
          }
          isGetFileCollectionConversationHistoryFetching={
            isGetFileCollectionConversationHistoryFetching
          }
        />
        <CloseDrawerButton>
          <IconButton onClick={toggleDrawer}>
            <ChevronRight />
          </IconButton>
        </CloseDrawerButton>
        {!isGetConversationHistoryFetching && (
          <>
            {conversation.length > 0 && (
              <StyledList>
                {conversation.map((message, index) => (
                  <React.Fragment key={index}>
                    <StyledListItem role={message.role}>
                      {message.role === 'user' ? (
                        <>
                          <ListItemText
                            ref={conversationListRef}
                            primary={
                              <Box>
                                <Typography fontSize="10px">
                                  {message.time} - {message.timestamp}
                                </Typography>

                                {message.query}
                              </Box>
                            }
                            sx={{
                              textAlign: 'right',
                              marginRight: '16px',
                              paddingLeft: '65px',
                            }}
                          ></ListItemText>
                          <ListItemAvatar>
                            <StyledAvatar>
                              <AvatarLetterWrap>
                                {auth.user.profile.name?.charAt(0) ||
                                  auth.user.profile.oid?.charAt(0)}
                              </AvatarLetterWrap>
                            </StyledAvatar>
                          </ListItemAvatar>
                        </>
                      ) : (
                        <>
                          <ListItemAvatar ref={conversationListRef}>
                            {assistantLogoFile ? (
                              <Avatar alt="bot" src={assistantLogoFile} />
                            ) : (
                              <Avatar alt="bot" src={BotLogo} />
                            )}
                          </ListItemAvatar>

                          <ListItemText
                            ref={conversationListRef}
                            primary={
                              <Box>
                                <Typography fontSize="10px">
                                  {message.time} - {message.timestamp}
                                </Typography>
                                <MarkdownComponent
                                  markdown={
                                    message.answer && message.answer.length > 0
                                      ? message.answer
                                      : t('conversationPage.errorMessage')
                                  }
                                />
                              </Box>
                            }
                          ></ListItemText>
                          {/* <AnswerWithPdf
                            title={
                              message.answer && message.answer.length > 0
                                ? message.answer
                                : t('conversationPage.errorMessage')
                            }
                            metas={message.pdfMeta}
                            pdfPreview={false}
                            isDefaultFeedbackRequired={false}
                          /> */}
                        </>
                      )}
                    </StyledListItem>

                    {index === conversation.length - 1 &&
                      isGetResultsLoading && (
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar alt="bot" src={assistantLogoFile} />
                          </ListItemAvatar>
                          <div
                            style={{
                              lineHeight: 0,
                              fontSize: 'large',
                              fontWeight: 'bolder',
                            }}
                          >
                            {showCursor && '|'}{' '}
                          </div>
                        </ListItem>
                      )}
                  </React.Fragment>
                ))}
              </StyledList>
            )}
          </>
        )}

        <TextFieldContainer>
          <StyledTextField
            variant="outlined"
            placeholder="Ask your question..."
            value={userInput}
            onChange={handleUserInput}
            fullWidth
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleUserInputSubmit();
              }
            }}
          ></StyledTextField>

          <Button
            variant="contained"
            size="small"
            onClick={handleUserInputSubmit}
          >
            {<Send />}
          </Button>
        </TextFieldContainer>
      </ConversationContainer>
    </PageContainer>
  );
};

export default ConversationPage;
