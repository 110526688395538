/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({ collectionId, filters = {}, query, size, pageNo }) => ({
      url: `file-collections/${collectionId}/file-search`,
      method: 'POST',
      body: {
        filters: filters,
        query: query,
        size: size,
        pageNo: pageNo,
      },
      'Content-Type': 'application/json',
    }),
  });
