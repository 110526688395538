/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  Box,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';
import {
  DialogActionsWrapper,
  StyledClearIcon,
  StyledDataGrid,
  StyledSearchIcon,
  StyledWrapper,
  StyledLoadingButton,
} from './reportSummaryTopics.styled';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomToolbar from './reportSummaryTopicsToolbar.component';
import { useEffect, useState } from 'react';
import { useDeleteReportSummaryTopicMutation } from '../../redux/services/speciphicAsk';
import { pushToast } from '../../redux/reducers/toasts.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditSummaryTopic from './addReportSummaryTopic.component/editSummaryTopic.component';
import DisplayTime from '../../components/timezone.component';

const SummaryTopicsDataGrid = ({ topics, feature }) => {
  const { t } = useTranslation();
  const [topicIdDelete, setTopicIdDelete] = useState('');
  const [openDeleteTopic, setOpenDeleteTopic] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [topicId, setTopicId] = useState(null);
  const [topicName, setTopicName] = useState('');
  const [topicDetails, setTopicDetails] = useState('');
  const [topicOrder, setTopicOrder] = useState('');
  const dispatch = useDispatch();
  const [
    deleteTopic,
    {
      isError: isDeleteTopicError,
      isLoading: isDeleteTopicLoading,
      isSuccess: isDeleteTopicSuccess,
    },
  ] = useDeleteReportSummaryTopicMutation();

  const handleDeleteTopic = (topicIdDelete) => {
    setOpenDeleteTopic(false);
    deleteTopic({
      topicId: topicIdDelete,
    });
  };

  const openDeleteTopicDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteTopic(true);
    setTopicIdDelete(row.id);
  };

  const closeDelete = () => {
    setOpenDeleteTopic(false);
  };

  const handleUpdateClick = (e, row) => {
    setOpenEdit(true);
    setTopicId(row.id);
    setTopicName(row?.topicName);
    setTopicDetails(row?.topicDetails);
    setTopicOrder(row?.order);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    if (isDeleteTopicSuccess) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.dataGrid.deleteSuccessToast'),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteTopicSuccess]);

  useEffect(() => {
    if (isDeleteTopicError) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.dataGrid.deleteErrorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteTopicError]);

  const getTopicName = (props) => {
    return <StyledWrapper>{props.row.topicName}</StyledWrapper>;
  };

  const getTopicDetails = (props) => {
    return (
      <StyledWrapper>
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {props?.row?.topicDetails ? props?.row?.topicDetails : 'NA'}
        </span>
      </StyledWrapper>
    );
  };

  const actionColumn = (props) => {
    return (
      <>
        <Tooltip
          title={t('reportSummaryTopicsPage.dataGrid.actionButton.update')}
          arrow
        >
          <StyledLoadingButton
            onClick={(e) => handleUpdateClick(e, props.row)}
            // loading={isDeleteTopicLoading}
          >
            <span>
              <Edit />
            </span>
          </StyledLoadingButton>
        </Tooltip>
        <Tooltip
          title={t('reportSummaryTopicsPage.dataGrid.actionButton.delete')}
          arrow
        >
          <StyledLoadingButton
            onClick={(e) => openDeleteTopicDialog(e, props.row)}
            loading={topicIdDelete === props.row.id && isDeleteTopicLoading}
          >
            <span>
              <DeleteForever />
            </span>
          </StyledLoadingButton>
        </Tooltip>
      </>
    );
  };

  const columns = [
    {
      field: 'topicName',
      headerName: 'Topic Name',
      width: 300,
      headerAlign: 'left',
      align: 'left',
      renderCell: getTopicName,
    },
    {
      field: 'topicDetails',
      headerName: 'Topic Details',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: getTopicDetails,
    },
    {
      field: 'order',
      headerName: 'Topic Order',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Updated By',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <StyledWrapper>
          {' '}
          <span>{params.row.lastUpdatedBy}</span>
        </StyledWrapper>
      ),
    },

    {
      field: 'lastUpdatedDate',
      headerName: 'Updated Date',
      minWidth: 210,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <StyledWrapper>
          {' '}
          <DisplayTime time={params.row.lastUpdatedDate} />{' '}
        </StyledWrapper>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: actionColumn,
    },
  ];

  const DeleteDialogPopup = () => {
    return (
      <Dialog open={openDeleteTopic}>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>
              {t('reportSummaryTopicsPage.dataGrid.deletePopup.heading')}
            </AlertTitle>
            <AlertTitle>
              <strong>
                {t('reportSummaryTopicsPage.dataGrid.deletePopup.warning')}
              </strong>
            </AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActionsWrapper>
          <Button variant="outlined" size="small" onClick={closeDelete}>
            {t('reportSummaryTopicsPage.dataGrid.deletePopup.cancelButtonText')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDeleteTopic(topicIdDelete)}
          >
            {t('reportSummaryTopicsPage.dataGrid.deletePopup.deleteButtonText')}
          </Button>
        </DialogActionsWrapper>
      </Dialog>
    );
  };
  return (
    <Box>
      <StyledDataGrid
        sx={{
          borderColor: 'white',
        }}
        autoHeight
        pageSize={50}
        rows={topics}
        disableSelectionOnClick
        disableColumnMenu
        columns={columns}
        getRowId={(row) => row.id}
        components={{
          Toolbar: CustomToolbar,
          QuickFilterIcon: StyledSearchIcon,
          QuickFilterClearIcon: StyledClearIcon,
        }}
        componentsProps={{
          toolbar: { feature },
        }}
      />
      <DeleteDialogPopup />
      <Dialog
        onClose={handleClose}
        open={openEdit}
        style={{ width: '450px', margin: 'auto' }}
      >
        <EditSummaryTopic
          handleClose={handleClose}
          topicId={topicId}
          topic={topicName}
          details={topicDetails}
          order={topicOrder}
        />
      </Dialog>
    </Box>
  );
};

SummaryTopicsDataGrid.propTypes = {
  row: PropTypes.object,
  topics: PropTypes.array,
  feature: PropTypes.string,
};

export default SummaryTopicsDataGrid;
