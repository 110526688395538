/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { object, string, array } from 'yup';
import { Form } from 'formik';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import AddPageHeader from '../../../components/form/addPageHeader.component';
import { ACCESS_CONTROL } from '../../../constants/path';
import { USER_ROLE } from '../../../constants/accessControlPaths';
import AppForm, {
  AppSelectField,
  AppAutocompleteField,
  FormButtons,
} from '../../../components/form';
import { getChangedValues } from '../../../components/form/functions';
import { FormContainer, InputsContainer } from '../styled';
import {
  RBAC_ROLES_ENDPOINT,
  RBAC_USERS_ENDPOINT,
  RBAC_USER_ROLES_ENDPOINT,
} from '../../../constants/accessControl';
import { AlertStyled } from '../../settings/filesCollectionSettingsPage/styled';
import {
  useCreateRBACMutation,
  useLazyGetAllRBACQuery,
  useLazyGetOneRBACQuery,
  useUpdateRBACMutation,
} from '../../../redux/services/speciphicAsk';
import Loader from '../../../components/loader/loader.component';
import { filterByIds } from '../../../utils/functions';

const mainPageRoute = `${ACCESS_CONTROL}${USER_ROLE.endpoint}`;

const translationJSONPrefix = 'accessControlSection.userRole';

const defaultDataState = {
  roleId: '',
  users: [],
};

const formSchema = (t) => {
  const translationPrefix = (label) =>
    `${translationJSONPrefix}.form.fields.${label}.errorMessages`;

  return object({
    roleId: string().required(t(`${translationPrefix('roleId')}.required`)),
    users: array().required(t(`${translationPrefix('users')}.required`)),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  rolesData,
  rolesLoading,
  usersData,
  usersLoading,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.form.fields`;

  return (
    <Form>
      <InputsContainer>
        <AppSelectField
          name="roleId"
          label={t(`${translationPrefix}.roleId.label`)}
          placeholder={
            rolesLoading
              ? t(`${translationPrefix}.roleId.loading`)
              : t(`${translationPrefix}.roleId.placeholder`)
          }
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          menuData={rolesData.map((ro) => {
            return {
              label: `${ro?.name} (${ro?.policyCount} polic${
                ro?.policyCount > 1 ? 'ies' : 'y'
              })`,
              value: ro?.id,
            };
          })}
        />
      </InputsContainer>

      <InputsContainer>
        <AppAutocompleteField
          name="users"
          label={t(`${translationPrefix}.users.label`)}
          placeholder={
            usersLoading
              ? t(`${translationPrefix}.users.loading`)
              : t(`${translationPrefix}.users.placeholder`)
          }
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          options={usersData}
          getOptionLabel={(option) =>
            `${option?.firstName || '-'} ${option?.lastName || ''} (${
              option?.emailId || option?.sub
            })`
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={`${option?.firstName || '-'} ${
                  option?.lastName || ''
                } (${option?.emailId || option?.sub})`}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </InputsContainer>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const AccessControlAddUserRolePage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [initialFormData, setInitialFormData] = useState(defaultDataState);
  const [formData, setFormData] = useState(defaultDataState);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [entityId, setEntityId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [
    createRBAC,
    {
      data: createdData,
      error: createError,
      isError: isCreateError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateRBACMutation();

  const [
    updateRBAC,
    {
      data: updatedData,
      error: updateError,
      isError: isUpdateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateRBACMutation();

  const [
    getRBAC,
    {
      data: rbacData,
      isError: isDataError,
      isLoading: isDataLoading,
      isSuccess: isDataSuccess,
    },
  ] = useLazyGetOneRBACQuery();

  const [
    getRoles,
    {
      data: rolesDataDB,
      isError: isGetRolesError,
      isLoading: isGetRolesLoading,
      isSuccess: isGetRolesSuccess,
    },
  ] = useLazyGetAllRBACQuery();

  const [
    getUsers,
    {
      data: usersDataDB,
      isError: isUsersDataError,
      isLoading: isUsersDataLoading,
      isSuccess: isUsersDataSuccess,
    },
  ] = useLazyGetAllRBACQuery();

  useEffect(() => {
    const tempEntityId = searchParams.get('id');

    if (tempEntityId) {
      setIsEdit(true);
      getRBAC({ endpoint: RBAC_USER_ROLES_ENDPOINT, id: tempEntityId });
      setEntityId(tempEntityId);
    }

    getRoles({ endpoint: RBAC_ROLES_ENDPOINT });
    getUsers({ endpoint: RBAC_USERS_ENDPOINT });
  }, []);

  useEffect(() => {
    if (isDataSuccess && rbacData && usersDataDB) {
      const tempFormData = {
        roleId: rbacData?.id,
        users: filterByIds(rbacData?.users, usersDataDB),
      };

      setFormData(tempFormData);
      setInitialFormData(tempFormData);
    }
  }, [rbacData, usersDataDB]);

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateError || isUpdateError) {
      tempAlert.formUpdateError = true;
      tempAlert.formUpdateSuccess = false;
      setShowAlert(tempAlert);

      const tempErrorMessage =
        createError?.data?.message ||
        updateError?.data?.message ||
        t(`${translationJSONPrefix}.form.errorAlertLabel`);
      setErrorMessage(tempErrorMessage);
    }
  }, [isCreateError, isUpdateError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isCreateSuccess || isUpdateSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
      getRBAC({
        endpoint: RBAC_USER_ROLES_ENDPOINT,
        id: createdData?.id || updatedData?.id,
      });
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  return (
    <PageContainer drawer={SECTIONS.ACCESS_CONTROL}>
      <AddPageHeader
        routes={[
          {
            title: t(`${translationJSONPrefix}.mainPage.title`),
            endpoint: mainPageRoute,
          },
        ]}
        isEdit={isEdit}
      />

      <FormContainer>
        <AlertStyled
          onClose={closeAlert}
          severity={showAlert.formUpdateError ? 'error' : 'success'}
          style={{
            marginTop:
              showAlert.formUpdateError || showAlert.formUpdateSuccess
                ? 10
                : -60,
          }}
        >
          {showAlert.formUpdateSuccess &&
            (isEdit
              ? t(`${translationJSONPrefix}.form.updatedAlertLabel`)
              : t(`${translationJSONPrefix}.form.createdAlertLabel`))}
          {showAlert.formUpdateError && errorMessage}
        </AlertStyled>

        {isDataLoading ? (
          <Loader label={t(`${translationJSONPrefix}.form.loadingLabel`)} />
        ) : (
          <AppForm
            initialValues={formData}
            validationSchema={formSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
              let valuesToSend = isEdit
                ? getChangedValues(values, initialFormData)
                : { ...values };

              if (valuesToSend?.users?.length > 0) {
                valuesToSend.users = valuesToSend.users.map((u) => u.id);
              }

              if (isEdit)
                updateRBAC({
                  endpoint: RBAC_USER_ROLES_ENDPOINT,
                  id: entityId,
                  update: valuesToSend,
                });
              else
                createRBAC({
                  endpoint: RBAC_USER_ROLES_ENDPOINT,
                  body: valuesToSend,
                });

              setSubmitting(false);
            }}
            formRenderFunction={(formikState) =>
              formRenderFunction(
                formikState,
                isCreateLoading || isUpdateLoading,
                rolesDataDB || [],
                isGetRolesLoading,
                usersDataDB || [],
                isUsersDataLoading,
              )
            }
          />
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default AccessControlAddUserRolePage;
