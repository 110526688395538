export const HOMEPAGE = '/';
export const SITE_CONFIG = '/config';
export const NOT_FOUND = '*';
export const PROFILE = '/profile';
export const COOKIES = '/cookie-policy';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_USE = '/terms-of-use';
export const LEGAL = '/legal';
export const SERVICES = '/services';
export const SPECIPHIC_ASK = '/search';
export const SEARCH_HISTORY = '/search-history';
export const FILE_COLLECTIONS = '/file-collections';
export const DATA_SOURCES = '/data-sources';
export const ADD_DATA_SOURCE = `${DATA_SOURCES}/create-data-source`;
export const ANALYTICS = '/analytics';
export const READING_ASSISTANT = '/reading-assistant';
export const ADD_USER = '/users/add-user';
export const LOGOUT = '/logout';
export const APP_CONFIGURATION = '/app-configuration';
export const FILE_COLLECTIONS_FILE_UPLOAD = `${FILE_COLLECTIONS}/create-file-collection`;
export const FAQ = '/frequently-asked-questions';
export const FLEXDAY_WEBSITE = 'https://flexdaysolutions.com';
export const FLEXDAY_MAIL = 'mailto:info@weareflexday.com';
export const FLEXDAY_SUPPORT = 'mailto:support@weareflexday.com';
export const FLEXDAY_CONTACT = 'https://calendly.com/flexhima';
export const FLEXDAY_FACEBOOK = 'https://www.facebook.com/weareflexday';
export const FLEXDAY_INSTAGRAM = 'https://www.instagram.com/weareflexday';
export const FLEXDAY_LINKEDIN =
  'https://www.linkedin.com/company/flexdaysolutions';
export const POLICY_MAKER = 'https://policymaker.io/';
export const MARKET_INTELLIGENCE = '/market-intelligence';
export const REPORT_SOURCES = '/report-sources';
export const UNLISTED_COMPANY_REPORTS = `${MARKET_INTELLIGENCE}/market-intelligence-unlisted`;
export const REPORT_SUMMARY_TOPICS = `${MARKET_INTELLIGENCE}/report-topics`;
export const SEARCH_METADATA_PAGE = '/search-metadata';
export const ADD_METADATA_PAGE = 'add-metadata';
export const METADATA_NOT_ENABLED_ERROR_PAGE = 'metadata-not-enabled';
export const CONVERSATION = 'conversation';
export const CONVERSATION_HISTORY = 'conversation-history';
export const MANAGE_METADATA_PROPERTIES = 'metadata-properties';
export const SETTINGS = '/settings';
export const UNLISTED_COMPANY_SUMMARY_TOPIC = `${MARKET_INTELLIGENCE}/report-topics/market-intelligence-unlisted`;
export const ACCESS_CONTROL = '/access-control';
export const ADD_PAGE = {
  endpoint: '/add',
};
export const MI_REPORT_SOURCES = '/mi/report-sources';
export const MI_REPORT_TOPICS = '/mi/report-topics';
export const MI_REPORTS = '/mi/reports';
export const MI_CONFIGURATION = '/mi/configuration';
