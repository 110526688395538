/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React from 'react';
import { useContext, useEffect, useState, useCallback } from 'react';
import {
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Box,
  Tooltip,
  ButtonGroup,
  Typography,
} from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { FileCollectionContext } from '../../hooks/useContext';
import PageContainer from '../../components/pageContainer';
import AppShadowBox from '../../components/app.shadowbox';
import DataGridTable from './faqComponents/datagridTable';
import {
  useAddFaqToCollectionMutation,
  useGetFaqQuery,
  useLazyGetFaqQuery,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
} from '../../redux/services/speciphicAsk';
import DialogComponent from './faqComponents/dialog';
import { pushToast } from '../../redux/reducers/toasts.slice';
import DisplayTime from '../../components/timezone.component';
import UnauthorizedMessage from '../../components/unauthorized';
import { LoadingTypography } from './faq.page.styled';

const Faq = () => {
  const [faqList, setFaqList] = useState([]);
  const dispatch = useDispatch();
  const { getCollectionId, isFileCollectionChanged } = useContext(
    FileCollectionContext,
  );
  const [fileCollectionId, setFileCollectionId] = useState('');
  const [openFaqDialog, setOpenFaqDialog] = useState(false);
  const [openEditFaqDialog, setOpenEditFaqDialog] = useState(false);
  const [openDeleteFaqDialog, setOpenDeleteFaqDialog] = useState(false);
  const [faqText, setFaqText] = useState('');
  const [faqAddText, setAddFaqText] = useState('');
  const [faqId, setFaqId] = useState('');
  const location = useLocation();
  const { t } = useTranslation();

  const DATE_OPTIONS = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const [
    addFaqsToFileCollection,
    {
      data: AddFaqs,
      isSuccess: isAddFaqsSuccess,
      isLoading: isAddFaqsLoading,
      isError: isAddFaqsError,
    },
  ] = useAddFaqToCollectionMutation();

  const [
    getFaqs,
    {
      data: faqs = [],
      error: getFaqError,
      isLoading: isGetFaqLoading,
      isSuccess: isGetFaqSuccess,
      isFetching: isGetFaqFetching,
    },
  ] = useLazyGetFaqQuery();

  const [
    updateFaq,
    {
      data: updatedFaq,
      isSuccess: isAddUpdatedFaqSuccess,
      isLoading: isAddUpdatedFaqLoading,
      isError: isAddUpdatedFaqError,
    },
  ] = useUpdateFaqMutation();

  const [
    deleteFaq,
    {
      isSuccess: isDeletedFaqSuccess,
      isLoading: isDeletedFaqLoading,
      isError: isDeletedFaqError,
    },
  ] = useDeleteFaqMutation();

  useEffect(() => {
    getCollectionId() && setFileCollectionId(getCollectionId());
    getFaqs({
      collectionId: getCollectionId(),
    });
  }, []);

  useEffect(() => {
    getCollectionId() && setFileCollectionId(getCollectionId());
  }, [location]);

  useEffect(() => {
    if (faqs.length > 0) {
      setFaqList(faqs);
    }
  }, [faqs, fileCollectionId]);

  useEffect(() => {
    getCollectionId() && setFileCollectionId(getCollectionId());
    getFaqs({
      collectionId: getCollectionId(),
    });
  }, [isFileCollectionChanged]);

  const handleOpenEditFaqDialog = (e, row) => {
    e.stopPropagation();
    setOpenEditFaqDialog(true);
    setFaqText(row.query);
    setFaqId(row.id);
  };

  const handleOpenDeleteFaqDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteFaqDialog(true);
    setFaqId(row.id);
  };

  useEffect(() => {
    if (isDeletedFaqSuccess) {
      dispatch(
        pushToast({
          message: t('faqPage.alertMessage.delete'),
          severity: 'success',
        }),
      );
    }
    setOpenDeleteFaqDialog(false);
  }, [isDeletedFaqSuccess]);

  useEffect(() => {
    if (isAddUpdatedFaqSuccess) {
      dispatch(
        pushToast({
          message: t('faqPage.alertMessage.edit'),
          severity: 'success',
        }),
      );
    }
    setOpenEditFaqDialog(false);
  }, [isAddUpdatedFaqSuccess]);

  useEffect(() => {
    if (isAddFaqsSuccess) {
      dispatch(
        pushToast({
          message: t('faqPage.alertMessage.add'),
          severity: 'success',
        }),
      );
    }
    setOpenFaqDialog(false);
  }, [isAddFaqsSuccess]);

  const getCreatedDate = (props) => {
    return new Date(props.row.createdDate).toLocaleDateString(
      'en-US',
      DATE_OPTIONS,
    );
  };

  const getLastUpdatedDate = (props) => {
    return new Date(props.row.lastUpdatedDate).toLocaleDateString(
      'en-US',
      DATE_OPTIONS,
    );
  };

  const actionColumn = (props) => {
    return (
      <>
        <ButtonGroup>
          <Tooltip title="Edit" arrow>
            <LoadingButton
              variant="text"
              onClick={(e) => handleOpenEditFaqDialog(e, props.row)}
            >
              <span>
                <Edit />
              </span>
            </LoadingButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <LoadingButton
              variant="text"
              onClick={(e) => handleOpenDeleteFaqDialog(e, props.row)}
            >
              <span>
                <DeleteForever />
              </span>
            </LoadingButton>
          </Tooltip>
        </ButtonGroup>
      </>
    );
  };

  const columns = [
    {
      field: 'query',
      headerName: 'Frequently Asked Questions',
      minWidth: 400,
      flex: 1,
      sortable: false,
      editable: true,
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Updated By',
      width: 230,
      sortable: false,
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Updated At',
      width: 230,
      sortable: false,
      renderCell: (params) => <DisplayTime time={params.row.lastUpdatedDate} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  const handleOpenFaqDialog = () => {
    setOpenFaqDialog(true);
  };
  const handleCloseFaqDialog = () => {
    setOpenFaqDialog(false);
    setOpenEditFaqDialog(false);
    setOpenDeleteFaqDialog(false);
  };
  const handleAddFaqText = (event) => {
    setAddFaqText(event.target.value);
  };

  const handleEditFaq = (event) => {
    setFaqText(event.target.value);
  };

  const handleAddFaq = () => {
    addFaqsToFileCollection({
      collectionId: fileCollectionId,
      query: faqAddText,
    });
  };

  const handleUpdateEdit = () => {
    updateFaq({
      collectionId: fileCollectionId,
      id: faqId,
      query: faqText,
    });
  };

  const handleDeleteFaq = () => {
    deleteFaq({
      collectionId: fileCollectionId,
      id: faqId,
    });
  };

  if (getFaqError?.status === 403)
    return (
      <UnauthorizedMessage
        description={t('faqPage.unauthorizedMessages.list')}
      />
    );

  return (
    <PageContainer>
      <AppShadowBox>
        {isGetFaqFetching && (
          <>
            <LinearProgress />
            <LoadingTypography variant="h5">
              {t('faqPage.loading')}
            </LoadingTypography>
          </>
        )}

        {isGetFaqSuccess && (
          <DataGridTable
            column={columns}
            row={faqList}
            rowId="id"
            rowHeightAuto={true}
            buttonText={t('faqPage.add')}
            openDialog={handleOpenFaqDialog}
          />
        )}
        <DialogComponent
          isEditDialogOpen={openEditFaqDialog}
          isDeleteDialogOpen={openDeleteFaqDialog}
          isAddDialogOpen={openFaqDialog}
          handleCloseFaqDialog={handleCloseFaqDialog}
          faqText={faqText}
          faqId={faqId}
          handleEditFaq={handleEditFaq}
          handleUpdateFaq={handleUpdateEdit}
          handleAddFaqText={handleAddFaqText}
          handleAddFaq={handleAddFaq}
          handleDeleteFaq={handleDeleteFaq}
          isAddUpdatedFaqLoading={isAddUpdatedFaqLoading}
          isDeletedFaqLoading={isDeletedFaqLoading}
          isAddFaqsLoading={isAddFaqsLoading}
        />
      </AppShadowBox>
    </PageContainer>
  );
};

Faq.propTypes = {
  row: PropTypes.object,
  faqList: PropTypes.array,
};

export default Faq;
