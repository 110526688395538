import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer } from 'recharts';
import FormControl from '@mui/material/FormControl';
import { Box, Select } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {
  ChartContainer,
  ChartHeader,
  ChartSelectors,
  ChartSelector,
  ChartTitle,
  LoaderContainer,
  ChartMainContainer,
  LegendsContainer,
  LegendStyled,
  LegendTitle,
  LegendCount,
  PieBox,
} from './chartBox.styled';
import colors from '../../themes/colors';

const ChartBox = ({
  isLoading,
  chartTitle,
  titleExtraContent = <></>,
  enableSelector,
  selectors,
  children,
  pie = false,
  pieLegends = [],
  fcPieCellHover,
  setPieCellHover = () => {},
  style,
  isLargeScreen = true,
}) => {
  if (!isLargeScreen) {
    pie = false;
  }
  return (
    <ChartContainer style={style}>
      <ChartHeader>
        <Box>
          <ChartTitle>{chartTitle}</ChartTitle>
        </Box>

        <ChartSelectors>
          {enableSelector &&
            selectors.map((selector, index) => (
              <ChartSelector key={index}>
                <FormControl fullWidth>
                  <Select value={selector.value} onChange={selector.onChange}>
                    {selector.menuItems}
                  </Select>
                </FormControl>
              </ChartSelector>
            ))}
        </ChartSelectors>
      </ChartHeader>

      {isLoading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <ChartMainContainer>
          <PieBox pie={pie}>
            <ResponsiveContainer position="relative" width="100%">
              {children}
            </ResponsiveContainer>
          </PieBox>
          {pie && isLargeScreen && (
            <LegendsContainer>
              {pieLegends
                .filter((pl) => pl.title !== 'Others')
                .map((pl, i) => (
                  <Box key={i}>
                    <LegendStyled
                      hoverIndex={i}
                      gradient={colors}
                      fcPieCellHover={fcPieCellHover}
                      onMouseEnter={() => setPieCellHover(i)}
                      onMouseLeave={() => setPieCellHover(null)}
                    >
                      <LegendTitle>{pl?.title}</LegendTitle>
                      <LegendCount>{pl?.count}</LegendCount>
                    </LegendStyled>
                    <Box
                      style={{ height: 5 }}
                      onMouseEnter={() => setPieCellHover(i)}
                      onMouseLeave={() => setPieCellHover(null)}
                    />
                  </Box>
                ))}
            </LegendsContainer>
          )}
        </ChartMainContainer>
      )}
    </ChartContainer>
  );
};

ChartBox.propTypes = {
  isLoading: PropTypes.bool,
  chartTitle: PropTypes.string,
  titleExtraContent: PropTypes.node,
  enableSelector: PropTypes.bool,
  selectors: PropTypes.array,
  pie: PropTypes.bool,
  pieLegends: PropTypes.array,
  fcPieCellHover: PropTypes.array,
  setPieCellHover: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node,
  isLargeScreen: PropTypes.bool,
};

export default ChartBox;
