/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getUserFromSession } from '../../user';
import { getPdfEPFactory } from './endpoints';

export const usePdfFetch = async (pdfUrl) => {
  try {
    const user = getUserFromSession();
    const token = user?.access_token;
    const response = await fetch(pdfUrl, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (e) {
    console.error(e);
  }
};

export const speciphicAskApi = createApi({
  reducerPath: 'speciphicAskApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: (headers) => {
      const user = getUserFromSession();
      const token = user?.access_token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPdf: getPdfEPFactory(builder),
  }),
});

export const { useGetPdfQuery, useLazyGetPdfQuery } = speciphicAskApi;
